import React from 'react';
import { TextField, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CostEstimationAddress = ({ estimationData, handleInputChange }) => {
  const { address } = estimationData;
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="address.street"
          label={t('costEstimationDetailPage.address.street')}
          variant="outlined"
          value={address.street}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          fullWidth
          name="address.zipCode"
          label={t('costEstimationDetailPage.address.zipCode')}
          variant="outlined"
          value={address.zipCode}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          fullWidth
          name="address.city"
          label={t('costEstimationDetailPage.address.city')}
          variant="outlined"
          value={address.city}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="address.country"
          label={t('costEstimationDetailPage.address.country')}
          variant="outlined"
          value={address.country}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="address.email"
          label={t('costEstimationDetailPage.address.email')}
          variant="outlined"
          value={address?.email?.length > 0 ? address.email : ''}
          onChange={handleInputChange}
        />
      </Grid>
    </Grid>
  );
};

export default CostEstimationAddress;
