import React, { useState, useEffect } from 'react';
import {
  Paper,
  Button,
  Chip,
  Box,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import PaymentIcon from '@mui/icons-material/Payment';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';
import { fetchAllInvoices } from '../services/InvoiceService';
import { createPayment } from '../services/PaymentsService';
import { useAuth } from '../context/AuthContext';
import { usePreferences } from '../context/UserPreferencesContext';
import GenericCardView from '../components/GenericCardView';
import GenericTableView from '../components/GenericTableView';
import SnackbarAlert from '../components/SnackbarAlert';
import ConfirmationDialog from '../components/ConfirmationDialog';

const InvoicesListPage = () => {
  const { t } = useTranslation();
  const { apiKey, organizationId, token, userId } = useAuth();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [viewMode, setViewMode] = useState(isMobile ? 'card' : 'table');
  const { devicePreferences, updateDevicePreferences } = usePreferences();

  const handlePayment = (invoiceId) => {
    setSelectedInvoiceId(invoiceId);
    setConfirmDialogOpen(true);
  };

  const handlePaymentConfirmation = async () => {
    try {
      const invoice = invoices.find(inv => inv.id === selectedInvoiceId);
      const paymentData = {
        amount: invoice.totalAmount,
        date: new Date().toISOString(),
        payerName: invoice.customerName,
        payerContact: invoice?.address?.email || '',
        payerReference: selectedInvoiceId,
        paymentMethod: 'cash', // Default or chosen by user
        referenceId: selectedInvoiceId,
        referenceType: 'Invoice',
        payerReference: selectedInvoiceId,
        notes: `Payment for Invoice ${selectedInvoiceId}`,
      };
      await createPayment(paymentData, apiKey, organizationId, token);
      setSnackbar({ open: true, message: 'Payment successfully processed.', severity: 'success' });
      setInvoices(prevInvoices => prevInvoices.map(inv =>
        inv.id === selectedInvoiceId ? { ...inv, isPaid: true } : inv
      ));
    } catch (error) {
      setSnackbar({ open: true, message: 'Error processing payment.', severity: 'error' });
    } finally {
      setConfirmDialogOpen(false);
    }
  };

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const invoiceData = await fetchAllInvoices(apiKey, organizationId, token, userId);
        setInvoices(invoiceData);
      } catch (error) {
        console.error("Error fetching invoice data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoices();
  }, []);

  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);

  const navigateToDetail = (invoiceId) => {
    navigate(`/sales-process/invoices/detail/${invoiceId}`);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const renderPaymentStatus = (invoice) => {
    if (invoice.cancel && invoice.cancel === true) {
      return <Chip icon={<WarningAmberIcon />} label={t("invoicesListPage.cancelled")} color="secondary" />;
    }
    let returnChip = null;
    if (invoice.isPaid)
      returnChip = <Chip icon={<CheckCircleIcon />} label={t("invoicesListPage.paid")} color="success" />;
    else if (invoice.alreadyReminded)
      returnChip = <Chip icon={<WarningAmberIcon />} label={t("invoicesListPage.reminded")} color="warning" />;
    else
      returnChip = <Chip icon={<CancelIcon />} label={t("invoicesListPage.unpaid")} color="error" />;
    return returnChip;
  };

  const toggleViewMode = () => {
    updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
  };

  const actions = [
    { icon: <PaymentIcon />, handler: handlePayment, label: 'Pay' }
  ];

  const transformData = () => {
    return {
      headers: [
        { key: 'invoiceNumber', label: t("invoicesListPage.invoiceNumber"), display: { value: true, chipColor: false } },
        { key: 'customerName', label: t("invoicesListPage.customerName"), display: { value: true, chipColor: false } },
        { key: 'issueDate', label: t("invoicesListPage.issueDate"), display: { value: true, chipColor: false } },
        { key: 'totalAmount', label: t("invoicesListPage.totalAmount"), display: { value: true, chipColor: false } },
        { key: 'paymentStatus', label: t("invoicesListPage.paymentStatus"), display: { value: true, chipColor: false } }
      ],
      rows: invoices.map(invoice => ({
        id: invoice.id,
        invoiceNumber: { value: invoice.invoiceNumber, icon: null },
        customerName: { value: invoice.customerName, icon: null },
        issueDate: { value: new Date(invoice.issueDate).toLocaleDateString(), icon: null },
        totalAmount: { value: invoice.totalAmount, icon: null },
        paymentStatus: { value: renderPaymentStatus(invoice), icon: null },
        actions: [
          {
            icon: <PaymentIcon />,
            handler: invoice.isPaid ? null : () => handlePayment(invoice.id),
            disabled: invoice.isPaid,
            label: t("invoicesListPage.pay")
          }
        ]
      }))
    };
  };

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} component={Link} to="/invoices/detail/new">
          {t("invoicesListPage.addNewInvoice")}
        </Button>
        <IconButton onClick={toggleViewMode} color="primary">
          {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
        </IconButton>
      </Box>
      {loading ? (
        <div style={{ width: '100%', marginTop: 20 }}>
          <LinearProgress />
        </div>
      ) : viewMode === 'table' ? (
        <GenericTableView structure={transformData()} onClick={navigateToDetail} />
      ) : (
        <GenericCardView structure={transformData()} onClick={navigateToDetail} />
      )}

      <SnackbarAlert
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />
      <ConfirmationDialog
        isOpen={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handlePaymentConfirmation}
        text={t("invoicesListPage.confirmPayment")}
      />
    </Paper>
  );
};

export default InvoicesListPage;
