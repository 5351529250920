import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useMapDocumentToSalesOrder = (state, initialSalesOrderData, setSalesOrderData) => {

    useEffect(() => {
        if (state?.fromDocument) {
            const { sourceDocumentType, ...fromDocument } = state.fromDocument;

            let mappedSalesOrderData = { ...initialSalesOrderData };

            switch (sourceDocumentType) {
                case 'costEstimation':
                    mappedSalesOrderData = {
                        ...mappedSalesOrderData,
                        customerName: fromDocument.customerName,
                        customerId: fromDocument.customerId,
                        companyName: fromDocument.companyName,
                        companyBRN: fromDocument.companyBRN,
                        recipientType: fromDocument.recipientType,
                        orderDate: fromDocument.issueDate || initialSalesOrderData.orderDate,
                        deliveryDate: fromDocument.dueDate || initialSalesOrderData.deliveryDate,
                        totalAmount: fromDocument.totalAmount,
                        totalNetAmount: fromDocument.totalNetAmount,
                        totalTaxAmount: fromDocument.totalTaxAmount,
                        organizationId: fromDocument.organizationId,
                        address: { ...fromDocument.address },
                        rows: fromDocument.rows.map(row => ({
                            description: row.description,
                            itemId: row.itemId,
                            quantity: row.quantity,
                            bruttoPrice: row.bruttoPrice,
                            netPrice: row.netPrice,
                            discountPercentage: row.discountPercentage,
                            discountAmount: row.discountAmount,
                            taxRate: row.taxRate,
                            rowTotal: row.rowTotal,
                            type: row.type,
                        })),
                        references: [...fromDocument.references],
                    };
                    break;

                default:
                    console.warn(`Unsupported document type: ${sourceDocumentType}`);
                    break;
            }

            setSalesOrderData(mappedSalesOrderData);
        }
    }, [state]);

};

export default useMapDocumentToSalesOrder;
