// src/components/Bars/TopBar.js

import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useDrawerState } from '../../context/DrawerContext'; // Adjust the path as necessary
import { usePermissions } from '../../context/PermissionsContext';

const TopBar = () => {


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { entitlements } = usePermissions();

    const topBarHeight = isMobile ? '56px' : '64px';
    const { toggleDrawer } = useDrawerState();

    // Determine the entitlement type
    const entitlement = entitlements.find(entitlement => entitlement.isActive) || {};
    const isEquistab = entitlement.internalName?.includes('equistab');
    const isErp = entitlement.internalName?.includes('erp');

    return (
        <AppBar position="fixed" elevation={1} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, height: topBarHeight, bgcolor: 'background.default', color: 'text.primary' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={toggleDrawer}
                    className="topbar-icon-button"
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                    {isEquistab ? 'EquiStab' : isErp ? 'Zenith' : 'Home'}
                </Typography>
                {/* Add more elements here as needed */}
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
