import React, { useState } from 'react';
import { TextField, Button, Box, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SetPassword = ({ onNext }) => {
    const { t } = useTranslation();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmError, setConfirmError] = useState(false);  // State to handle confirmation password error

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (e.target.value !== password) {
            setConfirmError(true);
        } else {
            setConfirmError(false);
        }
    }

    const handleSubmit = () => {
        if (!password) {
            alert(t('SetPassword.alerts.enterPassword'));
        } else if (password !== confirmPassword) {
            setConfirmError(true);
        } else {
            onNext(password);
        }
    }

    return (
        <Box>
            <Box mb={2}>
                <TextField 
                    type="password"
                    label={t('SetPassword.labels.setPassword')}
                    variant="outlined"
                    fullWidth
                    value={password}
                    onChange={handlePasswordChange}
                    error={confirmError}  // Display error if the confirmation password is wrong
                    required
                />
            </Box>

            <Box mb={2}>
                <TextField 
                    type="password"
                    label={t('SetPassword.labels.confirmPassword')}
                    variant="outlined"
                    fullWidth
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    error={confirmError}  // Display error if passwords do not match
                    required
                />
                {confirmError && 
                <FormHelperText error>
                    {t('SetPassword.errors.mismatch')}
                </FormHelperText>}
            </Box>

            <Button variant="contained" color="primary" onClick={handleSubmit}>
                {t('SetPassword.buttons.next')}
            </Button>
        </Box>
    );
};

export default SetPassword;
