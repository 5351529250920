import { useEffect } from 'react';

const useMapDocumentToInvoice = (state, initialInvoiceData, setInvoiceData) => {
    useEffect(() => {
        if (state?.fromDocument) {
            const { sourceDocumentType, ...fromDocument } = state.fromDocument;

            let mappedInvoiceData = { ...initialInvoiceData };

            switch (sourceDocumentType) {
                case 'deliveryNote':
                    mappedInvoiceData = {
                        ...mappedInvoiceData,
                        customerName: fromDocument.customerName,
                        ownerId: fromDocument.customerId,
                        companyName: fromDocument.companyName,
                        companyBRN: fromDocument.companyBRN,
                        recipientType: fromDocument.recipientType,
                        issueDate: fromDocument.orderDate || initialInvoiceData.issueDate,
                        totalAmount: fromDocument.totalAmount,
                        totalNetAmount: fromDocument.totalNetAmount,
                        taxAmount: fromDocument.taxAmount,
                        organizationId: fromDocument.organizationId,
                        address: { ...fromDocument.address },
                        rows: fromDocument.rows.map(row => ({
                            description: row.description,
                            itemId: row.itemId,
                            quantity: row.quantity,
                            bruttoPrice: row.bruttoPrice,
                            netPrice: row.netPrice,
                            discountPercentage: row.discountPercentage,
                            discountAmount: row.discountAmount,
                            taxRate: row.taxRate,
                            rowTotal: row.rowTotal,
                            type: row.type,
                        })),
                        references: [...fromDocument.references],
                    };
                    break;
                case 'costEstimation':
                    mappedInvoiceData = {
                        ...mappedInvoiceData,
                        customerName: fromDocument.customerName,
                        ownerId: fromDocument.customerId,
                        companyName: fromDocument.companyName,
                        companyBRN: fromDocument.companyBRN,
                        recipientType: fromDocument.recipientType,
                        issueDate: fromDocument.issueDate || initialInvoiceData.issueDate,
                        totalAmount: fromDocument.totalAmount,
                        totalNetAmount: fromDocument.totalNetAmount,
                        taxAmount: fromDocument.taxAmount,
                        organizationId: fromDocument.organizationId,
                        address: { ...fromDocument.address },
                        rows: fromDocument.rows.map(row => ({
                            description: row.description,
                            itemId: row.itemId,
                            quantity: row.quantity,
                            bruttoPrice: row.bruttoPrice,
                            netPrice: row.netPrice,
                            discountPercentage: row.discountPercentage,
                            discountAmount: row.discountAmount,
                            taxRate: row.taxRate,
                            rowTotal: row.rowTotal,
                            type: row.type,
                        })),
                        references: [...fromDocument.references],
                    };
                    break;
                case 'salesOrder':
                    mappedInvoiceData = {
                        ...mappedInvoiceData,
                        customerName: fromDocument.customerName,
                        ownerId: fromDocument.customerId,
                        companyName: fromDocument.companyName,
                        companyBRN: fromDocument.companyBRN,
                        recipientType: fromDocument.recipientType,
                        issueDate: fromDocument.orderDate || initialInvoiceData.issueDate,
                        totalAmount: fromDocument.totalAmount,
                        totalNetAmount: fromDocument.totalNetAmount,
                        taxAmount: fromDocument.taxAmount,
                        organizationId: fromDocument.organizationId,
                        address: { ...fromDocument.address },
                        rows: fromDocument.rows.map(row => ({
                            description: row.description,
                            itemId: row.itemId,
                            quantity: row.quantity,
                            bruttoPrice: row.bruttoPrice,
                            netPrice: row.netPrice,
                            discountPercentage: row.discountPercentage,
                            discountAmount: row.discountAmount,
                            taxRate: row.taxRate,
                            rowTotal: row.rowTotal,
                            type: row.type,
                        })),
                        references: [...fromDocument.references],
                    };
                    break;
                // Add cases for other document types as needed.
                default:
                    console.warn(`Unsupported document type: ${sourceDocumentType}`);
                    break;
            }

            setInvoiceData(mappedInvoiceData);
        }
    }, [state]);
}

export default useMapDocumentToInvoice;