// src/pages/DeliveryPlanningPage.js
import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Typography, Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteOverviewPage from './DeliveryPlanning_Routes/RouteOverviewPage';
import DeliveryPlanOverviewPage from './DeliveryPlanning/DeliveryPlanOverviewPage';
import { useTranslation } from 'react-i18next';

const DeliveryPlanningPage = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const { t } = useTranslation();

    useEffect(() => {
        const tabQuery = query.get('tab');
        const tabValue = tabQuery !== null ? parseInt(tabQuery) : 0;
        setTabIndex(tabValue);
    }, [location, query]);

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
        const newQuery = new URLSearchParams(location.search);
        newQuery.set('tab', newValue);
        navigate(`${location.pathname}?${newQuery.toString()}`, { replace: true });
    };

    return (
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
            <Box>
                <Typography variant="h4" gutterBottom>
                    {t('DeliveryPlanningPage.title')}
                </Typography>
                <Tabs value={tabIndex} onChange={handleChange} aria-label={t('DeliveryPlanningPage.tabsAriaLabel')}>
                    <Tab label={t('DeliveryPlanningPage.deliveryPlans')} />
                    <Tab label={t('DeliveryPlanningPage.routes')} />
                </Tabs>
                {tabIndex === 0 && <DeliveryPlanOverviewPage />}
                {tabIndex === 1 && <RouteOverviewPage />}
            </Box>
        </Paper>
    );
};

export default DeliveryPlanningPage;
