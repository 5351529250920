// src/pages/dewormingDetailsPage/useDeworming.js

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatISO } from 'date-fns'; // Make sure to install date-fns if you haven't
import { useSnackbar } from './useSnackbar'; // Import the useSnackbar hook
import { getAllHorses } from '../../services/horseService';
import { getDeworming, createDeworming, updateDeworming, deleteDeworming } from '../../services/dewormingService';
import { useAuth } from '../../context/AuthContext';

export const useDeworming = (dewormingId) => {
    const [loading, setLoading] = useState(false);
    const { apiKey, organizationId, token, userId } = useAuth();
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [horseIds, setHorseIds] = useState([]);
    const isEditMode = dewormingId !== 'new';
    const { openSnackbar, SnackbarComponent } = useSnackbar();
    const initialDewormingData = {
        id: '',
        date: formatISO(new Date()), // Set default date to today
        nextDueDate: formatISO(new Date()), // Set default date to today
        rows: [], // Rows to store individual deworming items
    };
    const [dewormingData, setDewormingData] = useState(initialDewormingData);

    useEffect(() => {
        console.log('useEffect editmode', isEditMode);
        if (isEditMode) {
            setLoading(true);
            getDeworming(dewormingId, apiKey, organizationId, token)
                .then((deworming) => {
                    setDewormingData(deworming);
                })
                .catch((error) => {
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        // Fetch horse ids from the server
        setLoading(true);
        getAllHorses(apiKey, organizationId, token)
            .then((horses) => {
                setHorseIds(horses);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [dewormingId, isEditMode]);

    const addRow = () => {
        setDewormingData({
            ...dewormingData,
            rows: [
                ...dewormingData.rows,
                {
                    id: '',
                    dewormingId: '',
                    horseId: '',
                    additionalInfo: '',
                    dewormer: 'stableManager',
                    isPaid: false,
                    dewormerOption: '',
                },
            ],
        });
    }

    const handleRowChange = (index, name, value) => {
        const newRows = dewormingData.rows.map((row, i) => {
            if (i === index) {
                return { ...row, [name]: value };
            }
            return row;
        });
        setDewormingData({ ...dewormingData, rows: newRows });
    }


    const addAllHorses = () => {
        setDewormingData({
            ...dewormingData,
            rows: horseIds.map((horse) => ({
                id: '',
                dewormingId: '',
                horseId: horse.id,
                additionalInfo: '',
                dewormer: 'stableManager',
                isPaid: false,
                dewormerOption: '',
            })),
        });
    }

    const removeRow = (index) => {
        const newRows = dewormingData.rows.filter((_, i) => i !== index);
        setDewormingData({ ...dewormingData, rows: newRows });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDewormingData({ ...dewormingData, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (isEditMode) {
                await updateDeworming(dewormingId, dewormingData, apiKey, organizationId, token);
                openSnackbar('Deworming updated successfully', 'success');
            } else {
                await createDeworming(dewormingData, apiKey, organizationId, token);
                openSnackbar('Deworming created successfully', 'success');
            }
            navigate('/dewormings');
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    const removeDeworming = async (id) => {
        setLoading(true);
        try {
            await deleteDeworming(id, apiKey, organizationId, token);
            openSnackbar('Deworming deleted successfully', 'success');
            navigate('/dewormings');
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }


    return {
        dewormingData,
        setDewormingData,
        handleInputChange,
        handleSubmit,
        loading,
        error,
        addRow,
        addAllHorses,
        removeRow,
        handleRowChange,
        horseIds,
        isEditMode,
        removeDeworming,
    };
}

