// pages/GuidingMachinePage/TodaysBookings.js

import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Paper,
    Typography,
    LinearProgress,
    IconButton,
} from '@mui/material';
import { Snackbar, Alert } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import {
    fetchBookingsPerDate,
    createBooking,
    deleteBooking,
} from '../../services/guidingMachineService';
import {
    getOwnerByEmail,
    getAllOwners,
} from '../../services/ownerService';
import {
    getAllHorses,
    getHorsesByOwnerID,
} from '../../services/horseService';
import { fetchMachineSettings } from '../../services/MachineSettingsService';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete'; // Import DeleteIcon
import { useDateLocale } from '../../hooks/useDateLocale'; // Your custom hook
import AddIcon from '@mui/icons-material/Add';
import MachineBookingModal from '../../components/MachineBookingModal';
import GenericTableView from '../../components/GenericTableView'; // Assuming you have this component
import GenericCardView from '../../components/GenericCardView'; // Assuming you have this component
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useTheme, useMediaQuery } from '@mui/material';
import { usePreferences } from '../../context/UserPreferencesContext';

const TodaysBookings = () => {
    const { t } = useTranslation(); // Hook for translation
    const { apiKey, userId, userEmail, userType, ownerId, organizationId, token: jwtToken } = useAuth();
    const { devicePreferences, updateDevicePreferences } = usePreferences();
    const dateLocale = useDateLocale();

    const [bookings, setBookings] = useState([]);
    const [horseData, setHorseData] = useState([]);
    const [ownerData, setOwnerData] = useState([]);
    const [deviceData, setDeviceData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [filterDate, setFilterDate] = useState(new Date());
    const [viewMode, setViewMode] = useState('table');

    const defaultStructure = {
        headers: [
            {
                key: 'start_time',
                label: t('GuidingMachinePage.todaysBookings.startTime'),
                display: { value: true, chipColor: false },
            },
            {
                key: 'horse',
                label: t('GuidingMachinePage.todaysBookings.horseText'),
                display: { value: true, chipColor: false },
            },
            {
                key: 'owner',
                label: t('GuidingMachinePage.todaysBookings.owner'),
                display: { value: true, chipColor: false },
            },
            {
                key: 'managementType',
                label: t('GuidingMachinePage.todaysBookings.managementType'),
                display: { value: true, chipColor: false },
            },
            {
                key: 'comment',
                label: t('GuidingMachinePage.todaysBookings.commentCard'),
                display: { value: true, chipColor: false },
            },
            {
                key: 'deviceName',
                label: t('GuidingMachinePage.todaysBookings.deviceName'),
                display: { value: true, chipColor: false },
            },
        ],
        rows: [],
    };
    const [structure, setStructure] = useState(defaultStructure);

    // Effect for fetching bookings, owners, horses, and devices
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch all required data concurrently
                const [owners, horses, devices, bookingsResponse] = await Promise.all([
                    getAllOwners(apiKey, organizationId, jwtToken),
                    userType === 'customer'
                        ? getHorsesByOwnerID(ownerId, apiKey, organizationId, jwtToken)
                        : getAllHorses(apiKey, organizationId, jwtToken),
                    fetchMachineSettings(apiKey, organizationId, jwtToken),
                    fetchBookingsPerDate(userId, filterDate.toISOString(), apiKey, organizationId, jwtToken),
                ]);

                setOwnerData(owners);
                setHorseData(horses);
                setDeviceData(devices);
                setBookings(bookingsResponse);

                // Construct table/card structure
                setStructure({
                    ...defaultStructure,
                    rows: bookingsResponse.map((row) => ({
                        id: row.id,
                        start_time: {
                            value: new Date(row.start_time).toLocaleTimeString(dateLocale),
                            icon: null,
                        },
                        horse: {
                            value: horses.find((horse) => horse.id === row.horse_id)?.name ?? '',
                            icon: null,
                        },
                        owner: {
                            value: owners.find((owner) => owner.id === row.owner_id)
                                ? `${owners.find((owner) => owner.id === row.owner_id).firstname} ${owners.find((owner) => owner.id === row.owner_id).surname
                                }`
                                : '',
                            icon: null,
                        },
                        managementType: {
                            value: row.whom,
                            icon: null,
                        },
                        comment: {
                            value: row.description,
                            icon: null,
                        },
                        deviceName: {
                            value:
                                devices.find((device) => device.id === row.device_id)?.machineType ?? '',
                            icon: null,
                        },
                    })),
                });
            } catch (error) {
                console.error('Error fetching data:', error);
                setErrorMessage(error.message || 'An error occurred while fetching data.');
                setIsErrorOpen(true);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiKey, organizationId, jwtToken, userId, filterDate, userType, ownerId, dateLocale]);

    // Effect to update view mode based on preferences
    useEffect(() => {
        setViewMode(devicePreferences.viewMode);
    }, [devicePreferences.viewMode]);

    const handleDelete = async (bookingId) => {
        setLoading(true);
        try {
            await deleteBooking(bookingId, apiKey, organizationId, jwtToken);
            setSuccessMessage(t('GuidingMachinePage.deleteBookingSuccess'));
            setIsSuccessOpen(true);
            // Refresh bookings after deletion
            const updatedBookings = bookings.filter((booking) => booking.id !== bookingId);
            setBookings(updatedBookings);
            setStructure((prevStructure) => ({
                ...prevStructure,
                rows: prevStructure.rows.filter((row) => row.id !== bookingId),
            }));
        } catch (error) {
            console.error('Error deleting booking:', error);
            setErrorMessage(error.message || 'An error occurred while deleting the booking.');
            setIsErrorOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        // Optionally, refetch bookings after closing the modal
        // You can trigger the useEffect by changing a dependency if needed
    };

    const toggleViewMode = () => {
        const newViewMode = viewMode === 'table' ? 'card' : 'table';
        updateDevicePreferences('viewMode', newViewMode);
    };

    const handleRowClick = (bookingId) => {
        // Implement navigation or other actions on row click if needed
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
            <Paper elevation={3} sx={{ mt: 4, p: 2 }}>
                <Box>
                    <Typography variant="h4" gutterBottom>
                        {t('GuidingMachinePage.todaysBookings.heading')}
                    </Typography>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ mt: 4, mb: 2 }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenModal}
                            startIcon={<AddIcon />}
                            disabled={loading}
                        >
                            {t('GuidingMachinePage.bookNewTimeButton')}
                        </Button>

                        <IconButton onClick={toggleViewMode} color="primary" disabled={loading}>
                            {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
                        </IconButton>
                    </Box>
                    {loading ? (
                        <Box sx={{ width: '100%' }}>
                            <Typography variant="h6" gutterBottom>
                                {t('GuidingMachinePage.Bookings.waitingText')}
                            </Typography>
                            <LinearProgress />
                        </Box>
                    ) : viewMode === 'table' ? (
                        <GenericTableView
                            structure={structure}
                            onDelete={handleDelete}
                            onClick={handleRowClick}
                            loading={loading}
                        />
                    ) : (
                        <GenericCardView
                            structure={structure}
                            onDelete={handleDelete}
                            onClick={handleRowClick}
                            loading={loading}
                        />
                    )}
                </Box>

                {/* Error Snackbar */}
                <Snackbar
                    open={isErrorOpen}
                    autoHideDuration={6000}
                    onClose={() => setIsErrorOpen(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert
                        onClose={() => setIsErrorOpen(false)}
                        severity="error"
                        sx={{ width: '100%' }}
                    >
                        {errorMessage}
                    </Alert>
                </Snackbar>

                {/* Success Snackbar */}
                <Snackbar
                    open={isSuccessOpen}
                    autoHideDuration={6000}
                    onClose={() => setIsSuccessOpen(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert
                        onClose={() => setIsSuccessOpen(false)}
                        severity="success"
                        sx={{ width: '100%' }}
                    >
                        {successMessage}
                    </Alert>
                </Snackbar>

                {/* Booking Modal */}
                <MachineBookingModal
                    isOpen={showModal}
                    onClose={handleCloseModal}
                    devicesProps={deviceData}
                    horsesProp={horseData}
                />
            </Paper>
        </LocalizationProvider>
    );
};

export default TodaysBookings;
