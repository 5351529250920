// src/services/importService.js

import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig.js';

export async function importData(data, importType, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.post(`/api/dataImport?importType=${importType}`, data);
    return response.data;
}
