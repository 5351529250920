// src/services/archivingService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/archiving';

export async function archiveHorseAndOwnerByHorseId(horseId, organizationId, apiKey, jwtToken) {
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);
    setApiKeyHeader(apiKey);

    const response = await axiosInstance.put(`${API_BASE_URL}/ownerAndHorse?horseId=${horseId}&archiveAllHorses=true`);
    return response.data;
}

export async function archiveHorseAndOwnerByOwnerId(ownerId, organizationId, apiKey, jwtToken) {
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);
    setApiKeyHeader(apiKey);

    const response = await axiosInstance.put(`${API_BASE_URL}/ownerAndHorse?ownerId=${ownerId}`);
    return response.data;
}

export default {
    archiveHorseAndOwnerByHorseId,
    archiveHorseAndOwnerByOwnerId
};