import React from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const Last12MonthsLineChart = ({ titleKey, data, expenseKey, incomeKey }) => {
    const { t } = useTranslation();
    return (
        <Grid item xs={12} md={12}>
            <Card>
                <CardContent>
                    <Typography variant="h6">
                        {t(titleKey)}
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart width={1000} height={300} data={data}>
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey={expenseKey} stroke="#8884d8" />
                            <Line type="monotone" dataKey={incomeKey} stroke="#82ca9d" />
                        </LineChart>
                    </ResponsiveContainer>
                </CardContent>
            </Card>
        </Grid>
    );
};

Last12MonthsLineChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    expenseKey: PropTypes.string.isRequired,
    incomeKey: PropTypes.string.isRequired
};

export default Last12MonthsLineChart;
