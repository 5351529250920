// src/services/DocumentSettingsService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/document-settings';

export async function fetchDocumentSettings(apiKey, organizationId, jwtToken, documentType) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}?documentType=${documentType}`);
  return response.data;
}

export async function updateDocumentSettings(settings, apiKey, organizationId, jwtToken, documentType) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(`${API_BASE_URL}?documentType=${documentType}`, settings);
  return response.data;
}

export default {
  fetchDocumentSettings,
  updateDocumentSettings,
};
