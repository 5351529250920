// src/components/OrgTabs/OrganizationInfo.js
import React, { useState, useEffect } from 'react';
import { TextField, Button, Paper, Typography, Divider, LinearProgress, Box } from '@mui/material';
import { updateOrganizationInfo, fetchOrganizationInfo } from '../../services/organizationService';
import { deleteIcon, fetchIcon, uploadOrUpdateIcon } from '../../services/iconService';
import { useAuth } from '../../context/AuthContext';
import { convertFileToBase64 } from '../../utils/fileUtils';

const OrganizationInfo = () => {
  const [organizationInfo, setOrganizationInfo] = useState({
    name: '',
    website: '',
    email: '',
    number: '',
    address: '',
    zipCode: '',
    country: '',
    city: '',
    gln: '',
    vat: '', 
    ceo: '', 
    iban: '',
    bic: '',
    taxId: '',
    cid: '',
    iconBlobName: ''
  });
  const { apiKey, token, organizationId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [iconFile, setIconFile] = useState(null);
  const [iconPreview, setIconPreview] = useState(null); // For displaying a preview of the icon
  const [isIconUpdate, setIsIconUpdate] = useState(false);
  const [iconUrl, setIconUrl] = useState(null);
  const [removeIcon, setRemoveIcon] = useState(false);


  useEffect(() => {
    const loadOrganizationInfo = async () => {
      setLoading(true);
      try {
        const data = await fetchOrganizationInfo(apiKey, organizationId, token);
        setOrganizationInfo(data);

        if (data.iconBlobName) {
          setIsIconUpdate(true);
          const iconData = await fetchIcon(apiKey, organizationId, token);
          setIconUrl(`data:image/png;base64,${iconData.icon}`); // Convert to base64 URL
        }
      } catch (error) {
        console.error('Error fetching organization info:', error);
      }
      setLoading(false);
    };

    loadOrganizationInfo();
  }, [apiKey, organizationId, token]);


  const handleIconUploadClick = () => {
    document.getElementById('icon-upload-input').click();
  };

  const handleRemoveIcon = () => {
    setIconFile(null);
    setIconPreview(null);
    setIconUrl(null);
    setIsIconUpdate(false);
    setRemoveIcon(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setOrganizationInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleIconChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.log("No file selected");
      return;
    }

    // Example constraints: 1MB max size, 500x500 max dimensions
    const maxSizeBytes = 10 * 1024 * 1024; // 1MB
    const maxWidth = 1024;
    const maxHeight = 1024;

    if (file.size > maxSizeBytes) {
      console.log("File is too large");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        if (img.width > maxWidth || img.height > maxHeight) {
          console.log("Image dimensions are too large");
        } else {
          setIconFile(file);
          setIconPreview(e.target.result);
        }
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    let updatedInfo = { ...organizationInfo };
    try {
      setLoading(true);
      let updatedInfo = { ...organizationInfo };

      if (removeIcon) {
        await deleteIcon(apiKey, organizationId, token);
        updatedInfo.iconBlobName = ''; // Remove icon reference from organization info
      } else if (iconFile) {
        try {
          const base64Icon = await convertFileToBase64(iconFile);
          console.log('Base64 Icon:', base64Icon);
          const { blobName } = await uploadOrUpdateIcon(base64Icon, apiKey, organizationId, token);
          if (blobName) {
            updatedInfo = { ...updatedInfo, iconBlobName: blobName };
            setOrganizationInfo(updatedInfo);
          }
        } catch (error) {
          console.error('Error converting icon file:', error);
        }
      }

      // Wait for a tick to ensure state update
      await new Promise(resolve => setTimeout(resolve, 0));

      const response = await updateOrganizationInfo(updatedInfo, apiKey, organizationId, token);
      console.log('Save Response:', response);
    } catch (error) {
      console.error('Error saving organization info:', error);
    } finally {
      setLoading(false);
    }
  };



  return (
    <div>
      {loading && <LinearProgress />}
      <Typography variant="h4" gutterBottom>
        Organization Information
      </Typography>
      {!loading && (
        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
          <form onSubmit={handleSubmit}>

            <Divider textAlign="left"><b>Organization Icon</b></Divider>
            <Box my={2}>
              <input
                id="icon-upload-input"
                type="file"
                onChange={handleIconChange}
                accept="image/*"
                style={{ display: 'none' }}
              />
              <Button variant="contained" color="primary" onClick={handleIconUploadClick}>
                {isIconUpdate ? 'Change Icon' : 'Upload Icon'}
              </Button>
              {iconPreview || iconUrl ? (
                <Button variant="outlined" color="secondary" onClick={handleRemoveIcon}>
                  Remove Icon
                </Button>
              ) : null}

              {iconPreview ? (
                <Box mt={2}>
                  <img src={iconPreview} alt="Icon Preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                </Box>
              ) : iconUrl && (
                <Box mt={2}>
                  <img src={iconUrl} alt="Current Icon" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                </Box>
              )}
            </Box>
            <Divider textAlign="left"><b>Organization Details</b>  </Divider>
            <TextField
              label="Organization Name"
              name="name"
              value={organizationInfo.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Organization Website"
              name="website"
              value={organizationInfo.website}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="CEO"
              name="ceo"
              value={organizationInfo.ceo}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email Address"
              name="email"
              value={organizationInfo.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Phone Number"
              name="number"
              value={organizationInfo.number}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Street Address"
              name="address"
              value={organizationInfo.address}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Zip Code"
              name="zipCode"
              value={organizationInfo.zipCode}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Country"
              name="country"
              value={organizationInfo.country}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="City"
              name="city"
              value={organizationInfo.city}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Global Location Number"
              name="gln"
              value={organizationInfo.gln}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="VAT Number"
              name="vat"
              value={organizationInfo.vat}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            {/* Add more fields similar to above for zip code, country, city */}
            {/* CEO Dropdown */}
            {/* You'll need to fetch and map users to dropdown options */}
            <Divider textAlign="left"><b>Financial and Banking Information</b></Divider>
            <TextField
              label="IBAN"
              name="iban"
              value={organizationInfo.iban}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="BIC"
              name="bic"
              value={organizationInfo.bic}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Tax ID"
              name="taxId"
              value={organizationInfo.taxId}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            {/* Add fields for BIC and CID */}
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </form>
        </Paper>
      )}
    </div>
  );
};

export default OrganizationInfo;
