import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Box, Paper, Typography, Grid, LinearProgress, IconButton, Chip, FormControlLabel, Checkbox
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useExpense } from './useExpense';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import ExpenseTable from './ExpenseTable';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import SuggestionModal from '../../components/SuggestionModal';

const ExpenseDetailsPage = () => {
  const { t } = useTranslation();
  const { expenseId } = useParams();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const { apiKey, organizationId, token, userId } = useAuth();

  const {
    expenseData, handleRowChange, handleSubmit, setExpenseData, handleHeaderInputChange, handleCheckboxChange, items,
    isEditMode, removeRow, addRow, loading, error, isCancelled, measureUnits, handleCancel, categories, filteredSubcategories,
    suggestions, setSuggestions, isSuggestionModalOpen, setIsSuggestionModalOpen, handleSaveSuggestions
  } = useExpense(expenseId, apiKey, organizationId, token, userId, state);

  useEffect(() => {
    if (state?.fromDocument?.suggestions) {
      if (state?.fromDocument?.suggestions?.categories?.filter(category => category.newOrExisting === 'new').length > 0 ||
        state?.fromDocument?.suggestions?.rows?.filter(item => Boolean(item.stockItem) === true && !item.itemId).length > 0) {
        setSuggestions(state.fromDocument);
        setIsSuggestionModalOpen(true);
      } else {
        setSuggestions({ rows: [{}], suggestions: { categories: [], items: [] } });
        setIsSuggestionModalOpen(false);
      }
    }
  }, [state, setSuggestions, setIsSuggestionModalOpen]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        {loading && <LinearProgress />}
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
            <Grid item>
              <Typography variant="h4">
                <IconButton onClick={() => navigate(-1)} aria-label="back">
                  <ArrowBackIcon />
                </IconButton>
                {expenseId === 'new' ? t('expenseRecording.createExpense') : t('expenseRecording.editExpense')}
              </Typography>
            </Grid>
            <Grid item>
              {expenseId !== 'new' && isCancelled && (
                <Chip
                  icon={<WarningAmberIcon />}
                  label={t('invoiceDetailPage.cancelled')}
                  color="warning"
                  variant="outlined"
                />
              )}
            </Grid>
          </Grid>
          <TextField
            label={t('expenseRecording.expenseNumber')}
            name="expenseNumber"
            value={expenseData.expenseNumber}
            onChange={handleHeaderInputChange}
            margin="normal"
            fullWidth
            disabled
          />
          <TextField
            label={t('expenseRecording.vendor')}
            name="vendor"
            value={expenseData.vendor}
            onChange={handleHeaderInputChange}
            margin="normal"
            fullWidth
          />
          <TextField
            label={t('expenseRecording.vendorDocumentNumber')}
            name="vendorDocumentNumber"
            value={expenseData.vendorDocumentNumber}
            onChange={handleHeaderInputChange}
            margin="normal"
            fullWidth
          />
          <TextField
            label={t('expenseRecording.notes')}
            name="notes"
            value={expenseData.notes}
            onChange={handleHeaderInputChange}
            margin="normal"
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={expenseData.isInvoice}
                onChange={handleCheckboxChange}
                name="isInvoice"
                color="primary"
              />
            }
            label={t('expenseRecording.isInvoice')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={expenseData.isDeliveryNote}
                onChange={handleCheckboxChange}
                name="isDeliveryNote"
                color="primary"
              />
            }
            label={t('expenseRecording.isDeliveryNote')}
          />
          <ExpenseTable
            rows={expenseData.rows}
            addRow={addRow}
            removeRow={removeRow}
            handleRowChange={handleRowChange}
            items={items}
            measureUnits={measureUnits}
            isEditMode={isEditMode}
            categories={categories}
            filteredSubcategories={filteredSubcategories}
          />
          <Box sx={{ mt: 3, mb: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">{`${t('expenseRecording.totalTaxes')}: ${expenseData.taxAmount}`}</Typography>
            <Typography variant="h6">{`${t('expenseRecording.totalNetAmount')}: ${expenseData.totalNetAmount}`}</Typography>
            <Typography variant="h6">{`${t('expenseRecording.totalExpense')}: ${expenseData.totalBruttoAmount}`}</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item>
              <Button type="submit" variant="contained" color="primary">
                {t('expenseRecording.saveButton')}
              </Button>
            </Grid>
            {expenseId !== 'new' && (
              <Grid item>
                <Button variant="contained" color="error" onClick={handleCancel} disabled={isCancelled}>
                  {t('expenseRecording.cancelButton')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
        <SuggestionModal
          open={isSuggestionModalOpen}
          onClose={() => setIsSuggestionModalOpen(false)}
          suggestions={suggestions}
          onSave={handleSaveSuggestions}
        />
      </Paper>
    </LocalizationProvider>
  );
};

export default ExpenseDetailsPage;
