import React from 'react';

const Icon = ({ src, alt, size = 50, color = 'inherit', style, ...props }) => {
  // Create a wrapper style to apply size and color
  const wrapperStyle = {
    width: size,
    height: size,
    filter: color !== 'inherit' ? `drop-shadow(0 0 0 ${color})` : 'none',
    ...style,
  };

  return <img src={src} alt={alt} style={wrapperStyle} {...props} />;
};

export default Icon;
