// src/dataCollection/currencySettings.js
// define hard coded currency settings in this file


export const currencyInfo = {
    EUR: {
        symbol: '€',
        name: 'Euro',
        countries: ['Germany', 'Austria', 'France', 'Italy', 'Spain'],
    },
    CHF: {
        symbol: 'CHF',
        name: 'Swiss Franc',
        countries: ['Switzerland'],
    },
    USD: {
        symbol: '$',
        name: 'US Dollar',
        countries: ['United States'],
    },
    AED: {
        symbol: 'AED',
        name: 'UAE Dirham',
        countries: ['United Arab Emirates'],
    },
    // Add other currencies as necessary
};
