// src/context/setPolicyBasedOnUrl.js

import { msalConfig } from './authConfig';
import { policies } from './authPolicies';
import { PublicClientApplication } from '@azure/msal-browser';


export function setPolicyBasedOnUrl() {
    let policyName;
    const pathname = window.location.pathname;

    if (pathname.includes('/password-password')) {
        policyName = policies.passwordResetPolicy;
    } else if (pathname.includes('/signup')) {
        policyName = policies.signUpPolicy;
    } else {
        policyName = policies.signInPolicy;
    }

    const currentConfig = msalConfig(policyName);
    return new PublicClientApplication(currentConfig);
}

