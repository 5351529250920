// src/pages/auth/HorseOwnership.js

import { useState } from 'react';
import { Button, Box, Paper, Grid } from '@mui/material';
import HorseForm from './HorseForm';
import { useTranslation } from 'react-i18next';
import DocumentUploadModal from '../../components/DocumentUploadModal';
import { scanDocument, fetchDocumentMetadata } from '../../services/documentService';
import { useAuth } from '../../context/AuthContext';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Tooltip from '@mui/material/Tooltip';
import { v4 as uuidv4 } from 'uuid';

const HorseOwnership = ({ onNext, veterinarians, ferries, addNewPerson, initialData }) => {
    const { t } = useTranslation();
    const [horses, setHorses] = useState(initialData || []); // Initial data is an array of horse objects
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [isScanning, setIsScanning] = useState(false);
    const { apiKey, organizationId, token } = useAuth();

    const addHorse = () => {
        setHorses(prev => [...prev,
        {
            id: uuidv4(),
            name: '',
            birthday: '',
            breed: '',
            ownerId: '',
            coRiders: [],
            emergencyContact: {
                name: '',
                number: '',
            },
            veterinarianId: '',
            farrierId: '',
            foodPortions: {},
            identificationNumber: '',
            sex: '',
            color: '',
            microchipIdentification:
            {
                dateOfInsertion: null,
                locationOfInsertion: '',
                number: '',
                issuedBy: '',
                barcode: '',
            },
            breeder:
            {
                name: '',
                address: '',
                id: '',
            },
            sire:
            {
                name: '',
                identificationNumber: '',
            },
            dam:
            {
                name: '',
                identificationNumber: '',
            },
            branding:
            {
                brandNumber: '',
                location: '',
                brandingAgent: '',
            },
            markings:
            {
                head: '',
                legs:
                {
                    frontLeft: '',
                    frontRight: '',
                    backLeft: '',
                    backRight: '',
                },
                body: '',
            },
            documents: [
            ],
            isFilledByScan: false,

        }]);
    }

    const areAllHorsesValid = () => {
        return horses.length > 0 && horses.every(horse => {
            return (
                horse.name &&
                horse.emergencyContact.name &&
                horse.emergencyContact.number
            );
        });
    };


    const removeHorse = (index) => {
        const newHorses = [...horses];
        newHorses.splice(index, 1);
        setHorses(newHorses);
    }

    const updateHorseData = (index, newData) => {
        const newHorses = [...horses];
        newHorses[index] = newData;
        setHorses(newHorses);
    }

    const handleNext = () => {

        onNext(horses);
    }
    const triggerDocumentUpload = () => {
        setIsUploadModalOpen(true);
    };

    const onDocumentUploadClose = async (documentId) => {
        setIsUploadModalOpen(false);
        if (documentId && typeof documentId === 'string') {
            try {
                setIsScanning(true);
                // Trigger the document scanning process
                const scannedData = await scanDocument(documentId, apiKey, organizationId, token);
                if (scannedData) {
                    // If scanning was successful, fetch the document metadata
                    const metadata = await fetchDocumentMetadata(documentId, apiKey, organizationId, token);
                    // Assuming metadata contains detailed information that you want to use to update horse data
                    // You might need to adjust the structure based on your actual metadata format
                    if (metadata) {
                        const fromDocument = metadata.structuredData;
                        // Determine the document type and map data accordingly
                        if (fromDocument) {
                            const horseData = fromDocument;
                            const newHorseData = {
                                ...horses,
                                name: horseData.horseName,
                                identificationNumber: horseData.identificationNumber,
                                breed: horseData.breed,
                                birthday: horseData.dateOfBirth,
                                sex: horseData.sex,
                                color: horseData.color,
                                ownerId: '',
                                coRiders: [],
                                emergencyContact: {
                                    name: '',
                                    number: '',
                                },
                                veterinarianId: '',
                                farrierId: '',
                                foodPortions: {},
                                microchipIdentification: {
                                    dateOfInsertion: horseData.microchipIdentification.dateOfInsertion,
                                    location: horseData.microchipIdentification.location,
                                    number: horseData.microchipIdentification.number,
                                    issuedBy: horseData.microchipIdentification.issuedBy,
                                    barcode: horseData.microchipIdentification.barcode,
                                },
                                breeder: {
                                    name: horseData.breeder.name,
                                    address: horseData.breeder.address,
                                },
                                sire: {
                                    name: horseData.sire.name,
                                    identificationNumber: horseData.sire.identificationNumber,
                                },
                                dam: {
                                    name: horseData.dam.name,
                                    identificationNumber: horseData.dam.identificationNumber,
                                },
                                branding: {
                                    brandNumber: horseData.branding.brandNumber,
                                    location: horseData.branding.location,
                                    brandingAgent: horseData.branding.brandingAgent,
                                },
                                markings: {
                                    head: horseData.markings.head,
                                    legs: {
                                        frontLeft: horseData.markings.legs.leftForeLeg,
                                        frontRight: horseData.markings.legs.rightForeLeg,
                                        backLeft: horseData.markings.legs.leftHindLeg,
                                        backRight: horseData.markings.legs.rightHindLeg,
                                    },
                                    body: horseData.markings.body,
                                    other: horseData.markings.other,
                                },
                                documents: [
                                    {
                                        id: metadata.id,
                                        name: horseData.orginalName,
                                    },
                                ],
                                isFilledByScan: true,
                            };

                            // Adding new horse data to the horses array
                            setHorses(prevHorses => [...prevHorses, newHorseData]);
                        }
                    }
                }
            } catch (error) {
                console.error('Error processing document upload:', error);
                // Handle any errors that might occur during scanning or metadata fetching
            } finally {
                setIsScanning(false);
            }
        }
    };


    return (
        <div>
            {horses.map((horseData, index) => (
                <Paper key={index} style={{ padding: 16, marginBottom: 16 }}> {/* Space between each HorseForm */}
                    <Box mb={3} key={index}> {/* Space between each HorseForm */}
                        <HorseForm
                            onRemove={() => removeHorse(index)}
                            initialData={horseData}
                            onUpdate={(data) => updateHorseData(index, data)}
                            addNewPerson={addNewPerson}
                            veterinarians={veterinarians}
                            ferries={ferries}
                            isFilledByScan={horseData.isFilledByScan}
                        />
                    </Box>
                </Paper>
            ))}
            <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ marginTop: 16 }}>
                <Grid item>
                    <Button startIcon={<AddCircleOutlineIcon />} onClick={addHorse} disabled={isScanning} size='large' variant='outlined'>
                        {t('HorseOwnership.buttons.addHorse')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button startIcon={isScanning ? <CircularProgress/>:<CloudUploadIcon/>} onClick={triggerDocumentUpload} disabled={isScanning} size='large' variant='outlined'>
                        {t('HorseOwnership.buttons.scanPassport')}
                    </Button>
                </Grid>
            </Grid>
            {/* New Grid container specifically for the Next button */}
            <Grid container spacing={2} justifyContent="center" style={{ marginTop: 2 }}>
                <Grid item>
                    <Button onClick={handleNext} variant="contained" color="primary" disabled={!areAllHorsesValid() || isScanning}>
                        {t('HorseOwnership.buttons.next')}
                    </Button>
                </Grid>
            </Grid>
            <DocumentUploadModal
                isOpen={isUploadModalOpen}
                onClose={onDocumentUploadClose}
                scanType='horsepass' />
        </div>
    );
};


export default HorseOwnership;
