// src/services/ownerService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/owners';


export async function getAllOwners(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(API_BASE_URL);
  return response.data;
}

export async function getOwnerById(id, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${id}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function getOwnerByEmail(email, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?email=${email}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function createOwner(owner, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(API_BASE_URL, owner);
  return response.data;
}

export async function updateOwner(id, owner, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${id}`;
  const response = await axiosInstance.put(url, owner);
  return response.data;
}

export async function deleteOwner(id, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${id}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

export async function inviteOwnerByEmail(ownerId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}/inviteOwnerByEmail?ownerId=${ownerId}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function getTokenInviteOwners(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}/inviteOwners`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function refreshTokenInviteOwners(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}/inviteOwners`;
  const response = await axiosInstance.post(url);
  return response.data;
}

export async function fetchCustomers(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?entityType=customer`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function fetchOwners(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?entityType=owner`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function fetchVendor(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?entityType=vendor`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export default {
  getAllOwners,
  getOwnerById,
  getOwnerByEmail,
  createOwner,
  updateOwner,
  deleteOwner,
  inviteOwnerByEmail,
  getTokenInviteOwners,
  refreshTokenInviteOwners,
  fetchCustomers,
  fetchOwners,
  fetchVendor
};
