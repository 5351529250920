import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    Typography,
    List,
    ListItem,
    ListItemText,
    Divider,
    Box
} from '@mui/material';
import { useTranslation } from 'react-i18next'; // Import the hook

const GenericCardListView = ({ data, columns, title='' }) => {
    const { t } = useTranslation(); // Initialize the translation hook

    return (
        <Card raised sx={{ margin: 2, width: 'auto' }}>
            <CardContent>
                <Typography variant="h6" gutterBottom component="div">
                    {title}
                </Typography>
                <List dense>
                    {data.map((item, index) => (
                        <React.Fragment key={index}>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={columns.map((column) => (
                                        <Box key={column.id} component="span">
                                            <Typography variant="body1" component="span" color="text.primary">
                                                {t(column.label)}: {/* Localize column labels */}
                                            </Typography>
                                            {' '}{item[column.id]}
                                            <br />
                                        </Box>
                                    ))}
                                />
                            </ListItem>
                            {index !== data.length - 1 && <Divider variant="inset" component="li" />}
                        </React.Fragment>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

GenericCardListView.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired // Ensure label is always provided
        })
    ).isRequired,
};

export default GenericCardListView;
