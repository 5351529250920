// src/pages/inventoryManagement/inventoryManagement/useTransactionManagement.js
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTransactionById, createTransaction, updateTransaction } from '../../../services/transactionService';
import { getAllItems } from '../../../services/itemService';
import { useAuth } from '../../../context/AuthContext';
import { t } from 'i18next';
import { measureUnits } from '../itemManagement/ItemUtils';

export const useTransactionManagement = (transactionId, isEditMode, onClose) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { apiKey, organizationId, token: jwtToken } = useAuth();
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const initialTransactionData = {
    id: '',
    date: !isEditMode ? new Date() : '',
    type: '',
    itemId: '',
    quantity: 0,
    oldquantity: 0,
    newquantity: 0,
    reason: '',
    comment: '',
  };
  const [transactionData, setTransactionData] = useState(initialTransactionData);
  const [items, setItems] = useState([]);
  const [filteredReasons, setFilteredReasons] = useState([]);



  // Define possible transaction types
  const transactionTypes = [
    { value: 'incoming', label: t('TransactionManagement.incoming') },
    { value: 'outgoing', label: t('TransactionManagement.outgoing') },
    // Add more types as needed
  ];


  // Group reasons by transaction types
  const reasonsByType = {
    incoming: ['purchase', 'restock'],
    outgoing: ['sale', 'usage', 'other']
  };

  // Define possible reasons with labels
  const allTransactionReasons = {
    purchase: t('TransactionManagement.purchase'),
    sale: t('TransactionManagement.sale'),
    usage: t('TransactionManagement.usage'),
    other: t('TransactionManagement.other'),
    restock: t('TransactionManagement.restock')
  };


  useEffect(() => {
    const reasonsForType = reasonsByType[transactionData.type] || [];
    const filtered = reasonsForType.map(reasonKey => ({ value: reasonKey, label: allTransactionReasons[reasonKey] }));
    setFilteredReasons(filtered);
  }, [transactionData.type, t]);

  useEffect(() => {
    const fetchTransactionData = async () => {
      if (isEditMode) {
        setLoading(true);
        try {
          const data = await getTransactionById(transactionId, apiKey, organizationId, jwtToken);
          const formattedDate = data.date ? new Date(data.date) : new Date();
          setTransactionData({ ...data, date: formattedDate });

          // Find and set the selectedItem after fetching transaction data
          setSelectedItem(items.find(item => item.id === data.itemId));
          setError(null);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchTransactionData();
  }, [transactionId, apiKey, organizationId, jwtToken, items]); // Include items in dependency array



  useEffect(() => {
    const fetchItems = async () => {
      try {
        const data = await getAllItems(apiKey, organizationId, jwtToken);
        setItems(data);

        // Update selectedItem if items list is updated and transaction is in edit mode
        if (isEditMode && transactionData.itemId) {
          setSelectedItem(data.find(item => item.id === transactionData.itemId));
        }
      } catch (err) {
        setError(err.message);
      }
    }
    fetchItems();
  }, [apiKey, organizationId, jwtToken, transactionId, transactionData.itemId]);


  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (!isEditMode) {
        await createTransaction(transactionData, apiKey, organizationId, jwtToken)
      } else {
        await updateTransaction(transactionId, transactionData, apiKey, organizationId, jwtToken)
      }
      if (transactionId) {
        navigate('/inventory?tab=1');
      } else {
        onClose();
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setTransactionData({ ...transactionData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (newDate) => {
    setTransactionData({ ...transactionData, date: newDate });
  };
  const handleItemSelection = (e) => {
    setTransactionData({ ...transactionData, itemId: e.target.value });
    setSelectedItem(items.find(item => item.id === e.target.value));
  }

  const getLocalizedMeasureUnit = (unitKey) => {
    const measureUnitI18nKey = measureUnits[unitKey];
    return t(measureUnitI18nKey);
  };

  return {
    transactionData,
    setTransactionData,
    handleInputChange,
    handleItemSelection,
    handleDateChange,
    handleSubmit,
    getLocalizedMeasureUnit,
    transactionTypes,
    filteredReasons,
    loading,
    items,
    selectedItem,
    error
  };
};
