// src/pages/PaymentReminders/PaymentRemindersListPage.js
import React, { useState, useEffect } from 'react';
import {
    Paper,
    Button,
    Chip,
    Box,
    IconButton,
    Typography,
    LinearProgress,
} from '@mui/material';
//import PaymentRemindersList from './PaymentRemindersList'; // Import the Payment Reminders List Page
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ViewModuleIcon from '@mui/icons-material/ViewModule';  // <-- add this line
import ViewListIcon from '@mui/icons-material/ViewList';  // <-- add this line
import GenericCardView from '../../components/GenericCardView';
import GenericTableView from '../../components/GenericTableView';
import { useTheme, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from '../../context/AuthContext';
import { fetchPaymentReminders } from '../../services/PaymentReminderService';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CancelIcon from '@mui/icons-material/Cancel';
import { formatRelative, format } from 'date-fns';
import { useDateLocale } from '../../hooks/useDateLocale';

const PaymentRemindersListPage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const dateLocale = useDateLocale();
    //const { organizationId } = useAuth();
    const { apiKey, userType, token, organizationId } = useAuth();
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [viewMode, setViewMode] = useState(isMobile ? 'card' : 'table');
    const [loading, setLoading] = useState(true);
    const [paymentReminders, setPaymentReminders] = useState([]);


    // Add the rest of the component logic here
    // mock data for the payment reminders

    useEffect(() => {
        // fetch the payment reminders data
        const fetchPaymentRemindersData = async () => {
            try {
                const data = await fetchPaymentReminders(apiKey, organizationId, token);
                setPaymentReminders(data);
            } catch (error) {
                console.error('Error fetching payment reminders:', error);
            } finally {
                setLoading(false);
            }
        }
        fetchPaymentRemindersData();
    }, []);

    const handleAddPaymentReminder = () => {
        navigate('/payment-reminders/new');
    };

    const toggleViewMode = () => {
        setViewMode((prevMode) => (prevMode === 'card' ? 'table' : 'card'));
    };

    const transformData = () => {
        return {
            headers: [
                { key: 'customer', label: t('paymentReminder.listPage.customer'), display: { value: true, chipColor: false } },
                { key: 'date', label: t('paymentReminder.listPage.date'), display: { value: true, chipColor: false } },
                { key: 'amount', label: t('paymentReminder.listPage.amount'), display: { value: true, chipColor: false } },
                { key: 'status', label: t('paymentReminder.listPage.status'), display: { value: true, chipColor: false } },
                { key: 'level', label: t('paymentReminder.listPage.level'), display: { value: true, chipColor: false } },
            ],
            rows: paymentReminders.map((paymentReminder) => ({
                id: paymentReminder.id,
                customer: { value: paymentReminder.payerName, icon: null },
                date: { value: format(new Date(paymentReminder.creationDate), 'P', { locale: dateLocale }), icon: null },
                amount: { value: paymentReminder.totalGrossAmount, icon: null },
                level: { value: paymentReminder.level, icon: null },
                status: { value: renderStatus(paymentReminder.status), icon: null },
            })),
        };
    };

    const renderStatus = (status) => {
        let reeturnChip = null;
        if (status === 'unpaid') {
            reeturnChip = <Chip label={t('paymentReminder.listPage.unpaid')} color="error" icon={<CancelIcon />} />;
        }
        else if (status === 'paid') {
            reeturnChip = <Chip label={t('paymentReminder.listPage.paid')} color="success" icon={<CheckCircleIcon />} />;
        }
        else if (status === 'escalated') {
            reeturnChip = <Chip label={t('paymentReminder.listPage.escalated')} color="warning" icon={<WarningAmberIcon />} />;
        }
        return reeturnChip;
    }

    const handlePaymentReminderClick = (paymentReminderId) => {
        navigate(`/payment-reminders/${paymentReminderId}`);
    };

    return (

        <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
            <Typography variant="h4" gutterBottom>
                {t('paymentReminder.listPage.title')}
            </Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddPaymentReminder}
                    startIcon={<AddIcon />}
                    style={{ marginTop: '1rem', marginBottom: '1rem' }}
                >
                    {t('paymentReminder.listPage.addButton')}
                </Button>
                <IconButton onClick={toggleViewMode} color="primary">
                    {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
                </IconButton>
            </Box>
            {loading ? (
                <LinearProgress />
            ) : viewMode === 'card' ? (
                <GenericCardView structure={transformData()} onClick={handlePaymentReminderClick} />
            ) : (
                <GenericTableView structure={transformData()} onClick={handlePaymentReminderClick} />
            )}
        </Paper>
    );
}

export default PaymentRemindersListPage;