// DeliveryNoteSelectionModal.jsx
import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    FormControlLabel,
    FormGroup,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDateLocale } from '../hooks/useDateLocale';
import { format } from 'date-fns';

const DeliveryNoteSelectionModal = ({
    open,
    onClose,
    availableDeliveryNotes,
    selectedDeliveryNotes,
    setSelectedDeliveryNotes,
    onAddDeliveryNotes,
    statusFilter,
    setStatusFilter,
}) => {
    const { t } = useTranslation();
    const dateLocale = useDateLocale();

    const handleToggle = (deliveryNote) => {
        const currentIndex = selectedDeliveryNotes.findIndex((dn) => dn.id === deliveryNote.id);
        const newSelected = [...selectedDeliveryNotes];

        if (currentIndex === -1) {
            newSelected.push(deliveryNote);
        } else {
            newSelected.splice(currentIndex, 1);
        }

        setSelectedDeliveryNotes(newSelected);
    };

    const handleStatusFilterChange = (event) => {
        const value = event.target.value;
        setStatusFilter(typeof value === 'string' ? value.split(',') : value);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{t('invoiceDetailPage.selectDeliveryNotes')}</DialogTitle>
            <DialogContent>
                {/* Status Filter */}
                {/*<FormControl fullWidth sx={{ marginTop: 2 }}>
                    <InputLabel id="status-filter-label">{t('invoiceDetailPage.statusFilter')}</InputLabel>
                    <Select
                        labelId="status-filter-label"
                        multiple
                        value={statusFilter}
                        onChange={handleStatusFilterChange}
                    >
                        <MenuItem value="approved">{t('status.approved')}</MenuItem>
                        <MenuItem value="draft">{t('status.draft')}</MenuItem>
                        {/* Add other statuses as needed 
                    </Select>
                </FormControl>*/}

                {/* List of Delivery Notes */}
                <List>
                    {availableDeliveryNotes.map((deliveryNote) => (
                        <ListItem
                            key={deliveryNote.id}
                            button
                            onClick={() => handleToggle(deliveryNote)}
                        >
                            <ListItemText
                                primary={`${deliveryNote.deliveryNoteNumber} - ${format(new Date(deliveryNote.deliveryDate), 'P', { locale: dateLocale })}`}
                                secondary={`${t('status.' + deliveryNote.status)}`}
                            />
                            <ListItemSecondaryAction>
                                <Checkbox
                                    edge="end"
                                    onChange={() => handleToggle(deliveryNote)}
                                    checked={selectedDeliveryNotes.some((dn) => dn.id === deliveryNote.id)}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('common.cancel')}</Button>
                <Button
                    onClick={() => {
                        onAddDeliveryNotes();
                        onClose();
                    }}
                    color="primary"
                    disabled={selectedDeliveryNotes.length === 0}
                >
                    {t('common.add')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeliveryNoteSelectionModal;
