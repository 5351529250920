// src/components/RidingLessonBookingModal.js

import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, TextField, FormControl, InputLabel, Autocomplete, Typography, Grid, IconButton, LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ConfirmationDialog from './ConfirmationDialog';

// Assuming these services have methods for fetching data if not provided as props
import { getAllHorses, getHorsesByOwnerID } from '../services/horseService';
import { fetchTeacherSettings } from '../services/TeacherSettingsService';
import { fetchBookableServiceTypes } from '../services/serviceTypesService';
import { getAllOwners } from '../services/ownerService';
import { createBooking, updateBooking, deleteBooking } from '../services/bookingService';
import { useDateLocale } from '../hooks/useDateLocale';

import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import SnackbarAlert from './SnackbarAlert'; // Adjust the path as necessary


const RidingLessonBookingModal = ({ open, onClose, lessonData = {}, horses = [], teachers = [], serviceTypes = [], owners = [], onSave }) => {
  const initialFormData = {
    dateTime: new Date(),
    riderId: '',
    riderName: '',
    horseId: '',
    teacherId: '',
    serviceTypeId: '',
    duration: 0,
    notes: '',
    status: 'Booked',
    isPaid: false,
    horses: [],
    teachers: [],
    serviceTypes: [],
    owners: [],
  };
  const [loading, setLoading] = useState(true);
  const dateLocale = useDateLocale();
  const { apiKey, userId, userEmail, userType, organizationId, token: jwtToken } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(lessonData?.id ? true : false);
  const [formData, setFormData] = useState({
    ...initialFormData,
    ...lessonData,
    dateTime: lessonData && lessonData.dateTime && !isNaN(new Date(lessonData.dateTime).getTime()) ? new Date(lessonData.dateTime) : new Date()
  });

  const { t } = useTranslation();
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const hasFetchedInitialData = useRef(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // Other values can be 'error', 'warning', 'info'
  });


  useEffect(() => {
    // This effect ensures formData is updated when lessonData changes,
    // such as when a different lesson is selected to edit.
    setFormData({ ...initialFormData, ...lessonData, dateTime: new Date(lessonData.dateTime) || new Date() });
    teachers.length === 1 && setFormData((currentFormData) => ({
      ...currentFormData,
      teacherId: teachers[0].id,
    }));
  }, [open]);

  // Load data if not provided
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const fetchedHorses = horses.length === 0 ? await getAllHorses(apiKey, organizationId, jwtToken) : horses;
      const fetchedTeachers = teachers.length === 0 ? await fetchTeacherSettings(apiKey, organizationId, jwtToken) : teachers;
      const fetchedServiceTypes = serviceTypes.length === 0 ? await fetchBookableServiceTypes(apiKey, organizationId, jwtToken) : serviceTypes;
      const fetchedOwners = owners.length === 0 ? await getAllOwners(apiKey, organizationId, jwtToken) : owners;
      setEditMode(lessonData?.id ? true : false);

      setFormData((currentFormData) => ({
        ...currentFormData,
        dateTime: lessonData && lessonData.dateTime && !isNaN(new Date(lessonData.dateTime).getTime()) ? new Date(lessonData.dateTime) : new Date(),
        horses: fetchedHorses,
        teachers: fetchedTeachers,
        serviceTypes: fetchedServiceTypes.filter(service => service.isBookable),
        owners: fetchedOwners,
      }));
    };
    if (open && !hasFetchedInitialData.current) {
      fetchData().then(() => {
        hasFetchedInitialData.current = true; // Ensure fetch only happens once initially
        setLoading(false);
      });
    }
  }, [open]);

  const handleFieldChange = (field, value) => {
    setFormData((currentFormData) => ({
      ...currentFormData,
      [field]: value,
    }));
    if (field === 'serviceTypeId') {
      setFormData((currentFormData) => ({
        ...currentFormData,
        duration: formData.serviceTypes.find((service) => service.id === value)?.duration || ''
      }));
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if ((!formData.riderId && !formData.riderName) || !formData.horseId || !formData.teacherId || !formData.serviceTypeId) {
        return;
      }
      const bookingData = {
        id: lessonData?.id || '',
        riderId: formData.riderId,
        riderName: formData.riderName,
        horseId: formData.horseId,
        teacherId: formData.teacherId,
        serviceTypeId: formData.serviceTypeId,
        duration: formData.duration,
        notes: formData.notes,
        dateTime: formData.dateTime,
        status: formData.status,
        isPaid: formData.isPaid,
      };

      if (editMode) {
        await updateBooking(bookingData.id, bookingData, apiKey, organizationId, jwtToken);
      } else {
        await createBooking(bookingData, apiKey, organizationId, jwtToken);
      }

      handleClose();
      onSave(formData);
      clearFormData();

      setSnackbar({
        open: true,
        message: t('RidingLessonBookingModal.saveSuccess'),
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: t('RidingLessonBookingModal.saveError'),
        severity: 'error',
      });

    } finally {
      setLoading(false);
    }

  };

  const clearFormData = () => {
    setFormData({
      ...initialFormData,
      dateTime: new Date(),
      riderId: '',
      riderName: '',
      horseId: '',
      teacherId: '',
      serviceTypeId: '',
      duration: 0,
      notes: '',
      status: 'Booked',
      isPaid: false,
    });
    hasFetchedInitialData.current = false;
  };


  const handleDateChange = (newDate) => {
    setFormData((currentFormData) => ({
      ...currentFormData,
      dateTime: new Date(
        newDate?.getFullYear() || currentFormData.dateTime.getFullYear(),
        newDate?.getMonth() || currentFormData.dateTime.getMonth(),
        newDate?.getDate() || currentFormData.dateTime.getDate(),
        currentFormData.dateTime.getHours(),
        currentFormData.dateTime.getMinutes()
      ),
    }));

  };

  const handleTimeChange = (newTime) => {
    setFormData((currentFormData) => ({
      ...currentFormData,
      dateTime: new Date(
        currentFormData.dateTime.getFullYear(),
        currentFormData.dateTime.getMonth(),
        currentFormData.dateTime.getDate(),
        newTime?.getHours() || currentFormData.dateTime.getHours(),
        newTime?.getMinutes() || currentFormData.dateTime.getMinutes()
      ),
    }));
  };

  const handleAutocompleteChange = (event, newValue, reason) => {
    console.log('Autocomplete change', newValue, reason);
    if (reason === 'selectOption' && newValue && typeof newValue === 'object') {
      // Option selected from the list
      setFormData(currentFormData => ({
        ...currentFormData,
        riderId: newValue.id,
        riderName: `${newValue.firstname} ${newValue.surname}`,
      }));
      setFormData(currentFormData => ({
        ...currentFormData,
        horseId: formData.horses.find(horse => horse.ownerId === newValue.id)?.id || '',
      }));
    } else if (reason === 'clear') {
      // Input cleared
      setFormData(currentFormData => ({
        ...currentFormData,
        riderId: '',
        riderName: '',
      }));
    } else if (typeof newValue === 'string') {
      // Custom input by the user
      setFormData(currentFormData => ({
        ...currentFormData,
        riderId: '', // No ID for custom input
        riderName: newValue.trim(), // Trim whitespace
      }));
    } else if (reason === 'clear') {
      // Option removed from the list
      setFormData(currentFormData => ({
        ...currentFormData,
        riderId: '',
        riderName: '',
      }));
    } else if (reason === 'blur') {
      // Input blurred
      // Do nothing
    }
    // Additional else-if cases for handling other reasons like 'remove-option', 'blur' etc.
  };
  const handleDeleteConfirm = () => {
    setLoading(true);
    try {
      // Logic to delete the lesson goes here
      deleteBooking(lessonData.id, apiKey, organizationId, jwtToken);
      setOpenDeleteConfirm(false);
      setSnackbar({
        open: true,
        message: t('RidingLessonBookingModal.deleteSuccess'),
        severity: 'success',
      });
      onClose(); // Assuming this closes the main dialog
    } catch (error) {
      setSnackbar({
        open: true,
        message: t('RidingLessonBookingModal.deleteError'),
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    clearFormData();
    onClose();
  }
  // Function to handle Snackbar close event
  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          {lessonData?.id ? t('RidingLessonBookingModal.editLesson') : t('RidingLessonBookingModal.addLesson')}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
            {loading && <LinearProgress />}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  freeSolo
                  value={formData?.owners?.find(owner => owner.id === formData.riderId) || { id: '', firstname: formData.riderName.split(' ')[0], surname: formData.riderName.split(' ').slice(1).join(' '), isExternal: true }}
                  options={[...formData.owners, { id: '', firstname: 'External Rider', surname: '', isExternal: true }]}
                  getOptionLabel={(option) => option.isExternal ? formData.riderName : `${option.firstname} ${option.surname}`}
                  renderInput={(params) => <TextField {...params} label={t('RidingLessonBookingModal.riderName')} />}
                  onChange={handleAutocompleteChange}
                  onInputChange={handleAutocompleteChange}
                  isOptionEqualToValue={(option, value) => option.id ? option.id === value.id : option.firstname === value.firstname && option.surname === value.surname}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="teacher">{t('RidingLessonBookingModal.teacher')}</InputLabel>
                  <Select
                    labelId="teacher"
                    value={formData.teacherId}
                    onChange={(event) => handleFieldChange('teacherId', event.target.value)}
                    fullWidth
                  >
                    {formData.teachers?.map((teacher) => (
                      <MenuItem key={teacher.id} value={teacher.id}>
                        {teacher.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="service">{t('RidingLessonBookingModal.service')}</InputLabel>
                  <Select
                    labelId="service"
                    value={formData.serviceTypeId}
                    onChange={(event) => handleFieldChange('serviceTypeId', event.target.value)}
                    fullWidth
                  >
                    {formData.serviceTypes?.map((service) => (
                      <MenuItem key={service.id} value={service.id}>
                        {service.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <DatePicker
                  label={t('RidingLessonBookingModal.date')}
                  value={formData.dateTime}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TimePicker
                  label={t('RidingLessonBookingModal.time')}
                  value={formData.dateTime}
                  onChange={handleTimeChange}
                  renderInput={(params) => <TextField {...params} />}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('RidingLessonBookingModal.duration')}
                  value={formData.duration}
                  onChange={(event) => handleFieldChange('duration', event.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="horse">{t('RidingLessonBookingModal.horse')}</InputLabel>
                  <Select
                    labelId="horse"
                    value={formData.horseId}
                    onChange={(event) => handleFieldChange('horseId', event.target.value)}
                    fullWidth
                  >
                    {formData.horses && formData.horses.map((horse) => (
                      <MenuItem key={horse.id} value={horse.id}>
                        {horse.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('RidingLessonBookingModal.notes')}
                  value={formData.notes}
                  onChange={(event) => handleFieldChange('notes', event.target.value)}
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteConfirm(true)} color="error" disabled={!lessonData?.id} variant="contained">{t('RidingLessonBookingModal.delete')}</Button>
          <Button onClick={handleClose} color="secondary" variant="contained">{t('RidingLessonBookingModal.cancel')}</Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">{t('RidingLessonBookingModal.save')}</Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        isOpen={openDeleteConfirm}
        onClose={() => setOpenDeleteConfirm(false)}
        onConfirm={handleDeleteConfirm}
        text={t('RidingLessonBookingModal.deleteConfirmation')}
      />
      <SnackbarAlert
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />

    </>
  );
};

export default RidingLessonBookingModal;
