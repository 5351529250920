import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Button
} from '@mui/material';
import { fetchAllPayments } from '../../services/PaymentsService'; // Make sure this path is correct
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import GenericCardView from '../../components/GenericCardView';
import GenericTableView from '../../components/GenericTableView';
import IconButton from '@mui/material/IconButton';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useTheme, useMediaQuery } from '@mui/material';
import { usePreferences } from '../../context/UserPreferencesContext';

const PaymentsListPage = () => {
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { apiKey, organizationId, token } = useAuth();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [viewMode, setViewMode] = useState(isMobile ? 'card' : 'table');
    const { devicePreferences, updateDevicePreferences } = usePreferences();


    const handleRowClick = (paymentId) => {
        navigate(`/payments/detail/${paymentId}`);
    };

    const handleNewPayment = () => {
        navigate('/payments/detail/new');
    };

    useEffect(() => {
        const fetchPayments = async () => {
            try {
                setLoading(true);
                const paymentData = await fetchAllPayments(apiKey, organizationId, token);
                setPayments(paymentData);
            } catch (error) {
                console.error("Error fetching payment data: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPayments();
    }, [apiKey, organizationId, token]);

    // Update local state when context updates
    useEffect(() => {
        setViewMode(devicePreferences.viewMode);
    }, [devicePreferences.viewMode]);


    const toggleViewMode = () => {
        updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
    };

    const transformData = () => {
        return {
            headers: [
                { key: 'payerName', label: t('paymentsListPage.payerName'), display: { value: true, chipColor: false } },
                { key: 'amount', label: t('paymentsListPage.amount'), display: { value: true, chipColor: false } },
                { key: 'date', label: t('paymentsListPage.paymentDate'), display: { value: true, chipColor: false } }
            ],
            rows: payments.map(payment => ({
                id: payment.id,
                payerName: { value: payment.payerName, icon: null },
                amount: { value: payment.amount, icon: null },
                date: { value: new Date(payment.date).toLocaleDateString(), icon: null }
            }))
        };
    };



    return (
        <Paper elevation={3} sx={{ mt: 4, p: 3 }}>

            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
                <Button variant="contained" color="primary" onClick={handleNewPayment} startIcon={<AddIcon />} style={{ marginBottom: '20px' }}>
                    {t('paymentsListPage.addNewPayment')}
                </Button>
                <IconButton onClick={toggleViewMode} color="primary">
                    {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
                </IconButton>
            </Box>
            {loading ? (
                <div style={{ width: '100%', marginTop: 20 }}>
                    <LinearProgress />
                </div>
            ) : viewMode === 'table' ? (
                <GenericTableView structure={transformData()} onClick={handleRowClick} />
            ) : (
                <GenericCardView structure={transformData()} onClick={handleRowClick} />
            )}
        </Paper>
    );
};

export default PaymentsListPage;
