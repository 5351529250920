import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    FormControlLabel,
    LinearProgress,
} from '@mui/material';
import { saveVetVisit, updateVetVisit, deleteVetVisit } from '../services/VetVisitService'; // Import the required service functions
import { useAuth } from '../context/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { useDateLocale } from '../hooks/useDateLocale';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { getAllHorses, getHorsesByOwnerID, getHorsesByUserId } from '../services/horseService';
import { fetchAllVeterinarians } from '../services/VeterinarianService';
import SnackbarAlert from './SnackbarAlert'; // Adjust the path as necessary

const VeterinarianVisitModal = ({ isOpen, onClose, record, horses = [], vets = [] }) => {
    const { apiKey, organizationId, token, ownerId, userType, userId } = useAuth();
    const { t } = useTranslation();
    const dateLocale = useDateLocale();
    const [horseRecords, setHorseRecords] = useState([]);
    const [vetRecords, setVetRecords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        id: '',
        visitDate: '',
        vetName: '',
        vetId: '',
        reason: '',
        findings: '',
        treatment: '',
        followUp: '',
        cost: '',
        notes: '',
        horseId: '',
        shareWithOwner: true,
    });
    const reasonForVisitOptions = [
        "Checkup",
        "Emergency",
        "Injury",
        "Illness",
        "Colic",
        "Other"
    ];
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // Can be 'error', 'warning', 'info', or 'success'
    });

    useEffect(() => {
        if (record) {
            setFormData({
                ...record,
                shareWithOwner: record.shareWithOwner ?? true,
                visitDate: record.visitDate ? new Date(record.visitDate).toISOString() : null,
            });
        } else {
            setFormData({
                id: uuidv4(),
                visitDate: '',
                nextVisitDate: '',
                vetId: '',
                vetName: '',
                reason: null,
                findings: '',
                treatment: '',
                followUp: '',
                cost: '',
                notes: '',
                horseId: '',
                shareWithOwner: true,
            });
        }

        const fetchData = async () => {
            try {
                const fetchedHorses = horses.length > 0 ? horses : userType === 'admin' ? await getAllHorses(apiKey, organizationId, token) : await getHorsesByUserId(userId, apiKey, organizationId, token);
                setHorseRecords(fetchedHorses.filter(horse => horse.permissions.includes('handleVeterinarian')));
                const fetchedVets = vets.length > 0 ? vets : await fetchAllVeterinarians(apiKey, organizationId, token);
                setVetRecords(fetchedVets);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData().then(() => setLoading(false));
    }, [record]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        if (name === 'horseId' && horseRecords.find((horse) => horse.id === value).veterinarianId) {
            setFormData((prev) => ({ ...prev, vetId: horseRecords.find((horse) => horse.id === value).veterinarianId }));
        }
    };

    const handleVetChange = (e) => {
        const { value } = e.target;
        const selectedVet = vetRecords.find(vet => vet.id === value);
        setFormData((prev) => ({
            ...prev,
            vetId: selectedVet ? selectedVet.id : '',
            vetName: selectedVet ? `${selectedVet.firstname} ${selectedVet.surname}` : '',
        }));
    };

    const handleVetNameChange = (e) => {
        const { value } = e.target;
        setFormData((prev) => ({
            ...prev,
            vetName: value,
            vetId: '',
        }));
    };

    const handleDateChange = (name, value) => {
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSwitchChange = (event) => {
        setFormData({ ...formData, shareWithOwner: event.target.checked });
    };

    const handleSubmit = async () => {
        try {
            if (record) {
                await updateVetVisit({ ...formData, id: record.id }, apiKey, organizationId, token, ownerId);
            } else {
                await saveVetVisit(formData, apiKey, organizationId, token, ownerId);
            }
            onClose(); // Close the dialog after saving data

            setSnackbar({
                open: true,
                message: t('veterinarianVisits.Dialog.saveSuccess'),
                severity: 'success',
            });
        } catch (error) {
            setSnackbar({
                open: true,
                message: t('veterinarianVisits.Dialog.saveError'),
                severity: 'error',
            });
        }
    };

    const handleDelete = async () => {
        try {
            await deleteVetVisit(record.id, apiKey, organizationId, token, ownerId);
            setSnackbar({
                open: true,
                message: t('veterinarianVisits.Dialog.deleteSuccess'),
                severity: 'success',
            });
            onClose(); // Close the dialog after saving data
        } catch (error) {
            setSnackbar({
                open: true,
                message: t('veterinarianVisits.Dialog.deleteError'),
                severity: 'error',
            });
        }
    }

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>
                    {t('veterinarianVisits.Dialog.title')}
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {loading && <LinearProgress />}
                    <FormControl fullWidth margin="normal">
                        <InputLabel>{t('veterinarianVisits.Dialog.horseName')}</InputLabel>
                        <Select
                            name="horseId"
                            value={formData.horseId}
                            onChange={handleChange}
                        >
                            {horseRecords.map((horse) => (
                                <MenuItem key={horse.id} value={horse.id}>
                                    {horse.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <DatePicker
                            label={t('veterinarianVisits.Dialog.dateofVisit')}
                            value={formData.visitDate ? new Date(formData.visitDate) : null}
                            onChange={(newValue) => handleDateChange('visitDate', newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <DatePicker
                            label={t('veterinarianVisits.Dialog.dateOfNextVisit')}
                            value={formData.nextVisitDate ? new Date(formData.nextVisitDate) : null}
                            onChange={(newValue) => handleDateChange('nextVisitDate', newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>{t('veterinarianVisits.Dialog.Veterian')}</InputLabel>
                        <Select
                            name="vetId"
                            value={formData.vetId}
                            onChange={handleVetChange}
                        >
                            <MenuItem value="">
                                {t('veterinarianVisits.Dialog.enterManually')}
                            </MenuItem>
                            {vetRecords.map((vet) => (
                                <MenuItem key={vet.id} value={vet.id}>
                                    {vet.firstname + ' ' + vet.surname}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {formData.vetId === '' && (
                        <TextField
                            label={t('veterinarianVisits.Dialog.vetName')}
                            name="vetName"
                            value={formData.vetName}
                            onChange={handleVetNameChange}
                            fullWidth
                            margin="normal"
                        />
                    )}
                    <Autocomplete
                        id="reason-for-visit"
                        options={reasonForVisitOptions.map(reason => t(`veterinarianVisits.VisitTypes.${reason}`))}
                        fullWidth
                        value={formData.reason ? t(`veterinarianVisits.VisitTypes.${formData.reason}`) : null}
                        onChange={(event, newValue) => {
                            const selectedReason = newValue
                                ? Object.keys(t('veterinarianVisits.VisitTypes', { returnObjects: true }))
                                    .find(key => t(`veterinarianVisits.VisitTypes.${key}`) === newValue)
                                : ''; // Set to empty if nothing is selected

                            setFormData((prev) => ({ ...prev, reason: selectedReason }));
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('veterinarianVisits.Dialog.reasonForVisit')}
                                margin="normal"
                            />
                        )}
                    />
                    <TextField label={t('veterinarianVisits.Dialog.findings')} name="findings" value={formData.findings} onChange={handleChange} fullWidth margin="normal" />
                    <TextField label={t('veterinarianVisits.Dialog.prescribedTreatment')} name="treatment" value={formData.treatment} onChange={handleChange} fullWidth margin="normal" />
                    <TextField label={t('veterinarianVisits.Dialog.followUpRequiered')} name="followUp" value={formData.followUp} onChange={handleChange} fullWidth margin="normal" />
                    <TextField label={t('veterinarianVisits.Dialog.costOfVisit')} name="cost" value={formData.cost} onChange={handleChange} fullWidth margin="normal" />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.shareWithOwner}
                                onChange={handleSwitchChange}
                                name="shareWithOwner"
                            />
                        }
                        label={t('veterinarianVisits.Dialog.shareWithOwner')}
                    />
                    <TextField label={t('veterinarianVisits.Dialog.AdditionalNotes')} name="notes" value={formData.notes} onChange={handleChange} fullWidth margin="normal" multiline rows={4} />
                </DialogContent>
                <DialogActions>
                    {record && (
                        <Button variant="contained" color="error" onClick={handleDelete}>
                            {t('veterinarianVisits.Dialog.delete')}
                        </Button>
                    )}
                    <Button variant="contained" color="secondary" onClick={onClose}>
                        {t('veterinarianVisits.Dialog.cancel')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        {t('veterinarianVisits.Dialog.save')}
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackbarAlert
                open={snackbar.open}
                onClose={handleSnackbarClose}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </LocalizationProvider>
    );
};

export default VeterinarianVisitModal;
