// src/pages/inventoryManagement/itemManagement/ItemUtils.js

export const categories = {
  feeding: {
    label: 'item.categories.feeding',
    subcategories: {
      grains: {
        label: "item.subcategories.grains",
        measureUnits: ['kg', 'bigbag', 'bale']
      },
      hay: {
        label: "item.subcategories.hay",
        measureUnits: ['kg', 'bigbag', 'quaderBale', 'roundBale', 'smallBale', 'largeBale']
      },
      straw: {
        label: "item.subcategories.straw",
        measureUnits: ['kg', 'bigbag', 'quaderBale', 'roundBale', 'smallBale', 'largeBale']
      },
      supplements: {
        label: "item.subcategories.supplements",
        measureUnits: ['kg', 'liter']
      },
      otherFeeding: {
        label: "item.subcategories.other",
        measureUnits: ['kg', 'liter', 'piece', 'bale']
      },
    },
  },
  healthcare: {
    label: 'item.categories.healthcare',
    subcategories: {
      veterinarian: {
        label: "item.subcategories.veterinarian",
        measureUnits: ['piece', 'liter']
      },
      farrier: {
        label: "item.subcategories.farrier",
        measureUnits: ['piece']
      },
      dentist: {
        label: "item.subcategories.dentist",
        measureUnits: ['piece']
      },
      otherHealthcare: {
        label: "item.subcategories.other",
        measureUnits: ['piece', 'liter']
      },
    },
  },
  bedding: {
    label: 'item.categories.bedding',
    subcategories: {
      strawBedding: {
        label: "item.subcategories.straw",
        measureUnits: ['kg', 'bigbag', 'quaderBale', 'roundBale', 'smallBale', 'largeBale']
      },
      shavings: {
        label: "item.subcategories.shavings",
        measureUnits: ['kg', 'bigbag']
      },
      strawPellets: {
        label: "item.subcategories.strawPellets",
        measureUnits: ['kg', 'bigbag']
      },
      otherBedding: {
        label: "item.subcategories.other",
        measureUnits: ['kg', 'bigbag', 'bale']
      },
    },
  },
  equipment: {
    label: 'item.categories.equipment',
    subcategories: {
      tack: {
        label: "item.subcategories.tack",
        measureUnits: ['piece']
      },
      ridingEquipment: {
        label: "item.subcategories.riding",
        measureUnits: ['piece']
      },
      otherEquipment: {
        label: "item.subcategories.other",
        measureUnits: ['piece', 'set']
      },
    },
  },
  other: {
    label: 'item.categories.other',
    subcategories: {
      general: {
        label: "item.subcategories.other",
        measureUnits: ['piece', 'kg', 'liter', 'set', 'bale']
      },
    },
  },
};



// Measures of unit mapping
export const measureUnits = {
  kg: 'measureUnits.kg',
  liter: 'measureUnits.liter',
  piece: 'measureUnits.piece',
  bigbag: 'measureUnits.bigbag',
  europallet: 'measureUnits.europallet',
  set: 'measureUnits.set',
  bale: 'measureUnits.bale',
  smallBale: 'measureUnits.smallBale',
  largeBale: 'measureUnits.largeBale',
  quaderBale: 'measureUnits.quaderBale',
  roundBale: 'measureUnits.roundBale',
};

