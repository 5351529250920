import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useMapDocumentToDeliveryNote from './useMapDocumentToDeliveryNote';
import {
  fetchDeliveryNoteById,
  saveDeliveryNote,
  updateDeliveryNote,
  downloadDeliveryNotePdf,
  sendDeliveryNotePdf,
} from '../../services/DeliveryNoteService';
import { getAllOwners } from '../../services/ownerService';
import { calculateTax, calculateTotal } from './DeliveryNoteUtils';

export function useDeliveryNote(deliveryNoteId, apiKey, organizationId, token, userId) {
  const location = useLocation();
  const [deliveryNoteData, setDeliveryNoteData] = useState({
    id: '',
    deliveryNoteNumber: '',
    customerId: '',
    customerName: '',
    deliveryDate: new Date().toISOString().split('T')[0],
    issueDate: new Date().toISOString().split('T')[0],
    recipientType: '',
    companyName: '',
    companyBRN: '',
    totalNetAmount: 0,
    taxAmount: 0,
    totalAmount: 0,
    rows: [],
    address: {
      street: '',
      zipCode: '',
      city: '',
      country: '',
      email: '',
    },
    status: 'draft',
    cancel: false,
    references: [],
  });
  const navigate = useNavigate();

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const state = location.state || {};
  const [isCancelled, setIsCancelled] = useState(false);
  useMapDocumentToDeliveryNote(state, deliveryNoteData, setDeliveryNoteData);

  useEffect(() => {
    if (deliveryNoteId !== 'new') {
      fetchDeliveryNote();
    } else {
      setLoading(false);
    }
  }, [deliveryNoteId]);

  useEffect(() => {
    async function fetchAndSetOwners() {
      try {
        const ownersData = await getAllOwners(apiKey, organizationId, token);
        setCustomers(ownersData);
      } catch (error) {
        console.error(error);
      }
    }
    fetchAndSetOwners();
  }, [apiKey, organizationId, token]);

  const fetchDeliveryNote = async () => {
    try {
      const data = await fetchDeliveryNoteById(apiKey, organizationId, token, deliveryNoteId, userId);
      setDeliveryNoteData(data);
      setIsCancelled(data.cancel || false);
    } catch (error) {
      console.error('Error fetching delivery note:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRows = [...deliveryNoteData.rows];
    const row = updatedRows[index];

    // Parse input values to numeric types where applicable
    let numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
      numericValue = 0; // Default to 0 if the value is not a valid number
    }

    if (name === 'quantity' || name === 'bruttoPrice' || name === 'discountAmount' || name === 'discountPercentage' || name === 'taxRate' || name === 'netPrice') {
      row[name] = numericValue;
    } else {
      row[name] = value; // Handle non-numeric fields normally
    }

    if (name === 'discountPercentage' || name === 'discountAmount') {
      const bruttoPrice = parseFloat(row.bruttoPrice) || 0;
      let discountPercentage = parseFloat(row.discountPercentage) / 100 || 0;
      let discountAmount = parseFloat(row.discountAmount) || 0;

      if (name === 'discountPercentage') {
        discountAmount = parseFloat((bruttoPrice * discountPercentage).toFixed(2));
        row.discountAmount = discountAmount;
      } else if (name === 'discountAmount') {
        discountPercentage = parseFloat((discountAmount / bruttoPrice).toFixed(2));
        row.discountPercentage = (discountPercentage * 100).toFixed(2);
      }
    }

    if (name === 'netPrice' || name === 'bruttoPrice' || name === 'quantity' || name === 'discountPercentage' || name === 'discountAmount' || name === 'taxRate') {
      const quantity = parseFloat(row.quantity) || 0;
      const bruttoPrice = parseFloat(row.bruttoPrice) || 0;
      const taxRate = parseFloat(row.taxRate) / 100 || 0;
      const discountPercentage = parseFloat(row.discountPercentage) / 100 || 0;
      const discountAmount = parseFloat(row.discountAmount) || 0;
      const netPrice = parseFloat(row.netPrice) || 0;

      if (name === 'netPrice') {
        // Calculate bruttoPrice based on netPrice
        row.bruttoPrice = parseFloat((netPrice * (1 + taxRate) + discountAmount).toFixed(2));
      } else {
        // Calculate netPrice based on bruttoPrice
        const discountedPrice = parseFloat((bruttoPrice - discountAmount).toFixed(2));
        row.netPrice = parseFloat((discountedPrice / (1 + taxRate)).toFixed(2));
      }

      // Update rowTotal based on quantity and discountedPrice
      const finalDiscountedPrice = parseFloat((row.bruttoPrice - discountAmount).toFixed(2));
      const rowTotal = parseFloat((quantity * finalDiscountedPrice).toFixed(2));
      row.rowTotal = rowTotal;
    }



    setDeliveryNoteData({
      ...deliveryNoteData,
      rows: updatedRows,
    });

    calculateTotals();
  };

  const calculateTotals = () => {
    const totalAmount = calculateTotal(deliveryNoteData.rows);
    const taxAmount = calculateTax(deliveryNoteData.rows);

    setDeliveryNoteData((prevData) => ({
      ...prevData,
      totalAmount: totalAmount,
      taxAmount: taxAmount,
      totalNetAmount: totalAmount - taxAmount,
    }));
  };


  const handleCustomerChange = (event, newValue, reason) => {
    if (reason === 'selectOption' && newValue) {
      const selectedCustomer = customers.find((customer) => customer.id === newValue.id);
      if (selectedCustomer) {
        const { customerName, companyName, companyBRN, recipientType, ownerAddress } = getHeaderAndAddressData(selectedCustomer);
        setDeliveryNoteData(prevData => ({
          ...prevData,
          customerId: newValue.id,
          customerName: newValue.label,
          companyName: companyName,
          companyBRN: companyBRN,
          recipientType: recipientType,
          address: ownerAddress,
        }));
      }
    } else if (reason === 'clear') {
      setDeliveryNoteData(prevData => ({
        ...prevData,
        customerId: '',
        customerName: '',
        companyName: '',
        companyBRN: '',
        recipientType: '',
        address: {
          street: '',
          zipCode: '',
          city: '',
          country: '',
          email: '',
        },
      }));
    } else if (reason === 'create-option' || reason === 'input') {
      setDeliveryNoteData(prevData => ({
        ...prevData,
        customerId: '',
        customerName: newValue,
        companyName: '',
        companyBRN: '',
        recipientType: '',
        address: {
          street: '',
          zipCode: '',
          city: '',
          country: '',
          email: '',
        },
      }));
    }
  };

  const getHeaderAndAddressData = (selectedOwner) => {
    const headerAndAddress = {
      customerName: selectedOwner.firstName + ' ' + selectedOwner.surname || '',
      companyName: selectedOwner.companyName || '',
      companyBRN: selectedOwner.companyBRN || '',
      recipientType: selectedOwner.ownerType || '',
      ownerAddress: {
        street: selectedOwner.address?.street || '',
        zipCode: selectedOwner.address?.zipCode || '',
        city: selectedOwner.address?.city || '',
        country: selectedOwner.address?.country || '',
        email: selectedOwner.email || '',
      },
    };
    return headerAndAddress;
  };

  const handleAddressChange = (newAddress) => {
    setDeliveryNoteData({
      ...deliveryNoteData,
      address: newAddress,
    });
  };

  const addRow = (row) => {
    if (row) {
      setDeliveryNoteData({
        ...deliveryNoteData,
        rows: [...deliveryNoteData.rows, row],
      });
    } else {
      const newRow = {
        description: '',
        type: '',
        quantity: 1,
        netPrice: 0,
        taxRate: 0,
        bruttoPrice: 0,
        discountPercentage: 0,
        discountAmount: 0,
        rowTotal: 0,
        itemId: '',
      };

      setDeliveryNoteData({
        ...deliveryNoteData,
        rows: [...deliveryNoteData.rows, newRow],
      });
    }
  };

  const removeRow = (index) => {
    const updatedRows = [...deliveryNoteData.rows];
    updatedRows.splice(index, 1);
    setDeliveryNoteData({
      ...deliveryNoteData,
      rows: updatedRows,
    });
    calculateTotals();
  };

  const saveOrUpdateDeliveryNote = async (setSnackbar) => {
    try {
      if (deliveryNoteId === 'new') {
        await saveDeliveryNote(deliveryNoteData, apiKey, organizationId, token, userId);
      } else {
        await updateDeliveryNote(deliveryNoteData, apiKey, organizationId, token, userId);
      }
      setSnackbar({ open: true, message: 'Delivery note saved successfully', severity: 'success' });
      navigate('/sales-process?tab=2');
      return true;
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to save delivery note', severity: 'error' });
      console.error('Error saving delivery note:', error);
      return false;
    }
  };

  const handleDownloadPdf = async (deliveryNoteId) => {
    try {
      const pdfBlob = await downloadDeliveryNotePdf(apiKey, organizationId, token, deliveryNoteId, userId);
      const url = window.URL.createObjectURL(new Blob([pdfBlob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `DeliveryNote_${deliveryNoteId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  const handleSendPdf = async (deliveryNoteId) => {
    try {
      await sendDeliveryNotePdf(apiKey, organizationId, token, deliveryNoteId, userId);
    } catch (error) {
      console.error('Error sending PDF:', error);
    }
  };

  const handleCancel = async () => {
    setIsCancelled(true);
    navigate('/sales-process?tab=2');
    setDeliveryNoteData({ ...deliveryNoteData, cancel: true });
  };

  const onTransferToInvoice = () => {
    navigate('/sales-process/invoices/detail/new', {
      state: {
        fromDocument: {
          sourceDocumentType: 'deliveryNote',
          ...deliveryNoteData,
          references: [
            {
              sourceDocumentType: 'deliveryNote',
              sourceDocumentId: deliveryNoteData.id,
              tartgetDocumentType: 'invoice',
              targetDocumentId: '',
            }],
        }
      }
    });
  }

  return {
    deliveryNoteData,
    customers,
    isCancelled,
    loading,
    setDeliveryNoteData,
    saveOrUpdateDeliveryNote,
    removeRow,
    addRow,
    handleInputChange,
    handleCustomerChange,
    handleAddressChange,
    handleDownloadPdf,
    handleSendPdf,
    handleCancel,
    onTransferToInvoice,
  };
}
