// src/pages/DeliveryPlanning/SortableSalesOrderItem.js

import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';

import { useTranslation } from 'react-i18next';

const SortableSalesOrderItem = ({ id, salesOrder, onRemove, dragOverlay }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id });

    const { t } = useTranslation();

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: '#f9f9f9',
        padding: '8px',
        marginBottom: '8px',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'grab',
        zIndex: dragOverlay ? 1000 : 'auto',
    };

    const content = (
        <>
            <Box sx={{ mr: 2 }}>
                <DragHandleIcon />
            </Box>
            <Box flexGrow={1}>
                <Typography variant="body1">{t('SortableSalesOrderItem.orderId', { orderNumber: salesOrder.salesOrderNumber })}</Typography>
                <Typography variant="body2">{t('SortableSalesOrderItem.customer', { customerName: salesOrder.customerName })}</Typography>
                <Typography variant="body2">{t('SortableSalesOrderItem.items', { items: salesOrder.summary.totalItem })}</Typography>
                <Typography variant="body2">{t('SortableSalesOrderItem.totalAmount', { amount: salesOrder.summary.totalAmount.toFixed(2) })}</Typography>
            </Box>
            {onRemove && (
                <IconButton onClick={() => onRemove(id)} aria-label={t('SortableSalesOrderItem.removeSalesOrderAriaLabel')}>
                    <DeleteIcon />
                </IconButton>
            )}
        </>
    );

    if (dragOverlay) {
        return (
            <div style={{ ...style, width: '300px' }}>
                {content}
            </div>
        );
    }

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
        >
            {content}
        </div>
    );
};

export default SortableSalesOrderItem;
