import React, { useState, useEffect } from 'react';
import { Fab, Menu, MenuItem, Icon, Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from '../context/AuthContext'; // Adjust import path as necessary
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../context/PermissionsContext';
import { usePreferences } from '../context/UserPreferencesContext';

// Modal Components
import DocumentUploadModal from './DocumentUploadModal';
import RidingLessonBookingModal from './RidingLessonBookingModal';
import VaccinationModal from './VaccinationModal';
import FarrierVisitModal from './FarrierVisitModal';
import VeterinarianVisitModal from './VeterinarianVisitModal';
import MachineBookingModal from './MachineBookingModal';
import InventoryTransactionModal from './InventoryTransactionModal';
import InviteUserModal from './InviteUserModal';
import HorseActivityVoteModal from './HorseActivityVoteModal';

// Icons
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { MdPlayLesson } from "react-icons/md";
import InventoryIcon from '@mui/icons-material/Inventory';
import { GiHorseshoe } from "react-icons/gi";
import PersonAddAlt from '@mui/icons-material/PersonAddAlt';
import HowToVoteIcon from '@mui/icons-material/HowToVote';

const FloatingIconButton = () => {
  const { userPreferences, updateFavoriteActions } = usePreferences();
  const { userType } = useAuth();
  const { entitlements } = usePermissions();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState('');
  const { t } = useTranslation();
  const [favoriteActions, setFavoriteActions] = useState([]);

  // Extract features from entitlements
  const features = entitlements
    .map(entitlement => entitlement.features)
    .flat()
    .filter(feature => feature.status === 'enabled')
    .map(feature => feature.name);

  useEffect(() => {
    if (userPreferences && userPreferences.favoriteActions) {
      setFavoriteActions(userPreferences.favoriteActions);
    }
  }, [userPreferences]);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = (modalName) => {
    setOpenModal(modalName);
    handleCloseMenu();
  };

  const handleCloseModal = () => {
    setOpenModal('');
  };

  const handleToggleFavorite = async (actionId, e) => {
    e.stopPropagation();
    await updateFavoriteActions(actionId);
    handleCloseMenu();
  };

  const modalOptions = [
    { label: t('FloatingIconButton.documentUpload'), id: 'documentUpload', modalName: 'documentUpload', icon: DocumentScannerIcon, allowedUserTypes: ['admin'], requiredFeatures: ['document-management'] },
    { label: t('FloatingIconButton.ridingLessonBooking'), id: 'ridingLessonBooking', modalName: 'ridingLessonBooking', icon: MdPlayLesson, allowedUserTypes: ['admin'], requiredFeatures: ['booking-management'] },
    { label: t('FloatingIconButton.machineBooking'), id: 'machineBooking', modalName: 'machineBooking', icon: FitnessCenterIcon, allowedUserTypes: ['admin', 'customer'], requiredFeatures: ['machine-management'] },
    { label: t('FloatingIconButton.vaccination'), id: 'vaccination', modalName: 'vaccination', icon: VaccinesIcon, allowedUserTypes: ['customer', 'admin'], requiredFeatures: ['vaccination-management'] },
    { label: t('FloatingIconButton.farrierVisit'), id: 'farrierVisit', modalName: 'farrierVisit', icon: GiHorseshoe, allowedUserTypes: ['admin', 'customer'], requiredFeatures: ['farrier-management'] },
    { label: t('FloatingIconButton.veterinarianVisit'), id: 'veterinarianVisit', modalName: 'veterinarianVisit', icon: LocalHospitalIcon, allowedUserTypes: ['admin', 'customer'], requiredFeatures: ['veterinarian-management'] },
    { label: t('FloatingIconButton.inventoryTransaction'), id: 'inventoryTransaction', modalName: 'inventoryTransaction', icon: InventoryIcon, allowedUserTypes: ['admin'], requiredFeatures: ['inventory-management'] },
    { label: t('FloatingIconButton.inviteUser'), id: 'inviteUser', modalName: 'inviteUser', icon: PersonAddAlt, allowedUserTypes: ['admin'], requiredFeatures: [] },
    { label: t('FloatingIconButton.horseActivityVote'), id: 'horseActivityVote', modalName: 'horseActivityVote', icon: HowToVoteIcon, allowedUserTypes: ['admin', 'customer'], requiredFeatures: ['horse-activity-voting'] },
  ].filter(option =>
    option.allowedUserTypes.includes(userType) &&
    (option.requiredFeatures.length === 0 || option.requiredFeatures.every(feature => features.includes(feature)))
  );

  // Constants for spacing between floating favorites
  const baseBottom = 88; // Initial spacing from the bottom
  const verticalSpacing = 72; // Adjust this value to increase or decrease spacing between favorites

  const IconWrapper = ({ icon: Icon, size = 24 }) => (
    <Icon component={Icon} fontSize="inherit" style={{ width: size, height: size, display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
  );

  return (
    <>
      {favoriteActions?.map((favId, index) => {
        const favOption = modalOptions.find(option => option.id === favId);
        return favOption ? (
          <Fab
            key={favId}
            color="secondary"
            aria-label="favorite"
            sx={{
              position: 'fixed',
              bottom: baseBottom + index * verticalSpacing, // Calculate each button's position based on index
              right: 16,
            }}
            onClick={() => handleOpenModal(favOption.modalName)}
          >
            <IconWrapper icon={favOption.icon} />
          </Fab>
        ) : null;
      })}
      <Fab color="primary" aria-label="add" onClick={handleOpenMenu} sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        <AddIcon />
      </Fab>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        {modalOptions.map((option) => (
          <MenuItem key={option.id} onClick={() => handleOpenModal(option.modalName)}>
            <Box display="flex" alignItems="center" flexGrow={1}>
              <IconWrapper icon={option.icon} />
              {option.label}
            </Box>
            <IconButton
              edge="end"
              onClick={async (e) => await handleToggleFavorite(option.id, e)}
            >
              {favoriteActions.includes(option.id) ? <StarIcon /> : <StarBorderIcon />}
            </IconButton>
          </MenuItem>
        ))}
      </Menu>
      {/* Modal components */}
      <InviteUserModal isOpen={openModal === 'inviteUser'} onClose={handleCloseModal} />
      <DocumentUploadModal isOpen={openModal === 'documentUpload'} onClose={handleCloseModal} />
      <RidingLessonBookingModal open={openModal === 'ridingLessonBooking'} onClose={handleCloseModal} onSave={handleCloseModal} />
      <VaccinationModal isOpen={openModal === 'vaccination'} onClose={handleCloseModal} />
      <FarrierVisitModal isOpen={openModal === 'farrierVisit'} onClose={handleCloseModal} />
      <VeterinarianVisitModal isOpen={openModal === 'veterinarianVisit'} onClose={handleCloseModal} />
      <MachineBookingModal isOpen={openModal === 'machineBooking'} onClose={handleCloseModal} />
      <InventoryTransactionModal open={openModal === 'inventoryTransaction'} onClose={handleCloseModal} />
      <HorseActivityVoteModal open={openModal === 'horseActivityVote'} onClose={handleCloseModal} />
      {/* Render other modals based on `openModal` state */}
    </>
  );
};

export default FloatingIconButton;
