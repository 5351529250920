// src/pages/inventoryManagement/inventoryManagement/InventoryTransactions.js
import React, { useState, useEffect } from 'react';
import { Button, Paper, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from '../../../context/AuthContext';
import { getTransactions } from '../../../services/transactionService';
import { useTranslation } from 'react-i18next';
import { useDateLocale } from '../../../hooks/useDateLocale';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { getAllItems } from '../../../services/itemService';
import GenericCardView from '../../../components/GenericCardView';
import GenericTableView from '../../../components/GenericTableView';
import IconButton from '@mui/material/IconButton';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useTheme, useMediaQuery } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { usePreferences } from '../../../context/UserPreferencesContext';


const InventoryTransactions = () => {
  const navigate = useNavigate();
  const { apiKey, organizationId, token: jwtToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const { t } = useTranslation(); // Hook for translation
  const dateLocale = useDateLocale();
  const [items, setItems] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [viewMode, setViewMode] = useState(isMobile ? 'card' : 'table');
  const { devicePreferences, updateDevicePreferences } = usePreferences();


  const handleRowClick = (transactionId) => {
    // Navigate to the transaction edit page
    navigate(`/inventory/transaction/${transactionId}`);
  };

  const handleAddNewTransaction = () => {
    // Navigate to the page to add a new transaction
    navigate('/inventory/transaction/new');
  };

  const translateType = (type) => {
    return t(`TransactionManagement.${type}`);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [transactionsData, itemsData] = await Promise.all([
          getTransactions(apiKey, organizationId, jwtToken),
          getAllItems(apiKey, organizationId, jwtToken) // Fetch items
        ]);

        // Merge transactions with items to display item names
        const mergedTransactions = transactionsData.map(transaction => {
          const item = itemsData.find(item => item.id === transaction.itemId); // Find the corresponding item
          return {
            ...transaction,
            date: format(new Date(transaction.date), 'P', { locale: dateLocale }),
            itemName: item ? item.name : 'Unknown Item' // Use item name
          };
        });

        setTransactions(mergedTransactions);
        setItems(itemsData); // Update items state
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiKey, organizationId, jwtToken]);

  // Update local state when context updates
  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);



  const toggleViewMode = () => {
    updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
  };

  const transformData = () => {
    return {
      headers: [
        { key: 'itemName', label: t('InventoryTransactions.item'), display: { value: true, chipColor: false } },
        { key: 'date', label: t('InventoryTransactions.date'), display: { value: true, chipColor: false } },
        { key: 'type', label: t('InventoryTransactions.type'), display: { value: true, chipColor: false } },
        { key: 'quantity', label: t('InventoryTransactions.quantity'), display: { value: true, chipColor: false } }
      ],
      rows: transactions.map(transaction => ({
        id: transaction.id,
        date: { value: transaction.date, icon: null },
        type: { value: translateType(transaction.type), icon: null },
        itemName: { value: transaction.itemName, icon: null },
        quantity: { value: transaction.quantity, icon: null }
      }))
    };
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
      <Paper elevation={3} sx={{ mt: 4, p: 3 }}>

        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddNewTransaction}
          >
            {t('InventoryTransactions.addNewTransaction')}
          </Button>
          <IconButton onClick={toggleViewMode} color="primary">
            {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
          </IconButton>
        </Box>
        {loading ? (
          <div style={{ width: '100%', marginTop: 20 }}>
            <LinearProgress />
          </div>
        ) : viewMode === 'table' ? (
          <GenericTableView structure={transformData()} onClick={handleRowClick} />
        ) : (
          <GenericCardView structure={transformData()} onClick={handleRowClick} />
        )}
      </Paper>
    </LocalizationProvider>
  );
};

export default InventoryTransactions;
