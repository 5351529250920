import React, { useState, useEffect } from 'react';
import {
  Paper,
  Button,
  Chip,
  Box,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';
import { fetchAllDeliveryNotes } from '../services/DeliveryNoteService';
import { useAuth } from '../context/AuthContext';
import { usePreferences } from '../context/UserPreferencesContext';
import GenericCardView from '../components/GenericCardView';
import GenericTableView from '../components/GenericTableView';
import SnackbarAlert from '../components/SnackbarAlert';
import { approveDocument } from '../services/BusinessDocumentService';

const DeliveryNotesListPage = () => {
  const { t } = useTranslation();
  const { apiKey, organizationId, token, userId } = useAuth();
  const [deliveryNotes, setDeliveryNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [viewMode, setViewMode] = useState(isMobile ? 'card' : 'table');
  const { devicePreferences, updateDevicePreferences } = usePreferences();

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const notesData = await fetchAllDeliveryNotes(apiKey, organizationId, token, userId);
        setDeliveryNotes(notesData);
      } catch (error) {
        console.error("Error fetching delivery note data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotes();
  }, [apiKey, organizationId, token, userId]);

  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);

  const navigateToDetail = (noteId) => {
    navigate(`/sales-process/deliveryNotes/detail/${noteId}`);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const renderStatus = (note) => {
    if (note.cancel && note.cancel === true) {
      return <Chip icon={<WarningAmberIcon />} label={t("deliveryNotesListPage.cancelled")} color="secondary" />;
    } else if (note.status === 'draft') {
      return <Chip icon={<CancelIcon />} label={t("deliveryNotesListPage.draft")} color="default" />;
    } else if (note.status === 'approved') {
      return <Chip icon={<CheckCircleIcon />} label={t("deliveryNotesListPage.approved")} color="primary" />;
    } else if (note.status === 'Completed') {
      return <Chip icon={<CheckCircleIcon />} label={t("deliveryNotesListPage.completed")} color="primary" />;
    } else {
      return <Chip icon={<CancelIcon />} label={t("deliveryNotesListPage.unapproved")} color="error" />;
    }
  };

  const toggleViewMode = () => {
    updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
  };

  const approveDeliveryNote = async (noteId) => {
    try {
      await approveDocument(apiKey, organizationId, token, userId, noteId, 'deliveryNote');
      setSnackbar({ open: true, message: t("deliveryNotesListPage.noteApproved"), severity: 'success' });
      setDeliveryNotes(deliveryNotes.map(note => note.id === noteId ? { ...note, status: 'approved' } : note));
    } catch (error) {
      console.error("Error approving delivery note: ", error);
      setSnackbar({ open: true, message: t("deliveryNotesListPage.errorApprovingNote"), severity: 'error' });
    }
  };


  const transformData = () => {
    return {
      headers: [
        { key: 'deliveryNoteNumber', label: t("deliveryNotesListPage.deliveryNoteNumber"), display: { value: true, chipColor: false } },
        { key: 'customerName', label: t("deliveryNotesListPage.customerName"), display: { value: true, chipColor: false } },
        { key: 'deliveryDate', label: t("deliveryNotesListPage.deliveryDate"), display: { value: true, chipColor: false } },
        { key: 'totalAmount', label: t("deliveryNotesListPage.totalAmount"), display: { value: true, chipColor: false } },
        { key: 'status', label: t("deliveryNotesListPage.status"), display: { value: true, chipColor: false } }
      ],
      rows: deliveryNotes.map(note => ({
        id: note.id,
        deliveryNoteNumber: { value: note.deliveryNoteNumber, icon: null },
        customerName: { value: note.customerName, icon: null },
        deliveryDate: { value: new Date(note.deliveryDate).toLocaleDateString(), icon: null },
        totalAmount: { value: note.totalAmount, icon: null },
        status: { value: renderStatus(note), icon: null },
        actions: [
          {
            icon: <CheckCircleIcon />,
            handler: note.status !== 'draft' ? null : () => approveDeliveryNote(note.id),
            disabled: note.status !== 'draft' || note.cancel,
            label: t("deliveryNotesListPage.approve")
          }
        ]
      }))
    };
  };


  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} component={Link} to="/sales-process/deliveryNotes/detail/new">
          {t("deliveryNotesListPage.addNewDeliveryNote")}
        </Button>
        <IconButton onClick={toggleViewMode} color="primary">
          {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
        </IconButton>
      </Box>
      {loading ? (
        <div style={{ width: '100%', marginTop: 20 }}>
          <LinearProgress />
        </div>
      ) : viewMode === 'table' ? (
        <GenericTableView structure={transformData()} onClick={navigateToDetail} />
      ) : (
        <GenericCardView structure={transformData()} onClick={navigateToDetail} />
      )}

      <SnackbarAlert
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </Paper>
  );
};

export default DeliveryNotesListPage;
