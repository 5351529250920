import React from 'react';
import { Typography, Paper, Divider, Box } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import GenericCardView from './GenericCardView'; // Import your GenericCardView
import GenericTableView from './GenericTableView'; // Import your GenericTableView
import { useTheme, useMediaQuery } from '@mui/material';

const GenericDetailView = ({ structure, data }) => {
  const formatValue = (field, value) => {
    if (field.format && field.format === 'date') {
      return new Date(value).toLocaleDateString();
    }
    return value;
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderField = (field) => (
    <Box key={field.key}>
      <Typography variant="body2" color="text.secondary">
        {field.label}
      </Typography>
      <Typography variant="body1">
        {formatValue(field, data[field.key])}
      </Typography>
    </Box>
  );

  const renderComponent = (component) => {
    const viewData = {
      headers: component.headers,
      rows: data[component.rowArrayKey]
    }
    if (!data[component.rowArrayKey]) {
      return null;
    }
    switch (component.type) {
      case 'card':
        return <GenericCardView structure={viewData} onClick={component.onClick} />;
      case 'table':
        return <GenericTableView structure={viewData} onClick={component.onClick} />;
      case 'responsive':
        const ComponentToRender = isMobile ? GenericCardView : GenericTableView;
        return <ComponentToRender structure={viewData} onClick={component.onClick} />;
        break;
    }
  };

  return (
    <Masonry columns={{ xs: 1, sm: 2, md: 2 }} spacing={2}>
      {structure.sections.map((section, sectionIndex) => (
        <div key={sectionIndex}>
          <Paper elevation={2}>
            <Box sx={{ p: 2, bgcolor: 'background.default' }}>
              <Typography variant="h6" gutterBottom component="div">
                {section.title}
              </Typography>
              {/* Render groups if they exist */}
              {section.groups?.map((group, groupIndex) => (
                <div key={groupIndex}>
                  <Typography variant="subtitle1" sx={{ mt: 2 }}>
                    {group.title}
                  </Typography>
                  {group.fields.map((field) => renderField(field))}
                  {groupIndex !== section.groups.length - 1 && <Divider />}
                </div>
              ))}
              {/* Render ungrouped fields */}
              {section.fields?.map((field) => renderField(field))}
              {/* Render components such as GenericCardView or GenericTableView */}
              {section.components?.map((component, componentIndex) => (
                <Box key={componentIndex} sx={{ my: 2 }}>
                  {renderComponent(component)}
                </Box>
              ))}
            </Box>
          </Paper>
        </div>
      ))}
    </Masonry>
  );
};

export default GenericDetailView;
