// src/services/VehicleService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig';

const VEHICLE_API_BASE_URL = '/api/organization/vehicles';

// Fetch all vehicles
export async function fetchVehicles(apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.get(`${VEHICLE_API_BASE_URL}`);
    return response.data;
}

// Fetch specific vehicle by ID
export async function fetchVehicleById(apiKey, organizationId, jwtToken, vehicleId) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.get(`${VEHICLE_API_BASE_URL}?id=${vehicleId}`);
    return response.data;
}

// Create a new vehicle
export async function addVehicle(vehicleData, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.post(`${VEHICLE_API_BASE_URL}`, vehicleData);
    return response.data;
}

// Update an existing vehicle
export async function updateVehicle(vehicleData, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.put(`${VEHICLE_API_BASE_URL}?id=${vehicleData.id}`, vehicleData);
    return response.data;
}

// Delete a vehicle by ID
export async function deleteVehicle(vehicleId, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.delete(`${VEHICLE_API_BASE_URL}?id=${vehicleId}`);
    return response.data;
}

export default {
    fetchVehicles,
    fetchVehicleById,
    addVehicle,
    updateVehicle,
    deleteVehicle,
};
