// src\components\SelfSignup\HorseOwnership.js


import { useState } from 'react';
import { Button, Box } from '@mui/material';
import HorseForm from './HorseForm';
import { useTranslation } from 'react-i18next';

const HorseOwnership = ({ onNext, veterinarians, ferries, addNewPerson }) => {
    const { t } = useTranslation();
    const [horses, setHorses] = useState([]);

    const addHorse = () => {
        setHorses(prev => [...prev,
        {
            name: '',
            birthday: '',
            breed: '',
            emergencyContact: {
                name: '',
                number: '',
            },
            veterinarianId: '',
            farrierId: '',
        }]);
    }

    const areAllHorsesValid = () => {
        return horses.every(horse => {
            return (
                horse.name &&
                horse.emergencyContact.name &&
                horse.emergencyContact.number
            );
        });
    };


    const removeHorse = (index) => {
        const newHorses = [...horses];
        newHorses.splice(index, 1);
        setHorses(newHorses);
    }

    const updateHorseData = (index, newData) => {
        const newHorses = [...horses];
        newHorses[index] = newData;
        setHorses(newHorses);
    }

    const handleNext = () => {
        onNext(horses);
    }

    return (
        <div>
            {horses.map((horseData, index) => (
                <Box mb={3} key={index}> {/* Space between each HorseForm */}
                    <HorseForm
                        onRemove={() => removeHorse(index)}
                        initialData={horseData}
                        onUpdate={(data) => updateHorseData(index, data)}
                        addNewPerson={addNewPerson}
                        veterinarians={veterinarians}
                        ferries={ferries}
                    />
                </Box>
            ))}
            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center"> {/* Container for buttons with space between */}
                <Button onClick={addHorse} variant="contained" color="secondary">{t('HorseOwnership.buttons.addHorse')}</Button>
                <Box ml={2}> {/* Space between the buttons */}
                    <Button onClick={handleNext} variant="contained" color="primary" disabled={!areAllHorsesValid()}>{t('HorseOwnership.buttons.next')}</Button>
                </Box>
            </Box>
        </div>
    );
};


export default HorseOwnership;
