// src/pages/auth/HorseForm.js

import { useState, useEffect } from 'react';
import { TextField, Grid, Button, Box, FormControl, InputLabel, Select, MenuItem, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import { useDateLocale } from '../../hooks/useDateLocale';  // Your custom hook
import AddPersonModal from './AddPersonModal';
import { parseISO } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const HorseForm = ({ onRemove, initialData, onUpdate, veterinarians, ferries, addNewPerson, isFilledByScan = false }) => {
    const { t } = useTranslation();
    const dateLocale = useDateLocale();
    const [expandedAccordion, setExpandedAccordion] = useState('panel1');
    const [modalInfo, setModalInfo] = useState({
        open: false,
        type: null, // 'veterinarian' or 'farrier'
    });
    const defaultHorseData = {
        name: '',
        birthday: '',
        breed: '',
        emergencyContact: {
            name: '',
            number: '',
        },
        veterinarianId: '',
        farrierId: '',
    };

    const [horse, setHorse] = useState({
        ...defaultHorseData,
        ...initialData,
        emergencyContact: {
            ...defaultHorseData.emergencyContact,
            ...(initialData && initialData.emergencyContact)
        },
        veterinarianId: initialData ? initialData.veterinarianId : '',
        farrierId: initialData ? initialData.farrierId : '',

    });

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : false);
    };

    const handleAddNewPerson = (person) => {
        const newPersonId = uuidv4(); // Assume this generates a unique ID for the new person
        const newPerson = {
            id: newPersonId,
            ...person,
        };


        if (modalInfo.type === 'veterinarian') {
            const person = {
                id: newPerson.id,
                firstname: newPerson.firstname,
                surname: newPerson.surname,
                phoneNumber: newPerson.phoneNumber,
            };
            addNewPerson('veterinarians', person);
        } else if (modalInfo.type === 'farrier') {
            const person = {
                id: newPerson.id,
                firstname: newPerson.firstname,
                surname: newPerson.surname,
                phoneNumber: newPerson.phoneNumber,
            };
            addNewPerson('ferries', person);
        }
        setHorse(prevHorse => ({
            ...prevHorse,
            [modalInfo.type === 'veterinarian' ? 'veterinarianId' : 'farrierId']: newPersonId,
        }));
        setModalInfo({ open: false, type: null });
    };

    useEffect(() => {
        onUpdate(horse);
    }, [horse, onUpdate]);

    // Generic input change handler that supports nested paths
    const handleInputChange = (event) => {
        const name = event.target.name; // Could be a nested path like "microchipIdentification.number"
        const value = event.target.value;

        setHorse((prevHorse) => {
            return setNestedObjectValue(prevHorse, name, value);
        });
    };

    // Utility function to update nested state based on a path like "microchipIdentification.number"
    function setNestedObjectValue(obj, path, value) {
        const keys = path.split('.');
        const lastKey = keys.pop();
        const lastObj = keys.reduce((obj, key) => obj[key] = obj[key] || {}, obj);
        lastObj[lastKey] = value;
        return { ...obj };
    }


    const handleOpenModal = (type) => {
        setModalInfo({ open: true, type });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
            <Grid item xs={12}>
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h6" component="h2">{horse.name}</Typography>
                    <IconButton onClick={onRemove} color="error">
                        <DeleteIcon />
                    </IconButton>
                </Box>
            </Grid>
            {/* Identification and Microchip */}
            <Accordion expanded={expandedAccordion === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{t('HorseInfoPage.basicInfo')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{ mt: 4 }}>
                                <TextField
                                    fullWidth
                                    label={t('HorseForm.labels.name')}
                                    name="name"
                                    value={horse.name}
                                    onChange={handleInputChange}
                                    required
                                    error={!horse.name}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{ mt: 2 }}>
                                <DatePicker
                                    label={t('HorseForm.labels.birthday')}
                                    value={horse.birthday ? parseISO(horse.birthday) : null}
                                    onChange={(newValue) => setHorse(prev => ({ ...prev, birthday: newValue.toISOString() }))}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{ mt: 2 }}>
                                <TextField
                                    fullWidth
                                    label={t('HorseForm.labels.breed')}
                                    name="breed"
                                    value={horse.breed}
                                    onChange={handleInputChange}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{ mt: 2 }}>
                                <TextField
                                    fullWidth
                                    label={t('HorseInfoPage.identificationNumber')}
                                    name="identificationNumber"
                                    value={horse.identificationNumber}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{ mt: 2 }}>
                                <TextField
                                    fullWidth
                                    label={t('HorseInfoPage.color')}
                                    name="color"
                                    value={horse.color}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{ mt: 2 }}>
                                <TextField
                                    fullWidth
                                    label={t('HorseInfoPage.sex')}
                                    name="sex"
                                    value={horse.sex}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                />
                            </Box>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={t('HorseForm.labels.emergencyContactName')}
                                name="emergencyContact.name"
                                value={horse.emergencyContact.name}
                                required
                                error={!horse.emergencyContact.name}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={t('HorseForm.labels.emergencyContactNumber')}
                                name="emergencyContact.number"
                                required
                                error={!horse.emergencyContact.number}
                                value={horse.emergencyContact.number}
                                onChange={handleInputChange}
                            />
                        </Grid>

                        {/* Veterinarian & Farrier */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel>{t('HorseForm.labels.veterinarianName')}</InputLabel>
                                <Select
                                    name="veterinarianId"
                                    value={horse.veterinarianId || ''}
                                    onChange={e => e.target.value === 'add_new' ? handleOpenModal('veterinarian') : handleInputChange(e)}
                                    label={t('HorseForm.labels.veterinarianName')}
                                >
                                    <MenuItem value="add_new">{t('HorseForm.labels.addNewVeterinarian')}</MenuItem>
                                    {veterinarians.map(vet => (
                                        <MenuItem key={vet.id} value={vet.id}>{vet.firstname} {vet.surname}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel>{t('HorseForm.labels.farrierName')}</InputLabel>
                                <Select
                                    name="farrierId"
                                    value={horse.farrierId || ''}
                                    onChange={e => e.target.value === 'add_new' ? handleOpenModal('farrier') : handleInputChange(e)}
                                    label={t('HorseForm.labels.farrierName')}
                                >
                                    <MenuItem value="add_new">{t('HorseForm.labels.addNewFarrier')}</MenuItem>
                                    {ferries.map(farrier => (
                                        <MenuItem key={farrier.id} value={farrier.id}>{farrier.firstname} {farrier.surname}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            {/* Identification and Microchip */}

            {isFilledByScan && (
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{t('HorseInfoPage.identificationMicrochip.title')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* Fields for Identification Number, Sex, Color, Microchip Details */}
                        <Box sx={{ mt: 2 }}>
                            <DatePicker
                                fullWidth
                                label={t('HorseInfoPage.identificationMicrochip.dateOfInsertion')}
                                value={horse?.microchipIdentification?.dateOfInsertion ? parseISO(horse.microchipIdentification.dateOfInsertion) : null}
                                onChange={(newValue) => {
                                    setHorse({ ...horse, microchipIdentification: { ...horse.microchipIdentification, dateOfInsertion: newValue } });
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth required />}
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label={t('HorseInfoPage.identificationMicrochip.locationOfInsertion')}
                                name="microchipIdentification.locationOfInsertion"
                                value={horse.microchipIdentification.locationOfInsertion}
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label={t('HorseInfoPage.identificationMicrochip.number')}
                                name="microchipIdentification.number"
                                value={horse.microchipIdentification.number}
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label={t('HorseInfoPage.identificationMicrochip.issuedBy')}
                                name="microchipIdentification.issuedBy"
                                value={horse.microchipIdentification.issuedBy}
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
            )}

            {/* sire and dam */}
            {isFilledByScan && (
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{t('HorseInfoPage.sireAndDam.title')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label={t('HorseInfoPage.sireAndDam.sire.name')}
                                name="sire.name"
                                value={horse.sire.name}
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <TextField

                                fullWidth
                                label={t('HorseInfoPage.sireAndDam.sire.identificationNumber')}
                                name="sire.identificationNumber"
                                value={horse.sire.identificationNumber}
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label={t('HorseInfoPage.sireAndDam.dam.name')}
                                name="dam.name"
                                value={horse.dam.name}
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label={t('HorseInfoPage.sireAndDam.dam.identificationNumber')}
                                name="dam.identificationNumber"
                                value={horse.dam.identificationNumber}
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
            )}
            {/* Breeding and Branding */}

            {isFilledByScan && (
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{t('HorseInfoPage.breeder.title')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label={t('HorseInfoPage.breeder.name')}
                                name="breeder.name"
                                value={horse.breeder.name}
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label={t('HorseInfoPage.breeder.address')}
                                name="breeder.address"
                                value={horse.breeder.address}
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
            )}

            {/* Markings */}

            {isFilledByScan && (
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{t('HorseInfoPage.markings.title')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label={t('HorseInfoPage.markings.head')}
                                name="markings.head"
                                value={horse.markings?.head}
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label={t('HorseInfoPage.markings.legs.frontLeft')}
                                name="markings.legs.frontLeft"
                                value={horse.markings?.legs?.frontLeft || ''}
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label={t('HorseInfoPage.markings.legs.frontRight')}
                                name="markings.legs.frontRight"
                                value={horse.markings?.legs?.frontRight || ''}
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label={t('HorseInfoPage.markings.legs.backLeft')}
                                name="markings.legs.backLeft"
                                value={horse.markings?.legs?.backLeft || ''}
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label={t('HorseInfoPage.markings.legs.backRight')}
                                name="markings.legs.backRight"
                                value={horse.markings?.legs?.backRight || ''}
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label={t('HorseInfoPage.markings.body')}
                                name="markings.body"
                                value={horse.markings?.body}
                                onChange={handleInputChange}
                                variant="outlined"
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
            )}

            {/* Breeding and Branding */}
            <AddPersonModal
                open={modalInfo.open}
                onClose={() => setModalInfo({ open: false, type: null })}
                onSave={handleAddNewPerson}
                title={modalInfo.type === 'veterinarian' ? t('HorseForm.addNewVeterinarianTitle') : t('HorseForm.addNewFarrierTitle')}
            />
        </LocalizationProvider>
    );
};

export default HorseForm;
