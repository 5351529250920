// src/components/HorseInfoPageTabs/PermissionsTab.js
import React, { useState } from 'react';
import { Box, IconButton, Grid, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import PermissionSelectionModal from '../PermissionSelectionModal';

const predefinedPermissions = [
  'handleBookings',
  'handleVaccine',
  'handleFarrier',
  'handleVeterinarian',
  'handleHorseProfile',
];

const PermissionsTab = ({ permissions, handlePermissionsChange, handleAddPermission, handleRemovePermission, horse, users, isAdmin }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const handlePermissionSelectChange = (index, event) => {
    const { target: { value } } = event;
    handlePermissionsChange(index, 'permissions', typeof value === 'string' ? value.split(',') : value);
  };

  const handleUserSelectChange = (index, event) => {
    const { target: { value } } = event;
    handlePermissionsChange(index, 'userId', value);
  };

  const handleModalOpen = () => {
    if (isAdmin) {
      handleAddPermission({ userId: '', permissions: [] });
    } else {
      setOpenModal(true);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleModalSubmit = (selectedPermissions) => {
    handleAddPermission({ userId: '', permissions: selectedPermissions });
  };

  return (
    <Box>
      <Typography variant="h6">{t('PermissionsTab.title')}</Typography>
      {permissions.map((permission, index) => (
        <Box key={index} mb={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              {isAdmin ? (
                <FormControl fullWidth>
                  <InputLabel>{t('PermissionsTab.userId')}</InputLabel>
                  <Select
                    value={permission.userId || ''}
                    onChange={(e) => handleUserSelectChange(index, e)}
                  >
                    {users.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.firstname} {user.surname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Typography>{permission.userName}</Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>{t('PermissionsTab.permissions')}</InputLabel>
                <Select
                  multiple
                  value={permission.permissions || []}
                  onChange={(e) => handlePermissionSelectChange(index, e)}
                  renderValue={(selected) => selected.map((perm) => t(`PermissionsTab.${perm}`)).join(', ')}
                >
                  {predefinedPermissions.map((perm) => (
                    <MenuItem key={perm} value={perm}>
                      <Checkbox checked={permission.permissions?.indexOf(perm) > -1} />
                      <ListItemText primary={t(`PermissionsTab.${perm}`)} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={() => handleRemovePermission(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      ))}
      <Box display="flex" justifyContent="center" mt={2}>
        <IconButton color="primary" onClick={handleModalOpen}>
          <AddIcon />
        </IconButton>
      </Box>
      <PermissionSelectionModal open={openModal} onClose={handleModalClose} onSubmit={handleModalSubmit} horseId={horse.id} />
    </Box>
  );
};

export default PermissionsTab;
