import axiosInstance, {
    setApiKeyHeader,
    setOrganizationIdHeader,
    setAuthHeader,
} from './apiConfig/axiosConfig';

const API_BASE_URL = '/api/deliveryPlans/routes';

// Fetch all route templates
export async function fetchAllRoutes(apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.get(API_BASE_URL);
    return response.data;
}

// Fetch a single route template by ID
export async function fetchRouteById(apiKey, organizationId, jwtToken, id) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.get(`${API_BASE_URL}?id=${id}`);
    return response.data;
}

// Save a new route template
export async function saveRoute(route, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.post(API_BASE_URL, route);
    return response.data;
}

// Update an existing route template
export async function updateRoute(route, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.put(`${API_BASE_URL}?id=${route.id}`, route);
    return response.data;
}

// Delete a route template by ID
export async function deleteRoute(routeId, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.delete(`${API_BASE_URL}?id=${routeId}`);
    return response.data;
}

export default {
    fetchAllRoutes,
    fetchRouteById,
    saveRoute,
    updateRoute,
    deleteRoute,
};
