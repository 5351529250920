// src/components/HorseInfoPageTabs/DocumentTab.js

import React, { useState } from 'react';
import { Button, List, ListItem, ListItemText, IconButton, Typography, Modal, Box, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import UnlinkIcon from '@mui/icons-material/LinkOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DocumentPreviewModal from '../../components/DocumentPreviewModal';
import DocumentUploadModal from '../DocumentUploadModal';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FlipIcon from '@mui/icons-material/Flip';
import { useTranslation } from 'react-i18next';

const DocumentTab = ({ horse, onAddDocument, onRemoveDocument, documents, horseDocuments, onUpdateData, loading }) => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [scanType, setScanType] = useState(null);

  const { t } = useTranslation();

  const handleOpenPreview = (docId) => {
    const documentDetails = horseDocuments.find((d) => d.id === docId);
    setSelectedDocument(documentDetails);
    setIsModalOpen(true);
  };

  const checkDocumentForPassport = (docId) => {
    const documentDetails = horseDocuments.find((d) => d.id === docId);
    if (documentDetails) {
      return documentDetails.businessType !== 'horsepass';
    }
    return false;
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedDocument(null);
  };

  const handleUploadDocument = (scanType) => {
    setScanType(scanType);
    // Open the DocumentUploadModal with the specified scanType
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadModal = (documentId) => {
    // Close the DocumentUploadModal and optionally refresh documents list
    onAddDocument(documentId);
    setIsUploadModalOpen(false);
  };

  return (
    <>
      <Grid container spacing={1} alignItems="center" justifyContent="space-between" sx={{ marginBottom: '1rem', marginTop: '1rem' }}>
        <Grid item xs={6} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            startIcon={<FileUploadIcon />}
            onClick={() => handleUploadDocument('horsepass')}
            disabled={loading}
          >
            {t('HorseInfoPage.DocumentTab.passport')}
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} >
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            startIcon={<FileUploadIcon />}
            onClick={() => handleUploadDocument('vacination')}
            disabled={loading}
          >
            {t('HorseInfoPage.DocumentTab.vacination')}
          </Button>
        </Grid>
      </Grid>
      <List>
        {horse.documents.map((doc) => (
          <ListItem
            key={doc.id}
            secondaryAction={
              <>
                <IconButton edge="end" aria-label="preview" onClick={() => onUpdateData(doc.id)} disabled={checkDocumentForPassport(doc.id) || loading} >
                  <FlipIcon />
                </IconButton>
                <IconButton edge="end" aria-label="preview" onClick={() => handleOpenPreview(doc.id)} disabled={loading}>
                  <VisibilityIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => onRemoveDocument(doc.id)} disabled={loading}>
                  <UnlinkIcon />
                </IconButton>
              </>
            }
          >
            <ListItemText primary={documents.find((d) => d.id === doc.id)?.orginalName} />
          </ListItem>
        ))}
      </List>
      <DocumentUploadModal isOpen={isUploadModalOpen} onClose={handleCloseUploadModal} scanType={scanType} predefinedDescription={horse.name} />
      <DocumentPreviewModal open={isModalOpen} onClose={handleCloseModal} documentDetails={selectedDocument} />
    </>
  );
};

export default DocumentTab;
