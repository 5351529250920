// path: /Routes/RequireAuth.js

import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, EventType } from '@azure/msal-browser';
import { Box, Typography, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Sidebar from '../components/Bars/Sidebar';
import TopBar from '../components/Bars/TopBar';
import { useAuth } from '../context/AuthContext';
import { renewToken, renewTokenIfNeeded } from '../context/tokenUtils';
import jwtDecode from 'jwt-decode';
import { loginWithAzure } from '../services/authService';
import { useTheme, useMediaQuery } from '@mui/material';
import { DrawerStateProvider } from '../context/DrawerContext';
import MainContent from './MainContent';
import { UserPreferencesProvider } from '../context/UserPreferencesContext';
import { Button } from '@mui/material';

const RequireAuth = () => {
    const { instance, accounts, inProgress } = useMsal();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [token, setToken] = useState(null);
    const tenantURL = process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME;
    const { login, loginPostInformation, loading, isLoggedIn, readyToUse, apiKeyLoading, token: authToken, logout } = useAuth();
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {

        if (!isLoggedIn && !readyToUse) {
            const callbackId = instance.addEventCallback((event) => {
                if (event.eventType === EventType.LOGIN_SUCCESS && !isAuthenticating) {
                    console.log("Login success event received. Acquiring token...");
                    renewToken(instance, login, loginPostInformation, navigate);
                    setIsAuthenticating(true);
                }
            });

            return () => {
                if (callbackId) {
                    instance.removeEventCallback(callbackId);
                }
            };
        }
    }, [instance, isLoggedIn, readyToUse, isAuthenticating]);

    useEffect(() => {
        if (inProgress === 'none' && accounts.length > 0 && !isLoggedIn) {
            renewToken(instance, login, loginPostInformation, navigate);
        } else if (inProgress === 'none' && accounts.length === 0) {
            navigate('/auth');
        }
    }, [inProgress, accounts, navigate, login, loginPostInformation]);


    useEffect(() => {
        const intervalId = setInterval(() => {
            renewTokenIfNeeded(instance, token, login, loginPostInformation, navigate);
        }, 60000);

        return () => clearInterval(intervalId);
    }, [token]);

    const handleLogout = () => {
        instance
            .logoutRedirect()
            .then(() => {
                logout();
            })
            .catch((e) => {
                console.error(e);
            });
    };

    if (loading || apiKeyLoading) {
        return (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography variant="h6">{t('Authentication.waitingText')}</Typography>
                <LinearProgress />
            </Box>
        );
    }

    if (inProgress !== "none" || accounts.length === 0) {
        return (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography variant="h6">{t('Authentication.waitingText')}</Typography>
                <LinearProgress />
            </Box>
        );
    }

    if (!readyToUse) {
        return (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography variant="h6">{t('Authentication.waitingText')}</Typography>
                <LinearProgress />
            </Box>
        );
    }

    if (!isLoggedIn) {
        return <div>Please log in</div>;
    }

    const topBarHeight = isMobile ? '56px' : '64px';

    return (
        <UserPreferencesProvider>
            <DrawerStateProvider>
                <TopBar />
                <Sidebar />
                <Box sx={{ marginTop: topBarHeight }}>
                    <MainContent>
                        <Outlet />
                    </MainContent>
                </Box>
            </DrawerStateProvider>
        </UserPreferencesProvider>
    );
};

export default RequireAuth;
