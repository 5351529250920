import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const IncomeBarChart = ({ titleKey, data }) => {
    const { t } = useTranslation();
    return (
        <Grid item xs={12} sm={6}>
            <Card>
                <CardContent>
                    <Typography variant="h6">
                        {t(titleKey)}
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart width={500} height={300} data={data}>
                            <XAxis dataKey="category" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="amount" fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>
                </CardContent>
            </Card>
        </Grid>
    );
};

IncomeBarChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            category: PropTypes.string.isRequired,
            amount: PropTypes.number.isRequired
        })
    ).isRequired
};

export default IncomeBarChart;
