// /src/pages/DewormingPage.js

import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { getAllDewormings, deleteDeworming } from '../services/dewormingService';
import { useAuth } from '../context/AuthContext';
import IconButton from '@mui/material/IconButton';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDateLocale } from '../hooks/useDateLocale';
import { format } from 'date-fns';
import ViewModuleIcon from '@mui/icons-material/ViewModule';  // <-- add this line
import ViewListIcon from '@mui/icons-material/ViewList';  // <-- add this line
import GenericCardView from '../components/GenericCardView';
import GenericTableView from '../components/GenericTableView';
import { LinearProgress } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import { usePreferences } from '../context/UserPreferencesContext';


const DewormingPage = () => {
    const { t } = useTranslation();
    const dateLocale = useDateLocale();
    const { apiKey, organizationId, token, userId } = useAuth();
    const [dewormings, setDewormings] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [viewMode, setViewMode] = useState(isMobile ? 'card' : 'table');
    const { devicePreferences, updateDevicePreferences } = usePreferences();


    useEffect(() => {
        try {
            setIsLoading(true);
            const fetchDewormings = async () => {
                const dewormings = await getAllDewormings(apiKey, organizationId, token);
                setDewormings(dewormings);
            };

            fetchDewormings();
        } catch (error) {
            console.error('Error fetching dewormings', error);
        } finally {
            setIsLoading(false);
        }

    }, []);


    // Update local state when context updates
    useEffect(() => {
        setViewMode(devicePreferences.viewMode);
    }, [devicePreferences.viewMode]);




    const handleRowClick = (id) => {
        navigate(`/dewormings/detail/${id}`);
    };


    const formattedDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'PPP', { locale: dateLocale });
    };



    const toggleViewMode = () => {
        updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
    };

    const transformData = () => {

        return {

            headers: [
                { key: 'date', label: t('dewormingPage.table.date'), display: { value: true, chipColor: false } },
                { key: 'nextDueDate', label: t('dewormingPage.table.nextDueDate'), display: { value: true, chipColor: false } }
            ],
            rows: dewormings.map(deworming => ({
                id: deworming.id,
                date: { value: formattedDate(deworming.date), icon: null },
                nextDueDate: { value: formattedDate(deworming.nextDueDate), icon: null }
            }))
        };
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale} >
            <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
                {isLoading && <LinearProgress />}
                <div>
                    <Typography variant="h4" gutterBottom>
                        {t('dewormingPage.title')}
                    </Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigate('/dewormings/detail/new')}
                            startIcon={<AddIcon />}
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        >
                            {t('dewormingPage.addDeworming')}
                        </Button>
                        <IconButton onClick={toggleViewMode} color="primary">
                            {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
                        </IconButton>
                    </Box>
                    {isLoading ? (
                        <LinearProgress />
                    ) : viewMode === 'table' ? (
                        <GenericTableView structure={transformData()} onClick={handleRowClick} />
                    ) : (
                        <GenericCardView structure={transformData()} onClick={handleRowClick} />
                    )}

                </div>
            </Paper>
        </LocalizationProvider >
    );
}


export default DewormingPage;