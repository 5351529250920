
import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { countrySpecificTaxInfo } from '../../dataCollection/countryTaxConfig';
import { currencyInfo } from '../../dataCollection/currencySettings';
import { countryCodeNameMap } from '../../dataCollection/countryCodeMapping';
import { saveTaxSettings, fetchTaxSettings } from '../../services/TaxSettingsService';

const countryOptions = Object.keys(countrySpecificTaxInfo).map(country => ({ value: country, label: country }));

const TaxSettings = () => {
    const { apiKey, organizationId, token } = useAuth();
    const [settings, setSettings] = useState({
        country: '',
        orgType: '',
        currency: '', // For countries with multiple currencies
        region: '', // For cantons in Switzerland or states in the USA
        vatRate: '',
        corporateTaxRate: '',
        corporateTaxRates: [], // For progressive tax rates
    });


    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const result = await fetchTaxSettings(apiKey, organizationId, token);
                setSettings(result);
            } catch (error) {
            }
        };

        fetchSettings();
    }, [apiKey, organizationId, token]);


    useEffect(() => {
        const updateTaxSettings = () => {
            if (settings.country && settings.orgType) {
                const countryInfo = countrySpecificTaxInfo[settings.country];
                const orgTypeInfo = countryInfo ? countryInfo[settings.orgType] : null;

                let vatRate = orgTypeInfo?.vatRate || '';
                let corporateTaxRate = orgTypeInfo?.corporateTaxRate || '';

                if (settings.region) {
                    const regionInfo = orgTypeInfo?.cantons?.[settings.region] || orgTypeInfo?.states?.[settings.region] || {};
                    corporateTaxRate = regionInfo.corporateTaxRate || corporateTaxRate;
                }

                setSettings(prev => ({
                    ...prev,
                    vatRate,
                    corporateTaxRate,
                    corporateTaxRates: orgTypeInfo?.corporateTaxRates || [],
                }));
            }
        };

        updateTaxSettings();
    }, [settings.country, settings.orgType, settings.region]);

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        // Reset relevant parts of settings when changing selections
        setSettings(prev => ({
            ...prev,
            [name]: value,
            ...(name === 'country' || name === 'orgType') && { region: '', vatRate: '', corporateTaxRate: '', corporateTaxRates: [] },
        }));
        if (name === 'country') {
            // Find the currency for the selected country
            const currencyCode = Object.keys(currencyInfo).find(currency =>
                currencyInfo[currency].countries.includes(countryCodeNameMap[value])
            );

            if (currencyCode) {
                setSettings(prev => ({
                    ...prev,
                    currency: currencyCode, // Set the found currency code
                }));
            }
        }
    };

    const handleRegionChange = (e) => {
        const { name, value } = e.target;
        setSettings(prev => ({ ...prev, [name]: value }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const result = await saveTaxSettings(settings, apiKey, organizationId, token);
            console.log('Tax settings saved successfully:', result);
            // Add any post-save actions here
        } catch (error) {
            console.error('Error saving tax settings:', error);
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
            <Typography variant="h6">Tax Settings</Typography>
            {/* Country Select */}
            <FormControl fullWidth margin="normal">
                <InputLabel id="country-select-label">Country</InputLabel>
                <Select
                    labelId="country-select-label"
                    id="country-select"
                    value={settings.country}
                    name="country"
                    onChange={handleSelectChange}
                >
                    {Object.keys(countrySpecificTaxInfo).map(country => (
                        <MenuItem key={country} value={country}>{countryCodeNameMap[country]}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
                <InputLabel id="currency-select-label">Currency</InputLabel>
                <Select
                    labelId="currency-select-label"
                    id="currency-select"
                    value={settings.currency}
                    name="currency"
                    onChange={handleSelectChange}
                >

                    {Object.keys(currencyInfo).map(currency => (
                        <MenuItem key={currency} value={currency}>{currency}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            {/* Organization Type Select */}
            {settings.country && (
                <FormControl fullWidth margin="normal">
                    <InputLabel id="org-type-select-label">Organization Type</InputLabel>
                    <Select
                        labelId="org-type-select-label"
                        id="org-type-select"
                        value={settings.orgType}
                        name="orgType"
                        onChange={handleSelectChange}
                    >
                        {Object.keys(countrySpecificTaxInfo[settings.country] || {}).map(type => (
                            <MenuItem key={type} value={type}>{type}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            {/* Conditional Region Select for Switzerland and USA */}
            {['CH', 'USA'].includes(settings.country) && (
                <FormControl fullWidth margin="normal">
                    <InputLabel id="region-select-label">{settings.country === 'Switzerland' ? 'Canton' : 'State'}</InputLabel>
                    <Select
                        labelId="region-select-label"
                        id="region-select"
                        value={settings.region}
                        name="region"
                        onChange={handleSelectChange}
                    >
                        {Object.keys(countrySpecificTaxInfo[settings.country][settings.orgType]?.cantons || {}).map(region => (
                            <MenuItem key={region} value={region}>{region}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            {/* VAT Rate and Corporate Tax Rate Fields (Read-Only) */}
            <TextField
                margin="normal"
                fullWidth
                name="vatRate"
                label="VAT Rate (%)"
                value={settings.vatRate}
                InputProps={{ readOnly: true }}
            />
            <TextField
                margin="normal"
                fullWidth
                name="corporateTaxRate"
                label="Corporate Tax Rate (%)"
                value={settings.corporateTaxRate}
                InputProps={{ readOnly: true }}
                disabled={settings.corporateTaxRates.length > 0}
            />
            {settings.corporateTaxRates.length > 0 && (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1">Corporate Tax Rates</Typography>
                    {settings.corporateTaxRates.map((rate, index) => (
                        <Typography key={index}>{`Income: ${rate.income.from} - ${rate.income.to}, Rate: ${rate.rate}%`}</Typography>
                    ))}
                </Box>
            )}
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Save Settings
            </Button>
        </Box>
    );
};

export default TaxSettings;
