// src/components/DocumentUploadModal.js

import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input
} from '@mui/material';
import { uploadDocument, createDocumentMetadata } from '../services/documentService';
import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const DocumentUploadModal = ({ isOpen, onClose, scanType = 'all', predefinedDescription = '' }) => {
    const { apiKey, organizationId, token } = useAuth();
    const { t } = useTranslation();
    const [selectedFile, setSelectedFile] = useState(null);
    const [description, setDescription] = useState(predefinedDescription);
    const [documentType, setDocumentType] = useState(scanType !== 'all' ? scanType : '');
    const [loading, setLoading] = useState(false);
    const documentOptions = scanType === 'all' ? [
        { value: 'expense', label: t('documentData.DocumentUploadModal.documentBusinessTypes.expense') },
        { value: 'horsepass', label: t('documentData.DocumentUploadModal.documentBusinessTypes.horsepass') },
        { value: 'salesOrder', label: t('documentData.DocumentUploadModal.documentBusinessTypes.salesOrder') },
        { value: 'vacination', label: t('documentData.DocumentUploadModal.documentBusinessTypes.vacination') },
        { value: 'other', label: t('documentData.DocumentUploadModal.documentBusinessTypes.other') },
    ] : [
        { value: scanType, label: t(`documentData.DocumentUploadModal.documentBusinessTypes.${scanType}`) }
    ];

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleDocumentTypeChange = (event) => {
        setDocumentType(event.target.value);
    };

    function fileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    };

    useEffect(() => {
        // Automatically set documentType if scanType is specific
        if (scanType !== 'all') {
            setDocumentType(scanType);
        }
    }, [scanType]);

    const uploadDocumentHandler = async () => {
        setLoading(true);
        if (selectedFile) {
            try {

                const base64EncodedFile = await fileToBase64(selectedFile);
                const base64Content = base64EncodedFile.split(',')[1];
                const extensionMatch = selectedFile.name.match(/\.[0-9a-z]+$/i);
                const extension = extensionMatch ? extensionMatch[0] : '';
                const response = await uploadDocument(base64Content, extension, apiKey, organizationId, token);

                // Assuming uploadDocument function returns a response with the document ID
                // const uploadResponse = await uploadDocument(selectedFile, apiKey, organizationId, token);
                const documentMetadata = {
                    id: response.documentId,
                    orginalName: selectedFile.name,
                    documentType: selectedFile.type,
                    businessType: documentType,
                    description: description,
                };
                await createDocumentMetadata(documentMetadata, apiKey, organizationId, token);
                resetValues();
                onClose(documentMetadata.id);
            } catch (error) {
                console.error('Upload failed:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const resetValues = () => {
        setSelectedFile(null);
        setDescription('');
        setDocumentType('');
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            {loading && <LinearProgress />}
            <DialogTitle>
                {t('documentData.DocumentUploadModal.title')}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="normal">
                    <Input type="file" onChange={handleFileChange} />
                </FormControl>
                <TextField
                    fullWidth
                    margin="normal"
                    label={t('documentData.DocumentUploadModal.description')}
                    value={description}
                    onChange={handleDescriptionChange}
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel>{t('documentData.DocumentUploadModal.type')}</InputLabel>
                    <Select
                        value={documentType}
                        onChange={handleDocumentTypeChange}
                    >
                        {documentOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={uploadDocumentHandler} variant="contained" color="primary" disabled={loading}>
                    {t('documentData.DocumentUploadModal.uploadButton')}
                </Button>
                <Button onClick={onClose} variant="contained" color="secondary" disabled={loading}>
                    {t('documentData.DocumentUploadModal.cancelButton')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DocumentUploadModal;
