// src/context/DrawerContext.js
import React, { createContext, useContext, useState } from 'react';
import DrawerStates from '../components/Bars/DrawerStates'; // Adjust the path as necessary

const DrawerStateContext = createContext();

export const DrawerStateProvider = ({ children }) => {
    const [drawerState, setDrawerState] = useState(DrawerStates.PEEK);

    const toggleDrawer = () => {
        setDrawerState((prevState) => {
            switch (prevState) {
                case DrawerStates.CLOSED:
                    return DrawerStates.PEEK;
                case DrawerStates.PEEK:
                    return DrawerStates.OPEN;
                case DrawerStates.OPEN:
                    return DrawerStates.CLOSED;
                default:
                    return DrawerStates.CLOSED;
            }
        });
    };

    const closeDrawer = () => {
        setDrawerState(DrawerStates.CLOSED);
    }

    return (
        <DrawerStateContext.Provider value={{ drawerState, toggleDrawer, closeDrawer }}>
            {children}
        </DrawerStateContext.Provider>
    );
};

export const useDrawerState = () => useContext(DrawerStateContext);
