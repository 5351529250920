import React from 'react';
import { Button, Typography, List, ListItem, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Summary = ({ data, onSubmit }) => { 
    const { t } = useTranslation();
    
    console.log('Summary Data:', data);
    const handleConfirmAndSignup = () => {
        console.log('Confirming Signup Data:', data);
        onSubmit();
    };

    return (
        <div>
            <Typography variant="h6">{t('Summary.titles.review')}</Typography>
            <List>
                {/* Personal Information */}
                <ListItem>
                    <Typography><strong>{t('Summary.labels.name')}:</strong> {data.personalInfo.firstname} {data.personalInfo.surname}</Typography>
                </ListItem>
                <ListItem>
                    <Typography><strong>{t('Summary.labels.email')}:</strong> {data.personalInfo.email}</Typography>
                </ListItem>
                {/* Add other personal info fields here... */}
                
                <Divider />

                {/* Horse Ownership */}
                {data.horses.map((horse, index) => (
                    <ListItem key={index}>
                        <Typography><strong>{t('Summary.labels.horse', { number: index + 1 })}:</strong> {horse.name} ({horse.breed})</Typography>
                    </ListItem>
                ))}

                <Divider />

                {/* Password - We'll just indicate presence for security */}
                <ListItem>
                    <Typography><strong>{t('Summary.labels.password')}:</strong> {data.password ? t('Summary.status.set') : t('Summary.status.notSet')}</Typography>
                </ListItem>
            </List>

            <Button variant="contained" color="primary" onClick={handleConfirmAndSignup}>
                {t('Summary.buttons.confirmAndSignup')}
            </Button>
        </div>
    );
};

export default Summary;
