// src/components/OrgTabs/FoodSettings/FoodItem.js

import React from 'react';
import {
    TextField, Checkbox, FormGroup, FormControlLabel, Button, Box, IconButton, Autocomplete,
    Typography, Card, CardHeader, CardContent, Accordion, AccordionSummary, AccordionDetails, Select, MenuItem
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FoodItem = ({ food, index, items, measureUnits, onInputChange, onCheckboxChange, onRemove, onPortionChange, onAddPortion, onRemovePortion, handleAutocompleteChange }) => {
    const { t } = useTranslation();

    /*const defaultPortions = [
        { title: t('HorseInfoPage.FoodManagement.Portions.None') },
        { title: t('HorseInfoPage.FoodManagement.Portions.Handful') },
        { title: '1/4 liter' },
        { title: '1/2 liter' },
        { title: '3/4 liter' },
        { title: '1 liter' }
    ];*/

    const defaultPortions = [
        { title: t('HorseInfoPage.FoodManagement.Portions.None'), id: 'none', measureUnitId: '', quantity: 0.0 },
        { title: t('HorseInfoPage.FoodManagement.Portions.Handful'), id: 'handful', measureUnitId: 'unit1', quantity: 0.0 },
        { title: '1/4 liter', id: '1/4 liter', measureUnitId: 'unit2', quantity: 0.25 },
        { title: '1/2 liter', id: '1/2 liter', measureUnitId: 'unit2', quantity: 0.5 },
        { title: '3/4 liter', id: '3/4 liter', measureUnitId: 'unit2', quantity: 0.75 },
        { title: '1 liter', id: '1 liter', measureUnitId: 'unit2', quantity: 1 }
        // Add other portions as needed
    ];


    return (
        <Card key={index} style={{ margin: '10px', position: 'relative' }}>
            <CardHeader title={food.name} />

            <CardContent>
                <Box sx={{ marginBottom: '1rem' }}>
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Autocomplete
                            freeSolo
                            options={items.map((item) => ({ label: item.name, id: item.id }))}
                            value={{ label: food.name, id: food.itemId || '' }}
                            onChange={(event, newValue, reason) => {
                                handleAutocompleteChange(event, newValue, reason, index);
                            }}
                            onInputChange={(event, newInputValue, reason) => {
                                handleAutocompleteChange(event, newInputValue, reason, index);
                            }}
                            fullWidth
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('OrganizationSettings.FoodSettings.Food')}
                                    variant="outlined"
                                    placeholder={t('OrganizationSettings.FoodSettings.FoodPlaceholder')}
                                />
                            )}
                        />
                        <IconButton onClick={() => onRemove(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <TextField
                            label={t('OrganizationSettings.FoodSettings.printName')}
                            variant="outlined"
                            name="printName"
                            value={food.printName}
                            fullWidth
                            onChange={(event) => onInputChange(index, event)}
                        />
                    </Box>

                    {/* Availability Section with Accordion */}
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{t('OrganizationSettings.FoodSettings.Availability')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FormGroup>
                                {['morning', 'midday', 'evening'].map((time) => (
                                    <FormControlLabel
                                        key={time}
                                        control={
                                            <Checkbox
                                                checked={food.availability[time]}
                                                onChange={(event) => onCheckboxChange(index, event)}
                                                name={time}
                                            />
                                        }
                                        label={t(`OrganizationSettings.FoodSettings.${time.charAt(0).toUpperCase() + time.slice(1)}`)}
                                    />
                                ))}
                            </FormGroup>
                        </AccordionDetails>
                    </Accordion>

                    {/* Portion option Section with Accordion */}
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{t('OrganizationSettings.FoodSettings.PortionOptions')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                food.portions.map((portion, portionIndex) => (
                                    <>
                                        <Box key={portionIndex} sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: '1rem' }}>
                                            <Autocomplete
                                                key={portionIndex}
                                                freeSolo
                                                options={defaultPortions.map((option) => option.title)}
                                                value={portion.name}
                                                onChange={(event, newValue, reason) => {
                                                    if (reason === 'selectOption' || reason === 'createOption') {
                                                        onPortionChange(index, portionIndex, 'name', newValue);
                                                        onPortionChange(index, portionIndex, 'quantity', defaultPortions.find((portion) => portion.title === newValue).quantity);
                                                    }
                                                }}
                                                onInputChange={(event, newInputValue, reason) => {
                                                    if (reason === 'input') {
                                                        onPortionChange(index, portionIndex, 'name', newInputValue);
                                                    }
                                                }}
                                                sx={{ width: '100%' }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('OrganizationSettings.FoodSettings.PortionLabel')}
                                                        variant="outlined"
                                                        sx={{ my: 1 }}
                                                    />
                                                )}
                                            />
                                            <IconButton onClick={() => onRemovePortion(index, portionIndex)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                        <Box key={portionIndex} sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: '1rem' }}>

                                            <TextField
                                                label="Quantity"
                                                type="number"
                                                value={portion.quantity}
                                                onChange={(e) => onPortionChange(index, portionIndex, 'quantity', e.target.value)}
                                            />
                                            <Select
                                                value={portion.measureUnitId}
                                                label="Measure Unit"
                                                onChange={(e) => onPortionChange(index, portionIndex, 'measureUnitId', e.target.value)}
                                                fullWidth
                                            >
                                                {measureUnits.map((unit) => (
                                                    <MenuItem key={unit.id} value={unit.id}>
                                                        {unit.nameLong}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Box>
                                    </>
                                ))
                            }
                            <Button onClick={() => onAddPortion(index)}>Add Portion</Button>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </CardContent>
        </Card>
    );
};

export default FoodItem;