// src/components/horseInfoPageTabs/contactTab.js
import React from 'react';
import { Box, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionSummary, AccordionDetails, Typography, IconButton, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';


const ContactTab = ({ horse, setHorse, handleInputChange, veterinarians, farriers }) => {
    const { t } = useTranslation();

    const findSelectedData = (list, id) => list.find(item => item.id === id) || {};

    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        console.log(`Name: ${name}, Value: ${value}`); // Add this
        handleInputChange({
            target: {
                name: name,
                value: value
            }
        });
    };

    const selectedVeterinarian = findSelectedData(veterinarians, horse.veterinarianId);
    const selectedFarrier = findSelectedData(farriers, horse.farrierId);


    const handleCoRiderChange = (index, event) => {
        const newCoRiders = [...horse.coRiders];
        newCoRiders[index][event.target.name] = event.target.value;
        setHorse({ ...horse, coRiders: newCoRiders });
    };

    const addCoRider = () => {
        setHorse({ ...horse, coRiders: [...horse.coRiders, { firstname: '', surename: '', phoneNumber: '' }] });
    };

    const removeCoRider = (index) => {
        const newCoRiders = [...horse.coRiders];
        newCoRiders.splice(index, 1);
        setHorse({ ...horse, coRiders: newCoRiders });
    };



    return (
        <>
            <Box sx={{ mt: 2 }}>
                <TextField
                    fullWidth
                    label={t('HorseInfoPage.emergencyContactName')}
                    name="emergencyContact.name"
                    value={horse.emergencyContact.name}
                    onChange={handleInputChange}
                    variant="outlined"
                />
            </Box>
            <Box sx={{ mt: 2 }}>
                <TextField
                    fullWidth
                    label={t('HorseInfoPage.emergencyContactNumber')}
                    name="emergencyContact.number"
                    value={horse.emergencyContact.number}
                    onChange={handleInputChange}
                    variant="outlined"
                />
            </Box>
            <Box sx={{ mt: 2 }}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel>{t('HorseInfoPage.veterinarianName')}</InputLabel>
                    <Select
                        name="veterinarianId"
                        value={horse.veterinarianId}
                        onChange={handleSelectChange}
                        label={t('HorseInfoPage.veterinarianName')}
                    >
                        {veterinarians.map(vet => (
                            <MenuItem key={vet.id} value={vet.id}>{vet.firstname} {vet.surname}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    disabled
                    sx={{ mt: 1 }}
                    label={t('HorseInfoPage.veterinarianNumber')}
                    value={selectedVeterinarian.phoneNumber || ''}
                    variant="outlined"
                />
            </Box>

            <Box sx={{ mt: 2 }}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel>{t('HorseInfoPage.farrierName')}</InputLabel>
                    <Select
                        name="farrierId"
                        value={horse.farrierId}
                        onChange={handleSelectChange}
                        label={t('HorseInfoPage.farrierName')}
                    >
                        {farriers.map(f => (
                            <MenuItem key={f.id} value={f.id}>{f.firstname} {f.surname}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    disabled
                    sx={{ mt: 1 }}
                    label={t('HorseInfoPage.farrierNumber')}
                    value={selectedFarrier.phoneNumber || ''}
                    variant="outlined"
                />
            </Box>
            <Box sx={{ mt: 2 }}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{t('HorseInfoPage.contactTab.coRiders')} {horse.coRiders.length}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ mt: 2 }}>
                            {horse.coRiders.map((coRider, index) => (
                                <Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
                                    <Typography variant="h6">
                                        {t('HorseInfoPage.contactTab.coRider')} {coRider.firstname} {coRider.surename || ''}
                                    <IconButton onClick={() => removeCoRider(index)}><DeleteIcon /></IconButton>
                                    </Typography>
                                    <TextField
                                        sx={{ mr: 1 }}
                                        label={`${t('HorseInfoPage.contactTab.firstname')}`}
                                        name="firstname"
                                        value={coRider.firstname}
                                        onChange={(e) => handleCoRiderChange(index, e)}
                                        variant="outlined"
                                    />
                                    <TextField
                                        sx={{ mr: 1 }}
                                        label={`${t('HorseInfoPage.contactTab.surename')}`}
                                        name="surename"
                                        value={coRider.surename}
                                        onChange={(e) => handleCoRiderChange(index, e)}
                                        variant="outlined"
                                    />
                                    <TextField
                                        sx={{ mr: 1 }}
                                        label={`${t('HorseInfoPage.contactTab.phoneNumber')}`}
                                        name="phoneNumber"
                                        value={coRider.phoneNumber}
                                        onChange={(e) => handleCoRiderChange(index, e)}
                                        variant="outlined"
                                    />
                                </Box>
                            ))}
                            <Button onClick={addCoRider} sx={{ mt: 1 }}>{t('HorseInfoPage.contactTab.addCoRider')}</Button>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
};

export default ContactTab;
