import React, { useState } from 'react';
import ReactToPrint from 'react-to-print';
import { Button } from '@mui/material';
import './PrintStyles.css';
import morning from '../Icons/morning.png';
import midday from '../Icons/midday.png';
import evening from '../Icons/evening.png';
import { useTranslation } from 'react-i18next';



class ComponentToPrint extends React.Component {
  render() {
    const { t, horse, foodSettings, owner, veterian, farrier } = this.props;  // Receive the translation function as a prop
    const mealTimes = [t('MealTimes.Morning'), t('MealTimes.Midday'), t('MealTimes.Evening')]; // Translated meal times
    const originalMealTimes = ['morning', 'midday', 'evening'];
    console.log(horse);
    console.log(foodSettings);

    return (
      <div className="printContainer">
        <section className="section">
          <h1>{horse.name}</h1>
          <table className="Topgrid">
            <tbody>
              <tr>
                <td>{t('ComponentToPrint.Birthday')}:</td> {/* Translated label */}
                <td>{new Date(horse.birthday).toLocaleDateString()}</td>
              </tr>
              <tr>
                <td>{t('ComponentToPrint.Breed')}:</td> {/* Translated label */}
                <td>{horse.breed}</td>
              </tr>
              <tr>
                <td>{t('ComponentToPrint.FamilyTree')}:</td>
                <td>
                  {horse.sire?.name && horse.dam?.name
                    ? `${horse.sire.name} x ${horse.dam.name}`
                    : ` `
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className="section">
          <h2>{t('ComponentToPrint.EmergencyContacts')}</h2> {/* Translated label */}
          <table className="contactGrid">
            <tbody>
              <tr>
                <td>{t('ComponentToPrint.Owner')}:</td> {/* Translated label */}
                <td>{owner.firstname} {owner.surname}</td>
                <td>{owner.number}</td>
              </tr>
              <tr>
                <td>{t('ComponentToPrint.Emergency')}:</td> {/* Translated label */}
                <td>{horse.emergencyContact.name}</td>
                <td>{horse.emergencyContact.number}</td>
              </tr>
              <tr>
                <td>{t('ComponentToPrint.Veterinarian')}:</td> {/* Translated label */}
                <td>{veterian.firstname} {veterian.surname}</td>
                <td>{veterian.phoneNumber}</td>
              </tr>
              <tr>
                <td>{t('ComponentToPrint.Farrier')}:</td> {/* Translated label */}
                <td>{farrier.firstname} {farrier.surname}</td>
                <td>{farrier.phoneNumber}</td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className="section">
          <h2>{t('ComponentToPrint.FoodSchedule')}</h2> {/* Translated label */}
          <table className="foodGrid">
            <thead>
              <tr>
                <th></th>
                {mealTimes.map((mealTime, index) => (
                  <th key={mealTime}>
                    <img className="mealTimeImage" src={index === 0 ? morning : index === 1 ? midday : evening} alt={mealTime} />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {foodSettings.map((food) => (
                horse.foodPortions &&
                horse.foodPortions[food.id] && (
                  <tr key={food.id}>
                    <td>{food.printName || food.name}</td>
                    {originalMealTimes.map((mealTime) => (
                      <td key={`${food.id}_${mealTime}`} className="foodPortion">
                        {food.portions.find(p => p.id === horse.foodPortions[food.id][mealTime])?.name === t('HorseInfoPage.FoodManagement.Portions.None') ? '' : food.portions.find(p => p.id === horse.foodPortions[food.id][mealTime])?.name}
                        {/*horse.foodPortions[food.id][mealTime] !== t('HorseInfoPage.FoodManagement.Portions.None') ? horse.foodPortions[food.id][mealTime].name : ''*/}
                      </td>
                    ))}
                  </tr>
                )
              ))}
            </tbody>
          </table>
        </section>
      </div>
    );
  }
}



const PrintButton = ({ horse, foodSettings, owner, veterian, farrier }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const componentRef = React.useRef();
  const reactToPrintRef = React.useRef();

  const handlePrint = () => {
    setVisible(true);
    // Use setTimeout to delay the start of the printing process
    setTimeout(() => reactToPrintRef.current.handlePrint(), 0);
  };

  const handleAfterPrint = () => setVisible(false);

  // Don't render anything if the horse data isn't loaded
  if (!horse || !horse.name) return null;

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handlePrint} fullWidth>
        {t('PrintButton.PrintBoxLabel')}
      </Button>
      <ReactToPrint
        ref={reactToPrintRef}
        trigger={() => <React.Fragment />}
        content={() => componentRef.current}
        onAfterPrint={handleAfterPrint}
      />
      {visible && <ComponentToPrint ref={componentRef} horse={horse} foodSettings={foodSettings} owner={owner} t={t} veterian={veterian} farrier={farrier} />}
    </div>
  );
}

export default PrintButton;
