import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig';

const API_BASE_URL = '/api/iot/authorization/user';

export async function getAuthorizationUrl(apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);
    const response = await axiosInstance.get(`${API_BASE_URL}?action=authorization`);
    return response.data;
}

export async function fetchDevices(apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);
    const url = `${API_BASE_URL}?action=getDevices`;
    const response = await axiosInstance.get(url);
    return response.data;
}

export async function handleSmartThingsAuth(code, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);
    const url = `${API_BASE_URL}?action=token&code=${code}`;
    const response = await axiosInstance.get(url);
    return response.data;
}

export async function subscribeToCapability(apiKey, organizationId, jwtToken, data) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);
    const url = `${API_BASE_URL}?action=subscribe`;
    const response = await axiosInstance.post(url, data);
    return response.data;
}

export async function unsubscribeFromCapability(apiKey, organizationId, jwtToken, data) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);
    const url = `${API_BASE_URL}?action=unsubscribe`;
    const response = await axiosInstance.post(url, data);
    return response.data;
}

export async function updateSubscription(apiKey, organizationId, jwtToken, data) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);
    const url = `${API_BASE_URL}?action=updateSubscription`;
    const response = await axiosInstance.put(url, data);
    return response.data;
}

export default {
    getAuthorizationUrl,
    fetchDevices,
    handleSmartThingsAuth
};
