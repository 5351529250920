// src/components/OrgTabs/inventorySettings.js

// this file is going to be used to create the inventory settings tab for the organization page
// settings will be measuerUnits, categories, subcategories
// categories and subcategories will be used to create the items in the inventory
// measureUnits will be dediacted config but set to subcategories
// the user will be able to add new measureUnits, categories and subcategories by clicking on them will open a modal to add them
// the user will also be able to delete them
// the user will be able to edit them by clicking on them will open a modal to edit them
// this will be a tab in the organization page
// first itteration will be with mock data
// then we will implement the data from the backend


import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Grid, Paper, List, ListItem, ListItemText, IconButton, Collapse, Divider, ListItemSecondaryAction, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add, Delete, Edit, ExpandLess, ExpandMore } from '@mui/icons-material';
import CategoryModal from '../CategoryModal';
import MeasureUnitModal from '../MeasureUnitModal';
import { fetchMeasureUnits, deleteMeasureUnit } from '../../services/measureUnitsService';
import { fetchCategories, deleteCategory } from '../../services/categoryService';
import { useAuth } from '../../context/AuthContext';
import ConfirmationDialog from '../ConfirmationDialog';


const InventorySettings = () => {
    const { t } = useTranslation();


    const { apiKey, organizationId, token: jwtToken } = useAuth();
    const [categories, setCategories] = useState([]);
    const [measureUnits, setMeasureUnits] = useState([]);
    const [category, setCategory] = useState('');
    const [subcategory, setSubcategory] = useState('');
    const [open, setOpen] = useState({});
    const [measureUnitsOpen, setMeasureUnitsOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);
    const [editingMeasureUnit, setEditingMeasureUnit] = useState(null);
    const [measureUnitModalOpen, setMeasureUnitModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState(null);


    useEffect(() => {
        setLoading(true);
        try {
            //need to make sure that first the measure units are fetched and then the categories
            fetchMeasureUnits(apiKey, organizationId, jwtToken).then((data) => {
                setMeasureUnits(data);
                fetchCategories(apiKey, organizationId, jwtToken).then((data) => {
                    setCategories(data);
                });
            });
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }

    }, [apiKey, organizationId, jwtToken]);



    const handleClick = (id) => {
        setOpen(prev => ({ ...prev, [id]: !prev[id] }));
    };


    // Function to get measure unit details by ID
    const getMeasureUnitDetails = (measureUnitId) => {
        if (measureUnits.length === 0) return null;
        return measureUnits.find(unit => unit.id === measureUnitId);
    };


    const handleMeasureUnitsClick = () => {
        setMeasureUnitsOpen(!measureUnitsOpen);
    };


    const handleAddCategoryClick = () => {
        setEditingCategory(null); // Ensure no category is being edited
        setModalOpen(true);
    };

    const handleEditCategoryClick = (category) => {
        setEditingCategory(category);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleModalSubmit = (newCategoryData) => {
        fetchCategories(apiKey, organizationId, jwtToken).then((data) => {
            setCategories(data);
        });
        setModalOpen(false);
    };


    /* handleDeleteCategory = (categoryId) => {
        deleteCategory(categoryId, apiKey, organizationId, jwtToken).then(() => {
            // Logic to remove the category from the state
            setCategories(categories.filter(category => category.categoryId !== categoryId));
        });
    };*/

    const handleAddMeasureUnitClick = () => {
        setEditingMeasureUnit(null);
        setMeasureUnitModalOpen(true);
    };

    const handleEditMeasureUnitClick = (measureUnit) => {
        setEditingMeasureUnit(measureUnit);
        setMeasureUnitModalOpen(true);
    };

    const handleMeasureUnitModalClose = () => {
        setMeasureUnitModalOpen(false);
    };

    const handleMeasureUnitModalSubmit = (measureUnit) => {
        fetchMeasureUnits(apiKey, organizationId, jwtToken).then((data) => {
            setMeasureUnits(data);
        });
        handleMeasureUnitModalClose();
    };

    /*const handleDeleteMeasureUnit = (measureUnitId) => {
        deleteMeasureUnit(measureUnitId, apiKey, organizationId, jwtToken).then(() => {
            // Logic to remove the measure unit from the state
            setMeasureUnits(measureUnits.filter(unit => unit.id !== measureUnitId));
        });
    };*/

    const handleDeleteConfirm = () => {
        if (deleteTarget?.type === 'category') {
            deleteCategory(deleteTarget.id, apiKey, organizationId, jwtToken).then(() => {
                fetchCategories(apiKey, organizationId, jwtToken).then((data) => {
                    setCategories(data);
                });
            });
        } else if (deleteTarget?.type === 'measureUnit') {
            deleteMeasureUnit(deleteTarget.id, apiKey, organizationId, jwtToken).then(() => {
                fetchMeasureUnits(apiKey, organizationId, jwtToken).then((data) => {
                    setMeasureUnits(data);
                });
            });
        }
    };

    const handleDeleteCategory = (categoryId) => {
        setDeleteTarget({ type: 'category', id: categoryId });
        setConfirmOpen(true);
    };

    const handleDeleteMeasureUnit = (measureUnitId) => {
        setDeleteTarget({ type: 'measureUnit', id: measureUnitId });
        setConfirmOpen(true);
    };

    return (
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
            <Box>
                <Typography variant="h5" gutterBottom>
                    {t('OrganizationSettings.Tabs.InventorySettings')}
                </Typography>
                <Typography variant="h6" gutterBottom>
                    {t('OrganizationSettings.InventorySettings.Categories')}
                </Typography>
                <Button onClick={handleAddCategoryClick}>{t('OrganizationSettings.InventorySettings.AddCategory')}</Button>
                {categories.map((category) => (
                    <React.Fragment key={category.categoryId}>
                        <ListItem button onClick={() => handleClick(category.categoryId)}>
                            <ListItemText primary={category.categoryName} />
                            <IconButton edge="end" aria-label="edit" onClick={() => handleEditCategoryClick(category)} sx={{ mr: 0.5 }}>
                                <Edit />
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCategory(category.categoryId)}>
                                <Delete />
                            </IconButton>
                            {open[category.id] ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={open[category.categoryId]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {category.subcategories?.map((subcategory) => (
                                    <ListItem key={subcategory.id} sx={{ pl: 4 }}>
                                        <Grid container wrap="wrap" spacing={1} alignItems="center">
                                            <Grid item xs={12} sm={true}>
                                                <ListItemText primary={subcategory.subcategoryName} />
                                            </Grid>
                                            <Grid item>
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {subcategory.measureUnits?.map(unit => {
                                                        const measureUnit = getMeasureUnitDetails(unit.id);
                                                        if (!measureUnit) return (<Chip label={t('OrganizationSettings.InventorySettings.DeletedMeasureUnit')} />);
                                                        return (
                                                            <Chip key={measureUnit.id} label={`${measureUnit.nameLong} (${measureUnit.nameShort})`} />
                                                        );
                                                    })}
                                                </Box>
                                            </Grid>
                                            {/* Removed Edit and Delete buttons from here */}
                                        </Grid>
                                    </ListItem>
                                ))}
                            </List>
                        </Collapse>
                    </React.Fragment>
                ))}

                <Divider sx={{ mt: 2 }} />
                <ListItem button onClick={handleMeasureUnitsClick}>
                    <ListItemText primary={t('OrganizationSettings.InventorySettings.MeasureUnits')} />
                    {measureUnitsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Button onClick={handleAddMeasureUnitClick}>{t('OrganizationSettings.InventorySettings.AddMeasureUnit')}</Button>
                <Collapse in={measureUnitsOpen} timeout="auto" unmountOnExit>
                    <List>
                        {measureUnits.map((measureUnit) => (
                            <ListItem key={measureUnit.id} sx={{ pl: 4 }}>
                                <ListItemText primary={measureUnit.nameLong} secondary={measureUnit.nameShort} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditMeasureUnitClick(measureUnit)} sx={{ mr: 0.5 }}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteMeasureUnit(measureUnit.id)}>
                                        <Delete />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </Collapse>
                <CategoryModal
                    open={modalOpen}
                    onClose={handleModalClose}
                    onSubmit={handleModalSubmit}
                    editCategory={editingCategory}
                    measureUnits={measureUnits} // Ensure this prop is passed
                />
                <MeasureUnitModal
                    open={measureUnitModalOpen}
                    onClose={handleMeasureUnitModalClose}
                    onSubmit={handleMeasureUnitModalSubmit}
                    editMeasureUnit={editingMeasureUnit}
                />
                <ConfirmationDialog
                    isOpen={confirmOpen}
                    onClose={() => setConfirmOpen(false)}
                    onConfirm={() => {
                        handleDeleteConfirm();
                        setConfirmOpen(false);
                    }}
                    title={t('OrganizationSettings.InventorySettings.ConfirmDeleteTitle')}
                    text={t('OrganizationSettings.InventorySettings.ConfirmDeleteText')}
                />
            </Box>
        </Paper>
    );
}

export default InventorySettings;