import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { calculateTax, calculateTotal } from './costEstimationUtils';
import { fetchCostEstimationById, saveCostEstimation, updateCostEstimation, downloadCostEstimationPdf, sendCostEstimationPdf } from '../../services/CostEstimationService';
import { getAllOwners } from '../../services/ownerService';
import { getHorsesByOwnerID } from '../../services/horseService';
import { fetchPricingSettings } from '../../services/PricingService';
import { formatISO, add } from 'date-fns';
import { fetchSpecificOwnerBookings } from '../../services/guidingMachineService';
import { bookingStatisticsOwnerSpecific } from '../../services/bookingService';
import { fetchServiceTypes } from '../../services/serviceTypesService';
import { useTranslation } from "react-i18next";
import { usePermissions } from '../../context/PermissionsContext';

export const useCostEstimation = (estimationId, apiKey, organizationId, jwtToken, userId) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [ownersWithHorses, setOwnersWithHorses] = useState([]);
  const [pricingOptions, setPricingOptions] = useState([]);
  const today = new Date();
  const defaultIssueDate = formatISO(today);
  const defaultDueDate = formatISO(add(today, { days: 14 }));
  const { entitlements } = usePermissions();
  const initialEstimationData = {
    id: '',
    recipientType: '',
    customerId: '',
    companyName: '',
    cancel: false,
    creationMethod: 'manual',
    customerName: '',
    companyBRN: '',
    issueDate: defaultIssueDate,
    dueDate: defaultDueDate,
    totalAmount: 0,
    totalNetAmount: 0,
    taxAmount: 0,
    status: 'draft',
    organizationId: '',
    reminderLevel: 0,
    reminderId: '',
    alreadyReminded: false,
    rows: [],
    address: {
      street: '',
      zipCode: '',
      city: '',
      country: '',
      email: '',
    },
  };
  const entitlement = entitlements.find(entitlement => entitlement.isActive) || {};
  const isEquistab = entitlement.internalName?.includes('equistab');
  const isErp = entitlement.internalName?.includes('erp');
  const [estimationData, setEstimationData] = useState(initialEstimationData);
  const [isCancelled, setIsCancelled] = useState(false);
  const [showAddRows, setShowAddRows] = useState(false);

  const checkForCancellation = () => {
    if (estimationData.cancel) {
      setIsCancelled(true);
    } else {
      setIsCancelled(false);
    }
  };

  useEffect(() => {
    checkForCancellation();
  }, [estimationData.id, apiKey, organizationId, jwtToken]);

  const calculateTotals = () => {
    const totalAmount = calculateTotal(estimationData.rows);
    const taxAmount = calculateTax(estimationData.rows);

    setEstimationData((prevData) => ({
      ...prevData,
      totalAmount: totalAmount,
      taxAmount: taxAmount,
      totalNetAmount: totalAmount - taxAmount,
    }));
  };

  useEffect(() => {
    if (estimationId !== 'new') {
      const fetchData = async () => {
        setLoading(true);
        try {
          const data = await fetchCostEstimationById(apiKey, organizationId, jwtToken, estimationId, userId);
          setEstimationData(data);
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [estimationId, apiKey, organizationId, jwtToken, userId]);

  useEffect(() => {
    async function fetchAndSetOwners() {
      try {
        const ownersData = await getAllOwners(apiKey, organizationId, jwtToken);
        setOwnersWithHorses(ownersData);
      } catch (error) {
        console.error(error);
      }
    }
    fetchAndSetOwners();
  }, [apiKey, organizationId, jwtToken]);

  useEffect(() => {
    const fetchPricing = async () => {
      try {
        const pricingData = await fetchPricingSettings(apiKey, organizationId, jwtToken);
        setPricingOptions(pricingData);
      } catch (error) {
        console.error('Error fetching pricing settings:', error);
      }
    };
    fetchPricing();
  }, [apiKey, organizationId, jwtToken]);

  useEffect(() => {
    calculateTotals();
  }, [estimationData.rows]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRows = [...estimationData.rows];
    const row = updatedRows[index];

    // Parse input values to numeric types where applicable
    let numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
      numericValue = 0; // Default to 0 if the value is not a valid number
    }

    if (['quantity', 'bruttoPrice', 'discountAmount', 'discountPercentage', 'taxRate'].includes(name)) {
      row[name] = numericValue;
    } else {
      row[name] = value; // Handle non-numeric fields normally
    }

    if (['discountPercentage', 'discountAmount'].includes(name)) {
      const bruttoPrice = parseFloat(row.bruttoPrice) || 0;
      let discountPercentage = parseFloat(row.discountPercentage) / 100 || 0;
      let discountAmount = parseFloat(row.discountAmount) || 0;

      if (name === 'discountPercentage') {
        discountAmount = parseFloat((bruttoPrice * discountPercentage).toFixed(2));
        row.discountAmount = discountAmount;
      } else if (name === 'discountAmount') {
        discountPercentage = bruttoPrice !== 0 ? parseFloat((discountAmount / bruttoPrice).toFixed(2)) : 0;
        row.discountPercentage = (discountPercentage * 100).toFixed(2);
      }
    }

    if (['quantity', 'bruttoPrice', 'discountPercentage', 'discountAmount', 'taxRate'].includes(name)) {
      const quantity = parseFloat(row.quantity) || 0;
      const bruttoPrice = parseFloat(row.bruttoPrice) || 0;
      const taxRate = parseFloat(row.taxRate) / 100 || 0;
      const discountPercentage = parseFloat(row.discountPercentage) / 100 || 0;
      const discountAmount = parseFloat(row.discountAmount) || 0;

      const discountedPrice = parseFloat((bruttoPrice * (1 - discountPercentage) - discountAmount).toFixed(2));
      const rowTotal = parseFloat((quantity * discountedPrice).toFixed(2));
      const netPrice = parseFloat((discountedPrice / (1 + taxRate)).toFixed(2));

      row.rowTotal = rowTotal;
      row.netPrice = netPrice;
    }

    setEstimationData((prevData) => ({
      ...prevData,
      rows: updatedRows,
    }));

    calculateTotals();
  };


  const removeRow = (index) => {
    const updatedRows = [...estimationData.rows];
    updatedRows.splice(index, 1);
    setEstimationData({ ...estimationData, rows: updatedRows });
    calculateTotals();
  };

  const addRow = (row) => {
    console.log('Adding row:', row);
    if (!row) {
      setEstimationData({
        ...estimationData,
        rows: [...estimationData.rows, {
          description: '',
          rowNumber: '',
          type: '',
          quantity: 0,
          taxRate: 0,
          bruttoPrice: 0,
          netPrice: 0,
          discountPercentage: 0,
          discountAmount: 0,
          rowTotal: 0,
          organizationId: organizationId,
          itemId: ''
        }]
      });
    } else {
      setEstimationData({ ...estimationData, rows: [...estimationData.rows, row] });
    }
  };

  const saveOrUpdateEstimation = async (setSnackbar) => {
    setLoading(true);
    calculateTotals();
    try {
      let responseData;
      if (estimationId !== 'new') {
        console.log('Updating cost estimation:', estimationData);
        responseData = await updateCostEstimation(estimationData, apiKey, organizationId, jwtToken, userId);
      } else {
        responseData = await saveCostEstimation(estimationData, apiKey, organizationId, jwtToken, userId);
      }
      setEstimationData(responseData);
      setLoading(false);
      setSnackbar({ open: true, message: t("costEstimationDetailPage.saveSuccess"), severity: 'success' });
      navigate('/sales-process?tab=0'); // Adjusted tab index for cost estimations
      return true;
    } catch (err) {
      setLoading(false);
      setError(err.message);
      setSnackbar({ open: true, message: t("costEstimationDetailPage.saveError"), severity: 'error' });
      console.error('Error saving cost estimation:', err);
      return false;
    }
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    const nameParts = name.split('.');
    if (nameParts.length === 2) {
      const [addressKey, field] = nameParts;
      setEstimationData(prevData => ({
        ...prevData,
        [addressKey]: {
          ...prevData[addressKey],
          [field]: value,
        },
      }));
    }
  };

  const getHeaderAndAddressData = (selectedOwner) => {
    if (!selectedOwner) {
      return {
        customerName: '',
        companyName: '',
        companyBRN: '',
        recipientType: '',
        ownerAddress: {
          street: '',
          zipCode: '',
          city: '',
          country: '',
          email: '',
        }
      };
    }

    console.log('Selected owner:', selectedOwner);
    const headerAndAddress = {
      customerName: `${selectedOwner.firstname} ${selectedOwner.surname}`,
      companyName: selectedOwner.ownerType === 'company' ? selectedOwner.companyName : '',
      companyBRN: selectedOwner.ownerType === 'company' ? selectedOwner.companyBRN : '',
      recipientType: selectedOwner.ownerType,
      ownerAddress: {
        street: selectedOwner.address.street || '',
        zipCode: selectedOwner.address.zipCode || '',
        city: selectedOwner.address.city || '',
        country: selectedOwner.address.country || '',
        email: selectedOwner.email || '',
      }
    };

    return headerAndAddress;
  };

  const getRowsForBoxes = async (customerId, pricingOptions, apiKey, organizationId, jwtToken) => {
    const horses = await getHorsesByOwnerID(customerId, apiKey, organizationId, jwtToken);
    return horses.map(horse => {
      const horsePricing = pricingOptions.find(p => p.referenceId === horse.boxTypeId && p.referencePartition === 'Box');
      const defaultPricingValues = {
        taxRate: 0,
        bruttoPrice: 0,
        netPrice: 0,
        rowTotal: 0
      };

      return {
        description: horse.name,
        quantity: 1,
        taxRate: horsePricing ? horsePricing.VAT : defaultPricingValues.taxRate,
        bruttoPrice: horsePricing ? horsePricing.bruttoPrice : defaultPricingValues.bruttoPrice,
        netPrice: horsePricing ? horsePricing.nettoPrice : defaultPricingValues.netPrice,
        rowTotal: horsePricing ? horsePricing.bruttoPrice : defaultPricingValues.rowTotal,
        type: horsePricing ? horsePricing.referencePartition : 'Box',
        organizationId: organizationId,
      };
    });
  };

  const getRowsForMachines = async (userId, defaultIssueDate, customerId, apiKey, organizationId, jwtToken, pricingOptions) => {
    const ConvertedDate = new Date(defaultIssueDate);
    const selectedDate = new Date(ConvertedDate.getFullYear(), ConvertedDate.getMonth(), 1);
    const offset = selectedDate.getTimezoneOffset();
    const adjustedDate = new Date(selectedDate.getTime() - (offset * 60 * 1000));
    const date = adjustedDate.toISOString().slice(0, 10);
    const horses = await getHorsesByOwnerID(customerId, apiKey, organizationId, jwtToken);
    const response = await fetchSpecificOwnerBookings(userId, date, customerId, apiKey, organizationId, jwtToken);
    let combinedRows = [];

    if (response.bookingCounts && response.bookingCounts.length > 0) {
      const deviceUsage = response.bookingCounts[0];
      const machines = deviceUsage.machines;

      const machineHorseCounts = deviceUsage.bookings.reduce((acc, usage) => {
        if (usage.payed === "True") {
          const key = `${usage.machine_id}_${usage.horseId}`;
          acc[key] = (acc[key] || 0) + 1;
        }
        return acc;
      }, {});

      const machineHorseRows = Object.entries(machineHorseCounts).map(([key, count]) => {
        const [machineId, horseId] = key.split('_');
        const machine = machines.find(m => m.id === machineId);
        const horse = horses.find(h => h.id === horseId);
        const machinePricing = pricingOptions.find(p => p.referenceId === machineId && p.referencePartition === 'Machine');

        return {
          description: `${machine?.name || 'Unknown Machine'} used by ${horse?.name || 'Unknown Horse'}`,
          quantity: count,
          taxRate: machinePricing ? machinePricing.VAT : 0,
          bruttoPrice: machinePricing ? machinePricing.bruttoPrice : 0,
          netPrice: machinePricing ? machinePricing.nettoPrice : 0,
          rowTotal: machinePricing ? (machinePricing.bruttoPrice * count).toFixed(2) : 0,
          type: 'Device',
          organizationId: organizationId,
        };
      });

      combinedRows = [...combinedRows, ...machineHorseRows];
    }

    return combinedRows;
  };

  const getRowsforServices = async (userId, defaultIssueDate, customerId, apiKey, organizationId, jwtToken, pricingOptions) => {
    let serviceRows = [];
    try {
      const ConvertedDate = new Date(defaultIssueDate);
      const selectedDate = new Date(ConvertedDate.getFullYear(), ConvertedDate.getMonth(), 1);
      const offset = selectedDate.getTimezoneOffset();
      const adjustedDate = new Date(selectedDate.getTime() - (offset * 60 * 1000));
      const date = adjustedDate.toISOString().slice(0, 10);
      const horses = await getHorsesByOwnerID(customerId, apiKey, organizationId, jwtToken);
      const serviceTypes = await fetchServiceTypes(apiKey, organizationId, jwtToken);
      const response = await bookingStatisticsOwnerSpecific(userId, date, customerId, apiKey, organizationId, jwtToken);
      const pricingOptions = await fetchPricingSettings(apiKey, organizationId, jwtToken);

      if (response && response.length > 0) {
        serviceRows = response[0].lessons.map(lesson => ({
          description: lesson.serviceType,
          quantity: lesson.bookings.length,
          taxRate: lesson.bookings[0].pricing ? lesson.bookings[0].pricing.VAT : 0,
          netPrice: lesson.bookings[0].pricing ? lesson.bookings[0].pricing.netPrice : 0,
          bruttoPrice: lesson.bookings[0].pricing ? lesson.bookings[0].pricing.unitPrice : 0,
          rowTotal: lesson.bookings[0].pricing ? (lesson.bookings[0].pricing.unitPrice * lesson.bookings.length).toFixed(2) : 0,
          type: 'Service',
          organizationId: organizationId,
        }));
      }

      horses.forEach(horse => {
        const horseServiceIds = horse.services.map(service => service.serviceId);
        const horseServiceTypes = serviceTypes.filter(serviceType => horseServiceIds.includes(serviceType.id));

        serviceRows = [...serviceRows, ...horseServiceTypes.map(serviceType => ({
          description: serviceType.name,
          quantity: 1,
          taxRate: pricingOptions.find(p => p.referenceId === serviceType.id && p.referencePartition === 'Service')?.VAT || 0,
          netPrice: pricingOptions.find(p => p.referenceId === serviceType.id && p.referencePartition === 'Service')?.nettoPrice || 0,
          bruttoPrice: pricingOptions.find(p => p.referenceId === serviceType.id && p.referencePartition === 'Service')?.bruttoPrice || 0,
          rowTotal: pricingOptions.find(p => p.referenceId === serviceType.id && p.referencePartition === 'Service')?.bruttoPrice || 0,
          type: 'Service',
          organizationId: organizationId,
        }))];
      });
    } catch (error) {
      console.error('Error getting rows for services:', error);
    }
    return serviceRows;
  };

  const addServiceRow = async () => {
    try {
      setLoading(true);
      const serviceRows = await getRowsforServices(userId, defaultIssueDate, estimationData.customerId, apiKey, organizationId, jwtToken, pricingOptions);
      setEstimationData(prevData => ({
        ...prevData,
        rows: [...prevData.rows, ...serviceRows],
      }));
    } catch (error) {
      console.error('Error adding service row:', error);
    } finally {
      setLoading(false);
    }
  };

  const addBoxRow = async () => {
    try {
      setLoading(true);
      const boxRows = await getRowsForBoxes(estimationData.customerId, pricingOptions, apiKey, organizationId, jwtToken);
      setEstimationData(prevData => ({
        ...prevData,
        rows: [...prevData.rows, ...boxRows],
      }));
    } catch (error) {
      console.error('Error adding box row:', error);
    } finally {
      setLoading(false);
    }
  };

  const addMachineRow = async () => {
    try {
      setLoading(true);
      const machineRows = await getRowsForMachines(userId, defaultIssueDate, estimationData.customerId, apiKey, organizationId, jwtToken, pricingOptions);
      setEstimationData(prevData => ({
        ...prevData,
        rows: [...prevData.rows, ...machineRows],
      }));
    } catch (error) {
      console.error('Error adding machine row:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOwnerChange = (event, newValue, reason) => {
    console.log('Owner change:', newValue, reason);
    if (reason === 'selectOption' && newValue && typeof newValue === 'object') {
      setShowAddRows(isEquistab);
      const selectedOwner = newValue; // Since options are full owner objects
      const { customerName, companyName, companyBRN, recipientType, ownerAddress } = getHeaderAndAddressData(selectedOwner);
      setEstimationData(prevData => ({
        ...prevData,
        customerId: selectedOwner.id,
        customerName: customerName,
        companyName: companyName,
        companyBRN: companyBRN,
        recipientType: recipientType,
        address: ownerAddress,
      }));
    } else if (reason === 'clear') {
      setEstimationData(prevData => ({
        ...prevData,
        customerId: '',
        customerName: '',
        companyName: '',
        companyBRN: '',
        recipientType: '',
        address: {
          street: '',
          zipCode: '',
          city: '',
          country: '',
          email: '',
        }
      }));
      setShowAddRows(false);
    } else if (reason === 'create-option' || reason === 'input') {
      setShowAddRows(false);
      setEstimationData(prevData => ({
        ...prevData,
        customerId: '',
        customerName: newValue || '',
        companyName: '',
        companyBRN: '',
        recipientType: '',
        address: {
          street: '',
          zipCode: '',
          city: '',
          country: '',
          email: '',
        }
      }));
    }
  };

  const handleDownloadPdf = async (estimationId) => {
    try {
      const pdfBlob = await downloadCostEstimationPdf(apiKey, organizationId, jwtToken, estimationId, userId);
      const blobUrl = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `cost-estimation-${estimationData.estimationNumber}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading cost estimation PDF:', error);
    }
  };

  const handleSendPdf = async (estimationId) => {
    try {
      await sendCostEstimationPdf(apiKey, organizationId, jwtToken, estimationId, userId);
    } catch (error) {
      console.error('Error sending cost estimation PDF:', error);
    }
  };

  const handleCancel = async () => {
    try {
      const response = await updateCostEstimation({ ...estimationData, cancel: true }, apiKey, organizationId, jwtToken, userId);
      setEstimationData(response);
      navigate('/sales-process?tab=0'); // Adjusted tab index for cost estimations
    } catch (error) {
      console.error('Error cancelling cost estimation:', error);
    }
  };

  const onTransfer = (documentId, documentType, targetType, targetTypeURL) => {
    console.log('Transfer to:', targetType);
    navigate(`/sales-process/${targetTypeURL}/detail/new`, {
      state: {
        fromDocument: {
          sourceDocumentType: documentType,
          ...estimationData,
          references: [
            {
              sourceDocumentType: documentType,
              sourceDocumentId: documentId,
              targetDocumentType: targetType,
              targetDocumentId: '',
            }
          ],
        },
      },
    });
  };

  return {
    estimationData,
    ownersWithHorses,
    isCancelled,
    showAddRows,
    addBoxRow,
    addMachineRow,
    addServiceRow,
    handleOwnerChange,
    handleDownloadPdf,
    handleSendPdf,
    setEstimationData,
    calculateTotals,
    handleInputChange,
    handleAddressChange,
    handleCancel,
    saveOrUpdateEstimation,
    removeRow,
    addRow,
    loading,
    error,
    onTransfer,
  };
};
