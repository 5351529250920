import React, { createContext, useContext, useState, useEffect } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { fetchUserPreferences, updateUserPreferences } from '../services/userPrefrencesService';
import { useAuth } from './AuthContext';

const UserPreferencesContext = createContext();

export const UserPreferencesProvider = ({ children }) => {
    const { apiKey, organizationId, token } = useAuth();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Simplify device preferences to just 'viewMode'
    const [devicePreferences, setDevicePreferences] = useState(() => {
        const localPreferences = localStorage.getItem('devicePreferences');
        return localPreferences ? JSON.parse(localPreferences) : { viewMode: isMobile ? 'card' : 'table' };
    });

    // User preferences are managed via API
    const [userPreferences, setUserPreferences] = useState({
        id: '',
        language: 'en-US',
        theme: 'light', 
        favoriteActions: [],
    });

    useEffect(() => {
        const fetchAndSetUserPreferences = async () => {
            try {
                const fetchedPreferences = await fetchUserPreferences(apiKey, organizationId, token, userPreferences);
                if (fetchedPreferences) {
                    setUserPreferences(prev => {
                        return { ...prev, ...fetchedPreferences };
                    });
                }
            } catch (error) {
                console.log('Error fetching user preferences:', error);
                console.error('Error fetching user preferences:', error);
            }
        };

        fetchAndSetUserPreferences();
    }, [apiKey, organizationId, token]);

    const updateDevicePreferences = async (field, value) => {
        const updatedPreferences = {
            ...devicePreferences,
            [field]: value
        };
        setDevicePreferences(updatedPreferences);
        localStorage.setItem('devicePreferences', JSON.stringify(updatedPreferences));
    };

    //update and toogle the actionid in the favoriteActions array
    const updateFavoriteActions = async (actionId) => {
        const updatedFavorites = userPreferences.favoriteActions.includes(actionId)
            ? userPreferences.favoriteActions.filter(id => id !== actionId)
            : [...userPreferences.favoriteActions, actionId];
        return await updateUserPreference('favoriteActions', updatedFavorites);
    }

    const updateUserPreference = async (field, value) => {
        // Copy the current preferences and apply the update
        const updatedPreferences = {
            ...userPreferences,
            [field]: value
        };

        try {
            // Perform the async operation outside of the state setter
            const response = await updateUserPreferences(updatedPreferences, apiKey, organizationId, token);

            // Update the state once we have a successful response
            const newPreferences = response.userPreferences;
            setUserPreferences(updatedPreferences);

            // Optionally return the new preferences if needed
            return newPreferences;
        } catch (error) {
            console.error("Error updating user preferences:", error);
            // Handle any error logging or fallback actions here

            // Optionally return the current preferences (or null) in case of failure
            return null;
        }
    };


    return (
        <UserPreferencesContext.Provider value={{
            updateUserPreference,
            updateDevicePreferences,
            updateFavoriteActions,
            devicePreferences,
            userPreferences
        }}>
            {children}
        </UserPreferencesContext.Provider>
    );
};

export const usePreferences = () => useContext(UserPreferencesContext);
