// src/pages/auth/AddPersonModal.js

import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AddPersonModal = ({ open, onClose, onSave, title }) => {
    const [person, setPerson] = useState({
        firstname: '',
        surname: '',
        phoneNumber: '',
    });
    const { t } = useTranslation();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPerson(prev => ({ ...prev, [name]: value }));
    };

    const handleSave = () => {
        onSave(person);
        onClose();
        // Reset the form
        setPerson({ firstname: '', surname: '', phoneNumber: '' });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    name="firstname"
                    label={t('AddPersonModal.labels.firstname')}
                    type="text"
                    fullWidth
                    variant="standard"
                    value={person.firstname}
                    onChange={handleInputChange}
                />
                <TextField
                    margin="dense"
                    name="surname"
                    label={t('AddPersonModal.labels.surname')}
                    type="text"
                    fullWidth
                    variant="standard"
                    value={person.surname}
                    onChange={handleInputChange}
                />
                <TextField
                    margin="dense"
                    name="phoneNumber"
                    label={t('AddPersonModal.labels.number')}
                    type="text"
                    fullWidth
                    variant="standard"
                    value={person.phoneNumber}
                    onChange={handleInputChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('AddPersonModal.buttons.cancel')}</Button>
                <Button onClick={handleSave}>{t('AddPersonModal.buttons.save')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddPersonModal;
