// src/components/FarmDesigner/RoomItem.js
import React from 'react';
import { Paper, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const RoomItem = React.memo(({ room, handleRoomRemove, isPreview }) => {
    const { t } = useTranslation(); // Initialize the translation function

    return (
        <Paper
            elevation={2}
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1em',
                boxSizing: 'border-box',
                position: 'relative',
                cursor: 'move',
                border: isPreview ? '2px solid #000' : 'none',
            }}
        >
            <Typography variant="subtitle1">{room.name}</Typography>
            {!isPreview && (
                <IconButton
                    size="small"
                    className="non-draggable"
                    onClick={(event) => {
                        event.stopPropagation();
                        handleRoomRemove(room.instanceId);
                    }}
                    style={{
                        position: 'absolute',
                        top: '0.25em',
                        right: '0.25em',
                    }}
                    aria-label={t('RoomItem.RemoveRoom')}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            )}
        </Paper>
    );
});

export default RoomItem;
