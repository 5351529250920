// src/services/apiConfig/axiosConfig.js

import axios from 'axios';
import i18n from '../../i18n'; // Ensure you import your i18n config correctly

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
    baseURL: backendUrl,
    withCredentials: true,  // Enable cookies by default
    headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
    },
});

export const setAuthHeader = (jwtToken) => {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;
};

export const setApiKeyHeader = (apiKey) => {
    axiosInstance.defaults.headers.common['x-functions-key'] = apiKey;
};

export const setOrganizationIdHeader = (organizationId) => {
    axiosInstance.defaults.headers.common['Organization-Id'] = organizationId;
};

export const setWithCredentials = (withCredentials) => {
    axiosInstance.defaults.withCredentials = withCredentials;
};

// Optional: Add a way to dynamically update the language header when the language changes in the app
export const updateLanguage = (language) => {
    axiosInstance.defaults.headers['Accept-Language'] = language;
};

i18n.on('languageChanged', (lng) => {
    updateLanguage(lng);
});

export default axiosInstance;
