import { useEffect, useState } from 'react';
import i18n from '../i18n'; // Adjust the path according to your structure
// Import all locales you plan to support from date-fns
import { enUS, de, fr, es, it } from 'date-fns/locale';

// Mapping of i18n language codes to date-fns locales
const localeMap = {
  en: enUS,
  'en-US': enUS,
  de: de,
  'de-DE': de,
  'de-AT': de,
  'en-AT': de,
  'en-DE': de,
  fr: fr,
  'fr-FR': fr,
  'fr-CA': fr,
  'fr-BE': fr,
  'fr-CH': fr,
  'fr-LU': fr,
  'fr-MC': fr,
  es: es,
  'es-ES': es,
  'es-AR': es,
  'es-BO': es,
  'es-CL': es,
  'es-CO': es,
  'es-CR': es,
  'es-CU': es,
  'es-DO': es,
  'es-EC': es,
  it: it,
  'it-IT': it,
  'en-GB': enUS, // Example: Assuming you want British English to use enUS date formatting
  // Add more mappings as needed
};

export const useDateLocale = () => {
  const [dateLocale, setDateLocale] = useState(enUS); // Default to enUS if no match is found

  useEffect(() => {
    const handleLanguageChange = (lng) => {
      const selectedLocale = localeMap[lng] || enUS; // Fallback to enUS if no specific locale is matched
      setDateLocale(selectedLocale);
    };

    i18n.on('languageChanged', handleLanguageChange);

    // Call it initially in case the language is not default
    handleLanguageChange(i18n.language);

    return () => i18n.off('languageChanged', handleLanguageChange);
  }, []);

  return dateLocale;
};
