import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, TextField, Button, Paper, FormControl, InputLabel, Select, MenuItem, Typography, Switch, IconButton, LinearProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the trashcan icon
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useItemManagement } from './useItemManagement';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from './useSnackbar';
import { useNavigate } from 'react-router-dom';

const ItemInfoPage = () => {
  const { itemId } = useParams();
  const isEditMode = itemId && itemId !== 'new';
  const navigate = useNavigate();
  const {
    itemData,
    handleInputChange,
    categories,
    handleSubmit,
    measureUnits,
    loading,
    error,
    items, // all available items for dependencies
    addSalesDependency,
    handleSalesDependencyChange,
    removeSalesDependency,
  } = useItemManagement(itemId);
  const { t } = useTranslation();
  const { openSnackbar, SnackbarComponent } = useSnackbar();

  const getFilteredMeasureUnits = () => {
    if (!itemData.subcategory) return measureUnits;

    const selectedCategory = categories.find(category => category.categoryId === itemData.category);
    const selectedSubcategory = selectedCategory?.subcategories?.find(sub => sub.subcategoryId === itemData.subcategory);

    if (selectedSubcategory && selectedSubcategory.measureUnits.length > 0) {
      const subcategoryMeasureUnitIds = selectedSubcategory.measureUnits.map(mu => mu.id);
      return measureUnits.filter(mu => subcategoryMeasureUnitIds.includes(mu.id));
    }

    return measureUnits;
  };

  const filteredMeasureUnits = getFilteredMeasureUnits();

  if (error) {
    return <div>{t('error')}: {error}</div>;
  }

  return (
    <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
      {loading && <LinearProgress />}
      <Typography variant="h4">
        <IconButton onClick={() => navigate(-1)} aria-label="back">
          <ArrowBackIcon />
        </IconButton>
        {t('itemManagement.title')}
      </Typography>

      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(openSnackbar);
      }}>
        {/* Item Details Section */}
        <Paper elevation={2} sx={{ p: 3, mt: 3 }}>
          <Typography variant="h5">Item Details</Typography>
          <TextField
            label={t('itemManagement.itemName')}
            name="name"
            value={itemData.name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>{t('itemManagement.category')}</InputLabel>
            <Select
              name="category"
              value={itemData.category}
              onChange={handleInputChange}
            >
              {categories.map((category) => (
                <MenuItem key={category.categoryId} value={category.categoryId}>
                  {category.categoryName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>{t('itemManagement.subcategory')}</InputLabel>
            <Select
              name="subcategory"
              value={itemData.subcategory}
              onChange={handleInputChange}
              disabled={!itemData.category || categories.length === 0 || categories.find(category => category.categoryId === itemData.category).subcategories.length === 0}
            >
              {itemData.category && categories.find(category => category.categoryId === itemData.category).subcategories?.length > 0 &&
                categories.find(category => category.categoryId === itemData.category).subcategories?.map((subcategory) => (
                  <MenuItem key={subcategory.subcategoryId} value={subcategory.subcategoryId}>
                    {subcategory.subcategoryName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>{t('itemManagement.measureUnit')}</InputLabel>
            <Select
              name="measureUnit"
              value={itemData.measureUnit}
              onChange={handleInputChange}
            >
              {filteredMeasureUnits.map((measureUnit) => (
                <MenuItem key={measureUnit.id} value={measureUnit.id}>
                  {measureUnit.nameLong}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label={t('itemManagement.lastBoughtPrice')}
              name="lastBoughtPrice"
              value={itemData.lastBoughtPrice}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              type="number"
              disabled
            />
          </FormControl>
        </Paper>

        {/* Stock Information Section */}
        <Paper elevation={2} sx={{ p: 3, mt: 3, backgroundColor: itemData.stockItem ? 'inherit' : 'inherit' }}>
          <Typography variant="h5">Stock Information</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography>{t('itemManagement.stockItem')}</Typography>
            <Switch
              checked={itemData.stockItem}
              onChange={handleInputChange}
              name="stockItem"
            />
          </Box>

          {itemData.stockItem && (
            <>
              <TextField
                label={t('itemManagement.quantity')}
                name="quantity"
                value={itemData.quantity}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                type="number"
              />
              <TextField
                label={t('itemManagement.warningQuantity')}
                name="warningQuantity"
                value={itemData.warningQuantity}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                type="number"
              />
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography>{t('itemManagement.showInDashboard')}</Typography>
                <Switch
                  checked={itemData.showInDashboard}
                  disabled={!itemData.stockItem}
                  onChange={handleInputChange}
                  name="showInDashboard"
                />
              </Box>
            </>
          )}
        </Paper>

        {/* Sales Information Section */}
        <Paper elevation={2} sx={{ p: 3, mt: 3 }}>
          <Typography variant="h5">Sales Information</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography>{t('itemManagement.isSaleItem')}</Typography>
            <Switch
              checked={itemData.isSaleItem}
              onChange={handleInputChange}
              name="isSaleItem"
            />
          </Box>

          {itemData.isSaleItem && (
            <>
              {/* Gross Price */}
              <TextField
                label={t('itemManagement.bruttoPrice')}
                name="bruttoPrice"
                value={itemData.bruttoPrice || 0}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                type="number"
                inputProps={{ min: 0, step: 0.01 }}
              />

              {/* Net Price */}
              <TextField
                label={t('itemManagement.netPrice')}
                name="netPrice"
                value={itemData.netPrice || 0}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                type="number"
                inputProps={{ min: 0, step: 0.01 }}
              />

              {/* Tax Percentage */}
              <TextField
                label={t('itemManagement.taxPercentage')}
                name="taxPercentage"
                value={itemData.taxPercentage || 0}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                type="number"
                inputProps={{ min: 0, max: 100, step: 0.01 }}
              />

              {/* Sales Dependencies */}
              <Typography variant="h6" sx={{ mt: 2 }}>Sales Dependencies</Typography>
              <FormControl fullWidth margin="normal">
                <InputLabel>{t('itemManagement.selectDependency')}</InputLabel>
                <Select
                  name="dependency"
                  value=""
                  onChange={(e) => addSalesDependency(e.target.value)}
                >
                  {items.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {itemData.salesDependencies.map((dependency, index) => (
                <Box key={dependency.id} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                  <Typography sx={{ flex: 1 }}>{dependency.name}</Typography>
                  <TextField
                    label={t('itemManagement.quantity')}
                    value={dependency.quantity}
                    type="number"
                    onChange={(e) => handleSalesDependencyChange(index, e.target.value)}
                    sx={{ width: '100px', mr: 2 }}
                  />
                  <IconButton
                    color="error"
                    onClick={() => removeSalesDependency(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </>
          )}
        </Paper>

        <Button type="submit" variant="contained" color="primary" sx={{ mt: 3 }}>
          {isEditMode ? t('itemManagement.editItem') : t('itemManagement.saveItem')}
        </Button>
      </form>

      <SnackbarComponent />
    </Paper>
  );
};

export default ItemInfoPage;
