/**
 * Calculates the total amount for all rows in a sales order.
 * @param {Array} rows - Array of sales order rows.
 * @returns {number} - The total amount.
 */
export const calculateTotal = (rows) => {
  return rows.reduce((total, row) => {
    const quantity = parseFloat(row.quantity) || 0;
    const bruttoPrice = parseFloat(row.bruttoPrice) || 0;
    const discountPercentage = parseFloat(row.discountPercentage) / 100 || 0;
    const discountAmount = parseFloat(row.discountAmount) || 0;

    const discountedPrice = bruttoPrice - discountAmount;
    const rowTotal = quantity * discountedPrice;

    return total + rowTotal;
  }, 0);
};

/**
 * Calculates the total tax amount for all rows in a sales order.
 * @param {Array} rows - Array of sales order rows.
 * @returns {number} - The total tax amount.
 */
export const calculateTax = (rows) => {
  const totalTax = rows.reduce((totalTax, row) => {
    const bruttoPrice = parseFloat(row.bruttoPrice) || 0;
    const quantity = parseFloat(row.quantity) || 0;
    const taxRate = parseFloat(row.taxRate) / 100 || 0;
    const discountPercentage = parseFloat(row.discountPercentage) / 100 || 0;
    const discountAmount = parseFloat(row.discountAmount) || 0;


    // Apply amount discount
    const finalPrice = bruttoPrice - discountAmount;

    // Calculate the tax amount for the row
    const taxAmountForRow = (finalPrice - (finalPrice / (1 + taxRate))) * quantity;

    return totalTax + taxAmountForRow;
  }, 0);

  return parseFloat(totalTax.toFixed(2)); // Use toFixed(2) here and convert back to number
};

/**
 * Calculates the net price for a single row in a sales order.
 * @param {Object} row - A single sales order row.
 * @returns {number} - The net price for the row.
 */
export const calculateNetPrice = (row) => {
  const bruttoPrice = parseFloat(row.bruttoPrice) || 0;
  const discountPercentage = parseFloat(row.discountPercentage) / 100 || 0;
  const discountAmount = parseFloat(row.discountAmount) || 0;
  const taxRate = parseFloat(row.taxRate) / 100 || 0;

  const discountedPrice = bruttoPrice - discountAmount;
  const netPrice = discountedPrice / (1 + taxRate);

  return parseFloat(netPrice.toFixed(2));
};

/**
 * Formats a number to two decimal places.
 * @param {number} value - The number to format.
 * @returns {string} - The formatted number.
 */
export const formatToTwoDecimalPlaces = (value) => {
  return value.toFixed(2);
};

/**
 * Validates a sales order row to ensure all necessary fields are filled.
 * @param {Object} row - A single sales order row.
 * @returns {boolean} - True if the row is valid, false otherwise.
 */
export const validateRow = (row) => {
  return (
    row.description !== '' &&
    row.quantity > 0 &&
    row.bruttoPrice >= 0 &&
    row.taxRate >= 0 &&
    row.rowTotal >= 0
  );
};

/**
 * Calculates the total net amount for all rows in a sales order.
 * @param {Array} rows - Array of sales order rows.
 * @returns {number} - The total net amount.
 */
export const calculateTotalNetAmount = (rows) => {
  return rows.reduce((totalNet, row) => {
    const quantity = parseFloat(row.quantity) || 0;
    const netPrice = calculateNetPrice(row);

    return totalNet + quantity * netPrice;
  }, 0);
};
