import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig';

const API_BASE_URL = '/api/bookings';  // Adjust according to your actual API endpoint

const handleError = (error) => {
    if (error.response && error.response.data) {
        throw new Error(error.response.data.message || 'Operation failed');
    }
    throw new Error('Network or server issue');
};

// Fetch all bookings for a specific user
export const fetchOwnerBookings = async (ownerId, apiKey, organizationId, jwtToken) => {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    try {
        const response = await axiosInstance.get(`${API_BASE_URL}?ownerId=${ownerId}`);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// fetch all bookings
export const fetchBookings = async (apiKey, organizationId, jwtToken) => {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    try {
        const response = await axiosInstance.get(API_BASE_URL);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const fetchBookingById = async (bookingId, apiKey, organizationId, jwtToken) => {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    try {
        const response = await axiosInstance.get(`${API_BASE_URL}?id=${bookingId}`);
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
};

// Create a new booking
export const createBooking = async (bookingData, apiKey, organizationId, jwtToken) => {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);


    try {
        const response = await axiosInstance.post(API_BASE_URL, bookingData);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

// Update an existing booking
export const updateBooking = async (bookingId, bookingData, apiKey, organizationId, jwtToken) => {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    try {
        const response = await axiosInstance.put(`${API_BASE_URL}?id=${bookingId}`, bookingData);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const markBookingsAsPaid = async (bookingIds, apiKey, organizationId, jwtToken) => {
    const payload = {
        bookingIds,  // The list of booking IDs to mark as paid
    };

    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    try {
        const response = await axiosInstance.post(`${API_BASE_URL}/markaspaid`, payload);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const bookingStatistics = async (userId, date, apiKey, organizationId, jwtToken) => {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.get(`${API_BASE_URL}/statistics?userId=${userId}&date=${date}`);
    return response.data;
}


export const bookingStatisticsOwnerSpecific = async (userId, date, ownerId, apiKey, organizationId, jwtToken) => {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.get(`${API_BASE_URL}/statistics?userId=${userId}&date=${date}&ownerId=${ownerId}`);
    return response.data;
}



// Delete a booking
export const deleteBooking = async (bookingId, apiKey, organizationId, jwtToken) => {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    try {
        const response = await axiosInstance.delete(`${API_BASE_URL}?id=${bookingId}`);
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

