// src/pages/HorsePage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllHorses, getHorsesByUserId } from '../services/horseService';
import { archiveHorseAndOwnerByHorseId } from '../services/archivingService';
import { Box, Button, Paper, Typography, LinearProgress, IconButton } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import CakeIcon from '@mui/icons-material/Cake';
import GenericTableView from '../components/GenericTableView';
import GenericCardView from '../components/GenericCardView';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useTheme, useMediaQuery } from '@mui/material';
import { usePreferences } from '../context/UserPreferencesContext';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import EyeIcon from '@mui/icons-material/Visibility';
import SnackbarAlert from '../components/SnackbarAlert';
import ConfirmationDialog from '../components/ConfirmationDialog';

const HorsePage = () => {
  const { apiKey, userId, userType, organizationId, token } = useAuth();
  const { devicePreferences, updateDevicePreferences } = usePreferences();
  const [isLoading, setIsLoading] = useState(true);
  const [horses, setHorses] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedHorseId, setSelectedHorseId] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const jwtToken = token;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [viewMode, setViewMode] = useState(devicePreferences.viewMode ? devicePreferences.viewMode : isMobile ? 'card' : 'table');

  useEffect(() => {
    const fetchHorses = async () => {
      setIsLoading(true);
      let response;

      if (userType === 'admin') {
        response = await getAllHorses(apiKey, organizationId, jwtToken);
      } else {
        response = await getHorsesByUserId(userId, apiKey, organizationId, jwtToken);
      }

      if (!Array.isArray(response)) {
        response = [response];
      }

      setHorses(response);
      setIsLoading(false);
    };

    fetchHorses();
  }, [apiKey, organizationId, jwtToken, userType, userId]);

  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);

  const handleRowClick = (horseId) => {
    navigate(`/horses/detail/${horseId}`);
  };

  const handleArchiveClick = (horseId) => {
    setSelectedHorseId(horseId);
    setConfirmDialogOpen(true);
  };

  const handleArchiveConfirmation = async () => {
    try {
      await archiveHorseAndOwnerByHorseId(selectedHorseId, organizationId, apiKey, jwtToken);
      setSnackbar({ open: true, message: 'Horse and owner archived successfully.', severity: 'success' });
      setHorses(horses.filter(horse => horse.id !== selectedHorseId));
    } catch (error) {
      setSnackbar({ open: true, message: 'Error archiving horse and owner.', severity: 'error' });
    } finally {
      setConfirmDialogOpen(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: '' });
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  const isBirthdayToday = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();
    return birthDate.getDate() === today.getDate() && birthDate.getMonth() === today.getMonth();
  };

  const toggleViewMode = () => {
    updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
  };

  if (isLoading) {
    return (
      <div>
        <Typography variant="h6">{t('HorsePage.waitingText')}</Typography>
        <LinearProgress />
      </div>
    );
  }

  const structure = {
    headers: [{
      key: 'name', label: t('HorsePage.table.name'), display: { value: true, chipColor: false }
    }, {
      key: 'breed', label: t('HorsePage.table.breed'), display: { value: true, chipColor: false }
    }, {
      key: 'age', label: t('HorsePage.table.age'), display: { value: true, chipColor: false }
    }],
    rows: horses.map(row => ({
      id: row.id,
      name: {
        value: row.name,
        icon: null
      },
      breed: {
        value: row.breed,
        icon: null
      },
      age: {
        value: isNaN(calculateAge(row.birthday)) ? '' : `${calculateAge(row.birthday)}`,
        icon: isBirthdayToday(row.birthday) ? <CakeIcon fontSize="small" /> : null,
      },
      actions: [
        { icon: <EyeIcon />, handler: () => navigate(`/horses/detail/${row.id}`) },
        { icon: <EditIcon />, handler: () => navigate(`/horses/edit/${row.id}`) },
        ...(userType === 'admin' ? [{ icon: <ArchiveIcon />, handler: () => handleArchiveClick(row.id) }] : [])
      ]
    }))
  };

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {t('HorsePage.horses')}
      </Typography>

      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/horses/create/new')}
          startIcon={<AddIcon />}
        >
          {t('HorsePage.addHorse')}
        </Button>

        <IconButton onClick={toggleViewMode} color="primary">
          {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
        </IconButton>
      </Box>

      {viewMode === 'table' ? (
        <GenericTableView structure={structure} onClick={handleRowClick} />
      ) : (
        <GenericCardView structure={structure} onClick={handleRowClick} />
      )}

      <SnackbarAlert
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />

      <ConfirmationDialog
        isOpen={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        onConfirm={handleArchiveConfirmation}
        text={t("Are you sure you want to archive this horse and its owner?")}
      />
    </Paper>
  );
};

export default HorsePage;
