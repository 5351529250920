// src/pages/SortableCustomerItem.js
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';

import { useTranslation } from 'react-i18next';

const SortableCustomerItem = ({ id, customer, onRemove, dragOverlay }) => {
    const { t } = useTranslation();
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: '#f9f9f9',
        padding: '8px',
        marginBottom: '8px',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        cursor: dragOverlay ? 'grabbing' : 'default',
        zIndex: dragOverlay ? 1000 : 'auto',
    };

    const handleRemove = (event) => {
        event.stopPropagation();
        onRemove(id);
    };

    const content = (
        <>
            {!dragOverlay && (
                <Box sx={{ mr: 2, cursor: 'grab' }} {...listeners} {...attributes} aria-label={t('SortableCustomerItem.dragHandleAriaLabel')}>
                    <DragHandleIcon />
                </Box>
            )}
            <Box flexGrow={1}>
                <Typography variant="body1">{customer.firstname} {customer.surname}</Typography>
                <Typography variant="body2">{customer.address?.street} {customer.address?.city}</Typography>
            </Box>
            {onRemove && (
                <IconButton onClick={handleRemove} aria-label={t('SortableCustomerItem.removeCustomerAriaLabel')} color="secondary">
                    <DeleteIcon />
                </IconButton>
            )}
        </>
    );

    if (dragOverlay) {
        return (
            <div style={{ ...style, width: '100%' }}>
                {content}
            </div>
        );
    }

    return (
        <div
            ref={setNodeRef}
            style={style}
        >
            {content}
        </div>
    );
};

export default SortableCustomerItem;
