// src/pagesinventoryManagement/itemManagement/useSnackbar.js

import { useState } from 'react';
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';

export const useSnackbar = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('info');

  const openSnackbar = (message, severity = 'info') => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const closeSnackbar = () => {
    setOpen(false);
  };

  const SnackbarComponent = () => (
    <Snackbar open={open} autoHideDuration={5000} onClose={closeSnackbar}>
      <Alert onClose={closeSnackbar} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );

  return {
    openSnackbar,
    SnackbarComponent,
  };
};
