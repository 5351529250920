// src/services/PricingService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/pricing';

export async function fetchPricingSettings(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(API_BASE_URL);
  return response.data;
}

export async function fetchPricingById(apiKey, organizationId, jwtToken, id) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${id}`;
  const response = await axiosInstance.get(url);
  return Array.isArray(response.data) && response.data.length > 0 ? response.data[0] : 'No pricing found';
}

export async function fetchPricingByReference(apiKey, organizationId, jwtToken, referenceId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?referenceId=${referenceId}`;
  const response = await axiosInstance.get(url);
  return Array.isArray(response.data) && response.data.length > 0 ? response.data[0] : 'No pricing found';
}

export async function savePricing(pricing, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(API_BASE_URL, pricing);
  return response.data;
}

export async function updatePricing(pricing, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${pricing.pricingId}`;
  const response = await axiosInstance.put(url, pricing);
  return response.data;
}

export async function deletePricing(pricingId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${pricingId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

export default {
  fetchPricingSettings,
  fetchPricingById,
  fetchPricingByReference,
  savePricing,
  updatePricing,
  deletePricing
};
