// src/components/HomeDashboards/DashboardComponents/ExpenseBarChart.js

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const ExpenseBarChart = ({ titleKey, data, bars }) => {
    const { t } = useTranslation();
    return (
        <Grid item xs={12} sm={6}>
            <Card>
                <CardContent>
                    <Typography variant="h6">
                        {t(titleKey)}
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart width={500} height={300} data={data}>
                            <XAxis dataKey="category" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {/*{bars.map((entry, index) => (
                            <Bar key={index} dataKey={entry.category} fill={entry.color} />
                        ))}*/}

                            {
                                Array.from(bars).map((subcat, index) => (
                                    <Bar key={subcat.label} dataKey={subcat.label} stackId="a" fill={subcat.color} />
                                ))
                            }
                        </BarChart>
                    </ResponsiveContainer>
                </CardContent>
            </Card>
        </Grid>
    );
};

ExpenseBarChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            category: PropTypes.string.isRequired,
            color: PropTypes.string,
            // Additional data fields can be included here
        })
    ).isRequired
};

export default ExpenseBarChart;
