// src\components\SelfSignup\PersonalInfo.js
import { useState } from 'react';
import { TextField, Button, Grid, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import { useDateLocale } from '../../hooks/useDateLocale';  // Your custom hook
import { parseISO } from 'date-fns';

const PersonalInfo = ({ onNext, initialData }) => {
    const { t } = useTranslation();
    const dateLocale = useDateLocale();
    const [data, setData] = useState(initialData || {
        firstname: '',
        surname: '',
        number: '',
        birthday: '',
        ownerType: 'private',
        company: '',
    });

    const isFormValid = () => {
        // Adjust validation for the company field when ownerType is 'company'
        return (
            data.firstname &&
            data.surname &&
            data.number &&
            (data.ownerType === 'private' || (data.ownerType === 'company' && data.company))
        );
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prev => ({ ...prev, [name]: value }));
    };

    const handleNext = () => {
        onNext(data);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="ownerType-label">{t('PersonalInfo.labels.ownerType')}</InputLabel>
                        <Select
                            labelId="ownerType-label"
                            name="ownerType"
                            value={data.ownerType}
                            label={t('PersonalInfo.labels.ownerType')}
                            onChange={handleChange}
                        >
                            <MenuItem value="private">{t('PersonalInfo.ownerTypes.private')}</MenuItem>
                            <MenuItem value="company">{t('PersonalInfo.ownerTypes.company')}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {data.ownerType === 'company' && (
                    <>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                margin="normal"
                                name="company"
                                label={t('PersonalInfo.labels.companyName')}
                                value={data.company}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                margin="normal"
                                name="companyBRN"
                                label={t('PersonalInfo.labels.companyBRN')}
                                value={data.companyBRN}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        margin="normal"
                        name="firstname"
                        label={t('PersonalInfo.labels.firstName')}
                        value={data.firstname}
                        onChange={handleChange}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        margin="normal"
                        name="surname"
                        label={t('PersonalInfo.labels.surname')}
                        value={data.surname}
                        onChange={handleChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        margin="normal"
                        name="number"
                        label={t('PersonalInfo.labels.number')}
                        value={data.number}
                        onChange={handleChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        label={t('PersonalInfo.labels.birthday')}
                        value={data.birthday ? parseISO(data.birthday) : null}
                        onChange={(newValue) => setData(prev => ({ ...prev, birthday: newValue.toISOString() }))}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={handleNext} disabled={!isFormValid()}>
                        {t('PersonalInfo.buttons.next')}
                    </Button>
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

export default PersonalInfo;
