// src/components/CategoryModal.js

import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, IconButton, List, ListItem, ListItemText, Chip, Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { saveCategory, updateCategory } from '../services/categoryService';
import { useAuth } from '../context/AuthContext';
import { v4 as uuidv4 } from 'uuid';


function CategoryModal({ open, onClose, onSubmit, editCategory, measureUnits }) {
    const [categoryName, setCategoryName] = useState('');
    const [subcategories, setSubcategories] = useState([]);
    const { apiKey, organizationId, token: jwtToken } = useAuth();
    const [openSelects, setOpenSelects] = useState({}); // Tracks open states of all Selects

    useEffect(() => {
        if (editCategory) {
            setCategoryName(editCategory.categoryName);
            // Ensure subcategories' measureUnits are correctly initialized
            const initSubcategories = editCategory.subcategories.map(sub => {
                const measureUnitsForSubcategory = sub.measureUnits.map(mu => ({ id: mu.id }));
                return { ...sub, measureUnits: measureUnitsForSubcategory };
            });
            setSubcategories(initSubcategories);
        } else {
            resetForm();
        }
    }, [editCategory]);


    const resetForm = () => {
        setCategoryName('');
        setSubcategories([]);
    };

    const handleClose = () => {
        onClose();
        resetForm(); // Reset form
    };

    const handleAddSubcategory = () => {
        setSubcategories([...subcategories, { subcategoryId: uuidv4(), subcategoryName: '', measureUnits: [] }]);
    };
    const handleSelectOpen = subcategoryId => {
        setOpenSelects(prevOpenSelects => ({ ...prevOpenSelects, [subcategoryId]: true }));
    };

    const handleSelectClose = subcategoryId => {
        setOpenSelects(prevOpenSelects => ({ ...prevOpenSelects, [subcategoryId]: false }));
    };

    const handleRemoveSubcategory = (id) => {
        setSubcategories(subcategories.filter(sub => sub.subcategoryId !== id));
    };

    const handleSubcategoryNameChange = (id, value) => {
        const updatedSubcategories = subcategories.map(sub => {
            if (sub.subcategoryId === id) {
                return { ...sub, subcategoryName: value };
            }
            return sub;
        });
        setSubcategories(updatedSubcategories);
    };
    
    const handleSubcategoryMeasureUnitChange = (subcategoryId, clickedUnitId) => {
        const updatedSubcategories = subcategories.map(sub => {
            if (sub.subcategoryId === subcategoryId) {
                let newMeasureUnits;
                if (sub.measureUnits.find(mu => mu.id === clickedUnitId)) {
                    // Deselect: Remove the clicked measure unit from the array
                    newMeasureUnits = sub.measureUnits.filter(mu => mu.id !== clickedUnitId);
                } else {
                    // Select: Add the clicked measure unit to the array
                    newMeasureUnits = [...sub.measureUnits, { id: clickedUnitId }];
                }
                return { ...sub, measureUnits: newMeasureUnits };
            }
            return sub;
        });
        setSubcategories(updatedSubcategories);
    };



    const handleSubmit = () => {
        if (editCategory) {
            const updatedCategory = {
                id: editCategory.categoryId,
                categoryName,
                subcategories: subcategories.map(sub => ({
                    subcategoryId: sub.subcategoryId,
                    subcategoryName: sub.subcategoryName,
                    measureUnits: sub.measureUnits
                }))
            };
            updateCategory(updatedCategory, apiKey, organizationId, jwtToken)
                .then(onSubmit)
                .catch(console.error);
        }
        else {
            const newCategory = {
                categoryName,
                subcategories: subcategories.map(sub => ({
                    subcategoryId: sub.subcategoryId,
                    subcategoryName: sub.subcategoryName,
                    measureUnits: sub.measureUnits
                }))
            };
            saveCategory(newCategory, apiKey, organizationId, jwtToken)
                .then(onSubmit)
                .catch(console.error);
        }
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>{editCategory ? 'Edit Category' : 'Add New Category'}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Category Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                />
                <Button startIcon={<Add />} onClick={handleAddSubcategory}>
                    Add Subcategory
                </Button>
                <List>
                    {subcategories.map((sub, index) => (
                        <ListItem key={sub.subcategoryId} secondaryAction={
                            <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveSubcategory(sub.subcategoryId)}>
                                <Delete />
                            </IconButton>
                        }>
                            <ListItemText>
                                <TextField
                                    margin="dense"
                                    label={`Subcategory Name ${index + 1}`}
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={sub.subcategoryName}
                                    onChange={(e) => handleSubcategoryNameChange(sub.subcategoryId, e.target.value)}
                                />
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Measure Units</InputLabel>
                                    <Select
                                        multiple
                                        open={openSelects[sub.subcategoryId] || false}
                                        onOpen={() => handleSelectOpen(sub.subcategoryId)}
                                        onClose={() => handleSelectClose(sub.subcategoryId)}
                                        value={sub.measureUnits.map(mu => mu.id)} // Still visually represent selected items
                                        onChange={() => { }} // Now a no-op; selections are managed via MenuItem clicks
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map(value => {
                                                    const measureUnit = measureUnits.find(unit => unit.id === value);
                                                    return (
                                                        <Chip
                                                            key={value}
                                                            label={`${measureUnit?.nameLong} (${measureUnit?.nameShort})`}
                                                        />
                                                    );
                                                })}
                                            </Box>
                                        )}

                                    >
                                        {measureUnits.map((unit) => (
                                            <MenuItem
                                                key={unit.id}
                                                value={unit.id}
                                                onClick={() => handleSubcategoryMeasureUnitChange(sub.subcategoryId, unit.id)}
                                            >
                                                {unit.nameLong}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </FormControl>
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CategoryModal;
