import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const FinancialCard = ({ titleKey, amount }) => {
    const { t } = useTranslation();

    // Optional: Format the amount as currency. Adjust the locale and currency as needed.
    //const formattedAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
    const formattedAmount = amount;

    return (
        <Grid item xs={12} sm={6}>
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        {t(titleKey)}
                    </Typography>
                    <Typography variant="h5">
                        {formattedAmount}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );
};

FinancialCard.propTypes = {
    titleKey: PropTypes.string.isRequired,
    amount: PropTypes.number
};

FinancialCard.defaultProps = {
    amount: 0
};

export default FinancialCard;
