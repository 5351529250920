import React, { useState, useEffect } from 'react';
import { Button, Typography, LinearProgress, Box } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { deleteBooking, fetchUserBookingsbyUserId } from '../../services/guidingMachineService';
import { getAllOwners, getOwnerByEmail } from '../../services/ownerService';
import { getAllHorses, getHorsesByUserId } from '../../services/horseService';
import { fetchMachineSettings } from '../../services/MachineSettingsService';
import AddIcon from '@mui/icons-material/Add';
import MachineBookingModal from '../../components/MachineBookingModal';
import GenericTableView from '../../components/GenericTableView'; // Assuming this exists
import GenericCardView from '../../components/GenericCardView'; // Assuming this exists
import ConfirmationDialog from '../../components/ConfirmationDialog';
import SnackbarAlert from '../../components/SnackbarAlert';
import { useTheme, useMediaQuery } from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Chip from '@mui/material/Chip';
import { usePreferences } from '../../context/UserPreferencesContext';

const MachineBookings = () => {
    const { t } = useTranslation();
    const { apiKey, userId, userEmail, userType, ownerId, organizationId, token: jwtToken } = useAuth();
    const [bookings, setBookings] = useState([]);
    const [horseData, setHorseData] = useState([]);
    const [ownerData, setOwnerData] = useState([]);
    const [deviceData, setDeviceData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [bookingIdToDelete, setBookingIdToDelete] = useState(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [loading, setLoading] = useState(true);
    const dateLocale = useAuth().locale;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [viewMode, setViewMode] = useState(isMobile ? 'card' : 'table');
    const { devicePreferences, updateDevicePreferences } = usePreferences();

    const handleDelete = (bookingId) => openConfirmDialog(bookingId);

    const actions = [
        { 
            icon: <DeleteIcon />, 
            handler: handleDelete, 
        } 
    ];

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const ownersPromise = userType === 'admin' ? getAllOwners(apiKey, organizationId, jwtToken) : getOwnerByEmail(userEmail, apiKey, organizationId, jwtToken).then(owner => Array.isArray(owner) ? owner : [owner]);
                const horsesPromise = userType === 'admin' ? getAllHorses(apiKey, organizationId, jwtToken) : getHorsesByUserId(userId, apiKey, organizationId, jwtToken);
                const devicesPromise = fetchMachineSettings(apiKey, organizationId, jwtToken);
                const bookingsPromise = fetchUserBookingsbyUserId(userId, apiKey, organizationId, jwtToken);

                const [owners, horses, devices, bookingsData] = await Promise.all([ownersPromise, horsesPromise, devicesPromise, bookingsPromise]);

                setOwnerData(owners);
                setHorseData(horses.filter(horse => horse.permissions.includes('handleBookings')));
                setDeviceData(devices);
                // sort new bookings by start time
                setBookings(bookingsData.sort((b, a) => new Date(a.start_time) - new Date(b.start_time)));
            } catch (error) {
                setSnackbar({ open: true, message: t('Failed to fetch data'), severity: 'error' });
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [apiKey, ownerId, organizationId, jwtToken, userType, userEmail]);

    // Update local state when context updates
    useEffect(() => {
        setViewMode(devicePreferences.viewMode);
    }, [devicePreferences.viewMode]);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false);
        fetchUserBookingsbyUserId(userId, apiKey, organizationId, jwtToken).then(setBookings);
    };

    const handleDeleteConfirm = async () => {
        setLoading(true);
        try {
            await deleteBooking(bookingIdToDelete, ownerId, apiKey, organizationId, jwtToken);
            setBookings(prev => prev.filter(booking => booking.id !== bookingIdToDelete));
            setSnackbar({ open: true, message: t('Booking deleted successfully'), severity: 'success' });
        } catch (error) {
            setSnackbar({ open: true, message: t('Error deleting booking'), severity: 'error' });
        } finally {
            setLoading(false);
            setConfirmDialogOpen(false);
        }
    };

    const openConfirmDialog = (bookingId) => {
        setBookingIdToDelete(bookingId);
        setConfirmDialogOpen(true);
    };
    const toggleViewMode = () => {
        updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
    };

    const renderPaymentStatus = (booking) => {

        if (booking.payed === "True") {
            return <Chip icon={<CheckCircleIcon />} color="success" />;
        } else {
            return <Chip icon={<CancelIcon />} color="error" />;
        }
    };

    if (loading) return <LinearProgress />;

    const structure = {
        headers: [
            { key: 'date', label: t('GuidingMachinePage.startTime'), display: { value: true, chipColor: false } },
            { key: 'horse', label: t('GuidingMachinePage.horseText'), display: { value: true, chipColor: false } },
            { key: 'owner', label: t('GuidingMachinePage.owner'), display: { value: true, chipColor: false } },
            { key: 'comment', label: t('GuidingMachinePage.commentCard'), display: { value: true, chipColor: false } },
            { key: 'device', label: t('GuidingMachinePage.deviceName'), display: { value: true, chipColor: false } },
            { key: 'payed', label: t('GuidingMachinePage.payed'), display: { value: true, chipColor: false } },
        ],
        rows: bookings.map(booking => ({
            id: booking.id,
            date: {
                value: new Date(booking.start_time).toLocaleString(dateLocale),
                icon: null
            },
            time: {
                value: new Date(booking.start_time).toLocaleTimeString(dateLocale),
                icon: null
            },
            horse: {
                value: horseData.find(horse => horse.id === booking.horse_id)?.name || t('Unknown Horse'),
                icon: null
            },
            owner: {
                value: ownerData.find(owner => owner.id === booking.owner_id)?.firstname + ' ' + ownerData.find(owner => owner.id === booking.owner_id)?.surname || t('Unknown Owner'),
                icon: null
            },
            device: {
                value: deviceData.find(device => device.id === booking.device_id)?.machineType || t('Unknown Device'),
                icon: null
            },
            comment: {
                value: booking.description,
                icon: null
            },
            payed: {
                value: renderPaymentStatus(booking),
                icon: null
            }
        }))
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
            <Typography variant="h4" gutterBottom>{t('Bookings')}</Typography>

            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenModal}
                    startIcon={<AddIcon />}
                >
                    {t('GuidingMachinePage.bookNewTimeButton')}
                </Button>

                <IconButton onClick={toggleViewMode} color="primary">
                    {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
                </IconButton>
            </Box>

            {loading ? (
                <>
                    <Typography variant="h6" gutterBottom>{t('HorsePage.waitingText')}</Typography>
                    <LinearProgress />
                </>
            ) : viewMode === 'table' ? (
                <GenericTableView structure={structure} onClick={() => { }} actions={userType === 'admin' ? actions : []} />
            ) : (
                <GenericCardView structure={structure} onClick={() => { }} actions={userType === 'admin' ? actions : []} />
            )}
            <MachineBookingModal isOpen={showModal} onClose={handleCloseModal} devicesProps={deviceData} horsesProp={horseData} />
            <ConfirmationDialog isOpen={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)} onConfirm={handleDeleteConfirm} text={t('Are you sure you want to delete this booking?')} />
            <SnackbarAlert open={snackbar.open} onClose={() => setSnackbar({ ...snackbar, open: false })} message={snackbar.message} severity={snackbar.severity} />
        </LocalizationProvider>
    );
};

export default MachineBookings;
