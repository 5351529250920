// src/services/VeterinarianService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/veterinarians';

export async function fetchAllVeterinarians(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(API_BASE_URL);
  return response.data;
}

export async function fetchVeterinarianById(apiKey, organizationId, jwtToken, id) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${id}`;
  const response = await axiosInstance.get(url);
  return response.data[0];
}

export async function saveVeterinarian(veterinarian, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(API_BASE_URL, veterinarian);
  return response.data;
}

export async function updateVeterinarian(veterinarian, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${veterinarian.id}`;
  const response = await axiosInstance.put(url, veterinarian);
  return response.data;
}

export async function deleteVeterinarian(veterinarianId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${veterinarianId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

export default {
  fetchAllVeterinarians,
  fetchVeterinarianById,
  saveVeterinarian,
  updateVeterinarian,
  deleteVeterinarian
};
