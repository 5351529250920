// src/components/OrgTabs/ServiceOfferings/ServiceTypes.js

import React from 'react';
import {
    Button, TextField, Box, Grid, Typography, LinearProgress, IconButton,
    Accordion, AccordionSummary, AccordionDetails, Paper, Tooltip, FormControlLabel, Checkbox
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the Delete icon
import { useServiceTypes } from './useServiceTypes'; // Adjust the import path as needed


const ServiceTypes = () => {
    const {
        serviceTypes,
        loading,
        handleAddServiceType,
        handleFieldChange,
        handleSave,
        handleRemoveServiceType,
    } = useServiceTypes();

    return (
        <Paper>
            <Box sx={{ p: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Manage Service Types
                </Typography>
                {loading && <LinearProgress />}
                {serviceTypes.map((serviceType, index) => (
                    <Accordion key={serviceType.id}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{serviceType.name || `Service Type ${index + 1}`}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        variant="outlined"
                                        value={serviceType.name}
                                        onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Description"
                                        variant="outlined"
                                        value={serviceType.description}
                                        onChange={(e) => handleFieldChange(index, 'description', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={serviceType.isBookable}
                                                onChange={(e) => handleFieldChange(index, 'isBookable', e.target.checked)}
                                            />
                                        }
                                        label="Can be booked"
                                    />
                                </Grid>
                                {/* Conditionally render Capacity and Duration if isBookable is true */}
                                {serviceType.isBookable && (
                                    <>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Capacity"
                                                type="number"
                                                variant="outlined"
                                                value={serviceType.capacity}
                                                onChange={(e) => handleFieldChange(index, 'capacity', Number(e.target.value))}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Duration (minutes)"
                                                type="number"
                                                variant="outlined"
                                                value={serviceType.duration}
                                                onChange={(e) => handleFieldChange(index, 'duration', Number(e.target.value))}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={serviceType.isConnectedToHorse}
                                                onChange={(e) => handleFieldChange(index, 'isConnectedToHorse', e.target.checked)}
                                            />
                                        }
                                        label="Connected to a horse"
                                    />
                                </Grid>
                                {/* Remove Button */}
                                <Grid item>
                                    <Tooltip title="Remove Service Type">
                                        <IconButton onClick={() => handleRemoveServiceType(index)} color="error">
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                ))}
                <Box mt={2} display="flex" justifyContent="space-between">
                    <IconButton onClick={handleAddServiceType} disabled={loading}>
                        <AddCircleOutlineIcon />
                    </IconButton>
                    <Button variant="contained" onClick={handleSave} disabled={loading}>
                        Save Changes
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default ServiceTypes;
