import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Typography, Paper } from '@mui/material';
import InvoicesListPage from './InvoicesListPage';
import PaymentsListPage from './incomingPaymentPage/PaymentsListPage';
import PaymentRemindersListPage from './PaymentReminders/PaymentRemindersListPage';
import CostEstimationsListPage from './CostEstimationsListPage'; // Import the Cost Estimations List Page
import SalesOrdersListPage from './SalesOrdersListPage';
import DeliveryNotesListPage from './DeliveryNotesListPage';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SalesProcessPage = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const { t } = useTranslation();

    useEffect(() => {
        const tabQuery = query.get('tab');
        const tabValue = tabQuery !== null ? parseInt(tabQuery) : 0;
        setTabIndex(tabValue);
    }, [location]);

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
        const newQuery = new URLSearchParams(location.search);
        newQuery.set('tab', newValue);
        navigate(`${location.pathname}?${newQuery.toString()}`, { replace: true });
    };

    return (
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
            <Box>
                <Typography variant="h4" gutterBottom>
                    {t('SalesProcessPage.title')}
                </Typography>
                <Tabs value={tabIndex} onChange={handleChange} aria-label="Sales Process Tabs">
                    <Tab label={t('SalesProcessPage.costEstimations')} /> {/* New Tab for Cost Estimations */}
                    <Tab label={t('SalesProcessPage.salesOrders')} />
                    <Tab label={t('SalesProcessPage.deliveryNotes')} />
                    <Tab label={t('SalesProcessPage.invoices')} />
                    <Tab label={t('SalesProcessPage.payments')} />
                    <Tab label={t('SalesProcessPage.paymentReminders')} />
                </Tabs>
                {tabIndex === 0 && <CostEstimationsListPage />} {/* Render Cost Estimations List Page */}
                {tabIndex === 1 && <SalesOrdersListPage />}
                {tabIndex === 2 && <DeliveryNotesListPage />}
                {tabIndex === 3 && <InvoicesListPage />}
                {tabIndex === 4 && <PaymentsListPage />}
                {tabIndex === 5 && <PaymentRemindersListPage />}
            </Box>
        </Paper>
    );
};

export default SalesProcessPage;
