// src/pages/InvoiceDetailPage/invoiceUtils.js

// Utility function to check if a value is empty
const isEmpty = (value) => value === null || value === undefined || value.trim() === '';

export const validateInvoice = (invoiceData) => {
  const errors = {};

  const fields = ['customerName', 'issueDate', 'dueDate'];
  fields.forEach((field) => {
    if (isEmpty(invoiceData[field])) {
      errors[field] = `${field.charAt(0).toUpperCase() + field.slice(1).toLowerCase()} is required.`;
    }
  });

  return errors;
};

// Calculate total considering discount
export const calculateTotal = (rows) => {
  return rows.reduce((total, row) => {
    const bruttoPrice = parseFloat(row.bruttoPrice) || 0;
    const quantity = parseFloat(row.quantity) || 0;
    const discountPercentage = parseFloat(row.discountPercentage) / 100 || 0;
    const discountAmount = parseFloat(row.discountAmount) || 0;


    // Apply amount discount
    const finalPrice = bruttoPrice - discountAmount;

    return total + (finalPrice * quantity);
  }, 0);
};

export const calculateTax = (rows) => {
  const totalTax = rows.reduce((totalTax, row) => {
    const bruttoPrice = parseFloat(row.bruttoPrice) || 0;
    const quantity = parseFloat(row.quantity) || 0;
    const taxRate = parseFloat(row.taxRate) / 100 || 0;
    const discountPercentage = parseFloat(row.discountPercentage) / 100 || 0;
    const discountAmount = parseFloat(row.discountAmount) || 0;


    // Apply amount discount
    const finalPrice = bruttoPrice - discountAmount;

    // Calculate the tax amount for the row
    const taxAmountForRow = (finalPrice - (finalPrice / (1 + taxRate))) * quantity;

    return totalTax + taxAmountForRow;
  }, 0);

  return parseFloat(totalTax.toFixed(2)); // Use toFixed(2) here and convert back to number
};

// Calculate grand total including tax
export const calculateGrandTotal = (items, taxRate) => {
  const subtotal = calculateTotal(items);
  const taxAmount = calculateTax(items, taxRate);
  return subtotal + taxAmount;
};
