import React, { createContext, useContext, useState } from 'react';

const PermissionsContext = createContext();

export const usePermissions = () => {
    return useContext(PermissionsContext);
}

export const PermissionsProvider = ({ children }) => {
    const [features, setFeatures] = useState([]);
    const [entitlements, setEntitlements] = useState([]);
    const [permissions, setPermissions] = useState([]); // Remove duplicate declaration

    const updateFeatures = (newFeatures) => {
        setFeatures(newFeatures);
    };

    const updateEntitlements = (newEntitlements) => {
        setEntitlements(newEntitlements);
    };

    const updatePermissions = (newPermissions) => {
        setPermissions(newPermissions);
    };

    const value = {
        features,
        permissions,
        updateFeatures,
        updatePermissions,
        updateEntitlements, // Add updateEntitlements to the value object
        entitlements // Add entitlements to the value object
    };

    return (
        <PermissionsContext.Provider value={value}>
            {children}
        </PermissionsContext.Provider>
    );
};
