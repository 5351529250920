// src/dataCollection/countryTaxConfig.js


export const countryCodeNameMap = {
    DE: 'Germany',
    AT: 'Austria',
    FR: 'France',
    IT: 'Italy',
    ES: 'Spain',
    CH: 'Switzerland',
    USA: 'United States',
    UAE: 'United Arab Emirates',
};