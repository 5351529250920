import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useCostEstimation } from './useCostEstimation';
import CostEstimationTable from './CostEstimationTable';
import CostEstimationAddress from './CostEstimationAddress';
import SnackbarAlert from '../../components/SnackbarAlert';
import { useAuth } from '../../context/AuthContext';
import SplitTransferButton from '../../components/SplitTransferButton';
import {
  TextField,
  MenuItem,
  Paper,
  Button,
  Grid,
  Typography,
  Tab,
  Tabs,
  Box,
  Chip,
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { useDateLocale } from '../../hooks/useDateLocale';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useTranslation } from "react-i18next";
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const CostEstimationDetailPage = () => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });
  const { apiKey, organizationId, token, userId } = useAuth();
  const navigate = useNavigate();
  const { estimationId } = useParams();
  const dateLocale = useDateLocale();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const {
    estimationData,
    ownersWithHorses,
    isCancelled,
    setEstimationData,
    loading,
    saveOrUpdateEstimation,
    removeRow,
    showAddRows,
    addBoxRow,
    addMachineRow,
    addRow,
    addServiceRow,
    handleInputChange,
    handleOwnerChange,
    handleAddressChange,
    handleDownloadPdf,
    handleSendPdf,
    handleCancel,
    onTransfer,
  } = useCostEstimation(estimationId, apiKey, organizationId, token, userId);
  const { t } = useTranslation();

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const onSave = async () => {
    const isSuccess = await saveOrUpdateEstimation(setSnackbar);
    if (!isSuccess) {
      setSnackbar({ open: true, message: t("costEstimationDetailPage.saveFailed"), severity: 'error' });
    }
  };

  const downloadPdf = async () => {
    if (estimationId !== 'new') {
      await handleDownloadPdf(estimationId);
    } else {
      setSnackbar({ open: true, message: t("costEstimationDetailPage.saveBeforeDownload"), severity: 'warning' });
    }
  };

  const sendPDF = async () => {
    if (estimationId !== 'new') {
      await handleSendPdf(estimationId);
    } else {
      setSnackbar({ open: true, message: t("costEstimationDetailPage.saveBeforeSend"), severity: 'warning' });
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderCancellationStatus = () => {
    return isCancelled ? (
      <Chip
        icon={<WarningAmberIcon />}
        label={t("costEstimationDetailPage.cancelled")}
        color="warning"
        variant="outlined"
      />
    ) : null;
  };

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
        {loading && <LinearProgress />}
        <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
          <Grid item>
            <Typography variant="h4">
              <IconButton onClick={() => navigate(-1)} aria-label="back"><ArrowBackIcon /></IconButton>
              {estimationId === 'new' ? t('costEstimationDetailPage.createCostEstimation') : t('costEstimationDetailPage.editCostEstimation', { estimationNumber: estimationData.estimationNumber })}
            </Typography>
          </Grid>
          <Grid item>
            {renderCancellationStatus()}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Autocomplete
              freeSolo
              options={ownersWithHorses}
              getOptionLabel={(option) => option.firstname && option.surname ? `${option.firstname} ${option.surname}` : ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={ownersWithHorses.find(owner => owner.id === estimationData.customerId) || null}
              onChange={handleOwnerChange}
              renderInput={(params) => (
                <TextField {...params} label={t("costEstimationDetailPage.customerName")} fullWidth />
              )}
            />
          </Grid>
          {/* Recipient Type Dropdown */}
          <Grid item xs={6}>
            <TextField
              select
              fullWidth
              name="recipientType"
              label={t("costEstimationDetailPage.recipientType")}
              variant="outlined"
              value={estimationData.recipientType}
              onChange={(e) => setEstimationData({ ...estimationData, recipientType: e.target.value })}
            >
              <MenuItem value="private">{t("costEstimationDetailPage.private")}</MenuItem>
              <MenuItem value="company">{t("costEstimationDetailPage.company")}</MenuItem>
            </TextField>
          </Grid>
          {/* Company Name Field - Conditionally Rendered */}
          {estimationData.recipientType === 'company' && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t("costEstimationDetailPage.companyName")}
                value={estimationData.companyName || ''}
                onChange={(e) => setEstimationData({ ...estimationData, companyName: e.target.value })}
              />
            </Grid>
          )}
          {estimationData.recipientType === 'company' && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t("costEstimationDetailPage.companyBRN")}
                value={estimationData.companyBRN || ''}
                onChange={(e) => setEstimationData({ ...estimationData, companyBRN: e.target.value })}
              />
            </Grid>
          )}
          {estimationId !== 'new' && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                name="estimationNumber"
                label={t("costEstimationDetailPage.estimationNumber")}
                variant="outlined"
                value={estimationData.estimationNumber}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <DatePicker
              label={t("costEstimationDetailPage.issueDate")}
              value={estimationData.issueDate ? new Date(estimationData.issueDate) : null}
              onChange={(date) => setEstimationData({ ...estimationData, issueDate: date })}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label={t("costEstimationDetailPage.dueDate")}
              value={estimationData.dueDate ? new Date(estimationData.dueDate) : null}
              onChange={(date) => setEstimationData({ ...estimationData, dueDate: date })}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
        </Grid>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="cost estimation tabs">
          <Tab label={t("costEstimationDetailPage.costEstimationItems")} />
          <Tab label={t("costEstimationDetailPage.contactInformation")} />
        </Tabs>
        {selectedTab === 0 && (
          <Box sx={{ p: 3 }}>
            <Typography variant="h6">{t("costEstimationDetailPage.costEstimationItems")}</Typography>
            <CostEstimationTable
              estimationData={estimationData}
              handleInputChange={handleInputChange}
              removeRow={removeRow}
              addRow={addRow}
              showAddRows={showAddRows}
              addBoxRow={addBoxRow}
              addMachineRow={addMachineRow}
              addServiceRow={addServiceRow}
            />
            {/* ... */}
          </Box>
        )}
        {selectedTab === 1 && (
          <Box sx={{ p: 3 }}>
            <CostEstimationAddress
              estimationData={estimationData}
              handleInputChange={handleAddressChange} />
          </Box>
        )}
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Typography variant="h6">{t("costEstimationDetailPage.totalNetAmount")}</Typography>
            <Typography variant="subtitle1">{`€${estimationData.totalNetAmount}`}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h6">{t("costEstimationDetailPage.taxAmount")}</Typography>
            <Typography variant="subtitle1">{`€${estimationData.taxAmount}`}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h6">{t("costEstimationDetailPage.totalAmount")}</Typography>
            <Typography variant="subtitle1">{`€${estimationData.totalAmount}`}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={onSave} disabled={isCancelled}>
              {t("costEstimationDetailPage.save")}
            </Button>
          </Grid>
          <Grid item>
            <SplitTransferButton
              documentId={estimationId}
              documentType="costEstimation"
              visibleOptions={['salesOrder', 'deliveryNote', 'invoice']}
              onTransfer={onTransfer}
              disabled={isCancelled || estimationId === 'new'}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={downloadPdf} disabled={isCancelled || estimationId === 'new'}>
              {t("costEstimationDetailPage.downloadPDF")}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={sendPDF} disabled={isCancelled || estimationId === 'new'}>
              {t("costEstimationDetailPage.sendPDF")}
            </Button>
          </Grid>
          {estimationId !== 'new' && (
            <Grid item>
              <Button variant="contained" color="error" onClick={handleCancel} disabled={isCancelled}>
                {t("costEstimationDetailPage.cancel")}
              </Button>
            </Grid>
          )}
        </Grid>

        <SnackbarAlert
          open={snackbar.open}
          onClose={handleSnackbarClose}
          message={snackbar.message}
          severity={snackbar.severity}
        />
      </LocalizationProvider>
    </Paper>
  );
};

export default CostEstimationDetailPage;
