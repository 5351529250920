// frontend/equistab/src/components/FarmDesigner/HorseList.js
import React from 'react';
import HorseItem from './HorseItem';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const HorseList = React.memo(({ horses }) => {
  const { t } = useTranslation(); // Initialize the translation function

  return (
    <div>
      <Typography variant="h6">{t('HorseList.AvailableHorses')}</Typography>
      {horses.map((horse) => (
        <HorseItem key={horse.id} horse={horse} />
      ))}
    </div>
  );
});

export default HorseList;
