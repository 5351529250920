// src/components/HomeDashboards/WorkerDashboard.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchUserBookingsbyUserId, fetchBookingsPerDate } from '../../services/guidingMachineService';
import { getHorsesByUserId } from '../../services/horseService';
import { Container, Typography, Card, CardContent, List, ListItem, ListItemText, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DashboardCard from './DashboardComponents/DashboardCard';

const WorkerDashboard = ({ userId, apiKey, organizationId, jwtToken }) => {
    const { t } = useTranslation();
    const [bookings, setBookings] = useState([]);
    const [todaysBookingsCount, setTodaysBookingsCount] = useState(0);
    const [horses, setHorses] = useState([]);
    const [filterDate, setFilterDate] = useState(new Date());
    const navigate = useNavigate();

    useEffect(() => {
        getHorsesByUserId(userId, apiKey, organizationId, jwtToken)
            .then(horsesData => setHorses(horsesData))
            .catch(error => console.error('Error fetching horses data:', error));

        fetchUserBookingsbyUserId(userId, apiKey, organizationId, jwtToken)
            .then(data => setBookings(data))
            .catch(error => console.error('Error fetching bookings:', error));

        fetchBookingsPerDate(userId, filterDate, apiKey, organizationId, jwtToken)
            .then(data => setTodaysBookingsCount(data.length))
            .catch(error => console.error('Error fetching today\'s bookings:', error));
    }, [userId, apiKey, organizationId, jwtToken]);

    const getHorseNameById = (id) => {
        const horse = horses.find(h => h.id === id);
        return horse ? horse.name : 'Unknown Horse';
    };

    const handleHorseClick = (horseId) => {
        navigate(`/horses/detail/${horseId}`);
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>{t('Home.WorkerDashboard.dashboard')}</Typography>
            <Grid container spacing={3}>
                <DashboardCard titleKey='Home.WorkerDashboard.todaysBookings' count={todaysBookingsCount} onClick="/guidingMachine" />
                <DashboardCard titleKey='Home.WorkerDashboard.totalBookings' count={bookings.length} />
            </Grid>

            <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }}>{t('Home.WorkerDashboard.bookings')}</Typography>
            <Card>
                <CardContent>
                    <List>
                        {bookings.map(booking => (
                            <ListItem key={booking.id}>
                                <ListItemText primary={`${getHorseNameById(booking.horse_id)} - ${new Date(booking.start_time).toLocaleDateString()} ${new Date(booking.start_time).toLocaleTimeString()}`} />
                            </ListItem>
                        ))}
                    </List>
                </CardContent>
            </Card>
        </Container>
    );
};

export default WorkerDashboard;
