// src/pages/DocumentsPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Paper, Button, } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { fetchDocuments } from '../services/documentService';
import DocumentUploadModal from '../components/DocumentUploadModal';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';
import { IconButton } from '@mui/material';  // <-- add this line
import ViewModuleIcon from '@mui/icons-material/ViewModule';  // <-- add this line
import ViewListIcon from '@mui/icons-material/ViewList';  // <-- add this line
import GenericCardView from '../components/GenericCardView';
import GenericTableView from '../components/GenericTableView';
import { LinearProgress } from '@mui/material';
import { usePreferences } from '../context/UserPreferencesContext';



const DocumentsPage = () => {
    const navigate = useNavigate();
    const [documents, setDocuments] = useState([]);
    const { apiKey, token, organizationId } = useAuth();
    const [isUploadModalOpen, setUploadModalOpen] = useState(false);
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [viewMode, setViewMode] = useState(isMobile ? 'card' : 'table');
    const [isLoading, setIsLoading] = useState(false);
    const { devicePreferences, updateDevicePreferences } = usePreferences();

    useEffect(() => {
        const fetchDocumentsList = async () => {
            setIsLoading(true);
            try {
                const documentsList = await fetchDocuments(apiKey, organizationId, token);
                setDocuments(documentsList); // Ensure this updates correctly based on your API response
            } catch (error) {
                console.error('Failed to fetch documents:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchDocumentsList();
    }, [apiKey, organizationId, token, isUploadModalOpen]);

    // Update local state when context updates
    useEffect(() => {
      setViewMode(devicePreferences.viewMode);
    }, [devicePreferences.viewMode]);
  


    const handleRowClick = (documentId) => {
        navigate(`/documents/details/${documentId}`);
    };

    const toggleViewMode = () => {
        updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
    };



    const transformData = () => {
        return {
            headers: [
                { key: 'orginalName', label: t('documentData.list.tableHeader.name'), display: { value: true, chipColor: false } },
                { key: 'uploadDate', label: t('documentData.list.tableHeader.uploadDate'), display: { value: true, chipColor: false } },
                { key: 'description', label: t('documentData.list.tableHeader.description'), display: { value: true, chipColor: false } }
            ],
            rows: documents.map(document => ({
                id: document.id,
                orginalName: { value: document.orginalName, icon: null },
                uploadDate: { value: new Date(document.uploadDate).toLocaleDateString(), icon: null },
                description: { value: document.description, icon: null }
            }))
        };
    }

    return (
        <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
            <Typography variant="h4" gutterBottom>
                {t('documentData.list.title')}
            </Typography>

            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
                <Button variant="contained" color="primary" onClick={() => setUploadModalOpen(true)}>
                    {t('documentData.list.uploadButton')}
                </Button>
                <IconButton onClick={toggleViewMode} color="primary">
                    {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
                </IconButton>
            </Box>
            {isLoading ? (
                <LinearProgress />
            ) : viewMode === 'table' ? (
                <GenericTableView structure={transformData()} onClick={handleRowClick} />
            ) : (
                <GenericCardView structure={transformData()} onClick={handleRowClick} />
            )}
            <DocumentUploadModal
                isOpen={isUploadModalOpen}
                onClose={() => setUploadModalOpen(false)}
            />
        </Paper>
    );
};

export default DocumentsPage;
