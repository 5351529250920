// src/pages/InvoiceDetailPage/InvoicedetailPage.jsx

import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useInvoice } from './useInvoice';
import InvoiceTable from './InvoiceTable';
import InvoiceAddress from './InvoiceAddress';
import { useSnackbar } from './useSnackbar';
import { useAuth } from '../../context/AuthContext';
import {
  TextField,
  MenuItem,
  Paper,
  Button,
  Container,
  Grid,
  Typography,
  Tab,
  Tabs,
  Box,
  Chip,
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { useDateLocale } from '../../hooks/useDateLocale';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useTranslation } from "react-i18next";
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DocumentReferencesList from '../../components/DocumentReferencesList';
import DeliveryNoteSelectionModal from '../../components/DeliveryNoteSelectionModal';

const InvoiceDetailPage = () => {
  const { openSnackbar, SnackbarComponent } = useSnackbar();
  const { apiKey, organizationId, token, userId } = useAuth();
  const navigate = useNavigate();
  const { invoiceId } = useParams();
  const dateLocale = useDateLocale();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const {
    invoiceData,
    ownersWithHorses,
    paymentExists,
    paymentId,
    isCancelled,
    setInvoiceData,
    loading,
    handleSubmit,
    saveOrUpdateInvoice,
    removeRow,
    showAddRows,
    addBoxRow,
    addMachineRow,
    addRow,
    addServiceRow,
    handleInputChange,
    handleOwnerChange,
    handleIncomingPayment,
    handleAddressChange,
    handleDownloadPdf,
    handleOpenPayment,
    handleCancel,
    handleSendPdf,
    availableDeliveryNotes,
    selectedDeliveryNotes,
    setSelectedDeliveryNotes,
    handleAddDeliveryNotes,
    statusFilter,
    setStatusFilter,
    deliveryNoteSelectionOpen,
    setDeliveryNoteSelectionOpen,
    handleIssueDateChange,
  } = useInvoice(invoiceId, apiKey, organizationId, token, userId, openSnackbar);
  const { t } = useTranslation();

  const onSave = async () => {
    const isSuccess = await saveOrUpdateInvoice(openSnackbar);
    if (!isSuccess) {
      openSnackbar({ text: t("invoiceDetailPage.saveError"), severity: 'error' });
    }
  };

  const downloadPdf = async () => {
    if (invoiceId !== 'new') {
      await handleDownloadPdf(invoiceId);
    } else {
      openSnackbar({ text: t("invoiceDetailPage.saveBeforeDownload"), severity: 'warning' });
    }
  };


  const sendPDF = async () => {
    if (invoiceId !== 'new') {
      await handleSendPdf(invoiceId);
    } else {
      openSnackbar({ text: t("invoiceDetailPage.saveBeforeDownload"), severity: 'warning' });
    }
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderPaymentStatus = () => {
    return paymentExists ? (
      <Chip
        icon={<CheckCircleOutlineIcon />}
        label={t("invoiceDetailPage.paid")}
        color="success"
        variant="outlined"
      />
    ) : (
      <Chip
        icon={<HighlightOffIcon />}
        label={t("invoiceDetailPage.unpaid")}
        color="error"
        variant="outlined"
      />
    );
  };
  const renderCancellationStatus = () => {
    return isCancelled ? (
      <Chip
        icon={<WarningAmberIcon />}
        label={t("invoiceDetailPage.cancelled")}
        color="warning"
        variant="outlined"
      />
    ) : null;
  };

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
        {loading && <LinearProgress />}
        <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
          <Grid item>
            <Typography variant="h4">
              <IconButton onClick={() => navigate(-1)} aria-label="back"><ArrowBackIcon /></IconButton>
              {invoiceId === 'new' ? t('invoiceDetailPage.createInvoice') : t('invoiceDetailPage.editInvoice', { invoiceNumber: invoiceData.invoiceNumber })}
            </Typography>
          </Grid>
          <Grid item>
            {invoiceId !== 'new' && (
              <>
                {renderPaymentStatus()}
                {renderCancellationStatus()}
              </>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Autocomplete
              freeSolo
              options={ownersWithHorses.map((option) => ({ label: `${option.firstname} ${option.surname}`, id: option.id }))}
              getOptionLabel={(option) => option.label || ''}
              value={invoiceData.customerName ? { label: invoiceData.customerName, id: invoiceData.ownerId || '' } : null}
              onChange={handleOwnerChange}
              onInputChange={(event, newValue, reason) => {
                // Use the onInputChange event to handle free solo entries
                handleOwnerChange(event, newValue, reason);
              }}
              renderInput={(params) => (
                <TextField {...params} label={t("invoiceDetailPage.customerName")} fullWidth />
              )}
            />
          </Grid>
          {/* Recipient Type Dropdown */}
          <Grid item xs={6}>
            <TextField
              select
              fullWidth
              name="recipientType"
              label={t("invoiceDetailPage.recipientType")}
              variant="outlined"
              value={invoiceData.recipientType}
              onChange={(e) => setInvoiceData({ ...invoiceData, recipientType: e.target.value })}
            >
              <MenuItem value="private">{t("invoiceDetailPage.private")}</MenuItem>
              <MenuItem value="company">{t("invoiceDetailPage.company")}</MenuItem>
            </TextField>
          </Grid>
          {/* Company Name Field - Conditionally Rendered */}
          {invoiceData.recipientType === 'company' && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t("invoiceDetailPage.companyName")}
                value={invoiceData.companyName || ''}
                onChange={(e) => setInvoiceData({ ...invoiceData, companyName: e.target.value })}
              />
            </Grid>
          )}
          {invoiceData.recipientType === 'company' && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t("invoiceDetailPage.companyBRN")}
                value={invoiceData.companyBRN || ''}
                onChange={(e) => setInvoiceData({ ...invoiceData, companyBRN: e.target.value })}
              />
            </Grid>
          )}
          {invoiceId !== 'new' && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                name="invoiceNumber"
                label={t("invoiceDetailPage.invoiceNumber")}
                variant="outlined"
                value={invoiceData.invoiceNumber}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <DatePicker
              label={t("invoiceDetailPage.issueDate")}
              value={invoiceData.issueDate ? new Date(invoiceData.issueDate) : null}
              onChange={handleIssueDateChange}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label={t("invoiceDetailPage.dueDate")}
              value={invoiceData.dueDate ? new Date(invoiceData.dueDate) : null}
              onChange={(date) => setInvoiceData({ ...invoiceData, dueDate: date })}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
        </Grid>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="invoice tabs">
          <Tab label={t("invoiceDetailPage.invoiceItems")} />
          <Tab label={t("invoiceDetailPage.contactInformation")} />
        </Tabs>
        {selectedTab === 0 && (
          <Box sx={{ p: 3 }}>
            <Typography variant="h6">{t("invoiceDetailPage.invoiceItems")}</Typography>
            <InvoiceTable
              invoiceData={invoiceData}
              handleInputChange={handleInputChange}
              removeRow={removeRow}
              addRow={addRow}
              showAddRows={showAddRows}
              addBoxRow={addBoxRow}
              addMachineRow={addMachineRow}
              addServiceRow={addServiceRow}
              openDeliveryNoteSelection={() => setDeliveryNoteSelectionOpen(true)}
            />
            <Typography variant="h6" sx={{ mt: 4 }}>{t('documentReferencesList.references')}</Typography>
            <DocumentReferencesList
              references={invoiceData.references}
              apiKey={apiKey}
              organizationId={organizationId}
              token={token}
            />
          </Box>
        )}
        {selectedTab === 1 && (
          <Box sx={{ p: 3 }}>
            <InvoiceAddress
              invoiceData={invoiceData}
              handleInputChange={handleAddressChange} />
          </Box>
        )}
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Typography variant="h6">{t("invoiceDetailPage.totalNetAmount")}</Typography>
            <Typography variant="subtitle1">{`€${invoiceData.totalNetAmount}`}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h6">{t("invoiceDetailPage.taxAmount")}</Typography>
            <Typography variant="subtitle1">{`€${invoiceData.taxAmount}`}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h6">{t("invoiceDetailPage.totalAmount")}</Typography>
            <Typography variant="subtitle1">{`€${invoiceData.totalAmount}`}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={onSave} disabled={isCancelled}>
              {t("invoiceDetailPage.save")}
            </Button>
          </Grid>
          <Grid item>
            {paymentExists ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenPayment}>
                {t("invoiceDetailPage.openPayment")}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleIncomingPayment}
                disabled={isCancelled || invoiceId === 'new'}
              >
                {t("invoiceDetailPage.createIncomingPayment")}
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={downloadPdf} disabled={isCancelled || invoiceId === 'new'}>
              {t("invoiceDetailPage.downloadPDF")}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={sendPDF} disabled={isCancelled || invoiceId === 'new'}>
              {t("invoiceDetailPage.sendPDF")}
            </Button>
          </Grid>
          {invoiceId !== 'new' && (
            <Grid item>
              <Button variant="contained" color="error" onClick={handleCancel} disabled={isCancelled}>
                {t("invoiceDetailPage.cancel")}
              </Button>
            </Grid>
          )}
        </Grid>
        <SnackbarComponent />
        <DeliveryNoteSelectionModal
          open={deliveryNoteSelectionOpen}
          onClose={() => setDeliveryNoteSelectionOpen(false)}
          availableDeliveryNotes={availableDeliveryNotes}
          selectedDeliveryNotes={selectedDeliveryNotes}
          setSelectedDeliveryNotes={setSelectedDeliveryNotes}
          onAddDeliveryNotes={handleAddDeliveryNotes}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
        />


      </LocalizationProvider>
    </Paper >
  );
};

export default InvoiceDetailPage;
