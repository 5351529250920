// src/context/authConfig.js
import { policies } from './authPolicies';
const tenantURL = process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME;
const tenantClientId = process.env.REACT_APP_AZURE_AD_B2C_CLIENT_ID;
const customScope = `https://${tenantURL}.onmicrosoft.com/API-Scopes/Permission.All`;

export const msalConfig = (policy) => ({
    auth: {
        clientId: process.env.REACT_APP_AZURE_AD_B2C_CLIENT_ID,
        authority: `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME}.onmicrosoft.com/${policy}`,
        knownAuthorities: [`${process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME}.b2clogin.com`],
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false, // Set to true if needed for IE/Edge support
    }
});

export const loginRequest = {
    scopes: ['openid', 'profile', 'email', 'offline_access', customScope]
};

export const passwordResetRequest = {
    scopes: ['openid'],
    authority: `https://${process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME}.onmicrosoft.com/${policies.passwordResetPolicy}`
};
