// src/components/FarrierVisitModal.js
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    FormControlLabel,
    Typography
} from '@mui/material';
import { saveFarrierVisit, updateFarrierVisit, deleteFarrierVisit } from '../services/FarrierVisitService'; // Import your service functions
import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { useDateLocale } from '../hooks/useDateLocale';
import { v4 as uuidv4 } from 'uuid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { getAllHorses, getHorsesByOwnerID, getHorsesByUserId } from '../services/horseService';
import { fetchAllFerries } from '../services/FerryService';

const FarrierVisitModal = ({ isOpen, onClose, record, horses = [], farriers = [] }) => {
    const { apiKey, organizationId, token, ownerId, userType, userId } = useAuth();
    const { t } = useTranslation();
    const dateLocale = useDateLocale();
    const [horseRecords, setHorseRecords] = useState([]);
    const [farrierRecords, setFarrierRecords] = useState([]);
    const [formData, setFormData] = useState({
        id: '',
        dateOfVisit: new Date(),
        nextFarrierVisit: new Date(),
        horseId: '',
        farrierId: '',
        service: '',
        cost: '',
        notes: '',
        shareWithOwner: true,
    });
    const farrierServiceCategories = [
        {
            category: t('farrierVisits.serviceCategories.basicServices'),
            services: [
                { key: 'barehoof', label: t('farrierVisits.services.barehoof') },
                { key: 'lostShoe', label: t('farrierVisits.services.lostShoe') },
            ],
        },
        {
            category: t('farrierVisits.serviceCategories.shoeingOptions'),
            services: [
                { key: '2horseshoes', label: t('farrierVisits.services.2horseshoes') },
                { key: '4horseshoes', label: t('farrierVisits.services.4horseshoes') },
                { key: 'specialShoeing', label: t('farrierVisits.services.specialShoeing') },
            ],
        },
    ];

    useEffect(() => {

        const fetchData = async () => {
            const fetchedHorses = horses.length > 0 ? horses : userType === 'admin' ? await getAllHorses(apiKey, organizationId, token) : await getHorsesByUserId(userId, apiKey, organizationId, token);
            setHorseRecords(fetchedHorses.filter(horse => horse.permissions.includes('handleFarrier')));
            const fetchedFarriers = farriers.length > 0 ? farriers : await fetchAllFerries(apiKey, organizationId, token);
            setFarrierRecords(fetchedFarriers);
        };
        if (record) {
            setFormData({
                ...record,
                shareWithOwner: record.shareWithOwner ?? true,
                dateOfVisit: record.dateOfVisit ? new Date(record.dateOfVisit) : new Date(),
                nextFarrierVisit: record.nextFarrierVisit ? new Date(record.nextFarrierVisit) : new Date(),
            });
        } else {
            setFormData({
                id: uuidv4(),
                dateOfVisit: new Date(),
                nextFarrierVisit: new Date(),
                horseId: '',
                farrierId: '',
                service: '',
                cost: '',
                notes: '',
                shareWithOwner: true,
            });


        }
        fetchData();

    }, [record]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        if (name === 'horseId' && horseRecords.find((horse) => horse.id === value).farrierId) {
            setFormData((prev) => ({ ...prev, farrierId: horseRecords.find((horse) => horse.id === value).farrierId }));
        }
    };

    const handleDateChange = (value) => {
        setFormData((prev) => ({ ...prev, dateOfVisit: value }));
    };

    const handleSwitchChange = (event) => {
        setFormData({ ...formData, shareWithOwner: event.target.checked });
    };

    const handleSubmit = async () => {
        try {
            if (record) {
                await updateFarrierVisit({ ...formData, id: record.id }, apiKey, organizationId, token, ownerId);
            } else {
                await saveFarrierVisit(formData, apiKey, organizationId, token, ownerId);
            }
            onClose(); // Close the dialog after saving data
        } catch (error) {
            console.error('Failed to save/update farrier visit:', error);
        }
    };

    const handleDelete = async () => {
        try {
            await deleteFarrierVisit(record.id, apiKey, organizationId, token, ownerId);
            onClose(); // Close the dialog after saving data
        } catch (error) {
            console.error('Failed to delete farrier visit:', error);
        }
    }

    // Function to render categories and services
    const renderServiceOptions = () => {
        return farrierServiceCategories.flatMap((category, index) => [
            <MenuItem key={`category-${index}`} disabled>
                <Typography color="textSecondary" style={{ fontWeight: 'bold' }}>
                    {category.category}
                </Typography>
            </MenuItem>,
            ...category.services.map(service => (
                <MenuItem key={service.key} value={service.key}>
                    {service.label}
                </MenuItem>
            ))
        ]);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>
                    {t('farrierVisits.Form.scheduleVisit')}
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="normal">
                        <DatePicker
                            label={t('farrierVisits.Form.dateOfVisit')}
                            inputFormat="MM/dd/yyyy"
                            value={formData.dateOfVisit}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <DatePicker
                            label={t('farrierVisits.Form.nextFarrierVisit')}
                            inputFormat="MM/dd/yyyy"
                            value={formData.nextFarrierVisit}
                            onChange={(value) => setFormData({ ...formData, nextFarrierVisit: value })}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>{t('farrierVisits.Form.horse')}</InputLabel>
                        <Select
                            name="horseId"
                            value={formData.horseId}
                            label={t('farrierVisits.Form.horse')}
                            onChange={handleChange}
                        >
                            {horseRecords.map((horse) => (
                                <MenuItem key={horse.id} value={horse.id}>{horse.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>{t('farrierVisits.Form.farrier')}</InputLabel>
                        <Select
                            name="farrierId"
                            value={formData.farrierId}
                            label={t('farrierVisits.Form.farrier')}
                            onChange={handleChange}
                        >
                            {farrierRecords.map((farrier) => (
                                <MenuItem key={farrier.id} value={farrier.id}>{farrier.firstname} {farrier.surname}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>{t('farrierVisits.Form.serviceProvided')}</InputLabel>
                        <Select
                            name="service"
                            value={formData.service}
                            onChange={handleChange}
                            label={t('farrierVisits.Form.serviceProvided')}
                        >
                            {renderServiceOptions()}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        label={t('farrierVisits.Form.cost')}
                        margin="normal"
                        type="number"
                        name="cost"
                        value={formData.cost}
                        onChange={handleChange}
                    />
                    <TextField
                        fullWidth
                        label={t('farrierVisits.Form.notes')}
                        margin="normal"
                        multiline
                        rows={4}
                        name='notes'
                        value={formData.notes}
                        onChange={handleChange}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.shareWithOwner}
                                onChange={handleSwitchChange}
                                name="shareWithOwner"
                            />
                        }
                        label={t('farrierVisits.Form.shareWithOwner')}
                    />
                </DialogContent>
                <DialogActions>
                    {record && (
                        <Button variant="contained" color="error" onClick={handleDelete}>
                            {t('farrierVisits.Form.deleteVisit')}
                        </Button>
                    )}
                    <Button variant="contained" color="secondary" onClick={onClose}>
                        {t('farrierVisits.Form.cancel')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        {t('farrierVisits.Form.saveVisit')}
                    </Button>

                </DialogActions>
            </Dialog>
        </LocalizationProvider>
    );
};

export default FarrierVisitModal;