import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, Collapse, Typography, Chip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ItemSettingsModal = ({ open, onClose, structuredItemSuggestion, onSave }) => {
    const [expandedId, setExpandedId] = useState(null);
    const [formData, setFormData] = useState(structuredItemSuggestion || { "items": [], "categories": [], "measureUnits": [] });

    const handleToggle = (id) => {
        setExpandedId(expandedId === id ? null : id);
    };

    const isNewEntry = (id, existingId) => !existingId && id;

    const renderDetails = (itemId, details) => (
        <List component="div" disablePadding>
            {details.map((detail, index) => (
                <ListItem key={index} sx={{ pl: 4 }}>
                    <ListItemText primary={detail.name} secondary={detail.notes} />
                    {isNewEntry(detail.id, detail.existingId) && <Chip label="New" color="secondary" size="small" />}
                </ListItem>
            ))}
        </List>
    );

    const renderItem = (item) => {
        const categoryDetails = formData.categories
            .filter(cat => cat.categoryId === item.newCategoryId)
            .map(cat => ({
                name: `Category: ${cat.categoryName}`,
                notes: cat.notes,
                id: cat.categoryId,
                existingId: item.existingCategoryId
            }));

        const subcategoryDetails = formData.categories
            .flatMap(cat => cat.subcategories)
            .filter(sub => sub.id === item.newSubcategoryId)
            .map(sub => ({
                name: `Subcategory: ${sub.name}`,
                notes: sub.notes,
                id: sub.id,
                existingId: item.existingSubcategoryId
            }));

        const measureUnitDetails = formData.measureUnits
            .filter(mu => mu.newId === item.newMeasureUnitId)
            .map(mu => ({
                name: `Measure Unit: ${mu.nameLong} (${mu.nameShort})`,
                notes: mu.notes,
                id: mu.newId,
                existingId: item.existingMeasureUnitId
            }));

        return (
            <React.Fragment key={item.name}>
                <ListItem button onClick={() => handleToggle(item.name)}>
                    <ListItemText primary={item.name} secondary={item.notes} />
                    {expandedId === item.name ? <ExpandMoreIcon /> : <ExpandMoreIcon />}
                </ListItem>
                <Collapse in={expandedId === item.name} timeout="auto" unmountOnExit>
                    {renderDetails(item.name, [...categoryDetails, ...subcategoryDetails, ...measureUnitDetails])}
                </Collapse>
            </React.Fragment>
        );
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>Inventory Settings</DialogTitle>
            <DialogContent>
                <List>
                    {formData.items.map(renderItem)}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={() => onSave()} color="primary">Save Changes</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ItemSettingsModal;
