import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig.js';

const ICON_API_BASE_URL = '/api/organizationIcons';

// Upload or update an icon
export async function uploadOrUpdateIcon(iconFile, apiKey, organizationId, jwtToken) {
  const formData = new FormData();
  formData.append('icon', iconFile);

  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(ICON_API_BASE_URL, formData, {
    headers: { 'Content-Type': 'application/json' }
  });
  return response.data;
}

// Fetch icon URL for viewing
export async function fetchIcon(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(ICON_API_BASE_URL);
  return response.data;
}

// Delete an icon
export async function deleteIcon(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.delete(ICON_API_BASE_URL);
  return response.data;
}

export default {
  uploadOrUpdateIcon,
  fetchIcon,
  deleteIcon
};
