// src/components/DocumentPreviewModal.js
import React from 'react';
import { Box, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const DocumentPreviewModal = ({ open, onClose, documentDetails }) => {
    const renderDocumentPreview = () => {
        if (!documentDetails) return null;

        const { documentType, base64 } = documentDetails;
        if (documentType.startsWith("image/")) {
            return <img src={`data:${documentType};base64,${base64}`} alt="Document Preview" style={{ maxWidth: '100%' }} />;
        } else if (documentType === "application/pdf") {
            return (
                <object data={`data:${documentType};base64,${base64}`} type={documentType} width="100%" height="500px">
                    <Typography variant="body1">No preview available</Typography>
                </object>
            );
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="document-preview-modal-title"
            aria-describedby="document-preview-modal-description"
        >
            <Box sx={style}>
                <Typography id="document-preview-modal-title" variant="h6" component="h2">
                    Document Preview
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Typography>
                {renderDocumentPreview()}
            </Box>
        </Modal>
    );
};

export default DocumentPreviewModal;
