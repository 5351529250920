// src/components/OrgTabs/MachineSettings.js
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, TextField, Button, Autocomplete, Card, CardContent, Grid, Typography, Switch, FormControlLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { fetchMachineSettings, saveMachineSettings, deleteMachineSettings } from '../../services/MachineSettingsService';
import { useAuth } from '../../context/AuthContext';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useDateLocale } from '../../hooks/useDateLocale';
import i18n from '../../i18n';  // Your custom hook
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete'; // Import DeleteIcon

const MachineSettings = () => {
  const [machines, setMachines] = useState([]);
  const [isModified, setIsModified] = useState(false);
  const { apiKey, organizationId, token } = useAuth();
  const { t } = useTranslation();  // Hook for translation
  const dateLocale = useDateLocale();
  const is24HourFormat = i18n.language === 'de';


  const machineSuggestions = [
    t('OrganizationSettings.Machine.MachineTypes.HorseExerciser'),
    t('OrganizationSettings.Machine.MachineTypes.HorseWalker'),
    t('OrganizationSettings.Machine.MachineTypes.HorseTreadmill'),
    t('OrganizationSettings.Machine.MachineTypes.Solarium'),
    t('OrganizationSettings.Machine.MachineTypes.Aquatrainer')
  ];


  const localizedDaysMapping = {
    "Monday": t("Days.Monday"),
    "Tuesday": t("Days.Tuesday"),
    "Wednesday": t("Days.Wednesday"),
    "Thursday": t("Days.Thursday"),
    "Friday": t("Days.Friday"),
    "Saturday": t("Days.Saturday"),
    "Sunday": t("Days.Sunday")
  };

  useEffect(() => {
    setIsModified(true);
  }, [machines]);
  useEffect(() => {
    fetchMachineSettings(apiKey, organizationId, token)
      .then(initialMachines => {
        setMachines(initialMachines);
      })
      .catch(error => {
        // Handle the error accordingly
        console.error("Could not fetch machines:", error);
      });
  }, []);

  const handleEdit = (id, field, value) => {
    // Handle nested properties like 'availability.Monday.from'
    const parts = field.split('.');
    let nestedField = parts.shift();

    const updatedMachines = machines.map((machine) => {
      if (machine.id !== id) {
        return machine;
      }

      let newMachine = { ...machine };
      let currentObject = newMachine;

      while (parts.length > 0) {
        currentObject[nestedField] = { ...currentObject[nestedField] };
        currentObject = currentObject[nestedField];
        nestedField = parts.shift();
      }

      currentObject[nestedField] = value;
      return newMachine;
    });

    setMachines(updatedMachines);
  };

  const removeMachine = (removeid) => {
    deleteMachineSettings(removeid, apiKey, organizationId, token).
      then(() => {
        const updatedMachines = machines.filter((machine) => machine.id !== removeid);
        setMachines(updatedMachines);
      })
      .catch(error => {
        // Handle the error accordingly
        console.error("Could not fetch machines:", error);
      });
  };

  const addNewMachine = () => {
    setMachines([...machines, {
      id: uuidv4(),
      machineType: '',
      machineDescription: '',
      capacity: '',
      time: '',
      availability: {
        Monday: { from: '', to: '' },
        Tuesday: { from: '', to: '' },
        Wednesday: { from: '', to: '' },
        Thursday: { from: '', to: '' },
        Friday: { from: '', to: '' },
        Saturday: { from: '', to: '' },
        Sunday: { from: '', to: '' },
      },
      notificationSettings: [
        { notificationType: 'SMS', notificationEnabled: false, notificationTarget: '' },
      ],
    }]);
  };

  const sampleTexts = {
    'SMS': t('OrganizationSettings.Machine.Notification.SMSPlaceholder'), // e.g., 'Enter phone number'
    'Whatsapp': t('OrganizationSettings.Machine.Notification.WhatsappPlaceholder'), // e.g., 'Enter WhatsApp number'
    'Email': t('OrganizationSettings.Machine.Notification.EmailPlaceholder') // e.g., 'Enter email address'
  };

  const handleNotificationEdit = (machineId, index, field, value) => {
    const updatedMachines = machines.map(machine => {
      if (machine.id === machineId) {
        const updatedSettings = [...machine.notificationSettings];
        updatedSettings[index] = { ...updatedSettings[index], [field]: value };
        return { ...machine, notificationSettings: updatedSettings };
      }
      return machine;
    });

    setMachines(updatedMachines);
  };

  const handleSaveAll = () => {
    saveMachineSettings(machines, apiKey, organizationId, token)
      .then(() => {
        console.log('All changes saved', machines);
        setIsModified(false);
      })
      .catch(error => {
        console.error("Could not save machines:", error);
      });
  };

  return (

    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h4">{t('OrganizationSettings.Machine.title')}</Typography>
        {machines.map((machine) => (
          <Card key={machine.id} sx={{ my: 2 }} style={{ margin: '10px', position: 'relative' }}>
            <div style={{
              position: 'absolute',
              top: '10px',
              right: '10px'
            }}>
              <IconButton style={{ color: 'red' }} onClick={() => removeMachine(machine.id)}>
                <DeleteIcon />
              </IconButton>
            </div>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    options={machineSuggestions}
                    freeSolo
                    value={machine.machineType}
                    onChange={(event, newValue) => handleEdit(machine.id, 'machineType', newValue)}
                    renderInput={(params) => <TextField {...params} label={t('OrganizationSettings.Machine.Type')} variant="outlined" />}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label={t('OrganizationSettings.Machine.Description')}
                    variant="outlined"
                    value={machine.machineDescription}
                    onChange={(e) => handleEdit(machine.id, 'machineDescription', e.target.value)}
                    placeholder="e.g. Main Barn, Training"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label={t('OrganizationSettings.Machine.Capacity')}
                    variant="outlined"
                    value={machine.capacity}
                    onChange={(e) => handleEdit(machine.id, 'capacity', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label={t('OrganizationSettings.Machine.Time')}
                    variant="outlined"
                    value={machine.time}
                    onChange={(e) => handleEdit(machine.id, 'time', e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="body1">{t('OrganizationSettings.Machine.Availability')}</Typography>
                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                  machine.availability && (
                    <div key={day}>
                      <Typography variant="body2">{localizedDaysMapping[day]}</Typography>
                      <TimePicker
                        label={t('From')}
                        value={machine.availability[day]?.from ? new Date(machine.availability[day]?.from) : null}
                        onChange={(newValue) => handleEdit(machine.id, `availability.${day}.from`, newValue)}
                        ampm={!is24HourFormat}
                      />
                      <TimePicker
                        label={t('To')}
                        value={machine.availability[day]?.to ? new Date(machine.availability[day]?.to) : null}
                        onChange={(newValue) => handleEdit(machine.id, `availability.${day}.to`, newValue)}
                        ampm={!is24HourFormat}
                      />
                    </div>
                  )
                ))}
              </Grid>
              <Typography variant="h6">{t('OrganizationSettings.Machine.Notification.title')}</Typography>
              {machine.notificationSettings?.map((setting, index) => (
                <Box key={index}>
                  <Typography variant="body1">{setting.notificationType}</Typography>
                  <FormControlLabel
                    control={<Switch checked={setting.notificationEnabled} onChange={(e) => handleNotificationEdit(machine.id, index, 'notificationEnabled', e.target.checked)} />}
                    label={t('Enable')}
                  />
                  <TextField
                    label={t('OrganizationSettings.Machine.Notification.target')}
                    variant="outlined"
                    value={setting.notificationTarget}
                    onChange={(e) => handleNotificationEdit(machine.id, index, 'notificationTarget', e.target.value)}
                    disabled={!setting.notificationEnabled}
                    placeholder={sampleTexts[setting.notificationType] || ''}
                  />
                </Box>
              ))}
            </CardContent>
          </Card>
        ))}
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={addNewMachine}
          sx={{ mb: 2 }}
        >
          {t('OrganizationSettings.Machine.addMachine')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveAll}
          disabled={!isModified}
        >
          {t('OrganizationSettings.Machine.Save')}
        </Button>
      </Box>
    </LocalizationProvider>
  );
};

export default MachineSettings;
