// src/services/organizationService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/orgabnizationSettings'; // Corrected the spelling of 'organization'

export async function fetchOrganizationInfo(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(API_BASE_URL);
  return response.data;
}

export async function saveOrganizationInfo(organizationInfo, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(API_BASE_URL, organizationInfo);
  return response.data;
}

export async function updateOrganizationInfo(organizationInfo, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${organizationInfo.id}`;
  const response = await axiosInstance.put(url, organizationInfo);
  return response.data;
}

export default {
  fetchOrganizationInfo,
  saveOrganizationInfo,
  updateOrganizationInfo
};
