// src/pages/RidingLessons/RidingLessonPayments.js

import React, { useState, useEffect } from 'react';
import { Box, Button, Paper, Typography, LinearProgress, Dialog } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PaymentsIcon from '@mui/icons-material/Payments';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import IconButton from '@mui/material/IconButton';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import GenericCardView from '../../components/GenericCardView';
import GenericTableView from '../../components/GenericTableView'; // Assuming this is similar to GenericCardView but for table layouts
import RidingLessonPaymentDialog from '../../components/RidingLessonPaymentDialog'; // Assuming you have this component
import { useTheme, useMediaQuery } from '@mui/material';
import { bookingStatistics } from '../../services/bookingService'; // Assuming you have this service

const RidingLessonPayments = () => {
    const { apiKey, organizationId, token, userId } = useAuth();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [riders, setRiders] = useState([]);
    const [selectedRider, setSelectedRider] = useState(null);
    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [viewMode, setViewMode] = useState(isMobile ? 'card' : 'table');
    const [statistics, setStatistics] = useState([]); // Assuming this is the response from bookingStatistics
    const [selectedMonth, setSelectedMonth] = useState(new Date());

    const structureHeaders = {
        headers: [{
            key: 'riderName', label: t('RidingLessonsOverview.table.name'), display: { value: true, chipColor: false }
        }, {
            key: 'totalBookings', label: t('RidingLessonsOverview.table.totalBookings'), display: { value: true, chipColor: false }
        }, {
            key: 'paidBookings', label: t('RidingLessonsOverview.table.paidBookings'), display: { value: true, chipColor: false }
        }],
        rows: [],
    };
    const [structure, setStructure] = useState(structureHeaders);


    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const date = new Date().toISOString();
                const response = await bookingStatistics(userId, selectedMonth, apiKey, organizationId, token);
                // Assuming the response is directly the data you need
                // Adjust accordingly if the data is nested within the response
                const processedRiders = response.map(rider => ({
                    id: rider.riderkey,
                    riderName: rider.riderName,
                    totalBookings: rider.lessons.reduce((total, lesson) => total + lesson.bookings.length, 0),
                    paidBookings: rider.lessons.reduce((total, lesson) => total + lesson.bookings.filter(booking => booking.isPaid).length, 0),
                    bookings: rider.lessons.flatMap(lesson => lesson.bookings),
                }));

                setStatistics(response);
                setRiders(processedRiders);
                const mappedRows = processedRiders.map(rider => ({
                    id: rider.id,
                    riderName: {
                        value: rider.riderName,
                        icon: null,
                    },
                    totalBookings: {
                        value: rider.totalBookings,
                        icon: null,
                    },
                    paidBookings: {
                        value: rider.paidBookings,
                        icon: null,
                    }
                }));

                setStructure(prev => ({ ...prev, rows: mappedRows }));
            } catch (error) {
                console.error('Error fetching riders:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [selectedMonth, paymentDialogOpen]);

    const changeMonth = (delta) => {
        setSelectedMonth((current) => {
            const newMonth = new Date(current.setMonth(current.getMonth() + delta));
            return newMonth;
        });
    };

    const handleRowClick = (recordId) => {
        // Assuming riders state contains the full data including lessons and bookings
        //const rider = riders.find(r => r.id === riderId);
        const rider = statistics.find(r => r.riderkey === recordId);
        if (rider) {
            setSelectedRider(rider); // Pass the entire rider object
            setPaymentDialogOpen(true);
        }
    };


    const handleCloseDialog = () => {
        setPaymentDialogOpen(false);
    };

    const toggleViewMode = () => {
        setViewMode(prevMode => prevMode === 'table' ? 'card' : 'table');
    };


    return (
        <Paper elevation={3} sx={{ p: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
                <Typography variant="h5">{t('Riding Lesson Payments')}</Typography>
                <IconButton onClick={toggleViewMode} color="primary">
                    {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
                </IconButton>
            </Box>
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
                <IconButton onClick={() => changeMonth(-1)}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography>{selectedMonth.toLocaleDateString('default', { month: 'long', year: 'numeric' })}</Typography>
                <IconButton onClick={() => changeMonth(1)} >
                    <ArrowForwardIcon />
                </IconButton>
            </Box>

            <Box sx={{ my: 2 }}>
                {isLoading ? (
                    <>
                        <Typography variant="h6" gutterBottom>{t('HorsePage.waitingText')}</Typography>
                        <LinearProgress />
                    </>
                ) : viewMode === 'table' ? (
                    <GenericTableView structure={structure} onClick={handleRowClick} />
                ) : (
                    <GenericCardView structure={structure} onClick={handleRowClick} />
                )}
                {selectedRider && (
                    <RidingLessonPaymentDialog
                        open={paymentDialogOpen}
                        onClose={handleCloseDialog}
                        selectedRider={selectedRider}
                    />
                )}

            </Box>
        </Paper>
    );
};

export default RidingLessonPayments;
