// src/components/VaccinationModal.js
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Switch,
} from '@mui/material';
import { saveVaccination, updateVaccination, deleteVaccination } from '../services/VaccinationService';  // Import the service methods
import { useAuth } from '../context/AuthContext';  // To get the authentication tokens and IDs
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { useDateLocale } from '../hooks/useDateLocale';
import { format } from 'date-fns';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { getAllHorses, getHorsesByUserId } from '../services/horseService'; // Changed from getHorsesByOwnerID
import { fetchAllVeterinarians } from '../services/VeterinarianService';
import SnackbarAlert from './SnackbarAlert'; // Adjust the path as necessary
import LinearProgress from '@mui/material/LinearProgress';

const VaccinationModal = ({ isOpen, onClose, record, horses = [], vets = [] }) => {

    const { apiKey, organizationId, token, ownerId, userType, userId } = useAuth(); // Added userId
    const [horseRecords, setHorseRecords] = useState([]);
    const [vetRecords, setVetRecords] = useState([]);
    const { t } = useTranslation();
    const dateLocale = useDateLocale();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        id: '',
        horseId: '',
        vaccinationName: '',
        vaccinationDate: '',
        nextVaccinationDate: '',
        notifyOwner: false,
        shareWithOwner: true,
        vetId: ''
    });
    const vaccinationTypesMapping = {
        "Tetanus": t('Vaccination.Types.Tetanus'),
        "EquineInfluenza": t('Vaccination.Types.EquineInfluenza'),
        "EquineHerpesvirus": t('Vaccination.Types.EquineHerpesvirus'),
        "WestNileVirus": t('Vaccination.Types.WestNileVirus'),
        "Rabies": t('Vaccination.Types.Rabies'),
        "EquineViralArteritis": t('Vaccination.Types.EquineViralArteritis'),
        "Rotavirus": t('Vaccination.Types.Rotavirus')
    };
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // Can be 'error', 'warning', 'info', or 'success'
    });
    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    // Effect for setting form data when record changes
    useEffect(() => {
        if (record) {
            setFormData({
                ...record,
                id: record.id,
                horseId: record.horseId || '',
                vetId: record.vetId || '',
                notifyOwner: Boolean(record.notifyOwner),
                shareWithOwner: record.shareWithOwner ?? true,
                vaccinationDate: record.vaccinationDate ? new Date(record.vaccinationDate) : null,
                nextVaccinationDate: record.nextVaccinationDate ? new Date(record.nextVaccinationDate) : null,
            });
        } else {
            setFormData({
                id: uuidv4(),
                horseId: '',
                vaccinationName: '',
                vaccinationDate: null,
                nextVaccinationDate: null,
                notifyOwner: false,
                shareWithOwner: true,
                vetId: ''
            });
        }
    }, [record]);

    // Effect for fetching horse records
    useEffect(() => {
        const fetchHorses = async () => {
            try {
                setLoading(true);
                let fetchedHorses = [];
                if (horses.length > 0) {
                    fetchedHorses = horses;
                } else {
                    if (userType === 'admin') { // Changed from 'customer' to 'admin'
                        fetchedHorses = await getAllHorses(apiKey, organizationId, token);
                    } else {
                        fetchedHorses = await getHorsesByUserId(userId, apiKey, organizationId, token); // Changed from getHorsesByOwnerID to getHorsesByUserId
                    }
                }
                const filteredHorses = fetchedHorses.filter(horse => horse.permissions?.includes('handleVaccine'));
                setHorseRecords(filteredHorses);
            } catch (error) {
                console.error('Error fetching horses:', error);
                setSnackbar({
                    open: true,
                    message: t('Vaccination.Dialog.fetchHorsesError'),
                    severity: 'error',
                });
            } finally {
                setLoading(false);
            }
        };

        fetchHorses();
    }, [userType, userId, apiKey, organizationId, token]);

    // Effect for fetching veterinarian records
    useEffect(() => {
        const fetchVets = async () => {
            try {
                setLoading(true);
                let fetchedVets = [];
                if (vets.length > 0) {
                    fetchedVets = vets;
                } else {
                    fetchedVets = await fetchAllVeterinarians(apiKey, organizationId, token);
                }
                setVetRecords(fetchedVets);
            } catch (error) {
                console.error('Error fetching veterinarians:', error);
                setSnackbar({
                    open: true,
                    message: t('Vaccination.Dialog.fetchVetsError'),
                    severity: 'error',
                });
            } finally {
                setLoading(false);
            }
        };

        fetchVets();
    }, [apiKey, organizationId, token, t]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };
    const handleDateChange = (name, value) => {
        setFormData((prev) => ({ ...prev, [name]: value }));
    };
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prev) => ({ ...prev, [name]: checked }));
    };

    const handleSwitchChange = (event) => {
        setFormData((prev) => ({ ...prev, shareWithOwner: event.target.checked }));
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            // Prepare data with ISO-formatted dates
            const dataToSave = {
                ...formData,
                vaccinationDate: formData.vaccinationDate ? new Date(formData.vaccinationDate).toISOString() : null,
                nextVaccinationDate: formData.nextVaccinationDate ? new Date(formData.nextVaccinationDate).toISOString() : null
            };

            if (record) {
                await updateVaccination(dataToSave, apiKey, organizationId, token, ownerId);
                setSnackbar({
                    open: true,
                    message: t('Vaccination.Dialog.updateSuccess'),
                    severity: 'success',
                });
            } else {
                await saveVaccination(dataToSave, apiKey, organizationId, token, ownerId);
                setSnackbar({
                    open: true,
                    message: t('Vaccination.Dialog.saveSuccess'),
                    severity: 'success',
                });
            }
            onClose();  // Close the dialog after saving data
        } catch (error) {
            console.error('Error saving vaccination:', error);
            // Show error message
            setSnackbar({
                open: true,
                message: t('Vaccination.Dialog.saveError'),
                severity: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            if (record && record.id) {
                setLoading(true);
                await deleteVaccination(record.id, apiKey, organizationId, token, ownerId);
                setSnackbar({
                    open: true,
                    message: t('Vaccination.Dialog.deleteSuccess'),
                    severity: 'success',
                });
                onClose();  // Close the dialog after deleting
            }
        } catch (error) {
            console.error('Error deleting vaccination:', error);
            // Show error message
            setSnackbar({
                open: true,
                message: t('Vaccination.Dialog.deleteError'),
                severity: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
            <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    {t('Vaccination.Dialog.Title')}
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    {loading && <LinearProgress />}
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="horse-select-label">{t('Vaccination.Dialog.HorseName')}</InputLabel>
                        <Select
                            labelId="horse-select-label"
                            name="horseId"
                            value={formData.horseId}
                            onChange={handleChange}
                            label={t('Vaccination.Dialog.HorseName')}
                        >
                            {horseRecords.map((horse) => (
                                <MenuItem key={horse.id} value={horse.id}>{horse.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="vaccination-name-label">{t('Vaccination.Dialog.vaccinationName')}</InputLabel>
                        <Select
                            labelId="vaccination-name-label"
                            name="vaccinationName"
                            value={formData.vaccinationName}
                            onChange={handleChange}
                            label={t('Vaccination.Dialog.vaccinationName')}
                        >
                            {Object.entries(vaccinationTypesMapping).map(([key, value]) => (
                                <MenuItem key={key} value={key}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <DatePicker
                            label={t('Vaccination.Dialog.vaccinationDate')}
                            value={formData.vaccinationDate}
                            onChange={(newValue) => handleDateChange('vaccinationDate', newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <DatePicker
                            label={t('Vaccination.Dialog.nextVaccinationDate')}
                            value={formData.nextVaccinationDate}
                            onChange={(newValue) => handleDateChange('nextVaccinationDate', newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="vet-select-label">{t('Vaccination.Dialog.vetsName')}</InputLabel>
                        <Select
                            labelId="vet-select-label"
                            name="vetId"
                            value={formData.vetId}
                            onChange={handleChange}
                            label={t('Vaccination.Dialog.vetsName')}
                        >
                            {vetRecords.map((vet) => (
                                <MenuItem key={vet.id} value={vet.id}>{vet.firstname} {vet.surname}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.shareWithOwner}
                                onChange={handleSwitchChange}
                                name="shareWithOwner"
                                color="primary"
                            />
                        }
                        label={t('Vaccination.Dialog.shareWithOwner')}
                        sx={{ marginTop: 2 }}
                    />
                    <FormControl fullWidth margin="normal">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.notifyOwner}
                                        onChange={handleCheckboxChange}
                                        name="notifyOwner"
                                        color="primary"
                                    />
                                }
                                label={t('Vaccination.Dialog.NotifyOwner')}
                            />
                        </FormGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    {record && (
                        <Button variant="outlined" color="error" onClick={handleDelete} disabled={loading}>
                            {t('Vaccination.Dialog.delete')}
                        </Button>
                    )}
                    <Button variant="outlined" color="secondary" onClick={onClose} disabled={loading}>
                        {t('Vaccination.Dialog.cancel')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
                        {t('Vaccination.Dialog.save')}
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackbarAlert
                open={snackbar.open}
                onClose={handleSnackbarClose}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </LocalizationProvider>
    );

};

export default VaccinationModal;
