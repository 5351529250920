import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getHorseActivityVotes } from '../../services/horseActivityVoteService';
import { fetchUserBookingsbyUserId } from '../../services/guidingMachineService';
import { getHorsesByUserId } from '../../services/horseService';
import {
    Container,
    Typography,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    Grid,
    Box,
    Avatar,
    Divider,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { format, subDays } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDateLocale } from '../../hooks/useDateLocale';
import ActivityIcon from '@mui/icons-material/DirectionsRun';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import NoActivitiesIcon from '@mui/icons-material/Block';
import DressageIcon from '../../Icons/horseIcons/DressageIcon';
import JumpingIcon from '../../Icons/horseIcons/JumpingIcon';
import LungeIcon from '../../Icons/horseIcons/LungeIcon';
import RideOutIcon from '../../Icons/horseIcons/RideOutIcon';
import RidingIcon from '../../Icons/horseIcons/RidingIcon';
import WalkingIcon from '../../Icons/horseIcons/WalkingIcon';

const activityTypes = {
    lunge: <LungeIcon style={{ color: 'red' }} />,
    riding: <RideOutIcon style={{ color: 'blue' }} />,
    jumping: <JumpingIcon style={{ color: 'green' }} />,
    dressage: <DressageIcon style={{ color: 'purple' }} />,
    rideOut: <RidingIcon style={{ color: 'orange' }} />,
    walking: <WalkingIcon style={{ color: 'brown' }} />,
};

const OwnerDashboard = ({ userEmail, userId, ownerId, apiKey, organizationId, jwtToken }) => {
    const { t } = useTranslation();
    const dateLocale = useDateLocale();
    const [horses, setHorses] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [activities, setActivities] = useState([]);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Define the number of days to show in the timeline
    const daysToShow = 7;

    useEffect(() => {
        getHorsesByUserId(userId, apiKey, organizationId, jwtToken)
            .then((horsesData) => setHorses(horsesData))
            .catch((error) => console.error('Error fetching horses data:', error));

        fetchUserBookingsbyUserId(userId, apiKey, organizationId, jwtToken)
            .then((data) => setBookings(data))
            .catch((error) => console.error('Error fetching bookings:', error));

        getHorseActivityVotes(apiKey, organizationId, jwtToken)
            .then((data) => setActivities(data))
            .catch((error) => console.error('Error fetching activities:', error));
    }, [userEmail, userId, apiKey, organizationId, jwtToken]);

    const getHorseNameById = (horseId) => {
        const horse = horses.find((horse) => horse.id === horseId);
        return horse ? horse.name : '';
    };

    const getLastNDays = (n) => {
        const days = [];
        for (let i = n - 1; i >= 0; i--) {
            days.push(format(subDays(new Date(), i), 'yyyy-MM-dd'));
        }
        return days;
    };

    const getActivityIcon = (activityType) => {
        return activityTypes[activityType] || <ActivityIcon style={{ width: 60, height: 60 }} />;
    };

    const renderHorseTimeline = (horseId) => {
        const lastNDays = getLastNDays(daysToShow);
        const horseActivities = activities.filter((activity) => activity.horseId === horseId);
        const horseBookings = bookings.filter((booking) => booking.horse_id === horseId);

        if (horseActivities.length === 0 && horseBookings.length === 0) return null;

        return (
            <Grid container spacing={isMobile ? 1 : 2} direction={isMobile ? 'column' : 'row'} wrap={isMobile ? 'nowrap' : 'wrap'} key={horseId}>
                {lastNDays.map((day) => (
                    <Grid item xs={12} sm={isMobile ? 12 : 1} key={day} style={{ flex: isMobile ? 'none' : 1 }}>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography variant="caption">{format(new Date(day), 'P', { locale: dateLocale })}</Typography>
                            <Box display="flex" flexDirection={isMobile ? 'row' : 'column'} alignItems="center">
                                {horseActivities
                                    .filter((activity) => format(new Date(activity.selectedDate), 'yyyy-MM-dd') === day)
                                    .map((activity, index) => (
                                        <Avatar key={index} style={{ margin: '5px', backgroundColor: '#f0f0f0', width: 60, height: 60 }}>
                                            {getActivityIcon(activity.activityType)}
                                        </Avatar>
                                    ))}
                                {horseBookings
                                    .filter((booking) => format(new Date(booking.start_time), 'yyyy-MM-dd') === day)
                                    .map((booking, index) => (
                                        <Avatar key={index} style={{ margin: '5px', backgroundColor: '#f0f0f0', width: 60, height: 60 }}>
                                            <FitnessCenterIcon style={{ color: 'black', width: 40, height: 40 }} />
                                        </Avatar>
                                    ))}
                                {horseActivities.filter((activity) => format(new Date(activity.selectedDate), 'yyyy-MM-dd') === day).length ===
                                    0 && horseBookings.filter((booking) => format(new Date(booking.start_time), 'yyyy-MM-dd') === day).length === 0 && (
                                        <Avatar style={{ margin: '5px', backgroundColor: '#f0f0f0', border: '1px dashed #ccc', width: 60, height: 60 }}>
                                            <NoActivitiesIcon style={{ color: '#ccc', width: 40, height: 40 }} />
                                        </Avatar>
                                    )}
                            </Box>
                            <Divider style={{ margin: '10px 0', width: '80%' }} />
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );
    };

    const handleHorseClick = (horseId) => {
        navigate(`/horses/detail/${horseId}`);
    };

    const hasRecentActivitiesOrBookings = (horseId) => {
        const lastNDays = getLastNDays(daysToShow);
        const horseActivities = activities.filter((activity) => activity.horseId === horseId);
        const horseBookings = bookings.filter((booking) => booking.horse_id === horseId);

        return lastNDays.some(day =>
            horseActivities.some(activity => format(new Date(activity.selectedDate), 'yyyy-MM-dd') === day) ||
            horseBookings.some(booking => format(new Date(booking.start_time), 'yyyy-MM-dd') === day)
        );
    };

    const horsesWithActivitiesOrBookings = horses.filter((horse) => {
        return hasRecentActivitiesOrBookings(horse.id);
    });

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
            <Container>
                <Typography variant="h4" gutterBottom>
                    {t('Home.OwnerDashboard.ownedHorses')}
                </Typography>
                <Card>
                    <CardContent>
                        <List>
                            {horses.map((horse) => (
                                <ListItem key={horse.id} button onClick={() => handleHorseClick(horse.id)}>
                                    <ListItemText primary={horse.name} />
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>

                <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }}>
                    {t('Home.OwnerDashboard.last30DaysActivitiesBookings')}
                </Typography>
                {horsesWithActivitiesOrBookings.map((horse) => (
                    <Card key={horse.id} style={{ marginTop: '20px' }}>
                        <CardContent>
                            <Typography variant="h6">{horse.name}</Typography>
                            <Divider style={{ marginBottom: '10px' }} />
                            {renderHorseTimeline(horse.id)}
                        </CardContent>
                    </Card>
                ))}

                <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }}>
                    {t('Home.OwnerDashboard.bookings')}
                </Typography>
                <Card>
                    <CardContent>
                        <List>
                            {bookings.map((booking) => (
                                <ListItem key={booking.id}>
                                    <ListItemText
                                        primary={`${getHorseNameById(booking.horse_id)} - ${new Date(booking.start_time).toLocaleDateString()} ${new Date(
                                            booking.start_time
                                        ).toLocaleTimeString()}`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            </Container>
        </LocalizationProvider>
    );
};

export default OwnerDashboard;
