// i18n.js

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enUS from 'date-fns/locale/en-US';
import deDE from 'date-fns/locale/de';

import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  'en-AT': {
    translation: translationEN,
  },
  'de-AT': {
    translation: translationDE,
  },
};

const languageWhitelist = ['en', 'en-AT', 'de'];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    whitelist: languageWhitelist,
    nonExplicitWhitelist: true,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
      lookupNavigator: true,
    },
  });
  
export default i18n;
