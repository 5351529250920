// src/pages/ExpenseListPage.js

import React, { useState, useEffect } from 'react';
import {
  Paper,
  Button,
  Typography,
  Chip,
  Box,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from '../context/AuthContext';
import { fetchAllExpenses } from '../services/expenseService'; // Adjust the path as needed
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Icon for valid status
import WarningAmberIcon from '@mui/icons-material/WarningAmber'; // Icon for cancelled status
import ViewModuleIcon from '@mui/icons-material/ViewModule';  // <-- add this line
import ViewListIcon from '@mui/icons-material/ViewList';  // <-- add this line
import GenericCardView from '../components/GenericCardView';
import GenericTableView from '../components/GenericTableView';
import { useTheme, useMediaQuery } from '@mui/material';
import DocumentUploadModal from '../components/DocumentUploadModal';
import { scanDocument, fetchDocumentMetadata } from '../services/documentService';
import { usePreferences } from '../context/UserPreferencesContext';

const ExpenseListPage = () => {
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(false); // For demo purposes, loading is false
  const navigate = useNavigate();
  const { apiKey, organizationId, token, userId } = useAuth();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [viewMode, setViewMode] = useState(isMobile ? 'card' : 'table');
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const { devicePreferences, updateDevicePreferences } = usePreferences();

  const navigateToDetail = (expenseId) => {
    navigate(`/expenses/detail/${expenseId}`);
  };


  useEffect(() => {
    const fetchExpenses = async () => {
      setLoading(true); // Show loading indicator while fetching
      try {
        const expenseData = await fetchAllExpenses(apiKey, organizationId, token, userId);
        setExpenses(expenseData);
      } catch (error) {
        console.error("Error fetching expense data: ", error);
      } finally {
        setLoading(false); // Hide loading indicator when data is fetched
      }
    };

    fetchExpenses();
  }, [apiKey, organizationId, token, userId]);

  // Update local state when context updates
  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);


  const renderPaymentStatus = (expense) => {
    if (expense.cancel && expense.cancel === true) {
      return <Chip icon={<WarningAmberIcon />} label={t('expenseListPage.cancelled')} color="warning" />;
    } else {
      return <Chip icon={<CheckCircleIcon />} label={t('expenseListPage.valid')} color="success" />;
    }
  };


  const handleCloseUploadModal = async (documentId) => {
    if (!documentId || typeof documentId !== 'string') {
      setIsUploadModalOpen(false);
      return;
    }

    setIsUploadModalOpen(false);
    setLoading(true);
    let document = {};

    await scanDocument(documentId, apiKey, organizationId, token)
      .then(() => {
      })
      .catch(error => {
        console.error('Error scanning document:', error);
      })
      .finally(() => {
        setLoading(false);
      });

    document = await fetchDocumentMetadata(documentId, apiKey, organizationId, token);
    navigate(`/expenses/detail/new`, { state: { fromDocument: document.structuredData } });

  };

  const openUplodModal = () => {
    setIsUploadModalOpen(true);
  };

  const toggleViewMode = () => {
    updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
  };

  const transformData = () => {
    return {
      headers: [
        { key: 'expenseNumber', label: t('expenseListPage.expenseNumber'), display: { value: true, chipColor: false } },
        { key: 'expenseDate', label: t('expenseListPage.expenseDate'), display: { value: true, chipColor: false } },
        { key: 'totalBruttoAmount', label: t('expenseListPage.totalBruttoAmount'), display: { value: true, chipColor: false } },
        { key: 'status', label: t('expenseListPage.status'), display: { value: true, chipColor: false } }
      ],
      rows: expenses.map(expense => ({
        id: expense.id,
        expenseNumber: { value: expense.expenseNumber, icon: null },
        expenseDate: { value: new Date(expense.expenseDate).toLocaleDateString(), icon: null },
        totalBruttoAmount: { value: expense.totalBruttoAmount, icon: null },
        status: { value: renderPaymentStatus(expense), icon: null }
      }))
    };
  };

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {t('expenseListPage.title')}
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 3 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => navigateToDetail('new')}
        >
          {t('expenseListPage.addNewExpense')}
        </Button>

        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => openUplodModal()}
        >
          {t('expenseListPage.uploadDocument')}
        </Button>
        <IconButton onClick={toggleViewMode} color="primary">
          {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
        </IconButton>
      </Box>
      {loading ? (
        <div style={{ width: '100%', marginTop: 20 }}>
          <LinearProgress />
        </div>
      ) : viewMode === 'table' ? (
        <GenericTableView structure={transformData()} onClick={navigateToDetail} />
      ) : (
        <GenericCardView structure={transformData()} onClick={navigateToDetail} />
      )}
      <DocumentUploadModal isOpen={isUploadModalOpen} onClose={handleCloseUploadModal} scanType={'expense'} predefinedDescription={'Expense'} />
    </Paper>
  );
};

export default ExpenseListPage;
