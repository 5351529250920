import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box, InputAdornment, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';

const FoodManagement = ({ horse, handleFoodChange, foodSettings }) => {
    const { t } = useTranslation();

    // Helper function to get translated meal time
    const getLocalizedMealTime = (mealTime) => {
        return t(`HorseInfoPage.FoodManagement.MealTimes.${mealTime}`);
    };

    const clearSelection = (foodId, mealTime) => {
        handleFoodChange(foodId, mealTime, '');
    };

    return (
        <div>
            {foodSettings.map((food) => (
                <Box key={food.id} sx={{ mt: 2 }}>
                    <h2>{food.name}</h2>
                    {Object.entries(food.availability).map(([mealTime, isAvailable]) => (
                        isAvailable && (
                            <div key={mealTime}>
                                <h3>{getLocalizedMealTime(mealTime)}</h3>
                                <FormControl fullWidth>
                                    <InputLabel>{t('HorseInfoPage.FoodManagement.PortionLabel')}</InputLabel>
                                    <Select
                                        name="portion"
                                        value={horse.foodPortions && horse.foodPortions[food.id] && horse.foodPortions[food.id][mealTime] ? horse.foodPortions[food.id][mealTime] : ''}
                                        onChange={(event) => handleFoodChange(food.id, mealTime, event.target.value)}
                                        endAdornment={
                                            horse.foodPortions && horse.foodPortions[food.id] && horse.foodPortions[food.id][mealTime] && (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => clearSelection(food.id, mealTime)} size="small" style={{ marginRight: '12px' }}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }
                                    >
                                        {food.portions.map(portion => (
                                            <MenuItem key={portion.id} value={portion.id}>{portion.printName || portion.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        )
                    ))}
                </Box>
            ))}
        </div>
    );
};

export default FoodManagement;
