// src/pages/dewormingDetailsPage/dewormingDetailsPage.jsx

import React, { useState } from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Box, Paper, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { useDateLocale } from '../../hooks/useDateLocale';
import LinearProgress from '@mui/material/LinearProgress';
import { useDeworming } from './useDeworming';
import DewormingTable from './DewormingTable';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const DewormingDetailsPage = () => {

    const { t } = useTranslation();
    const { dewormingId } = useParams();
    const dateLocale = useDateLocale();
    const navigate = useNavigate();
    const {
        dewormingData,
        setDewormingData,
        handleInputChange,
        addRow,
        addAllHorses,
        removeRow,
        removeDeworming,
        handleRowChange,
        horseIds,
        isEditMode,
        handleSubmit,
        loading,
        error,
    } = useDeworming(dewormingId);



    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                {loading && <LinearProgress />}
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h5" gutterBottom>
                            <IconButton onClick={() => navigate(-1)} aria-label="back"><ArrowBackIcon /></IconButton>
                            {t('dewormingDetailsPage.title')}
                        </Typography>
                        {isEditMode &&
                            <IconButton onClick={() => removeDeworming(dewormingId)}>
                                <DeleteIcon />
                            </IconButton>
                        }
                    </Box>
                    <Grid container spacing={3}> {/* Use Grid container for layout */}
                        <Grid item xs={12} md={6}> {/* Adjust the grid size as needed */}
                            <FormControl fullWidth margin="normal">
                                <DatePicker
                                    label={t('dewormingDetailsPage.date')}
                                    value={dewormingData.date ? new Date(dewormingData.date) : null}
                                    onChange={(date) => setDewormingData({ ...dewormingData, date: date })}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth margin="normal">
                                <DatePicker
                                    label={t('dewormingDetailsPage.nextDueDate')}
                                    value={dewormingData.nextDueDate ? new Date(dewormingData.nextDueDate) : null}
                                    onChange={(date) => setDewormingData({ ...dewormingData, nextDueDate: date })}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <DewormingTable
                        rows={dewormingData.rows}
                        addRow={addRow}
                        removeRow={removeRow}
                        handleRowChange={handleRowChange}
                        horseIds={horseIds}
                        isEditMode={isEditMode}
                        addAllHorses={addAllHorses}
                    />
                    <Grid item >
                        <Button type="submit" variant="contained" color="primary">
                            {t('save')}
                        </Button>
                    </Grid>
                </Box>
            </Paper>
        </LocalizationProvider >
    );
}

export default DewormingDetailsPage;