import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Chip, TableSortLabel, IconButton, Tooltip } from '@mui/material';

const GenericTableView = ({ structure, onClick, actions }) => {
  const navigate = useNavigate();
  const [orderDirection, setOrderDirection] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortData = (rows) => {
    return rows.sort((a, b) => {
      if (orderBy === '') return 0;
      if (a[orderBy].value < b[orderBy].value) {
        return orderDirection === 'asc' ? -1 : 1;
      }
      if (a[orderBy].value > b[orderBy].value) {
        return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleRowClick = (rowId) => {
    onClick(rowId);
  };

  const handleActionClick = (action, rowId, event) => {
    event.stopPropagation();  // Prevent the event from bubbling up to the TableRow onClick
    action.handler(rowId);
  };

  if (!structure || structure.rows.length === 0) {
    return <Typography>No data available.</Typography>;
  }

  const sortedRows = sortData([...structure.rows]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {structure.headers.filter(header => header.display.value).map((header) => (
              <TableCell key={header.key}>
                <TableSortLabel
                  active={orderBy === header.key}
                  direction={orderBy === header.key ? orderDirection : 'asc'}
                  onClick={() => handleRequestSort(header.key)}
                >
                  {header.label}
                </TableSortLabel>
              </TableCell>
            ))}
            {(actions || structure.rows.some(row => row.actions)) && (<TableCell>Actions</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((row) => (
            <TableRow key={row.id} hover onClick={() => handleRowClick(row.id)} style={{ cursor: onClick ? 'pointer' : 'default' }}>
              {structure.headers.filter(header => header.display.value).map((header) => (
                <TableCell key={header.key}>
                  {row[header.key].value}
                  {row[header.key].icon && row[header.key].icon}
                </TableCell>
              ))}
              {(actions || row.actions) && (
                <TableCell>
                  {actions && actions.map(action => (
                    <Tooltip title={action.label} key={action.label}>
                      <span>
                        <IconButton onClick={(event) => handleActionClick(action, row.id, event)} disabled={action.disabled}>
                          {action.icon}
                        </IconButton>
                      </span>
                    </Tooltip>
                  ))}
                  {row.actions && row.actions.map(action => (
                    <Tooltip title={action.label} key={action.label}>
                      <span>
                        <IconButton onClick={(event) => handleActionClick(action, row.id, event)} disabled={action.disabled}>
                          {action.icon}
                        </IconButton>
                      </span>
                    </Tooltip>
                  ))}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GenericTableView;
