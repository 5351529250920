import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Button,
  Collapse,
  Box,
  Typography,
  Tooltip,
} from '@mui/material';

// Importing icons from react-icons
import { VscReferences } from 'react-icons/vsc'; // List icon
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';
import ItemSelectionPopup from '../../components/ItemSelectionPopup';

const InvoiceTable = ({
  invoiceData,
  handleInputChange,
  removeRow,
  addRow,
  showAddRows,
  addBoxRow,
  addMachineRow,
  addServiceRow,
  openDeliveryNoteSelection,
}) => {
  const { t } = useTranslation();
  const itemTypes = ['Manuell', 'Item', 'Box', 'Service', 'Device'];
  const [expandedRow, setExpandedRow] = useState(null);
  const [itemSelectionOpen, setItemSelectionOpen] = useState(false);

  const getItemTypeTranslation = (type) => {
    return t(`invoiceDetailPage.itemTypes.${type.toLowerCase()}`);
  };

  const toggleRowExpansion = (index) => {
    setExpandedRow((prev) => (prev === index ? null : index));
  };

  const handleAddItem = (item) => {
    addRow({
      description: item.name,
      quantity: 1,
      type: 'Item',
      netPrice: item.netPrice,
      taxRate: item.taxPercentage,
      bruttoPrice: item.bruttoPrice,
      discountPercentage: 0,
      discountAmount: 0,
      rowTotal: item.bruttoPrice,
      itemId: item.id,
      organizationId: item.organizationId
    });
    setItemSelectionOpen(false);
  };

  return (
    <TableContainer component={Paper} style={{ marginTop: '20px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('invoiceDetailPage.table.itemDescription')}</TableCell>
            <TableCell>{t('invoiceDetailPage.table.itemType')}</TableCell>
            <TableCell>{t('invoiceDetailPage.table.quantity')}</TableCell>
            <TableCell>{t('invoiceDetailPage.table.rowTotal')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceData?.rows?.map((row, index) => (
            <React.Fragment key={index}>
              <TableRow>
                <TableCell>
                  <TextField
                    name="description"
                    value={row.description}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </TableCell>
                <TableCell>
                  <Select
                    name="type"
                    value={row.type}
                    onChange={(e) => handleInputChange(e, index)}
                    fullWidth
                  >
                    {itemTypes.map((type, idx) => (
                      <MenuItem key={idx} value={type}>{getItemTypeTranslation(type)}</MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <TextField
                    name="quantity"
                    value={row.quantity}
                    type="number"
                    inputProps={{ min: "0", step: "1" }}
                    onChange={(e) => {
                      if (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) {
                        handleInputChange(e, index);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    disabled
                    name="rowTotal"
                    value={row.rowTotal}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  <Tooltip title={t('invoiceDetailPage.tooltips.deleteRow')} arrow>
                    <IconButton
                      color="secondary"
                      onClick={() => removeRow(index)}
                      aria-label={t('invoiceDetailPage.ariaLabels.deleteRow')}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={expandedRow === index ? t('invoiceDetailPage.tooltips.collapseRow') : t('invoiceDetailPage.tooltips.expandRow')}
                    arrow
                  >
                    <IconButton
                      onClick={() => toggleRowExpansion(index)}
                      aria-label={expandedRow === index ? t('invoiceDetailPage.ariaLabels.collapseRow') : t('invoiceDetailPage.ariaLabels.expandRow')}
                    >
                      {expandedRow === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                  <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                      <Typography variant="h6" gutterBottom component="div">
                        {t('invoiceDetailPage.additionalDetails')}
                      </Typography>
                      <Table size="small" aria-label="additional-details">
                        <TableBody>
                          <TableRow>
                            <TableCell>{t('invoiceDetailPage.table.netUnitPrice')}</TableCell>
                            <TableCell>
                              <TextField
                                name="netPrice"
                                value={row.netPrice}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('invoiceDetailPage.table.taxRate')}</TableCell>
                            <TableCell>
                              <TextField
                                name="taxRate"
                                value={row.taxRate}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('invoiceDetailPage.table.bruttoUnitPrice')}</TableCell>
                            <TableCell>
                              <TextField
                                name="bruttoPrice"
                                value={row.bruttoPrice}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('invoiceDetailPage.table.discountPercentage')}</TableCell>
                            <TableCell>
                              <TextField
                                name="discountPercentage"
                                value={row.discountPercentage}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('invoiceDetailPage.table.discountAmount')}</TableCell>
                            <TableCell>
                              <TextField
                                name="discountAmount"
                                value={row.discountAmount}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </TableCell>
                          </TableRow>
                          {/* Add more fields as needed */}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
          <TableRow>
            <TableCell colSpan={5} style={{ textAlign: 'right' }}>
              {showAddRows && (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={addBoxRow}
                    style={{ marginLeft: '10px' }}
                  >
                    {t('invoiceDetailPage.buttons.addBoxRow')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={addMachineRow}
                    style={{ marginLeft: '10px' }}
                  >
                    {t('invoiceDetailPage.buttons.addMachineRow')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={addServiceRow}
                    style={{ marginLeft: '10px' }}
                  >
                    {t('invoiceDetailPage.buttons.addServiceRow')}
                  </Button>
                </>
              )}
              {/* Secondary Action: Add Delivery Note */}
              <Tooltip title={t('invoiceDetailPage.tooltips.addDeliveryNote')} arrow>
                <IconButton
                  color="primary"
                  onClick={() => openDeliveryNoteSelection()}
                  aria-label={t('invoiceDetailPage.ariaLabels.addDeliveryNote')}
                >
                  <VscReferences />
                </IconButton>
              </Tooltip>
              {/* Primary Action: Select Item */}
              <Tooltip title={t('invoiceDetailPage.tooltips.selectItem')} arrow>
                <IconButton
                  color="primary"
                  onClick={() => setItemSelectionOpen(true)}
                  aria-label={t('invoiceDetailPage.ariaLabels.selectItem')}
                >
                  <ListIcon />
                </IconButton>
              </Tooltip>

              {/* Primary Action: Manual Entry */}
              <Tooltip title={t('invoiceDetailPage.tooltips.addManualEntry')} arrow>
                <IconButton
                  color="primary"
                  onClick={() => addRow()}
                  aria-label={t('invoiceDetailPage.ariaLabels.addManualEntry')}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* Item Selection Popup */}
      <ItemSelectionPopup
        open={itemSelectionOpen}
        onClose={() => setItemSelectionOpen(false)}
        onAddItem={handleAddItem}
      />
    </TableContainer>
  );
};

export default InvoiceTable;
