// src/services/ServiceTypesService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig';

const API_BASE_URL = '/api/service-types';

export async function fetchServiceTypes(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(API_BASE_URL);
  return response.data;
}


export async function fetchBookableServiceTypes(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}?type=bookable`);
  return response.data;
}


export async function fetchConnectedServiceTypes(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}?type=connected`);
  return response.data;
}

export async function saveServiceTypes(serviceTypesData, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(API_BASE_URL, serviceTypesData);
  return response.data;
}

export async function deleteServiceType(serviceTypeId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}/${serviceTypeId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

export default {
  fetchServiceTypes,
  saveServiceTypes,
  deleteServiceType,
  fetchBookableServiceTypes,
  fetchConnectedServiceTypes,
};
