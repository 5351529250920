// src/components/DataImportTable.js
import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Button,
    Typography,
    Snackbar,
    Alert,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    CircularProgress,
    IconButton,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Papa from 'papaparse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { fetchCategories } from '../../services/categoryService';
import { fetchMeasureUnits } from '../../services/measureUnitsService';
import { importData } from '../../services/importService';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next'; // Import useTranslation

// Styled components for better UX
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    maxHeight: '60vh',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(1),
    position: 'relative',
    minWidth: 150,
}));

const StickyTableCell = styled(StyledTableCell)(({ theme }) => ({
    position: 'sticky',
    left: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    borderRight: `1px solid ${theme.palette.divider}`,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    width: '100%',
}));

const DataImportTable = () => {
    const { t } = useTranslation(); // Initialize the translation hook
    const { apiKey, organizationId, token } = useAuth();
    const [importType, setImportType] = useState('');
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [message, setMessage] = useState('');
    const [importing, setImporting] = useState(false);
    const [fieldDescriptions, setFieldDescriptions] = useState({});
    const [schema, setSchema] = useState(null);
    const [errors, setErrors] = useState({});
    const [categories, setCategories] = useState([]);
    const [measureUnits, setMeasureUnits] = useState([]);
    const [loading, setLoading] = useState(false);

    const tableRef = useRef(null);
    // Fetch categories and measure units on component mount
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (importType === 'ItemData') {
                    setLoading(true);
                    const categoriesData = await fetchCategories(apiKey, organizationId, token);
                    setCategories(categoriesData);
                    const measureUnitsData = await fetchMeasureUnits(apiKey, organizationId, token);
                    setMeasureUnits(measureUnitsData);
                }
            } catch (error) {
                console.error('Failed to fetch categories and measure units:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [importType, apiKey, organizationId, token]);

    // Handle import type change
    const handleImportTypeChange = (event) => {
        setImportType(event.target.value);
        setRows([]);
        setColumns([]);
        setMessage('');
        setErrors({});

        // Set field descriptions and schema based on import type
        const selectedSchema = getSchema(event.target.value);
        setFieldDescriptions(getFieldDescriptions(event.target.value));
        setSchema(selectedSchema);

        // Set up columns
        const columnKeys = Object.keys(selectedSchema);
        setColumns(columnKeys);
    };

    // Define schemas for different import types
    const getSchema = (type) => {
        const schemas = {
            OwnerData: {
                ownerType: 'string',
                companyName: 'string',
                companyBRN: 'string',
                birthday: 'datetime',
                email: 'string',
                firstname: 'string',
                surname: 'string',
                number: 'string',
                'address.street': 'string',
                'address.zipCode': 'string',
                'address.city': 'string',
                'address.country': 'string',
                entityType: 'array',
            },
            ItemData: {
                name: 'string',
                category: 'string', // Now expects category name
                subcategory: 'string', // Now expects subcategory name
                lastBoughtPrice: 'double',
                stockItem: 'bool',
                isSaleItem: 'bool',
                salesDependencies: 'string',
                bruttoPrice: 'double',
                netPrice: 'double',
                taxPercentage: 'double',
                quantity: 'int32',
                warningQuantity: 'int32',
                showInDashboard: 'bool',
                measureUnit: 'string', // Now expects measure unit name
            },
            // Add more schemas as needed
        };

        return schemas[type];
    };

    // Get field descriptions for tooltips or guidance
    const getFieldDescriptions = (type) => {
        const descriptions = {
            OwnerData: {
                ownerType: 'ownerType',
                companyName: 'companyName',
                companyBRN: 'companyBRN',
                birthday: 'birthday',
                email: 'email',
                firstname: 'firstname',
                surname: 'surname',
                number: 'number',
                'address.street': 'addressStreet',
                'address.zipCode': 'addressZipCode',
                'address.city': 'addressCity',
                'address.country': 'addressCountry',
                entityType: 'entityType',
            },
            ItemData: {
                name: 'name',
                category: 'category',
                subcategory: 'subcategory',
                lastBoughtPrice: 'lastBoughtPrice',
                stockItem: 'stockItem',
                isSaleItem: 'isSaleItem',
                salesDependencies: 'salesDependencies',
                bruttoPrice: 'bruttoPrice',
                netPrice: 'netPrice',
                taxPercentage: 'taxPercentage',
                quantity: 'quantity',
                warningQuantity: 'warningQuantity',
                showInDashboard: 'showInDashboard',
                measureUnit: 'measureUnit',
            },
            // Add more descriptions as needed
        };

        return descriptions[type];
    };

    // Handle paste data from clipboard
    const handlePasteFromClipboard = async () => {
        try {
            const clipboardData = await navigator.clipboard.readText();

            if (!clipboardData) {
                setMessage(t('DataImportTable.messages.clipboardEmpty'));
                return;
            }

            parsePastedData(clipboardData);
        } catch (error) {
            console.error(error);
            setMessage(t('DataImportTable.messages.clipboardError'));
        }
    };

    // Parse and process the pasted data
    const parsePastedData = (dataString) => {
        Papa.parse(dataString, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                const { data, errors } = results;

                if (errors.length) {
                    setMessage(t('DataImportTable.messages.parsingError', { error: errors[0].message }));
                    return;
                }

                // Map data to rows with unique IDs
                const newRows = data.map((row, index) => ({
                    id: index,
                    ...row,
                }));

                setRows(newRows);

                // Validate data
                validateAllRows(newRows);
            },
            error: (error) => {
                console.error(error);
                setMessage(t('DataImportTable.messages.importFailure'));
            },
        });
    };

    // Handle paste event from keyboard (Ctrl+V or Cmd+V)
    const handlePasteEvent = (event) => {
        event.preventDefault();

        const clipboardData = event.clipboardData.getData('Text');

        if (!clipboardData) {
            setMessage(t('DataImportTable.messages.clipboardEmpty'));
            return;
        }

        parsePastedData(clipboardData);
    };

    // Validate all rows
    const validateAllRows = (data) => {
        const newErrors = {};
        data.forEach((row, rowIndex) => {
            const rowErrors = validateRow(row);
            if (Object.keys(rowErrors).length > 0) {
                newErrors[rowIndex] = rowErrors;
            }
        });
        console.log(data);
        setErrors(newErrors);
    };

    // Validate a single row
    const validateRow = (row) => {
        const rowErrors = {};

        for (const key of columns) {
            const value = row[key];
            const type = schema[key];

            if (value === undefined || value === null || value === '') {
                continue; // Skip empty values (handle required fields if needed)
            }

            switch (type) {
                case 'string':
                    // Additional validation for specific fields
                    if (importType === 'ItemData') {
                        if (key === 'category') {
                            const categoryExists = categories.some(
                                (cat) => cat.categoryName === value
                            );
                            if (!categoryExists) {
                                rowErrors[key] = t('DataImportTable.fieldDescriptions.categoryNotFound');
                            } else {
                                row.categoryId = categories.find(
                                    (cat) => cat.categoryName === value
                                ).categoryId;
                            }
                        }
                        if (key === 'subcategory' && row['category']) {
                            const category = categories.find(
                                (cat) => cat.categoryName === row['category']
                            );
                            const subcategoryExists =
                                category &&
                                category.subcategories.some(
                                    (sub) => sub.subcategoryName === value
                                );
                            if (!subcategoryExists) {
                                rowErrors[key] = t('DataImportTable.fieldDescriptions.subcategoryNotFound');
                            } else {
                                row.subcategoryId = category.subcategories.find(
                                    (sub) => sub.subcategoryName === value
                                ).subcategoryId;
                            }
                        }
                        if (key === 'measureUnit') {
                            const measureUnitExists = measureUnits.some(
                                (unit) => unit.nameLong === value || unit.nameShort === value
                            );
                            if (!measureUnitExists) {
                                rowErrors[key] = t('DataImportTable.fieldDescriptions.measureUnitNotFound');
                            } else {
                                row.measureUnitId = measureUnits.find(
                                    (unit) => unit.nameLong === value || unit.nameShort === value
                                ).id;
                            }
                        }
                    }
                    break;
                case 'bool':
                    if (
                        value.toString().toLowerCase() !== 'true' &&
                        value.toString().toLowerCase() !== 'false'
                    ) {
                        rowErrors[key] = t('DataImportTable.fieldDescriptions.invalidBoolean');
                    }
                    break;
                case 'int32':
                    if (isNaN(parseInt(value, 10))) {
                        rowErrors[key] = t('DataImportTable.fieldDescriptions.invalidInteger');
                    }
                    break;
                case 'double':
                    if (isNaN(parseFloat(value))) {
                        rowErrors[key] = t('DataImportTable.fieldDescriptions.invalidNumber');
                    }
                    break;
                case 'datetime':
                    if (isNaN(new Date(value).getTime())) {
                        rowErrors[key] = t('DataImportTable.fieldDescriptions.invalidDate');
                    }
                    break;
                case 'array':
                    // Assuming comma-separated values
                    break;
                default:
                    break;
            }
        }

        return rowErrors;
    };

    // Handle cell value change
    const handleCellChange = (rowIndex, key, value) => {
        const updatedRows = [...rows];
        updatedRows[rowIndex] = { ...updatedRows[rowIndex], [key]: value };
        setRows(updatedRows);

        // Validate the updated row
        const rowErrors = validateRow(updatedRows[rowIndex]);
        const updatedErrors = { ...errors };
        if (Object.keys(rowErrors).length > 0) {
            updatedErrors[rowIndex] = rowErrors;
        } else {
            delete updatedErrors[rowIndex];
        }
        setErrors(updatedErrors);
    };

    // Handle data submission
    const handleSubmit = async () => {
        if (rows.length === 0) {
            setMessage(t('DataImportTable.messages.noData'));
            return;
        }

        if (Object.keys(errors).length > 0) {
            setMessage(t('DataImportTable.messages.fixErrors'));
            return;
        }

        setImporting(true);

        const processedData = rows.map((row) => {
            const newRow = { ...row };
            delete newRow.id; // Remove the id property
            // Convert types according to schema
            for (const key in schema) {
                if (schema.hasOwnProperty(key)) {
                    let value = newRow[key];
                    if (value === undefined || value === null || value === '') {
                        continue;
                    }

                    switch (schema[key]) {
                        case 'bool':
                            newRow[key] = value.toString().toLowerCase() === 'true';
                            break;
                        case 'int32':
                            newRow[key] = parseInt(value, 10);
                            break;
                        case 'double':
                            newRow[key] = parseFloat(value);
                            break;
                        case 'datetime':
                            newRow[key] = new Date(value).toISOString();
                            break;
                        case 'array':
                            newRow[key] = value.split(',').map((item) => item.trim());
                            break;
                        default:
                            break;
                    }
                }
            }

            // Build nested 'address' object from flat fields
            const addressFields = ['address.street', 'address.zipCode', 'address.city', 'address.country'];
            const address = {};
            for (const field of addressFields) {
                if (newRow[field]) {
                    const key = field.split('.')[1];
                    address[key] = newRow[field];
                    delete newRow[field]; // Remove flat field
                }
            }
            newRow.address = address;

            // Add organizationId and archived fields
            newRow.organizationId = organizationId;
            newRow.archived = false;
            return newRow;
        });

        try {
            const response = await importData(processedData, importType, apiKey, organizationId, token);
            if (response.status === 200 || response.status === 201) {
                setMessage(t('DataImportTable.messages.importSuccess', { count: processedData.length }));
                // Reset the component state
                setRows([]);
                setErrors({});
            } else {
                setMessage(t('DataImportTable.messages.importFailure'));
            }
        } catch (error) {
            console.error(error);
            const serverMessage =
                error.response?.data?.message || t('DataImportTable.messages.importFailure');
            setMessage(serverMessage);
        } finally {
            setImporting(false);
        }
    };

    // Close snackbar message
    const handleCloseSnackbar = () => {
        setMessage('');
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                {t('DataImportTable.importData')}
            </Typography>

            {/* Import Type Selection */}
            <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="import-type-label">{t('DataImportTable.importType')}</InputLabel>
                <Select
                    labelId="import-type-label"
                    value={importType}
                    label={t('DataImportTable.importType')}
                    onChange={handleImportTypeChange}
                >
                    <MenuItem value="OwnerData">{t('DataImportTable.businessPartners')}</MenuItem>
                    <MenuItem value="ItemData">{t('DataImportTable.items')}</MenuItem>
                    {/* Add more options if needed */}
                </Select>
            </FormControl>

            {/* Collapsible Field Descriptions */}
            {fieldDescriptions && (
                <Box mt={2}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="field-descriptions-content"
                            id="field-descriptions-header"
                        >
                            <Typography variant="subtitle1">{t('DataImportTable.fieldDescriptionsTitle')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table size="small">
                                <TableBody>
                                    {Object.keys(fieldDescriptions).map((key) => (
                                        <TableRow key={key}>
                                            <TableCell>
                                                <strong>{t(`DataImportTable.tableHeaders.${key}`)}</strong>
                                            </TableCell>
                                            <TableCell>{t(`DataImportTable.fieldDescriptions.${fieldDescriptions[key]}`)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )}

            {/* Paste Data Button */}
            {importType && (
                <Box mt={2} display="flex" alignItems="center" gap={2}>
                    <Button variant="contained" onClick={handlePasteFromClipboard}>
                        {t('DataImportTable.pasteData')}
                    </Button>
                    <Typography variant="body2" color="textSecondary">
                        {t('PasteInstructions')}
                        {/* You can add a translation key for this entire sentence */}
                        or click on the table and press <strong>Ctrl+V</strong> (Windows) or{' '}
                        <strong>Cmd+V</strong> (Mac)
                    </Typography>
                </Box>
            )}

            {/* Data Table */}
            {importType && (
                <Box mt={2}>
                    <StyledTableContainer
                        component={Paper}
                        ref={tableRef}
                        onPaste={handlePasteEvent}
                    >
                        <Table
                            size="small"
                            aria-label="data table"
                            stickyHeader
                            sx={{ minWidth: 750 }}
                        >
                            <TableHead>
                                <TableRow>
                                    <StickyTableCell>{t('DataImportTable.tableHeaders.index')}</StickyTableCell>
                                    {columns.map((col, colIndex) => (
                                        <StyledTableCell key={col}>
                                            <Tooltip title={t(`DataImportTable.fieldDescriptions.${fieldDescriptions[col]}`) || ''}>
                                                <Typography variant="subtitle2" noWrap>
                                                    {t(`DataImportTable.tableHeaders.${col}`)}
                                                </Typography>
                                            </Tooltip>
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, rowIndex) => (
                                    <TableRow key={row.id}>
                                        <StickyTableCell>{rowIndex + 1}</StickyTableCell>
                                        {columns.map((col) => (
                                            <StyledTableCell key={col}>
                                                <Box display="flex" alignItems="center">
                                                    <StyledTextField
                                                        label={t(`DataImportTable.tableHeaders.${col}`)}
                                                        value={row[col] || ''}
                                                        onChange={(e) =>
                                                            handleCellChange(rowIndex, col, e.target.value)
                                                        }
                                                        error={
                                                            errors[rowIndex] && errors[rowIndex][col]
                                                                ? true
                                                                : false
                                                        }
                                                        variant="standard"
                                                        InputProps={{
                                                            disableUnderline: true,
                                                        }}
                                                    />
                                                    {errors[rowIndex] && errors[rowIndex][col] && (
                                                        <Tooltip
                                                            title={errors[rowIndex][col]}
                                                            placement="top"
                                                        >
                                                            <ErrorOutlineIcon
                                                                color="error"
                                                                fontSize="small"
                                                                sx={{ ml: 0.5 }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Box>
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </StyledTableContainer>
                </Box>
            )}

            {/* Buttons */}
            {rows.length > 0 && (
                <Box mt={2} display="flex" gap={2}>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleSubmit}
                        disabled={importing}
                    >
                        {importing ? <CircularProgress size={24} /> : t('DataImportTable.confirmImport')}
                    </Button>
                </Box>
            )}

            {/* Download Template */}
            <Button
                variant="outlined"
                onClick={async () => {
                    let data = [];
                    if (importType === 'OwnerData') {
                        data = [
                            {
                                ownerType: "company",
                                companyName: "Tech Innovators Inc.",
                                companyBRN: "BRN123456",
                                birthday: "", // Not applicable for companies
                                email: "contact@techinnovators.com",
                                firstname: "John",
                                surname: "Doe",
                                number: "+1234567890",
                                'address.street': "123 Innovation Drive",
                                'address.zipCode': "12345",
                                'address.city': "Techville",
                                'address.country': "USA",
                                entityType: "owner,vendor",
                            },
                            {
                                ownerType: "private",
                                companyName: "",
                                companyBRN: "",
                                birthday: "1985-05-15",
                                email: "jane.smith@example.com",
                                firstname: "Jane",
                                surname: "Smith",
                                number: "+0987654321",
                                'address.street': "456 Elm Street",
                                'address.zipCode': "67890",
                                'address.city': "Mapleton",
                                'address.country': "USA",
                                entityType: "customer",
                            },
                            // Add more sample records as needed
                        ];
                    } else if (importType === 'ItemData') {
                        data = [
                            {
                                name: "UltraWidget 3000",
                                category: "Gadgets",
                                subcategory: "Widgets",
                                lastBoughtPrice: 250.00,
                                stockItem: true,
                                isSaleItem: true,
                                salesDependencies: "",
                                bruttoPrice: 299.99,
                                netPrice: 249.99,
                                taxPercentage: 20.00,
                                quantity: 50,
                                warningQuantity: 10,
                                showInDashboard: true,
                                measureUnit: "Piece",
                            },
                            {
                                name: "MegaGizmo Pro",
                                category: "Gadgets",
                                subcategory: "Gizmos",
                                lastBoughtPrice: 500.00,
                                stockItem: true,
                                isSaleItem: true,
                                salesDependencies: "",
                                bruttoPrice: 599.99,
                                netPrice: 499.99,
                                taxPercentage: 20.00,
                                quantity: 20,
                                warningQuantity: 5,
                                showInDashboard: true,
                                measureUnit: "Piece",
                            },
                            // Add more sample items as needed
                        ];
                    }
                    // Add more conditions for other import types if needed

                    // Convert the data array to CSV format using Papa.unparse
                    const csv = Papa.unparse(data);

                    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${importType}_template.csv`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }}
                sx={{ mt: 2 }}
            >
                {t('DataImportTable.downloadTemplate')}
            </Button>

            {/* Snackbar for messages */}
            <Snackbar
                open={!!message}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="info"
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default DataImportTable;
