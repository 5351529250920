// src/services/PaymentReminderService.js
import axiosInstance, {
    setApiKeyHeader,
    setOrganizationIdHeader,
    setAuthHeader
} from './apiConfig/axiosConfig.js';

// Base URL for the payment reminder API
const API_BASE_URL = '/api/payment-reminders';

// Fetch all payment reminders
export async function fetchPaymentReminders(apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.get(API_BASE_URL);
    return response.data;
}

// Fetch a specific payment reminder by ID
export async function fetchPaymentReminderById(apiKey, organizationId, jwtToken, reminderId) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const url = `${API_BASE_URL}?id=${reminderId}`;
    const response = await axiosInstance.get(url);
    return response.data;
}

// Save a new payment reminder
export async function savePaymentReminder(paymentReminder, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const response = await axiosInstance.post(API_BASE_URL, paymentReminder);
    return response.data;
}

// Update an existing payment reminder
export async function updatePaymentReminder(paymentReminder, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const url = `${API_BASE_URL}?id=${paymentReminder.id}`;
    const response = await axiosInstance.put(url, paymentReminder);
    return response.data;
}

// Delete a payment reminder
export async function deletePaymentReminder(reminderId, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const url = `${API_BASE_URL}?id=${reminderId}`;
    const response = await axiosInstance.delete(url);
    return response.data;
}

export async function fetchUnPaidRemindersPerOwner(apiKey, organizationId, jwtToken, ownerId) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const url = `${API_BASE_URL}?status=unpaid&ownerId=${ownerId}`;
    const response = await axiosInstance.get(url);
    return response.data;
}



export async function fetchUnPaidReminders(apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    const url = `${API_BASE_URL}?status=unpaid`;
    const response = await axiosInstance.get(url);
    return response.data;
}


export async function fetchPaymentReminderPDF(reminderId, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    return axiosInstance.get(`${API_BASE_URL}/pdf?id=${reminderId}&action=getReminder`, { responseType: 'blob' });
};

export async function sendPaymentReminderEmail(reminderId, apiKey, organizationId, jwtToken) {
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);

    return axiosInstance.get(`${API_BASE_URL}/pdf?id=${reminderId}&action=sendReminder`);
};

// Export all functions as a default object
export default {
    fetchPaymentReminders,
    fetchPaymentReminderById,
    savePaymentReminder,
    updatePaymentReminder,
    deletePaymentReminder,
    fetchUnPaidRemindersPerOwner,
    fetchUnPaidReminders,
    fetchPaymentReminderPDF,
    sendPaymentReminderEmail
};
