// src/pages/OwnersPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllOwners, fetchCustomers, fetchOwners, fetchVendor } from '../services/ownerService';
import { Chip } from '@mui/material';  // <-- Add this line
import { Box, Button, Paper, Typography, LinearProgress } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';  // <-- add this line
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';  // <-- add this line
import ViewModuleIcon from '@mui/icons-material/ViewModule';  // <-- add this line
import ViewListIcon from '@mui/icons-material/ViewList';  // <-- add this line
import GenericCardView from '../components/GenericCardView';
import GenericTableView from '../components/GenericTableView';
import { useTheme, useMediaQuery } from '@mui/material';  // <-- add this line
import { usePreferences } from '../context/UserPreferencesContext';
import { usePermissions } from '../context/PermissionsContext'; // <-- Add this line


const OwnersPage = () => {
  const { t } = useTranslation();  // Hook for translation
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [viewMode, setViewMode] = useState(isMobile ? 'card' : 'table');
  const [owners, setOwners] = useState([]);
  const { apiKey, organizationId, token } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const jwtToken = token;
  const { devicePreferences, updateDevicePreferences } = usePreferences();
  const [activeFilter, setActiveFilter] = useState('All');
  const { entitlements } = usePermissions();

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const data = await getAllOwners(apiKey, organizationId, jwtToken);
      setOwners(data);
      setIsLoading(false);
    })();
  }, [apiKey, organizationId, jwtToken]);


  // Update local state when context updates
  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);


  const handleRowClick = (ownerId) => {
    navigate(`/owners/${ownerId}`);
  };

  const handleAddOwner = () => {
    navigate('/owners/new');
  };

  const toggleViewMode = () => {
    updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
  };

  const filteredOwners = owners.filter(owner => {
    if (activeFilter === 'All') {
      return true;
    }
    return owner.entityType.includes(activeFilter);
  });


  // Check if "horse-management" feature is enabled
  const hasHorseManagement = entitlements.some(entitlement =>
    entitlement.features.some(feature => feature.name === 'horse-management' && feature.status === 'enabled')
  );

  const getEntityTypes = (entityType) => {
    let entityTypes = [];
    if (entityType.includes('owner')) {
      entityTypes.push(t('OwnersPage.owner'));
    }
    if (entityType.includes('customer')) {
      entityTypes.push(t('OwnersPage.customer'));
    }
    if (entityType.includes('vendor')) {
      entityTypes.push(t('OwnersPage.vendor'));
    }
    return entityTypes.join(', ');
  };



  const getOwnerType = (ownerType) => {
    if (ownerType === 'private') {
      return t('OwnersPage.private');
    }
    if (ownerType === 'company') {
      return t('OwnersPage.company');
    }
    return ownerType;
  };

  const getName = (owner) => {
    if (owner.ownerType === 'company') {
      return owner.companyName;
    }
    return `${owner.firstname} ${owner.surname}`;
  };

  const transformData = () => {
    return {
      headers: [
        { key: 'partnerType', label: t('OwnersPage.table.partnerType'), display: { value: true, chipColor: true } },
        { key: 'entityTypes', label: t('OwnersPage.table.entityType'), display: { value: true, chipColor: true } },
        { key: 'name', label: t('OwnersPage.table.name'), display: { value: true, chipColor: false } },
      ],
      rows: filteredOwners.map(owner => ({
        id: owner.id,
        partnerType: { value: getOwnerType(owner.ownerType), icon: null },
        entityTypes: { value: getEntityTypes(owner.entityType), icon: null },
        name: { value: getName(owner), icon: null },
      }))
    };
  };



  if (isLoading) {
    return (
      <div>
        <Typography variant="h6">{t('OwnersPage.waitingText')}</Typography>  {/* Add this line for the label */}
        <LinearProgress />
      </div>
    );  // Render loading spinner if isLoading is true
  }
  return (

    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <div>
        <Typography variant="h4" gutterBottom>
          {t('OwnersPage.title')}
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddOwner}
            startIcon={<AddIcon />}
            style={{ marginTop: '1rem', marginBottom: '1rem' }}
          >
            {t('OwnersPage.addButton')}
          </Button>
          <IconButton onClick={toggleViewMode} color="primary">
            {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
          </IconButton>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Chip
            label={t('OwnersPage.all')}
            color={activeFilter === 'All' ? 'primary' : 'default'}
            onClick={() => setActiveFilter('All')}
            sx={{ marginRight: 1 }}
          />
          {hasHorseManagement && (  // <-- Conditionally render the Owners chip
            <Chip
              label={t('OwnersPage.owners')}
              color={activeFilter === 'owner' ? 'primary' : 'default'}
              onClick={() => setActiveFilter('owner')}
              sx={{ marginRight: 1 }}
            />
          )}
          <Chip
            label={t('OwnersPage.customers')}
            color={activeFilter === 'customer' ? 'primary' : 'default'}
            onClick={() => setActiveFilter('customer')}
            sx={{ marginRight: 1 }}
          />
          <Chip
            label={t('OwnersPage.vendors')}
            color={activeFilter === 'vendor' ? 'primary' : 'default'}
            onClick={() => setActiveFilter('vendor')}
            sx={{ marginRight: 1 }}
          />
        </Box>

        {isLoading ? (
          <LinearProgress />
        ) : viewMode === 'table' ? (
          <GenericTableView structure={transformData()} onClick={handleRowClick} />
        ) : (
          <GenericCardView structure={transformData()} onClick={handleRowClick} />
        )}
      </div>
    </Paper>
  );
};

export default OwnersPage;