import React, { useState, useEffect } from 'react';
import {
  Paper,
  Button,
  Chip,
  Box,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';
import { fetchAllCostEstimations } from '../services/CostEstimationService';
import { useAuth } from '../context/AuthContext';
import { usePreferences } from '../context/UserPreferencesContext';
import GenericCardView from '../components/GenericCardView';
import GenericTableView from '../components/GenericTableView';
import SnackbarAlert from '../components/SnackbarAlert';
import { approveDocument, rejectDocument } from '../services/BusinessDocumentService';

const CostEstimationsListPage = () => {
  const { t } = useTranslation();
  const { apiKey, organizationId, token, userId } = useAuth();
  const [estimations, setEstimations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [viewMode, setViewMode] = useState(isMobile ? 'card' : 'table');
  const { devicePreferences, updateDevicePreferences } = usePreferences();

  useEffect(() => {
    const fetchEstimations = async () => {
      try {
        const estimationData = await fetchAllCostEstimations(apiKey, organizationId, token, userId);
        setEstimations(estimationData);
      } catch (error) {
        console.error("Error fetching cost estimation data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEstimations();
  }, [apiKey, organizationId, token, userId]);

  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);

  const navigateToDetail = (estimationId) => {
    navigate(`costEstimations/detail/${estimationId}`);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const renderStatus = (estimation) => {
    if (estimation.cancel && estimation.cancel === true) {
      return <Chip icon={<WarningAmberIcon />} label={t("costEstimationsListPage.cancelled")} color="secondary" />;
    } else if (estimation.status === 'draft') {
      return <Chip icon={<CancelIcon />} label={t("costEstimationsListPage.draft")} color="default" />;
    } else if (estimation.status === 'approved') {
      return <Chip icon={<CheckCircleIcon />} label={t("costEstimationsListPage.approved")} color="primary" />;
    } else if (estimation.status === 'Completed') {
      return <Chip icon={<CheckCircleIcon />} label={t("costEstimationsListPage.completed")} color="primary" />;
    } else {
      return <Chip icon={<CancelIcon />} label={t("costEstimationsListPage.unapproved")} color="error" />;
    }
  };

  const toggleViewMode = () => {
    updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
  };

  const approveEstimation = async (estimationId) => {
    try {
      await approveDocument(apiKey, organizationId, token, userId, estimationId, 'costEstimation');
      setSnackbar({ open: true, message: t("costEstimationsListPage.estimationApproved"), severity: 'success' });
      setEstimations(estimations.map(estimation => estimation.id === estimationId ? { ...estimation, status: 'approved' } : estimation));
    }
    catch (error) {
      console.error("Error approving cost estimation: ", error);
      setSnackbar({ open: true, message: t("costEstimationsListPage.errorApprovingEstimation"), severity: 'error' });
    }
  };

  const transformData = () => {
    return {
      headers: [
        { key: 'estimationNumber', label: t("costEstimationsListPage.estimationNumber"), display: { value: true, chipColor: false } },
        { key: 'customerName', label: t("costEstimationsListPage.customerName"), display: { value: true, chipColor: false } },
        { key: 'issueDate', label: t("costEstimationsListPage.issueDate"), display: { value: true, chipColor: false } },
        { key: 'totalAmount', label: t("costEstimationsListPage.totalAmount"), display: { value: true, chipColor: false } },
        { key: 'status', label: t("costEstimationsListPage.status"), display: { value: true, chipColor: false } }
      ],
      rows: estimations.map(estimation => ({
        id: estimation.id,
        estimationNumber: { value: estimation.estimationNumber, icon: null },
        customerName: { value: estimation.customerName, icon: null },
        issueDate: { value: new Date(estimation.issueDate).toLocaleDateString(), icon: null },
        totalAmount: { value: estimation.totalAmount, icon: null },
        status: { value: renderStatus(estimation), icon: null },
        actions: [
          {
            icon: <CheckCircleIcon />,
            handler: estimation.status !== 'draft' ? null : () => approveEstimation(estimation.id),
            disabled: estimation.status !== 'draft' || estimation.cancel,
            label: t("costEstimationsListPage.approve")
          }
        ]
      }))
    };
  };

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} component={Link} to="/sales-process/costEstimations/detail/new">
          {t("costEstimationsListPage.addNewEstimation")}
        </Button>
        <IconButton onClick={toggleViewMode} color="primary">
          {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
        </IconButton>
      </Box>
      {loading ? (
        <div style={{ width: '100%', marginTop: 20 }}>
          <LinearProgress />
        </div>
      ) : viewMode === 'table' ? (
        <GenericTableView structure={transformData()} onClick={navigateToDetail} />
      ) : (
        <GenericCardView structure={transformData()} onClick={navigateToDetail} />
      )}

      <SnackbarAlert
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </Paper>
  );
};

export default CostEstimationsListPage;
