// src/components/SignUp.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, LinearProgress, Button } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { verifyToken } from '../services/tokenService';
import { useTranslation } from 'react-i18next';
import { policies } from '../context/authPolicies';
import { msalConfig, loginRequest } from '../context/authConfig';
import { signUpUser } from '../services/authService';
import { jwtDecode } from 'jwt-decode';
import { useMsal } from '@azure/msal-react';
import { setPolicyBasedOnUrl } from '../context/setPolicyBasedOnUrl';

const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [organizationId, setorganizationId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const { instance } = useMsal();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const params = new URLSearchParams(location.search);
  const oldemail = params.get('email');
  const [errorMessage, setErrorMessage] = useState("");

  // Parse URL parameters
  useEffect(() => {
    const tokenParam = params.get('token');
    const emailParam = params.get('email');
    const organizationIdParam = params.get('organizationId');
    setToken(tokenParam);
    setEmail(emailParam);
    setorganizationId(organizationIdParam);

    // Verify the token with your backend
    const validateToken = async () => {
      const tokenValid = await verifyToken(tokenParam, organizationIdParam);
      setIsTokenValid(tokenValid);
      setIsLoading(false);
    };

    validateToken();
  }, [location.search, isLoading, isTokenValid]);


  useEffect(() => {
    if (!isLoading && isTokenValid && !isAuthenticating) {
      //redirectToAADB2CSignUp();
    }
  }, [isLoading, isTokenValid, isAuthenticating]);

  const initiateSignUp = () => {
    if (isAuthenticating) {
      return; // Prevent multiple auth requests
    }
    setIsAuthenticating(true);
    let signUpType = params.get('signuptype');

    if (!signUpType) {
      signUpType = 'default';
    }
  
    // Include necessary state parameters
    const loginRequestWithState = {
      ...loginRequest,
      state: JSON.stringify({ signUpType, token, organizationId, email })
    };
  
    instance.loginRedirect(loginRequestWithState);
  };
  


  const redirectToAADB2CSignUp = () => {
    if (isAuthenticating) {
      return; // Prevent multiple auth requests
    }
    setIsAuthenticating(true);

    instance.loginPopup(loginRequest)
      .then((authResult) => {
        console.log('Sign up successful', authResult);
        const userData = extractUserData(authResult);
        signUpUser(userData);
        setIsAuthenticating(false);
        navigate('/', { replace: true });
      })
      .catch(e => {
        console.error('Sign up error:', e);
        setErrorMessage("An error occurred during sign up. Please try again."); // Set error message
        setIsAuthenticating(false);
      });
  };


  const extractUserData = (authResult) => {
    // Decode the ID token
    const decodedToken = jwtDecode(authResult.idToken);

    // Extract the email address from the token
    const email = decodedToken.emails ? decodedToken.emails[0] : '';

    // Return the necessary user data
    return {
      homeAccountId: authResult.account.homeAccountId,
      azSubject: decodedToken.sub,
      email: email,
      oldEmail: oldemail,
      organizationId: organizationId,
      // Include other data as needed
    };
  };

  if (isLoading) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h4" align="center" gutterBottom>
          {t('SignUp.verifyingToken')}
        </Typography>
        <LinearProgress />
      </Container>
    );
  }

  if (!isTokenValid) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h4" align="center" gutterBottom>
          {t('SignUp.invalidOrExpiredToken')}
        </Typography>
      </Container>
    );
  }

  /*if (errorMessage) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h6" color="error" align="center" gutterBottom>
          {errorMessage}
        </Typography>
      </Container>
    );
  }*/

  // Render a loading or redirect message as the user will be redirected
  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        {t('SignUp.title')}
      </Typography>
      {isLoading ? (
        <LinearProgress />
      ) : (
        isTokenValid ? (
          <Button fullWidth variant="contained" color="primary" onClick={initiateSignUp}>
            {t('SignUp.signUpButton')}
          </Button>
        ) : (
          <Typography variant="h4" align="center" gutterBottom>
            {t('SignUp.invalidOrExpiredToken')}
          </Typography>
        )
      )}
      {errorMessage && (
        <Typography variant="h6" color="error" align="center" gutterBottom>
          {errorMessage}
        </Typography>
      )}
    </Container>
  );
};


export default SignUp;
