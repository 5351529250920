// src/pages/inventoryManagement/itemManagement/ItemListPage.js

import React, { useState, useEffect } from 'react';
import { Box, Button, Paper, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from '../../../context/AuthContext';
import { getAllItems } from '../../../services/itemService';
import { useTranslation } from 'react-i18next';
//import { categories } from './ItemUtils';
import LinearProgress from '@mui/material/LinearProgress';
import ViewModuleIcon from '@mui/icons-material/ViewModule';  // <-- add this line
import ViewListIcon from '@mui/icons-material/ViewList';  // <-- add this line
import GenericCardView from '../../../components/GenericCardView';
import GenericTableView from '../../../components/GenericTableView';
import IconButton from '@mui/material/IconButton';
import { useTheme, useMediaQuery } from '@mui/material';
import { fetchCategories } from '../../../services/categoryService';
import { usePreferences } from '../../../context/UserPreferencesContext';

const ItemListPage = () => {
  const navigate = useNavigate();
  const { apiKey, organizationId, token } = useAuth();
  const [categories, setCategories] = useState([]); 
  const jwtToken = token;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const { t } = useTranslation(); // Hook for translation
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [viewMode, setViewMode] = useState(isMobile ? 'card' : 'table');
  const { devicePreferences, updateDevicePreferences } = usePreferences();

  const getCategoryLabel = (categoryKey) => {
    return categories.find(cat => cat.categoryId === categoryKey)?.categoryName || '';
  };

  useEffect(() => {
    setLoading(true);

    // Combine the fetch calls into a single promise array
    Promise.all([
      fetchCategories(apiKey, organizationId, jwtToken),
      getAllItems(apiKey, organizationId, jwtToken)
    ]).then(([categoriesData, itemsData]) => {
      // Set categories and items once they are both fetched
      setCategories(categoriesData);
      setItems(itemsData);
    }).catch(err => {
      console.error('Failed to fetch items:', err);
    }).finally(() => {
      setLoading(false);
    });
  }, [apiKey]);

  // Update local state when context updates
  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);


  const handleAddItem = () => {
    navigate('/inventory/item/new');
  };

  const handleRowClick = (itemId) => {
    navigate(`/inventory/item/${itemId}`);
  };


  const toggleViewMode = () => {
    updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
  };

  const transformData = () => {
    return {
      headers: [
        { key: 'name', label: t('itemListPage.itemName'), display: { value: true, chipColor: false } },
        { key: 'category', label: t('itemListPage.category'), display: { value: true, chipColor: false } },
        { key: 'quantity', label: t('itemListPage.quantity'), display: { value: true, chipColor: false } }
      ],
      rows: items.map(item => ({
        id: item.id,
        name: { value: item.name, icon: null },
        category: { value: getCategoryLabel(item.category), icon: null },
        quantity: { value: item.quantity, icon: null }
      }))
    };
  }

  return (
    <Box>
      <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddItem}>
            {t('itemListPage.addNewItem')}
          </Button>
          <IconButton onClick={toggleViewMode} color="primary">
            {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
          </IconButton>
        </Box>
        {loading ? (
          <div style={{ width: '100%', marginTop: 20 }}>
            <LinearProgress />
          </div>
        ) : viewMode === 'table' ? (
          <GenericTableView structure={transformData()} onClick={handleRowClick} />
        ) : (
          <GenericCardView structure={transformData()} onClick={handleRowClick} />
        )}
      </Paper>
    </Box>
  );
};

export default ItemListPage;
