// src/pages/VaccinationPage.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  LinearProgress,
  Switch,
  FormControlLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VaccinationModal from '../components/VaccinationModal';
import { fetchAllVaccinations } from '../services/VaccinationService';  // Imported the service function
import { useAuth } from '../context/AuthContext';  // To get the authentication tokens and IDs
import { getAllHorses, getHorsesByOwnerEmail, getHorsesByUserId } from '../services/horseService';  // Imported the service function
import { fetchAllVeterinarians } from '../services/VeterinarianService';  // Imported the service function
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDateLocale } from '../hooks/useDateLocale';
import { formatRelative, format } from 'date-fns';
import GenericTableView from '../components/GenericTableView';
import GenericCardView from '../components/GenericCardView';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import IconButton from '@mui/material/IconButton';
import { useTheme, useMediaQuery } from '@mui/material';
import { useMemo } from 'react';
import { usePreferences } from '../context/UserPreferencesContext';


const VaccinationPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [vaccinations, setVaccinations] = useState([]);  // To store vaccinations from the backend
  const { apiKey, organizationId, token, ownerId, userEmail, userType, userId } = useAuth();
  const [horses, setHorses] = useState([]);
  const [vets, setVets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const dateLocale = useDateLocale();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [viewMode, setViewMode] = useState(isMobile ? 'card' : 'table');
  const [viewOwnVaccine, setViewOwnVaccine] = useState(true);
  const [ownerHorses, setOwnerHorses] = useState([]);
  const { devicePreferences, updateDevicePreferences } = usePreferences();


  const vaccinationTypesMapping = {
    "Tetanus": t('Vaccination.Types.Tetanus'),
    "EquineInfluenza": t('Vaccination.Types.EquineInfluenza'),
    "EquineHerpesvirus": t('Vaccination.Types.EquineHerpesvirus'),
    "WestNileVirus": t('Vaccination.Types.WestNileVirus'),
    "Rabies": t('Vaccination.Types.Rabies'),
    "EquineViralArteritis": t('Vaccination.Types.EquineViralArteritis'),
    "Rotavirus": t('Vaccination.Types.Rotavirus')
  };

  const toggleViewMode = () => {
    updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);  // Set loading to true
        const data = await fetchAllVaccinations(apiKey, organizationId, token, ownerId);
        setVaccinations(data);

        let horseData;
        if (userType === 'admin') {
          horseData = await getAllHorses(apiKey, organizationId, token);
        } else {
          horseData = await getHorsesByUserId(userId, apiKey, organizationId, token);
        }

        // Assuming you have these service functions created
        setHorses(horseData.filter(horse => horse.permissions.includes('handleVaccine')));
        setOwnerHorses(horseData.filter(horse => horse.ownerId === ownerId));

        const vetData = await fetchAllVeterinarians(apiKey, organizationId, token);
        setVets(vetData);

      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [apiKey, organizationId, token, ownerId, isModalOpen]);


  // Update local state when context updates
  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);




  const handleModalClose = () => {
    setModalOpen(false);
    setCurrentRecord(null); // reset the current record when the modal is closed
  };

  const handleAddVaccination = () => {
    setCurrentRecord(null); // no current record for adding a new vaccination
    setModalOpen(true);
  };

  const handleEditVaccination = (recordId) => {
    const record = vaccinations.find(vaccination => vaccination.id === recordId);
    setCurrentRecord(record);
    setModalOpen(true);
  };

  const transformData = (vaccineData) => {
    return {
      headers: [{
        key: 'horseName', label: t('Vaccination.Page.TableHeaders.HorseName'), display: { value: true, chipColor: false }
      }, {
        key: 'vaccinationName', label: t('Vaccination.Page.TableHeaders.VaccinationName'), display: { value: true, chipColor: false }
      }, {
        key: 'vaccinationDate', label: t('Vaccination.Page.TableHeaders.VaccinationDate'), display: { value: true, chipColor: false }
      }, {
        key: 'nextVaccinationDate', label: t('Vaccination.Page.TableHeaders.NextVaccinationDate'), display: { value: true, chipColor: false }
      },
      ],
      rows: vaccineData.map(vaccination => ({
        id: vaccination.id,
        horseName: {
          value: horses.find(horse => horse.id === vaccination.horseId)?.name || t('Vaccination.Page.UnknownHorse'),
          icon: null
        },
        vaccinationName: {
          value: vaccinationTypesMapping[vaccination.vaccinationName],
          icon: null
        },
        vaccinationDate: {
          value: vaccination.vaccinationDate ? format(new Date(vaccination.vaccinationDate), 'P', { locale: dateLocale }) : t('Vaccination.Page.NoDate'),

          icon: null
        },
        nextVaccinationDate: {
          value: vaccination.nextVaccinationDate ? format(new Date(vaccination.nextVaccinationDate), 'P', { locale: dateLocale }) : t('Vaccination.Page.NoDate'),
          icon: null
        }
      }))
    };
  };

  const filteredVaccinations = useMemo(() => {
    if (!viewOwnVaccine) {
      return vaccinations;
    }

    return vaccinations.filter(vaccination => ownerHorses.some(horse => horse.id === vaccination.horseId));
  }, [viewOwnVaccine, vaccinations, ownerHorses]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
      <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
        <div>
          <Typography variant="h4" gutterBottom>
            {t('Vaccination.Page.Title')}
          </Typography>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddVaccination}
            >
              {t('Vaccination.Page.AddNewVaccinationButton')}
            </Button>
            <FormControlLabel
              control={<
                Switch
                checked={viewOwnVaccine}
                onChange={() => setViewOwnVaccine(!viewOwnVaccine)}
                name="viewMode"
                color="primary"
              />
              }
              label={t('veterinarianVisits.Page.viewOwnVisits')}
            />
            <IconButton onClick={toggleViewMode} color="primary">
              {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
            </IconButton>
          </Box>
          {isLoading ? (
            <LinearProgress />
          ) : viewMode === 'table' ? (
            <GenericTableView structure={transformData(filteredVaccinations)} onClick={handleEditVaccination} />
          ) : (
            <GenericCardView structure={transformData(filteredVaccinations)} onClick={handleEditVaccination} />
          )}

          <VaccinationModal isOpen={isModalOpen} onClose={handleModalClose} record={currentRecord} horses={horses} vets={vets} />
        </div>
      </Paper>
    </LocalizationProvider>
  );
};

export default VaccinationPage;
