// src/services/TeacherSettingsService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig';

const API_BASE_URL = '/api/teacher-settings';

export async function fetchTeacherSettings(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(API_BASE_URL);
  return response.data;
}

export async function saveTeacherSettings(teacherData, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(API_BASE_URL, teacherData);
  return response.data;
}

export async function deleteTeacherSettings(teacherId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${teacherId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

export default {
  fetchTeacherSettings,
  saveTeacherSettings,
  deleteTeacherSettings
};
