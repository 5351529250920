// src/components/FarmDesigner/BoxItem.js
import React from 'react';
import { useDrop } from 'react-dnd';
import { Paper, Typography, IconButton, Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const BoxItem = React.memo(
    ({
        box,
        handleHorseDrop,
        handleHorseRemove,
        isPreview,
        showFoodPortions,
        foodSettings,
    }) => {
        const { t } = useTranslation(); // Initialize the translation function
        const [hovered, setHovered] = React.useState(false);
        const [{ isOver }, drop] = useDrop({
            accept: 'HORSE',
            drop: (horse) => handleHorseDrop(box.instanceId, horse),
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
            }),
        });

        // Helper function to get food and portion names
        const getFoodPortionsDisplay = () => {
            if (!box.horse || !box.horse.foodPortions || !foodSettings) return null;

            const rows = [];

            ['morning', 'midday', 'evening'].forEach((time) => {
                const foodsAtTime = [];

                Object.entries(box.horse.foodPortions).forEach(([foodId, portions]) => {
                    if (portions[time]) {
                        const foodItem = foodSettings.find((food) => food.id === foodId);
                        const foodName = foodItem ? foodItem.name : foodId;
                        let portionName = portions[time]; // Default to portion ID or name

                        if (foodItem && foodItem.portions) {
                            const portionItem = foodItem.portions.find(
                                (p) => p.id === portions[time]
                            );
                            if (portionItem) {
                                portionName = portionItem.printName || portionItem.name;
                            }
                        }

                        foodsAtTime.push(`${foodName}: ${portionName}`);
                    }
                });

                if (foodsAtTime.length > 0) {
                    rows.push(
                        <TableRow key={time}>
                            <TableCell sx={{ padding: '2px', borderBottom: 'none' }}>
                                <Typography variant="body2" sx={{ fontSize: '0.75em' }}>
                                    <strong>
                                        {`${time.charAt(0).toUpperCase() + time.slice(1)}`}
                                    </strong>
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ padding: '2px', borderBottom: 'none' }}>
                                <Typography variant="body2" sx={{ fontSize: '0.75em' }}>
                                    {foodsAtTime.join(', ')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    );
                }
            });

            return rows.length > 0 ? (
                <Table size="small">
                    <TableBody>{rows}</TableBody>
                </Table>
            ) : null;
        };

        return (
            <div ref={!isPreview ? drop : null} style={{ height: '100%' }}>
                <Paper
                    elevation={2}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    style={{
                        backgroundColor: isOver ? 'lightgreen' : 'white',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0.5em',
                        boxSizing: 'border-box',
                        cursor: !isPreview ? 'move' : 'default',
                        position: 'relative',
                        willChange: 'transform',
                        overflow: 'hidden',
                        border: isPreview ? '2px solid #000' : 'none',
                    }}
                >
                    {/* Conditional rendering of the header */}
                    {isPreview ? (
                        // In print preview mode, show horse name in header
                        box.horse ? (
                            <Typography
                                variant="subtitle1"
                                align="center"
                                sx={{ fontSize: '1em', fontWeight: 'bold' }}
                            >
                                {box.horse.name}
                            </Typography>
                        ) : (
                            <Typography
                                variant="subtitle1"
                                align="center"
                                sx={{ fontSize: '1em', fontWeight: 'bold' }}
                            >
                                {t('BoxItem.Empty')}
                            </Typography>
                        )
                    ) : (
                        // In editing mode, show box name in header
                        <Typography
                            variant="subtitle1"
                            align="center"
                            sx={{ fontSize: '1em', fontWeight: 'bold' }}
                        >
                            {box.name}
                        </Typography>
                    )}
                    {/* Content */}
                    <Box sx={{ textAlign: 'center', flexGrow: 1, overflowY: 'auto' }}>
                        {isPreview && box.horse && showFoodPortions && (
                            <Box sx={{ mt: 0.5 }}>
                                {getFoodPortionsDisplay()}
                            </Box>
                        )}
                        {!isPreview && box.horse && (
                            <Typography variant="body2" sx={{ fontSize: '0.9em' }}>
                                <strong>{t('BoxItem.Horse')}:</strong> {box.horse.name}
                            </Typography>
                        )}
                        {!box.horse && !isPreview && (
                            <Typography variant="body2" sx={{ fontSize: '0.9em' }}>
                                {t('BoxItem.NoHorseAssigned')}
                            </Typography>
                        )}
                    </Box>
                    {/* Remove Horse Button */}
                    {!isPreview && box.horse && (
                        <IconButton
                            size="small"
                            className="non-draggable"
                            onClick={(event) => {
                                event.stopPropagation();
                                handleHorseRemove(box.instanceId);
                            }}
                            style={{
                                position: 'absolute',
                                top: '0.25em',
                                right: '0.25em',
                                visibility: hovered ? 'visible' : 'hidden',
                            }}
                            aria-label={t('BoxItem.RemoveHorse')}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    )}
                </Paper>
            </div>
        );
    }
);

export default BoxItem;
