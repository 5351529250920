import React, { useState } from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const SplitTransferButton = ({ documentId, documentType, visibleOptions, onTransfer, disabled }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    // Define all possible options inside the component
    const allOptions = [
        {
            type: 'salesOrder',
            label: 'Sales Order',
            url: 'sales-orders',
        },
        {
            type: 'deliveryNote',
            label: 'Delivery Note',
            url: 'deliveryNotes',
        },
        {
            type: 'invoice',
            label: 'Invoice',
            url: 'invoices',
        },
        // Add more options as needed
    ];

    // Filter options based on visibleOptions prop
    const optionsToShow = allOptions.filter(option => visibleOptions.includes(option.type));

    const handleClick = () => {
        onTransfer(documentId, documentType, optionsToShow[selectedIndex].type, optionsToShow[selectedIndex].url);
    };

    const handleMenuItemClick = (event, index) => {
        // Navigate directly without changing the button label
        onTransfer(documentId, documentType, optionsToShow[index].type, optionsToShow[index].url);
        setAnchorEl(null);
    };

    const handleToggle = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <ButtonGroup variant="contained" color="primary" aria-label="split button" disabled={disabled}>
            <Button onClick={handleClick}>
                Transfer to {optionsToShow[selectedIndex].label}
            </Button>
            <Button
                size="small"
                aria-controls={anchorEl ? 'split-button-menu' : undefined}
                aria-expanded={anchorEl ? 'true' : undefined}
                aria-label="select transfer option"
                aria-haspopup="menu"
                onClick={handleToggle}
            >
                <ArrowDropDownIcon />
            </Button>
            <Menu
                id="split-button-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {optionsToShow.map((option, index) => (
                    <MenuItem
                        key={option.type}
                        onClick={(event) => handleMenuItemClick(event, index)}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </ButtonGroup>
    );
};

export default SplitTransferButton;
