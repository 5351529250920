// src/components/MealPopup.js
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Select, MenuItem, DialogActions, Button, FormControl, InputLabel } from '@mui/material';

const MealPopup = ({ open, handleClose }) => {
  const mealTypes = ['Müsli', 'Oats']; // You can expand this in the future
  const portions = ['Full', 'Half'];

  const initialMealState = { type: '', portion: '' };

  const [meal, setMeal] = useState({
    morning: {...initialMealState},
    midDay: {...initialMealState},
    evening: {...initialMealState},
  });

  const handleMealChange = (mealTime) => (event) => {
    const { name, value } = event.target;
    setMeal(prevMeal => ({
      ...prevMeal,
      [mealTime]: {
        ...prevMeal[mealTime],
        [name]: value
      }
    }));
  };

  const handleSubmit = () => {
    console.log(meal); // Log the meal or do whatever you want with it
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Define Horse Meal</DialogTitle>
      <DialogContent>
        {['morning', 'midDay', 'evening'].map(mealTime => (
          <div key={mealTime}>
            <h2>{mealTime}</h2>
            <FormControl fullWidth>
              <InputLabel>Meal Type</InputLabel>
              <Select
                name="type"
                value={meal[mealTime].type}
                onChange={handleMealChange(mealTime)}
              >
                {mealTypes.map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Portion</InputLabel>
              <Select
                name="portion"
                value={meal[mealTime].portion}
                onChange={handleMealChange(mealTime)}
              >
                {portions.map(portion => <MenuItem key={portion} value={portion}>{portion}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MealPopup;
