// src/pages/incomingPaymentPage/PaymentDetailPage.jsx

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Button, Container, Grid, TextField, Typography, MenuItem, Select, Autocomplete } from '@mui/material';
import { usePayment } from './usePayment'; // Import the custom hook
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { useDateLocale } from '../../hooks/useDateLocale';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const PaymentDetailPage = () => {
  const { paymentId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isEditMode = paymentId !== 'new';
  const {
    paymentData,
    handleInputChange,
    removePayment,
    handleAutocompleteChange,
    savePayment,
    owners,
    loading,
    error
  } = usePayment(paymentId);
  const dateLocale = useDateLocale();

  // Define payment methods with keys and labels
  const paymentMethods = [
    { key: 'creditCard', label: t('paymentMethod.creditCard') },
    { key: 'debitCard', label: t('paymentMethod.debitCard') },
    { key: 'cash', label: t('paymentMethod.cash') },
    { key: 'bankTransfer', label: t('paymentMethod.bankTransfer') },
    { key: 'onlinePayment', label: t('paymentMethod.onlinepayment') },
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
      <Container>
        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
          <Typography variant="h4">
            <IconButton onClick={() => navigate(-1)} aria-label="back"><ArrowBackIcon /></IconButton>
            {paymentId === 'new' ? t('paymentDetailPage.newPayment') : t('paymentDetailPage.editPayment')}
          </Typography>
          <Grid container spacing={3} style={{ marginTop: '10px' }}>
            <Grid item xs={12}>
              <Autocomplete
                options={owners.map((owner) => owner.firstname + ' ' + owner.surname)} // Assuming owners have a 'name' property
                freeSolo
                disabled={isEditMode}
                value={paymentData.payerName || ''}
                onChange={handleAutocompleteChange}
                renderInput={(params) => (
                  <TextField {...params} label={t('paymentDetailPage.payerName')} fullWidth disabled={isEditMode} />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('paymentDetailPage.payerName')}
                name="payerContact"
                value={paymentData.payerContact || ''}
                onChange={handleInputChange}
                disabled={isEditMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('paymentDetailPage.amount')}
                name="amount"
                value={paymentData.amount || ''}
                onChange={handleInputChange}
                disabled={isEditMode}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                label={t('paymentDetailPage.paymentMethod')}
                name="paymentMethod"
                value={paymentData.paymentMethod || ''}
                onChange={handleInputChange}
                disabled={isEditMode}
                displayEmpty
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {paymentMethods.map(method => (
                  <MenuItem key={method.key} value={method.key}>{method.label}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label={t('paymentDetailPage.paymentDate')}
                fullWidth
                value={paymentData.date ? new Date(paymentData.date) : null}
                onChange={(date) => handleInputChange({
                  target: {
                    name: 'date',
                    value: date
                  }
                })}
                renderInput={(params) => <TextField {...params} fullWidth disabled={isEditMode} />}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('paymentDetailPage.notes')}
                name="notes"
                multiline
                rows={4}
                value={paymentData.notes || ''}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: '20px' }}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={savePayment}>Save Payment</Button>
            </Grid>
            {isEditMode && (
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={removePayment}
                >
                  {t('paymentDetailPage.deletePayment')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Container>
    </LocalizationProvider>
  );
};

export default PaymentDetailPage;
