// src/services/MachineSettingsService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/machine-settings';

export async function fetchMachineSettings(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(API_BASE_URL);
  return response.data;
}

export async function saveMachineSettings(machines, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(API_BASE_URL, machines);
  return response.data;
}

export async function deleteMachineSettings(machineId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.delete(`${API_BASE_URL}?id=${machineId}`);
  return response.data;
}

export default {
  fetchMachineSettings,
  saveMachineSettings,
  deleteMachineSettings
};
