// pages/GuidingMachinePage/index.js

import React, { useState } from 'react';
import { Tab, Tabs, Box, Paper } from '@mui/material';
import UserDetails from './UserDetails';
import UserDetailsYear from './UserDetailsYear';
import MachineBookings from './MachineBookings';
import TodaysBookings from './TodaysBookings';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';

const GuidingMachinePage = () => {
  const { userType } = useAuth();
  const isAdmin = userType === 'admin';
  const isWorker = userType === 'worker';
  const isCustomer = userType === 'customer';
  const { t } = useTranslation();

  const initialTabIndex = isAdmin ? 0 : isWorker ? 0 : 1;
  const [tabIndex, setTabIndex] = useState(initialTabIndex);
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const renderTab = () => {
    switch (tabIndex) {
      case 0: return <TodaysBookings />;
      case 1: return <MachineBookings />;
      case 2: return <UserDetails />;
      case 3: return <UserDetailsYear />;
      default: return <MachineBookings />;
    }
  };

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 2 }}>
      <Box>
        {isAdmin && (
          <Tabs value={tabIndex} onChange={handleChange} aria-label="admin tabs" variant="scrollable" scrollButtons="auto">
            <Tab label={t('GuidingMachinePage.tabs.todaysBookings')} />
            <Tab label={t('GuidingMachinePage.tabs.bookings')} />
            <Tab label={t('GuidingMachinePage.tabs.userDetails')} />
            <Tab label={t('GuidingMachinePage.tabs.userDetailsYear')} />
          </Tabs>
        )}
        {isWorker && (
          <Tabs value={tabIndex} onChange={handleChange} aria-label="worker tabs" variant="scrollable" scrollButtons="auto">
            <Tab label={t('GuidingMachinePage.tabs.todaysBookings')} />
            <Tab label={t('GuidingMachinePage.tabs.bookings')} />
          </Tabs>
        )}
        {isCustomer && (
          <Tabs value={tabIndex} onChange={handleChange} aria-label="customer tabs" variant="scrollable" scrollButtons="auto">
            <Tab label={t('GuidingMachinePage.tabs.bookings')} />
          </Tabs>
        )}
        {renderTab()}
      </Box>
    </Paper>
  );
};

export default GuidingMachinePage;
