// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { usePermissions } from './PermissionsContext';
import jwtDecode from 'jwt-decode';
import { fetchApiKey, logoutUser } from '../services/authService';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userType, setUserType] = useState(null);
  const [organizationId, setOrganizationId] = useState(null); // Adding organizationId
  const [organizationName, setorganizationName] = useState(null); // Adding organizationId
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [apiKeyLoading, setApiKeyLoading] = useState(true);
  const [apiKey, setApiKey] = useState(null);
  const [ownerId, setOwnerId] = useState(null);
  const [readyToUse, setReadyToUse] = useState(false);
  const { updateFeatures, updatePermissions, updateEntitlements } = usePermissions();

  // Function to handle organization and user-based settings
  const handleUserSettings = (UserData) => {

    const { userId, ownerId, userEmail, defaultHostKey, userType, organizationId, organizationName, features, entitlements } = UserData;

    setUserId(userId);
    setOwnerId(ownerId);
    setUserEmail(userEmail);
    setApiKey(defaultHostKey);
    setReadyToUse(true);
    setApiKeyLoading(false);
    setUserType(userType);
    setOrganizationId(organizationId);
    setorganizationName(organizationName);
    updateFeatures(features || []);
    updateEntitlements(entitlements || []);
    updatePermissions([]);
  };

  const login = (token) => {
    setToken(token);
    setIsLoggedIn(true);
  };


  const loginPostInformation = (UserData) => {

    handleUserSettings(UserData); // Set user and organization settings
    setIsLoggedIn(true);
    setLoading(false);
  };
  const logout = async () => {
    try {
      await logoutUser(); // Call the logoutUser function which hits the backend logout endpoint
      // Clear frontend state after successful backend logout
      setIsLoggedIn(false);
      setApiKeyLoading(false);
      setUserId(null);
      setUserEmail(null);
      setUserType(null);
      setOrganizationId(null);
      setorganizationName(null);
      setToken(null);
      setApiKey(null);
      setOwnerId(null);
      setReadyToUse(false);
      updateFeatures([]); // Clear features on logout
      updateEntitlements([]); // Clear entitlements on logout
      updatePermissions([]); // Clear permissions on logout
    } catch (error) {
      console.error('Logout failed:', error);
      // Handle logout failure (e.g., display an error message)
    }
  };


  const value = {
    isLoggedIn,
    login,
    loginPostInformation,
    logout,
    userId,
    readyToUse,
    userEmail,
    ownerId,
    userType,
    organizationId,
    organizationName,
    token,
    apiKey,
    loading,
    error,
    setError,
    apiKeyLoading,
    setApiKeyLoading,
    setIsLoggedIn,
  };

  return <AuthContext.Provider value={value}>
    {children}
  </AuthContext.Provider>;
};

export default AuthContext;
