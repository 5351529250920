import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Typography,
    Paper,
    Button,
    LinearProgress,
    IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../context/AuthContext';
import { getHorseActivityVotes, deleteHorseActivityVote } from '../services/horseActivityVoteService';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDateLocale } from '../hooks/useDateLocale';
import GenericTableView from '../components/GenericTableView';
import GenericCardView from '../components/GenericCardView';
import { usePreferences } from '../context/UserPreferencesContext';
import { useTheme, useMediaQuery } from '@mui/material';
import HorseActivityVoteModal from '../components/HorseActivityVoteModal';
import ConfirmationDialog from '../components/ConfirmationDialog';
import SnackbarAlert from '../components/SnackbarAlert';
import { getAllHorses, getHorsesByUserId } from '../services/horseService';  // Imported the service function

const ActivityPage = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [activities, setActivities] = useState([]);
    const { apiKey, organizationId, token, userType, userId } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const dateLocale = useDateLocale();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { devicePreferences, updateDevicePreferences } = usePreferences();
    const [viewMode, setViewMode] = useState(isMobile ? 'card' : 'table');
    const [horses, setHorses] = useState([]);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [activityIdToDelete, setActivityIdToDelete] = useState(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

    const toggleViewMode = () => {
        updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const activityData = await getHorseActivityVotes(apiKey, organizationId, token);
                setActivities(activityData);
                let horseData;
                if (userType === 'admin') {
                    horseData = await getAllHorses(apiKey, organizationId, token);
                } else {
                    horseData = await getHorsesByUserId(userId, apiKey, organizationId, token);
                }
                setHorses(horseData);
            } catch (error) {
                console.error('Failed to fetch activities:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [apiKey, organizationId, token, isModalOpen]);

    useEffect(() => {
        setViewMode(devicePreferences.viewMode);
    }, [devicePreferences.viewMode]);

    const handleModalClose = () => {
        setModalOpen(false);
        setCurrentRecord(null);
    };

    const handleAddActivity = () => {
        setCurrentRecord(null);
        setModalOpen(true);
    };

    const handleEditActivity = (id) => {
        const record = activities.find(a => a.id === id);
        setCurrentRecord(record);
        setModalOpen(true);
    };

    const handleDeleteActivity = (id) => {
        setActivityIdToDelete(id);
        setConfirmDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        setIsLoading(true);
        try {
            await deleteHorseActivityVote(activityIdToDelete, apiKey, organizationId, token);
            setActivities(prev => prev.filter(activity => activity.id !== activityIdToDelete));
            setSnackbar({ open: true, message: t('activities.deleteSuccess'), severity: 'success' });
        } catch (error) {
            setSnackbar({ open: true, message: t('activities.deleteError'), severity: 'error' });
        } finally {
            setIsLoading(false);
            setConfirmDialogOpen(false);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const getHorseNameById = (horseId) => {
        const horse = horses.find(h => h.id === horseId);
        return horse ? horse.name : '';
    };

    const transformData = (activityData) => {
        return {
            headers: [{
                key: 'activityType', label: t('activities.tableHeaders.activityType'), display: { value: true }
            }, {
                key: 'selectedDate', label: t('activities.tableHeaders.selectedDate'), display: { value: true }
            }, {
                key: 'horseId', label: t('activities.tableHeaders.horseName'), display: { value: true }
            }],
            rows: activityData.map(activity => ({
                id: activity.id,
                activityType: {
                    value: activity.activityType ? t(`HorseActivityModal.${activity.activityType}`) : '',
                    icon: null
                },
                selectedDate: {
                    value: new Date(activity.selectedDate).toLocaleDateString(dateLocale),
                    icon: null
                },
                horseId: {
                    value: getHorseNameById(activity.horseId),
                    icon: null
                }
            }))
        };
    };

    const filteredActivities = useMemo(() => {
        return activities;
    }, [activities, userId]);

    const actions = [
        {
            icon: <DeleteIcon />,
            handler: handleDeleteActivity,
        }
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
            <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
                <div>
                    <Typography variant="h4" gutterBottom>
                        {t('activities.pageTitle')}
                    </Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={handleAddActivity}
                        >
                            {t('activities.addNewActivity')}
                        </Button>
                        <IconButton onClick={toggleViewMode} color="primary">
                            {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
                        </IconButton>
                    </Box>
                    {isLoading ? (
                        <LinearProgress />
                    ) : viewMode === 'table' ? (
                        <GenericTableView structure={transformData(filteredActivities)} onClick={handleEditActivity} actions={actions} />
                    ) : (
                        <GenericCardView structure={transformData(filteredActivities)} onClick={handleEditActivity} actions={actions} />
                    )}
                    <HorseActivityVoteModal
                        open={isModalOpen}
                        onClose={handleModalClose}
                        activity={currentRecord ? currentRecord : null}
                    />
                    <ConfirmationDialog
                        isOpen={confirmDialogOpen}
                        onClose={() => setConfirmDialogOpen(false)}
                        onConfirm={handleDeleteConfirm}
                        text={t('activities.confirmDelete')}
                    />
                    <SnackbarAlert
                        open={snackbar.open}
                        onClose={handleSnackbarClose}
                        message={snackbar.message}
                        severity={snackbar.severity}
                    />
                </div>
            </Paper>
        </LocalizationProvider>
    );
};

export default ActivityPage;
