import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/deliveryNotes';

// Fetch all delivery notes
export async function fetchAllDeliveryNotes(apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?userId=${userId}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function fetchAllDeliveryNotesPerCustomer(apiKey, organizationId, jwtToken, userId, customerId, upToDate, statuses = ['approved', 'draft']) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?userId=${userId}&customerId=${customerId}&ToDate=${upToDate}&statuses=${statuses.join(',')}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

// Fetch a single delivery note by ID
export async function fetchDeliveryNoteById(apiKey, organizationId, jwtToken, id, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${id}&userId=${userId}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

// Save a new delivery note
export async function saveDeliveryNote(deliveryNote, apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?userId=${userId}`;
  const response = await axiosInstance.post(url, deliveryNote);
  return response.data;
}

// Update an existing delivery note
export async function updateDeliveryNote(deliveryNote, apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${deliveryNote.id}&userId=${userId}`;
  const response = await axiosInstance.put(url, deliveryNote);
  return response.data;
}

// Delete a delivery note by ID
export async function deleteDeliveryNote(deliveryNoteId, apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${deliveryNoteId}&userId=${userId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

// Download a PDF version of a delivery note
export async function downloadDeliveryNotePdf(apiKey, organizationId, jwtToken, deliveryNoteId, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}/pdf?id=${deliveryNoteId}&userId=${userId}&action=getDeliveryNotePDF`;
  const response = await axiosInstance.get(url, { responseType: 'blob' });
  return response.data;
}

// Send a PDF version of a delivery note via email
export async function sendDeliveryNotePdf(apiKey, organizationId, jwtToken, deliveryNoteId, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}/pdf?id=${deliveryNoteId}&userId=${userId}&action=sendDeliveryNotePDF`;
  const response = await axiosInstance.get(url, { responseType: 'blob' });
  return response.data;
}

// Fetch all unsent delivery notes
export async function fetchUnsentDeliveryNotes(apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?userId=${userId}&status=unsent`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export default {
  fetchAllDeliveryNotes,
  fetchDeliveryNoteById,
  saveDeliveryNote,
  updateDeliveryNote,
  deleteDeliveryNote,
  downloadDeliveryNotePdf,
  sendDeliveryNotePdf,
  fetchUnsentDeliveryNotes,
};
