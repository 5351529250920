// src/services/LocationSettingsService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig';

const API_BASE_URL = '/api/locations';

export async function fetchLocationSettings(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(API_BASE_URL);
  return response.data;
}

export async function saveLocationSettings(locationData, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(API_BASE_URL, locationData);
  return response.data;
}

export async function updateLocationSettings(locationId, locationData, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${locationId}`;
  const response = await axiosInstance.put(url, locationData);
  return response.data;
}

export async function deleteLocationSettings(locationId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${locationId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

export default {
  fetchLocationSettings,
  saveLocationSettings,
  updateLocationSettings,
  deleteLocationSettings
};
