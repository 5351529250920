import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    TextField,
    Select,
    MenuItem,
    TableContainer,
    Paper,
    Button,
    Collapse,
    Box,
    Typography,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';
import ListIcon from '@mui/icons-material/List'; // List icon for item selection
import ItemSelectionPopup from '../../components/ItemSelectionPopup'; // Importing the ItemSelectionPopup
import CreateIcon from '@mui/icons-material/Create'; // Icon for manual entry

const DeliveryNoteTable = ({ deliveryNoteData, handleInputChange, removeRow, addRow }) => {
    const { t } = useTranslation();
    const [expandedRow, setExpandedRow] = useState(null);
    const itemTypes = ['Manuell', 'Item', 'Box', 'Service', 'Device']; // Add 'Item' to the list of item types
    const [itemSelectionOpen, setItemSelectionOpen] = useState(false); // State to manage popup visibility
    const [selectedRowIndex, setSelectedRowIndex] = useState(null); // Store index of row to update


    const handleAddItem = (item) => {
        // Logic to add the selected item from the popup
        addRow({
            description: item.name,
            quantity: 1,
            type: 'Item',
            netPrice: item.netPrice,
            taxRate: item.taxPercentage,
            bruttoPrice: item.bruttoPrice,
            discountPercentage: 0,
            discountAmount: 0,
            rowTotal: item.bruttoPrice,
            itemId: item.id,
            organizationId: item.organizationId
        });
        setItemSelectionOpen(false); // Close the popup after adding an item
    };

    const getItemTypeTranslation = (type) => {
        return t(`deliveryNoteDetailPage.itemTypes.${type.toLowerCase()}`);
    };

    const toggleRowExpansion = (index) => {
        setExpandedRow((prevExpandedRow) => (prevExpandedRow === index ? null : index));
    };

    return (
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('deliveryNoteDetailPage.table.itemDescription')}</TableCell>
                        <TableCell>{t('deliveryNoteDetailPage.table.itemType')}</TableCell>
                        <TableCell>{t('deliveryNoteDetailPage.table.quantity')}</TableCell>
                        <TableCell>{t('deliveryNoteDetailPage.table.rowTotal')}</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {deliveryNoteData?.rows?.map((row, index) => (
                        <React.Fragment key={index}>
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        name="description"
                                        value={row.description}
                                        onChange={(e) => handleInputChange(e, index)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <Select
                                        name="type"
                                        value={row.type}
                                        onChange={(e) => handleInputChange(e, index)}
                                        fullWidth
                                    >
                                        {itemTypes.map((type, idx) => (
                                            <MenuItem key={idx} value={type}>{getItemTypeTranslation(type)}</MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        name="quantity"
                                        value={row.quantity}
                                        type="number"
                                        inputProps={{ min: "0", step: "1" }}
                                        onChange={(e) => {
                                            if (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) {
                                                handleInputChange(e, index);
                                            }
                                        }}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        disabled
                                        name="rowTotal"
                                        value={row.rowTotal}
                                        onChange={(e) => handleInputChange(e, index)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <IconButton color="secondary" onClick={() => removeRow(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton onClick={() => toggleRowExpansion(index)}>
                                        {expandedRow === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                                    <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                                        <Box margin={1}>
                                            <Typography variant="h6" gutterBottom component="div">
                                                {t('deliveryNoteDetailPage.additionalDetails')}
                                            </Typography>
                                            <Table size="small" aria-label="additional-details">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>{t('deliveryNoteDetailPage.table.netUnitPrice')}</TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name="netPrice"
                                                                value={row.netPrice}
                                                                onChange={(e) => handleInputChange(e, index)}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>{t('deliveryNoteDetailPage.table.taxRate')}</TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name="taxRate"
                                                                value={row.taxRate}
                                                                onChange={(e) => handleInputChange(e, index)}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>{t('deliveryNoteDetailPage.table.bruttoUnitPrice')}</TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name="bruttoPrice"
                                                                value={row.bruttoPrice}
                                                                onChange={(e) => handleInputChange(e, index)}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>{t('deliveryNoteDetailPage.table.discountPercentage')}</TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name="discountPercentage"
                                                                value={row.discountPercentage}
                                                                onChange={(e) => handleInputChange(e, index)}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>{t('deliveryNoteDetailPage.table.discountAmount')}</TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name="discountAmount"
                                                                value={row.discountAmount}
                                                                onChange={(e) => handleInputChange(e, index)}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                    <TableRow>
                        <TableCell colSpan={5} style={{ textAlign: 'right' }}>
                            <IconButton color="primary" onClick={() => addRow()}>
                                <CreateIcon /> {/* Manual entry */}
                            </IconButton>
                            <IconButton color="primary" onClick={() => setItemSelectionOpen(true)}>
                                <ListIcon /> {/* Popup selection */}
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            {/* Item Selection Popup */}
            <ItemSelectionPopup
                open={itemSelectionOpen}
                onClose={() => setItemSelectionOpen(false)}
                onAddItem={handleAddItem}
            />
        </TableContainer>
    );
};

export default DeliveryNoteTable;
