// src/dataCollection/countryTaxConfig.js

export const countrySpecificTaxInfo = {
    DE: {
        GmbH: { vatRate: '19', corporateTaxRate: '15' },
        AG: { vatRate: '19', corporateTaxRate: '15' },
        Einzelunternehmer: {
            vatRate: '19', corporateTaxRates: [
                { income: { from: '0', to: '11,604' }, rate: '0' },
                { income: { from: '11,604', to: '57,052' }, rate: '14' },
                { income: { from: '57,052', to: '270,500' }, rate: '42' },
                { income: { from: '270,500', to: '541,000' }, rate: '42' },
                { income: { from: '541,000', to: '1,000,000' }, rate: '42' },
                { income: { from: '1,000,000', to: '1,000,000' }, rate: '45' },
                { income: { from: '1,000,000', to: '1,000,000' }, rate: '45' },
            ]
        },
    },
    AT: {
        GmbH: { vatRate: '20', corporateTaxRate: '25' },
        AG: { vatRate: '20', corporateTaxRate: '25' },
        Einzelunternehmer: {
            vatRate: '20', corporateTaxRates: [
                { income: { from: '0', to: '11,000' }, rate: '0' },
                { income: { from: '11,000', to: '18,000' }, rate: '25' },
                { income: { from: '18,000', to: '31,000' }, rate: '35' },
                { income: { from: '31,000', to: '60,000' }, rate: '42' },
                { income: { from: '60,000', to: '90,000' }, rate: '48' },
                { income: { from: '90,000', to: '1,000,000' }, rate: '50' },
                { income: { from: '1,000,000', to: '1,000,000' }, rate: '55' },
            ]
        },
    },
    FR: {
        SARL: { vatRate: '20', corporateTaxRate: '15' },
        SA: { vatRate: '20', corporateTaxRate: '15' },
        SAS: { vatRate: '20', corporateTaxRate: '15' },
    },
    IT: {
        SRL: { vatRate: '22', corporateTaxRate: '24' },
        SPA: { vatRate: '22', corporateTaxRate: '24' },
    },
    ES: {
        SL: { vatRate: '21', corporateTaxRate: '25' },
        SA: { vatRate: '21', corporateTaxRate: '25' },
    },
    CH: {
        "GmbH": {
            "vatRate": "7.7",
            "cantons": {
                "Zurich": { "corporateTaxRate": "18.19" },
                "Geneva": { "corporateTaxRate": "24.16" },
                "Bern": { "corporateTaxRate": "21.63" },
                "Vaud": { "corporateTaxRate": "21.17" },
                "Lucerne": { "corporateTaxRate": "12.32" },
                "Ticino": { "corporateTaxRate": "21.65" },
                "Valais": { "corporateTaxRate": "13.99" },
                "Aargau": { "corporateTaxRate": "18.19" },
                "Basel-Stadt": { "corporateTaxRate": "18.13" },
                "Schwyz": { "corporateTaxRate": "14.63" },
                "St. Gallen": { "corporateTaxRate": "19.13" },
                "Thurgau": { "corporateTaxRate": "18.13" },
                "Zug": { "corporateTaxRate": "14.63" },
                "Fribourg": { "corporateTaxRate": "15.63" },
                "Solothurn": { "corporateTaxRate": "16.63" },
                "Neuchâtel": { "corporateTaxRate": "18.63" },
                "Graubünden": { "corporateTaxRate": "12.63" },
                "Jura": { "corporateTaxRate": "20.63" },
                "Obwalden": { "corporateTaxRate": "12.63" },
                "Nidwalden": { "corporateTaxRate": "12.63" },
                "Uri": { "corporateTaxRate": "12.63" },
                "Appenzell Ausserrhoden": { "corporateTaxRate": "18.13" },
                "Appenzell Innerrhoden": { "corporateTaxRate": "18.13" },
                "Glarus": { "corporateTaxRate": "14.63" },
                "Schaffhausen": { "corporateTaxRate": "14.63" },
                "Basel-Landschaft": { "corporateTaxRate": "18.13" },
                "Grisons": { "corporateTaxRate": "12.63" },
                // Add other cantons as necessary
            }
        },
        "AG": {
            "vatRate": "7.7",
            "cantons": {
                "Zurich": { "corporateTaxRate": "18.19" },
                "Geneva": { "corporateTaxRate": "24.16" },
                "Bern": { "corporateTaxRate": "21.63" },
                "Vaud": { "corporateTaxRate": "21.17" },
                "Lucerne": { "corporateTaxRate": "12.32" },
                "Ticino": { "corporateTaxRate": "21.65" },
                "Valais": { "corporateTaxRate": "13.99" },
                "Aargau": { "corporateTaxRate": "18.19" },
                "Basel-Stadt": { "corporateTaxRate": "18.13" },
                "Schwyz": { "corporateTaxRate": "14.63" },
                "St. Gallen": { "corporateTaxRate": "19.13" },
                "Thurgau": { "corporateTaxRate": "18.13" },
                "Zug": { "corporateTaxRate": "14.63" },
                "Fribourg": { "corporateTaxRate": "15.63" },
                "Solothurn": { "corporateTaxRate": "16.63" },
                "Neuchâtel": { "corporateTaxRate": "18.63" },
                "Graubünden": { "corporateTaxRate": "12.63" },
                "Jura": { "corporateTaxRate": "20.63" },
                "Obwalden": { "corporateTaxRate": "12.63" },
                "Nidwalden": { "corporateTaxRate": "12.63" },
                "Uri": { "corporateTaxRate": "12.63" },
                "Appenzell Ausserrhoden": { "corporateTaxRate": "18.13" },
                "Appenzell Innerrhoden": { "corporateTaxRate": "18.13" },
                "Glarus": { "corporateTaxRate": "14.63" },
                "Schaffhausen": { "corporateTaxRate": "14.63" },
                "Basel-Landschaft": { "corporateTaxRate": "18.13" },
                "Grisons": { "corporateTaxRate": "12.63" },
                // Add other cantons as necessary
            }
        },
        "Einzelfirma": {
            "vatRate": "7.7",
            "cantons": {
                "Zurich": { "corporateTaxRate": "18.19" },
                "Geneva": { "corporateTaxRate": "24.16" },
                "Bern": { "corporateTaxRate": "21.63" },
                "Vaud": { "corporateTaxRate": "21.17" },
                "Lucerne": { "corporateTaxRate": "12.32" },
                "Ticino": { "corporateTaxRate": "21.65" },
                "Valais": { "corporateTaxRate": "13.99" },
                "Aargau": { "corporateTaxRate": "18.19" },
                "Basel-Stadt": { "corporateTaxRate": "18.13" },
                "Schwyz": { "corporateTaxRate": "14.63" },
                "St. Gallen": { "corporateTaxRate": "19.13" },
                "Thurgau": { "corporateTaxRate": "18.13" },
                "Zug": { "corporateTaxRate": "14.63" },
                "Fribourg": { "corporateTaxRate": "15.63" },
                "Solothurn": { "corporateTaxRate": "16.63" },
                "Neuchâtel": { "corporateTaxRate": "18.63" },
                "Graubünden": { "corporateTaxRate": "12.63" },
                "Jura": { "corporateTaxRate": "20.63" },
                "Obwalden": { "corporateTaxRate": "12.63" },
                "Nidwalden": { "corporateTaxRate": "12.63" },
                "Uri": { "corporateTaxRate": "12.63" },
                "Appenzell Ausserrhoden": { "corporateTaxRate": "18.13" },
                "Appenzell Innerrhoden": { "corporateTaxRate": "18.13" },
                "Glarus": { "corporateTaxRate": "14.63" },
                "Schaffhausen": { "corporateTaxRate": "14.63" },
                "Basel-Landschaft": { "corporateTaxRate": "18.13" },
                "Grisons": { "corporateTaxRate": "12.63" },
            }
        },
        // Repeat for other organization types if necessary
    },
    USA: {
        "LLC": {
            "vatRate": "N/A", // USA uses sales tax
            "states": {
                "California": { "stateCorporateTaxRate": "8.84", "salesTaxRate": "7.25" },
                "Texas": { "stateCorporateTaxRate": "0", "salesTaxRate": "6.25" }, // Texas has no corporate income tax but has a franchise tax and sales tax
                "New York": { "stateCorporateTaxRate": "6.5", "salesTaxRate": "4" },
                "Florida": { "stateCorporateTaxRate": "4.458", "salesTaxRate": "6" },
                "Illinois": { "stateCorporateTaxRate": "9.5", "salesTaxRate": "6.25" },
                "Pennsylvania": { "stateCorporateTaxRate": "9.99", "salesTaxRate": "6" },
                "Ohio": { "stateCorporateTaxRate": "0", "salesTaxRate": "5.75" },
                "Georgia": { "stateCorporateTaxRate": "5.75", "salesTaxRate": "4" },
                "North Carolina": { "stateCorporateTaxRate": "2.5", "salesTaxRate": "4.75" },
                "Michigan": { "stateCorporateTaxRate": "6.0", "salesTaxRate": "6" },
                "New Jersey": { "stateCorporateTaxRate": "11.5", "salesTaxRate": "6.625" },
                "Virginia": { "stateCorporateTaxRate": "6.0", "salesTaxRate": "5.3" },
                "Washington": { "stateCorporateTaxRate": "0", "salesTaxRate": "6.5" },
                "Arizona": { "stateCorporateTaxRate": "4.9", "salesTaxRate": "5.6" },
                "Massachusetts": { "stateCorporateTaxRate": "8.0", "salesTaxRate": "6.25" },
                "Indiana": { "stateCorporateTaxRate": "5.5", "salesTaxRate": "7" },
                "Tennessee": { "stateCorporateTaxRate": "6.5", "salesTaxRate": "7" },
                "Missouri": { "stateCorporateTaxRate": "4.0", "salesTaxRate": "4.225" },
                "Maryland": { "stateCorporateTaxRate": "8.25", "salesTaxRate": "6" },
                "Wisconsin": { "stateCorporateTaxRate": "7.9", "salesTaxRate": "5" },
                "Colorado": { "stateCorporateTaxRate": "4.63", "salesTaxRate": "2.9" },
                "Minnesota": { "stateCorporateTaxRate": "9.8", "salesTaxRate": "6.875" },
                "South Carolina": { "stateCorporateTaxRate": "5.0", "salesTaxRate": "6" },
                "Alabama": { "stateCorporateTaxRate": "6.5", "salesTaxRate": "4" },
                "Louisiana": { "stateCorporateTaxRate": "4.0", "salesTaxRate": "4.45" },
                "Kentucky": { "stateCorporateTaxRate": "5.0", "salesTaxRate": "6" },
                "Oregon": { "stateCorporateTaxRate": "6.6", "salesTaxRate": "0" },
                "Oklahoma": { "stateCorporateTaxRate": "6.0", "salesTaxRate": "4.5" },
                "Connecticut": { "stateCorporateTaxRate": "7.5", "salesTaxRate": "6.35" },
                "Iowa": { "stateCorporateTaxRate": "12.0", "salesTaxRate": "6" },
                "Mississippi": { "stateCorporateTaxRate": "5.0", "salesTaxRate": "7" },
                "Arkansas": { "stateCorporateTaxRate": "6.5", "salesTaxRate": "6.5" },
                "Kansas": { "stateCorporateTaxRate": "7.0", "salesTaxRate": "6.5" },
                "Utah": { "stateCorporateTaxRate": "5.0", "salesTaxRate": "5.95" },
                "Nevada": { "stateCorporateTaxRate": "0", "salesTaxRate": "6.85" },
                "New Mexico": { "stateCorporateTaxRate": "6.2", "salesTaxRate": "5.125" },
                "Nebraska": { "stateCorporateTaxRate": "7.81", "salesTaxRate": "5.5" },
                "West Virginia": { "stateCorporateTaxRate": "6.5", "salesTaxRate": "6" },
                "Idaho": { "stateCorporateTaxRate": "6.925", "salesTaxRate": "6" },
                "Hawaii": { "stateCorporateTaxRate": "6.4", "salesTaxRate": "4" },
                "New Hampshire": { "stateCorporateTaxRate": "8.5", "salesTaxRate": "0" },
                "Maine": { "stateCorporateTaxRate": "8.93", "salesTaxRate": "5.5" },
                "Rhode Island": { "stateCorporateTaxRate": "7.0", "salesTaxRate": "7" },
                "Montana": { "stateCorporateTaxRate": "6.75", "salesTaxRate": "0" },
                "Delaware": { "stateCorporateTaxRate": "8.7", "salesTaxRate": "0" },
                "South Dakota": { "stateCorporateTaxRate": "0", "salesTaxRate": "4.5" },
                "North Dakota": { "stateCorporateTaxRate": "4.31", "salesTaxRate": "5" },
                "Alaska": { "stateCorporateTaxRate": "0", "salesTaxRate": "0" },
                "Wyoming": { "stateCorporateTaxRate": "0", "salesTaxRate": "4" },
                "Vermont": { "stateCorporateTaxRate": "8.5", "salesTaxRate": "6" },
                "District of Columbia": { "stateCorporateTaxRate": "8.25", "salesTaxRate": "6" },
                // Add other states as necessary
            }
        },
        "Corporation": {
            "vatRate": "N/A", // USA uses sales tax
            "states": {
                "California": { "stateCorporateTaxRate": "8.84", "salesTaxRate": "7.25" },
                "Texas": { "stateCorporateTaxRate": "0", "salesTaxRate": "6.25" }, // Texas has no corporate income tax but has a franchise tax and sales tax
                "New York": { "stateCorporateTaxRate": "6.5", "salesTaxRate": "4" },
                "Florida": { "stateCorporateTaxRate": "4.458", "salesTaxRate": "6" },
                "Illinois": { "stateCorporateTaxRate": "9.5", "salesTaxRate": "6.25" },
                "Pennsylvania": { "stateCorporateTaxRate": "9.99", "salesTaxRate": "6" },
                "Ohio": { "stateCorporateTaxRate": "0", "salesTaxRate": "5.75" },
                "Georgia": { "stateCorporateTaxRate": "5.75", "salesTaxRate": "4" },
                "North Carolina": { "stateCorporateTaxRate": "2.5", "salesTaxRate": "4.75" },
                "Michigan": { "stateCorporateTaxRate": "6.0", "salesTaxRate": "6" },
                "New Jersey": { "stateCorporateTaxRate": "11.5", "salesTaxRate": "6.625" },
                "Virginia": { "stateCorporateTaxRate": "6.0", "salesTaxRate": "5.3" },
                "Washington": { "stateCorporateTaxRate": "0", "salesTaxRate": "6.5" },
                "Arizona": { "stateCorporateTaxRate": "4.9", "salesTaxRate": "5.6" },
                "Massachusetts": { "stateCorporateTaxRate": "8.0", "salesTaxRate": "6.25" },
                "Indiana": { "stateCorporateTaxRate": "5.5", "salesTaxRate": "7" },
                "Tennessee": { "stateCorporateTaxRate": "6.5", "salesTaxRate": "7" },
                "Missouri": { "stateCorporateTaxRate": "4.0", "salesTaxRate": "4.225" },
                "Maryland": { "stateCorporateTaxRate": "8.25", "salesTaxRate": "6" },
                "Wisconsin": { "stateCorporateTaxRate": "7.9", "salesTaxRate": "5" },
                "Colorado": { "stateCorporateTaxRate": "4.63", "salesTaxRate": "2.9" },
                "Minnesota": { "stateCorporateTaxRate": "9.8", "salesTaxRate": "6.875" },
                "South Carolina": { "stateCorporateTaxRate": "5.0", "salesTaxRate": "6" },
                "Alabama": { "stateCorporateTaxRate": "6.5", "salesTaxRate": "4" },
                "Louisiana": { "stateCorporateTaxRate": "4.0", "salesTaxRate": "4.45" },
                "Kentucky": { "stateCorporateTaxRate": "5.0", "salesTaxRate": "6" },
                "Oregon": { "stateCorporateTaxRate": "6.6", "salesTaxRate": "0" },
                "Oklahoma": { "stateCorporateTaxRate": "6.0", "salesTaxRate": "4.5" },
                "Connecticut": { "stateCorporateTaxRate": "7.5", "salesTaxRate": "6.35" },
                "Iowa": { "stateCorporateTaxRate": "12.0", "salesTaxRate": "6" },
                "Mississippi": { "stateCorporateTaxRate": "5.0", "salesTaxRate": "7" },
                "Arkansas": { "stateCorporateTaxRate": "6.5", "salesTaxRate": "6.5" },
                "Kansas": { "stateCorporateTaxRate": "7.0", "salesTaxRate": "6.5" },
                "Utah": { "stateCorporateTaxRate": "5.0", "salesTaxRate": "5.95" },
                "Nevada": { "stateCorporateTaxRate": "0", "salesTaxRate": "6.85" },
                "New Mexico": { "stateCorporateTaxRate": "6.2", "salesTaxRate": "5.125" },
                "Nebraska": { "stateCorporateTaxRate": "7.81", "salesTaxRate": "5.5" },
                "West Virginia": { "stateCorporateTaxRate": "6.5", "salesTaxRate": "6" },
                "Idaho": { "stateCorporateTaxRate": "6.925", "salesTaxRate": "6" },
                "Hawaii": { "stateCorporateTaxRate": "6.4", "salesTaxRate": "4" },
                "New Hampshire": { "stateCorporateTaxRate": "8.5", "salesTaxRate": "0" },
                "Maine": { "stateCorporateTaxRate": "8.93", "salesTaxRate": "5.5" },
                "Rhode Island": { "stateCorporateTaxRate": "7.0", "salesTaxRate": "7" },
                "Montana": { "stateCorporateTaxRate": "6.75", "salesTaxRate": "0" },
                "Delaware": { "stateCorporateTaxRate": "8.7", "salesTaxRate": "0" },
                "South Dakota": { "stateCorporateTaxRate": "0", "salesTaxRate": "4.5" },
                "North Dakota": { "stateCorporateTaxRate": "4.31", "salesTaxRate": "5" },
                "Alaska": { "stateCorporateTaxRate": "0", "salesTaxRate": "0" },
                "Wyoming": { "stateCorporateTaxRate": "0", "salesTaxRate": "4" },
                "Vermont": { "stateCorporateTaxRate": "8.5", "salesTaxRate": "6" },
                "District of Columbia": { "stateCorporateTaxRate": "8.25", "salesTaxRate": "6" },
                // Add other states as necessary
            }
        },
        // Additional organization types as necessary
    },
    UAE: {
        "FreeZoneCompany": {
            "vatRate": "5",
            "freeZones": {
                "Dubai Internet City": { "corporateTaxRate": "0" },
                "Jebel Ali Free Zone": { "corporateTaxRate": "0" },
                "Dubai Media City": { "corporateTaxRate": "0" },
                // Additional free zones as necessary
            }
        },
        "MainlandCompany": {
            "vatRate": "5",
            "corporateTaxRate": "0",
        },
        // Additional organization types as necessary
    },
};