// src/pages/RidingLessons/RidingLessonIndex.js


import React, { useState } from 'react';
import { Tab, Tabs, Box, Paper } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import RidingLessonsOverview from './RidingLessonsOverview';
import RidingLessonPayments from './RidingLessonPayments';

const RidingLessonIndex = () => {
    const { userType } = useAuth();
    const isAdmin = userType === 'admin';
    const { t } = useTranslation();

    const [tabIndex, setTabIndex] = useState(0);
    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const renderTab = () => {
        switch (tabIndex) {
            case 0: return <RidingLessonsOverview />;
            case 1: return <RidingLessonPayments />;
            default: return <RidingLessonsOverview />;
        }
    };

    return (
        <Paper elevation={3} sx={{ mt: 4, p: 2 }}>
            <Box>
                {isAdmin && (
                    <Tabs value={tabIndex} onChange={handleChange} aria-label="simple tabs example" variant="scrollable" scrollButtons="auto">
                        <Tab label={t('RidingLessonIndex.tabs.bookings')} />
                        <Tab label={t('RidingLessonIndex.tabs.userDetails')} />
                    </Tabs>
                )}
                {renderTab()}
            </Box>
        </Paper>
    );
}

export default RidingLessonIndex;