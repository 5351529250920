// src/components/OrgTabs/VeterinarianForm.js

import React, { useState, useEffect } from 'react';
import { TextField, Button, Paper, Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import { fetchVeterinarianById, saveVeterinarian, updateVeterinarian, deleteVeterinarian } from '../../services/VeterinarianService';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';


const VeterinarianForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const mode = id ? 'edit' : 'add';
    const { apiKey, token, organizationId } = useAuth();
    const [loading, setLoading] = useState(false);

    const [veterinarianData, setVeterinarianData] = useState({
        id: '',
        firstname: '',
        surname: '',
        email: '',
        phoneNumber: '',
        address: '',
        zipCode: '',
        city: '',
        website: '',
    });

    useEffect(() => {
        if (mode === 'edit') {
            setLoading(true);  // Set loading to true before fetching
            // Fetch veterinarian data by ID
            fetchVeterinarianById(apiKey, organizationId, token, id)
                .then(data => {
                    if (data) {
                        setVeterinarianData(data);
                    }
                })
                .catch(err => console.error("Failed to fetch veterinarian by ID:", err))
                .finally(() => setLoading(false));
        }
    }, [mode, id]);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setVeterinarianData({
            ...veterinarianData,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // If you're in 'edit' mode, you'll want to update the existing record
        if (mode === 'edit') {
            updateVeterinarian(veterinarianData, apiKey, organizationId, token)
                .then((response) => {
                    console.log('Updated successfully!', response);
                    navigate('/organization-settings?tab=veterinarian-settings');
                })
                .catch((error) => {
                    console.error('Failed to update veterinarian:', error);
                    // Handle error accordingly
                });
        } else {
            // If you're in 'add' mode, you'll want to create a new record
            saveVeterinarian(veterinarianData, apiKey, organizationId, token)
                .then((response) => {
                    console.log('Saved successfully!', response);
                    // Navigate or update UI accordingly
                    navigate('/organization-settings?tab=veterinarian-settings');
                })
                .catch((error) => {
                    console.error('Failed to save veterinarian:', error);
                    // Handle error accordingly
                });
        }
        navigate('/organization-settings?tab=veterinarian-settings');
    };

    const handleDelete = () => {
        deleteVeterinarian(id, apiKey, organizationId, token)
            .then(response => {
                console.log('Deleted successfully!', response);
                // Navigate or update UI accordingly, for example:
                navigate('/organization-settings?tab=veterinarian-settings');
            })
            .catch(error => {
                console.error('Failed to delete veterinarian:', error);
                // Handle error accordingly
            });
    };

    return (
        <Container component={Paper} style={{ padding: '2em' }}>
            {loading && <LinearProgress />}
            <h1>{mode === 'add' ? 'Add New Veterinarian' : 'Edit Veterinarian'}</h1>
            <form onSubmit={handleSubmit}>
                <TextField required name="firstname" label="First Name" variant="outlined" margin="normal" value={veterinarianData.firstname} fullWidth onChange={handleChange} />
                <TextField required name="surname" label="Surname" variant="outlined" margin="normal" value={veterinarianData.surname} fullWidth onChange={handleChange} />
                <TextField required name="phoneNumber" label="Phone Number" variant="outlined" margin="normal" value={veterinarianData.phoneNumber} fullWidth onChange={handleChange} />
                <TextField name="email" label="Email" variant="outlined" margin="normal" value={veterinarianData.email} fullWidth onChange={handleChange} />
                <TextField name="address" label="Address" variant="outlined" margin="normal" value={veterinarianData.address} fullWidth onChange={handleChange} />
                <TextField name="zipCode" label="ZIP Code" variant="outlined" margin="normal" value={veterinarianData.zipCode} fullWidth onChange={handleChange} />
                <TextField name="city" label="City" variant="outlined" margin="normal" value={veterinarianData.city} fullWidth onChange={handleChange} />
                <TextField name="website" label="Website" variant="outlined" margin="normal" value={veterinarianData.website} fullWidth onChange={handleChange} />
                <Button type="submit" variant="contained" color="primary">
                    Save
                </Button>
                {mode === 'edit' && (
                    <Button variant="contained" color="secondary" onClick={handleDelete}>
                        Delete
                    </Button>
                )}
            </form>
        </Container>
    );
};

export default VeterinarianForm;
