import React from 'react';
import { TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const OwnerAddressTab = ({ owner, setOwner }) => {
    const { t } = useTranslation(); // Hook for translation

    const handleAddressChange = (field, value) => {
        setOwner({
            ...owner,
            address: {
                ...owner.address,
                [field]: value
            }
        });
    };

    return (
        <Box>
            <TextField
                label={t('OwnerDetails.AddressTab.Street')} // Translated label
                value={owner && owner.address ? owner.address.street : ''}
                onChange={(e) => handleAddressChange('street', e.target.value)}
                fullWidth
                margin="normal" 
            />
            <TextField
                label={t('OwnerDetails.AddressTab.ZipCode')} // Translated label
                value={owner && owner.address ? owner.address.zipCode : ''}
                onChange={(e) => handleAddressChange('zipCode', e.target.value)}
                fullWidth
                margin="normal"
            />
            <TextField
                label={t('OwnerDetails.AddressTab.City')} // Translated label
                value={owner && owner.address ? owner.address.city : ''}
                onChange={(e) => handleAddressChange('city', e.target.value)}
                fullWidth
                margin="normal"
            />
            <TextField
                label={t('OwnerDetails.AddressTab.Country')} // Translated label
                value={owner && owner.address ? owner.address.country : ''}
                onChange={(e) => handleAddressChange('country', e.target.value)}
                fullWidth
                margin="normal"
            />
        </Box>
    );
};

export default OwnerAddressTab;