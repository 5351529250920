// src/pages/OAuthRedirect.js

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleSmartThingsAuth } from '../services/SmartThingsService';
import { CircularProgress, Box, Typography } from '@mui/material';
import { useAuth } from '../context/AuthContext';  // To get the authentication tokens and IDs

const OAuthRedirect = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { apiKey, organizationId, token, ownerId, userEmail, userType, userId } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            const query = new URLSearchParams(location.search);
            const code = query.get('code');
            const state = query.get('state'); // Use state to determine which integration it is for
            console.log('Code:', code);
            console.log('State:', state);
            if (code) {
                try {
                    if (state.startsWith('smartthings:')) {
                        await handleSmartThingsAuth(code, apiKey, organizationId, token);
                        navigate('/organization-settings?tab=13');
                    }
                    // Add other integrations here
                } catch (error) {
                    console.error('Error handling OAuth redirect:', error);
                    // Optionally, show an error message or redirect to an error page
                }
            } else {
                // Handle missing code error
                console.error('Authorization code missing');
            }
        };

        fetchData();
    }, [location.search, navigate]);

    return (
        <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
            <CircularProgress />
            <Typography variant="h6" marginLeft={2}>
                Completing Authorization...
            </Typography>
        </Box>
    );
};

export default OAuthRedirect;
