// src/pages/auth/GuidedSignupPage.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Stepper, Step, StepLabel, Typography, Box, useTheme, Paper } from '@mui/material';
import PersonalInfo from './PersonalInfo';
import HorseOwnership from './HorseOwnership';
import Summary from './Summary';
import { selfSignUp } from '../../services/authService';
import { verifyToken } from '../../services/tokenService';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/AuthContext';
import { set } from 'date-fns';
import { useMediaQuery } from '@mui/material';
import { useDrawerState } from '../../context/DrawerContext'; // Adjust the path as necessary
import FoodManagement from './FoodManagement';


const getSteps = (t) => [
    t('SelfSignupPage.steps.personalInformation'),
    t('SelfSignupPage.steps.horseOwnership'),
    t('SelfSignupPage.steps.foodManagement'),
    t('SelfSignupPage.steps.summary')
];

const GuidedSignupPage = () => {
    const { t } = useTranslation();
    const steps = getSteps(t)
    const location = useLocation();
    const navigate = useNavigate();
    const { toggleDrawer, closeDrawer } = useDrawerState();
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [foodSettings, setFoodSettings] = useState([]);

    const [isTokenValid, setIsTokenValid] = useState(null);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Adjust breakpoint as needed

    const [organizationId, setOrganizationId] = useState(null);
    const [token, setToken] = useState(null);
    const { token: jwtToken, userId } = useAuth();
    const [stage, setStage] = useState(0);
    const [userInfo, setUserInfo] = useState({
        personalInfo: {
            firstname: '',
            surname: '',
            number: '',
            birthday: '',
            ownerType: 'private',
            company: '',
        },
        horses: [],
        newPersons: {
            veterinarians: [],
            ferries: [],
        },
        userId: userId,
    });
    const [veterinarianData, setVeterinarianData] = useState([]);
    const [ferryData, setFerryData] = useState([]);

    const handleStepClick = (stepNumber) => {
        // Ensure users don't skip steps, can be modified according to your use case
        if (stepNumber <= stage) {
            setStage(stepNumber);
        }
    };
    useEffect(() => {
        // Use state from location if available
        const locationState = location.state;
        setToken(locationState.token);
        closeDrawer();
        setOrganizationId(locationState.organizationId);
    }, [location]);

    useEffect(() => {

        const verifyTheToken = async () => {
            const isValidResponse = await verifyToken(token, organizationId);
            if (!isValidResponse) {
                setIsLoading(false);
                setIsTokenValid(false);
                return;
            }
            const tokenValid = isValidResponse.valid
            setIsLoading(false);
            setIsTokenValid(isValidResponse.valid);  // set isTokenValid based on the validation result
            if (!tokenValid) {
                //navigate('/auth');
            } else {
                setVeterinarianData(isValidResponse.veterinarians);
                setFerryData(isValidResponse.ferries);
                setFoodSettings(isValidResponse.foodSettings);
            }
        }
        if (token && organizationId) {
            verifyTheToken();
        }

    }, [token, organizationId]);

    const handleNext = (data) => {
        switch (stage) {
            case 0:
                setUserInfo(prev => ({ ...prev, personalInfo: data }));
                break;
            case 1:
                setUserInfo(prev => ({ ...prev, horses: data }));
                break;
            case 2:
                console.log(data);
                setUserInfo(prev => ({ ...prev, horses: data }));
                break;
            default:
                break;
        }
        console.log(data);
        setStage(prev => prev + 1);
    };



    const handleSubmit = async () => {
        setIsSubmitting(true);  // Start the submission process
        // Call the API to save user signup info
        try {
            const userInfotoSend = { ...userInfo, userId: userId };
            //await setUserInfo(prev => ({ ...prev, userId: userId }));
            const response = await selfSignUp(userInfotoSend, organizationId, token, jwtToken);

            if (response && response.status === "success") {
                navigate('/');
                toggleDrawer();
            } else {
                setSnackbarMessage(t("SelfSignupPage.signupFailMessage"));
                setSnackbarOpen(true);
            }
        } catch (error) {
            setSnackbarMessage(t("SelfSignupPage.signupFailMessage"));
            setSnackbarOpen(true);
            console.error("Error during self-signup:", error);
        } finally {
            setIsSubmitting(false);
        }
    }


    const addNewPerson = (type, data) => {
        setUserInfo(prev => ({
            ...prev,
            newPersons: {
                ...prev.newPersons,
                [type]: [...prev.newPersons[type], data]
            }
        }));

        // Update the display state to immediately show the new person in the dropdown
        if (type === 'veterinarians') {
            setVeterinarianData(prevData => [...prevData, data]);
        } else if (type === 'ferries') {
            setFerryData(prevData => [...prevData, data]);
        }
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (

        <Container maxWidth="md">
            <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
                {isLoading && (
                    <>
                        <LinearProgress />
                        <Typography variant="h5" component="h2" gutterBottom>
                            {t('SelfSignupPage.tokenVerification')}
                        </Typography>
                    </>
                )}

                {!isLoading && isTokenValid === false && (
                    <Typography variant="h5" component="h2" gutterBottom>
                        {t('SelfSignupPage.expiredInvite')}
                    </Typography>
                )}

                {!isLoading && isTokenValid && (
                    <>
                        <Typography variant="h4" component="h1" gutterBottom>{t('SelfSignupPage.header')}</Typography> {/* Header */}
                        <Stepper activeStep={stage}
                            orientation={isSmallScreen ? "vertical" : "horizontal"}
                            sx={{
                                '& .MuiStepLabel-label': {
                                    fontSize: isSmallScreen ? '0.75rem' : '1rem', // Adjust font size for small screens
                                },
                                '& .MuiStepper-root': {
                                    flexDirection: isSmallScreen ? "column" : "row"
                                }
                            }}>
                            {steps.map((label, index) => (
                                <Step key={label} onClick={() => handleStepClick(index)}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Box mt={3}>
                            {stage === 0 && <PersonalInfo onNext={handleNext} initialData={userInfo.personalInfo} />}
                            {stage === 1 && <HorseOwnership onNext={handleNext} veterinarians={veterinarianData} ferries={ferryData} addNewPerson={addNewPerson} initialData={userInfo.horses} />}
                            {stage === 2 && <FoodManagement onNext={handleNext} horses={userInfo.horses} foodSettings={foodSettings} />}
                            {stage === 3 && <Summary data={userInfo} onSubmit={handleSubmit} />}
                        </Box>
                    </>
                )}

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={snackbarMessage}
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseSnackbar}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </Paper>
        </Container>
    );
};

export default GuidedSignupPage;
