import React, { useState, useEffect } from 'react';
import {
  Paper,
  Button,
  Chip,
  Box,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@mui/material';
import { fetchAllSalesOrders } from '../services/SalesOrderService';
import { approveDocument, rejectDocument } from '../services/BusinessDocumentService';
import { useAuth } from '../context/AuthContext';
import { usePreferences } from '../context/UserPreferencesContext';
import GenericCardView from '../components/GenericCardView';
import GenericTableView from '../components/GenericTableView';
import SnackbarAlert from '../components/SnackbarAlert';

const SalesOrdersListPage = () => {
  const { t } = useTranslation();
  const { apiKey, organizationId, token, userId } = useAuth();
  const [salesOrders, setSalesOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [viewMode, setViewMode] = useState(isMobile ? 'card' : 'table');
  const { devicePreferences, updateDevicePreferences } = usePreferences();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersData = await fetchAllSalesOrders(apiKey, organizationId, token, userId);
        setSalesOrders(ordersData);
      } catch (error) {
        console.error("Error fetching sales order data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [apiKey, organizationId, token, userId]);

  useEffect(() => {
    setViewMode(devicePreferences.viewMode);
  }, [devicePreferences.viewMode]);

  const navigateToDetail = (salesOrderId) => {
    navigate(`/sales-process/sales-orders/detail/${salesOrderId}`);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const renderStatus = (order) => {
    if (order.cancel && order.cancel === true) {
      return <Chip icon={<WarningAmberIcon />} label={t("salesOrdersListPage.cancelled")} color="secondary" />;
    } else if (order.status === 'draft') {
      return <Chip icon={<CancelIcon />} label={t("salesOrdersListPage.draft")} color="default" />;
    } else if (order.status === 'approved') {
      return <Chip icon={<CheckCircleIcon />} label={t("salesOrdersListPage.approved")} color="primary" />;
    } else if (order.status === 'Completed') {
      return <Chip icon={<CheckCircleIcon />} label={t("salesOrdersListPage.completed")} color="primary" />;
    } else {
      return <Chip icon={<CancelIcon />} label={t("salesOrdersListPage.unapproved")} color="error" />;
    }
  };

  const toggleViewMode = () => {
    updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
  };

  const approveOrder = async (orderId) => {
    try {
      await approveDocument(apiKey, organizationId, token, userId, orderId, 'salesOrder');
      setSnackbar({ open: true, message: t("salesOrdersListPage.orderApproved"), severity: 'success' });
      setSalesOrders(salesOrders.map(order => order.id === orderId ? { ...order, status: 'approved' } : order));
    } catch (error) {
      console.error("Error approving sales order: ", error);
      setSnackbar({ open: true, message: t("salesOrdersListPage.errorApprovingOrder"), severity: 'error' });
    }
  };

  const transformData = () => {
    return {
      headers: [
        { key: 'salesOrderNumber', label: t("salesOrdersListPage.salesOrderNumber"), display: { value: true, chipColor: false } },
        { key: 'customerName', label: t("salesOrdersListPage.customerName"), display: { value: true, chipColor: false } },
        { key: 'orderDate', label: t("salesOrdersListPage.orderDate"), display: { value: true, chipColor: false } },
        { key: 'totalAmount', label: t("salesOrdersListPage.totalAmount"), display: { value: true, chipColor: false } },
        { key: 'status', label: t("salesOrdersListPage.status"), display: { value: true, chipColor: false } }
      ],
      rows: salesOrders.map(order => ({
        id: order.id,
        salesOrderNumber: { value: order.salesOrderNumber, icon: null },
        customerName: { value: order.customerName, icon: null },
        orderDate: { value: new Date(order.orderDate).toLocaleDateString(), icon: null },
        totalAmount: { value: order.totalAmount, icon: null },
        status: { value: renderStatus(order), icon: null },
        actions: [
          {
            icon: <CheckCircleIcon />,
            handler: order.status !== 'draft' ? null : () => approveOrder(order.id),
            disabled: order.status !== 'draft',
            label: t("salesOrdersListPage.approve")
          }
        ]
      }))
    };
  };

  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} component={Link} to="/sales-process/sales-orders/detail/new">
          {t("salesOrdersListPage.addNewOrder")}
        </Button>
        <IconButton onClick={toggleViewMode} color="primary">
          {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
        </IconButton>
      </Box>
      {loading ? (
        <div style={{ width: '100%', marginTop: 20 }}>
          <LinearProgress />
        </div>
      ) : viewMode === 'table' ? (
        <GenericTableView structure={transformData()} onClick={navigateToDetail} />
      ) : (
        <GenericCardView structure={transformData()} onClick={navigateToDetail} />
      )}

      <SnackbarAlert
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </Paper>
  );
};

export default SalesOrdersListPage;
