// src/components/FarmDesigner/PrintPreviewModal.js
import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    InputLabel,
    FormControl,
} from '@mui/material';
import ReactGridLayout from 'react-grid-layout';
import html2canvas from 'html2canvas';
import BoxItem from './BoxItem';
import RoomItem from './RoomItem';
import { fetchFarmLayout } from '../../services/FarmLayoutService';
import { fetchLocationSettings } from '../../services/LocationSettingsService';
import { fetchFoodSettings } from '../../services/FoodSettingsService';
import { getHorseById } from '../../services/horseService';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';

const PrintPreviewModal = ({
    isOpen,
    onClose,
    layout: propLayout,
    boxes: propBoxes,
    rooms: propRooms,
    isInitializing,
    filteredItems: propFilteredItems,
}) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const printRef = useRef(null);
    const [internalLayout, setInternalLayout] = useState(propLayout || []);
    const [boxes, setBoxes] = useState(propBoxes || []);
    const [rooms, setRooms] = useState(propRooms || []);
    const [filteredItems, setFilteredItems] = useState(propFilteredItems || []);

    const [locations, setLocations] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedBuilding, setSelectedBuilding] = useState('');

    const { apiKey, organizationId, token: jwtToken } = useAuth();

    // State for toggling food portions display
    const [showFoodPortions, setShowFoodPortions] = useState(false);
    const [foodSettings, setFoodSettings] = useState([]);

    // New state variables for paper size and orientation
    const [paperSize, setPaperSize] = useState('A4');
    const [orientation, setOrientation] = useState('landscape');

    // Map paper sizes to dimensions in pixels (approximately)
    const paperDimensions = {
        A3: {
            landscape: { width: 1587, height: 1122 },
            portrait: { width: 1122, height: 1587 },
        },
        A4: {
            landscape: { width: 1122, height: 793 },
            portrait: { width: 793, height: 1122 },
        },
    };

    // Calculate grid dimensions based on selected paper size and orientation
    const gridWidth = paperDimensions[paperSize][orientation].width;
    const gridHeight = paperDimensions[paperSize][orientation].height;

    useEffect(() => {
        // If propLayout is not defined, fetch locations
        if (!propLayout || propLayout.length === 0) {
            const fetchData = async () => {
                try {
                    const locationsData = await fetchLocationSettings(apiKey, organizationId, jwtToken);
                    setLocations(locationsData);
                } catch (error) {
                    console.error('Error fetching locations', error);
                }
            };
            fetchData();
        }
    }, [isOpen, apiKey, organizationId, jwtToken, propLayout]);

    // Fetch food settings
    useEffect(() => {
        const fetchFoodData = async () => {
            try {
                const foodData = await fetchFoodSettings(apiKey, organizationId, jwtToken);
                setFoodSettings(foodData);
            } catch (error) {
                console.error('Error fetching food settings', error);
            }
        };

        fetchFoodData();
    }, [apiKey, organizationId, jwtToken]);

    const handleLocationChange = useCallback(
        (event) => {
            const locationId = event.target.value;
            setSelectedLocation(locationId);

            const selectedLocationData = locations.find(
                (location) => location.id === locationId
            );
            setBuildings(selectedLocationData?.buildings || []);
            setSelectedBuilding('');
        },
        [locations]
    );

    const handleBuildingChange = useCallback(
        async (event) => {
            const buildingId = event.target.value;
            setSelectedBuilding(buildingId);

            // Fetch layout data
            try {
                const data = await fetchFarmLayout(apiKey, organizationId, jwtToken, selectedLocation, buildingId);
                if (data && data.layoutData.layout.length > 0) {
                    const layoutData = data.layoutData;
                    setInternalLayout(layoutData.layout);
                    setBoxes(layoutData.boxes || []);
                    setRooms(layoutData.rooms || []);
                    setFilteredItems([...layoutData.boxes || [], ...layoutData.rooms || []]);

                    // Fetch horses assigned to boxes
                    const horseIds = (layoutData.boxes || [])
                        .filter(box => box.horse && box.horse.id)
                        .map(box => box.horse.id);

                    const uniqueHorseIds = [...new Set(horseIds)];

                    if (uniqueHorseIds.length > 0) {
                        try {
                            // Fetch horse data in parallel
                            const horseDataArray = await Promise.all(
                                uniqueHorseIds.map(horseId => getHorseById(horseId, apiKey, organizationId, jwtToken))
                            );

                            // Create a map of horseId to horseData
                            const horseDataMap = {};
                            horseDataArray.forEach(horseData => {
                                horseDataMap[horseData.id] = horseData;
                            });

                            // Update boxes with full horse data
                            const updatedBoxes = (layoutData.boxes || []).map(box => {
                                if (box.horse && box.horse.id && horseDataMap[box.horse.id]) {
                                    return {
                                        ...box,
                                        horse: horseDataMap[box.horse.id],
                                    };
                                }
                                return box;
                            });

                            setBoxes(updatedBoxes);
                            setFilteredItems([...updatedBoxes, ...layoutData.rooms || []]);

                        } catch (error) {
                            console.error('Error fetching horse data', error);
                        }
                    }
                } else {
                    // Handle case when no layout is found
                    console.warn('No layout data found for selected location and building');
                }
            } catch (error) {
                console.error('Error fetching farm layout', error);
            }
        },
        [apiKey, organizationId, jwtToken, selectedLocation]
    );

    const handlePrint = async () => {
        setIsLoading(true);
        try {
            const canvas = await html2canvas(printRef.current, {
                scrollY: -window.scrollY,
                width: gridWidth,
                height: gridHeight,
                windowWidth: gridWidth,
                windowHeight: gridHeight,
                scale: 2, // Adjust scale for better quality
            });
            const imgData = canvas.toDataURL('image/png');
            setIsLoading(false);

            // Create a hidden iframe
            const iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.style.position = 'fixed';
            iframe.style.right = '0';
            iframe.style.bottom = '0';
            iframe.style.width = '0';
            iframe.style.height = '0';
            iframe.style.border = '0';

            // Adjust CSS for print media
            const orientationCSS = orientation === 'landscape' ? '@page { size: landscape; }' : '@page { size: portrait; }';

            // Write the image to the iframe
            iframe.contentWindow.document.open();
            iframe.contentWindow.document.write(`
                <html>
                  <head>
                    <title>${t('PrintPreviewModal.Title')}</title>
                    <style>
                      ${orientationCSS}
                      body, html {
                        margin: 0;
                        padding: 0;
                      }
                      img {
                        width: 100%;
                        height: auto;
                      }
                    </style>
                  </head>
                  <body onload="window.print(); window.onafterprint = function() { window.parent.document.body.removeChild(window.frameElement); }">
                    <img src="${imgData}" />
                  </body>
                </html>
              `);
            iframe.contentWindow.document.close();
        } catch (error) {
            console.error('Failed to capture image', error);
            setIsLoading(false);
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                }}
            >
                <Typography variant="h6" gutterBottom>
                    {t('PrintPreviewModal.Title')}
                </Typography>

                {internalLayout && internalLayout.length > 0 ? (
                    <>
                        {/* Toggle for food portions */}
                        <Box sx={{ mt: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showFoodPortions}
                                        onChange={(e) => setShowFoodPortions(e.target.checked)}
                                    />
                                }
                                label={t('PrintPreviewModal.ShowFoodPortions')}
                            />
                        </Box>

                        {/* Select paper size and orientation */}
                        <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel id="paper-size-label">{t('PrintPreviewModal.PaperSize')}</InputLabel>
                                <Select
                                    labelId="paper-size-label"
                                    value={paperSize}
                                    label={t('PrintPreviewModal.PaperSize')}
                                    onChange={(e) => setPaperSize(e.target.value)}
                                >
                                    <MenuItem value="A4">A4</MenuItem>
                                    <MenuItem value="A3">A3</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="orientation-label">{t('PrintPreviewModal.Orientation')}</InputLabel>
                                <Select
                                    labelId="orientation-label"
                                    value={orientation}
                                    label={t('PrintPreviewModal.Orientation')}
                                    onChange={(e) => setOrientation(e.target.value)}
                                >
                                    <MenuItem value="portrait">{t('PrintPreviewModal.Portrait')}</MenuItem>
                                    <MenuItem value="landscape">{t('PrintPreviewModal.Landscape')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                        {/* Move printRef to only include the grid content */}
                        <div
                            style={{
                                width: `${gridWidth}px`,
                                height: `${gridHeight}px`,
                                overflow: 'hidden',
                                marginTop: '20px',
                                border: '1px solid #ccc',
                            }}
                        >
                            {/* Render the layout here for preview */}
                            <div
                                id="print-grid-container"
                                ref={printRef} // Moved ref here
                                style={{
                                    backgroundColor: '#fff',
                                    position: 'relative',
                                    boxSizing: 'border-box',
                                    userSelect: 'none',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                {!isInitializing && internalLayout.length > 0 && (
                                    <ReactGridLayout
                                        className="layout"
                                        layout={internalLayout}
                                        cols={12}
                                        rowHeight={30}
                                        width={gridWidth}
                                        isDraggable={false}
                                        isResizable={false}
                                        useCSSTransforms={true}
                                        isBounded={false}
                                    >
                                        {filteredItems.map((item) => (
                                            <div
                                                key={item.instanceId}
                                                data-grid={internalLayout.find((l) => l.i === item.instanceId)}
                                            >
                                                {item.type === 'box' ? (
                                                    <BoxItem
                                                        box={item}
                                                        isPreview={true}
                                                        showFoodPortions={showFoodPortions}
                                                        foodSettings={foodSettings}
                                                    />
                                                ) : (
                                                    <RoomItem room={item} isPreview={true} />
                                                )}
                                            </div>
                                        ))}
                                    </ReactGridLayout>
                                )}
                            </div>
                        </div>
                        <Button
                            variant="contained"
                            sx={{ mt: 2 }}
                            disabled={isLoading}
                            onClick={handlePrint}
                        >
                            {isLoading ? t('PrintPreviewModal.PreparingDocument') : t('PrintPreviewModal.Print')}
                        </Button>
                    </>
                ) : (
                    <>
                        <Typography variant="body1">
                            {t('PrintPreviewModal.PleaseSelectLocationBuilding')}
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                            <Select
                                value={selectedLocation}
                                onChange={handleLocationChange}
                                displayEmpty
                                fullWidth
                                sx={{ marginBottom: 2 }}
                            >
                                <MenuItem value="" disabled>
                                    {t('PrintPreviewModal.SelectLocation')}
                                </MenuItem>
                                {locations.map((location) => (
                                    <MenuItem key={location.id} value={location.id}>
                                        {location.name}
                                    </MenuItem>
                                ))}
                            </Select>

                            {selectedLocation && (
                                <Select
                                    value={selectedBuilding}
                                    onChange={handleBuildingChange}
                                    displayEmpty
                                    fullWidth
                                    sx={{ marginBottom: 2 }}
                                >
                                    <MenuItem value="" disabled>
                                        {t('PrintPreviewModal.SelectBuilding')}
                                    </MenuItem>
                                    {buildings.map((building) => (
                                        <MenuItem key={building.id} value={building.id}>
                                            {building.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default PrintPreviewModal;
