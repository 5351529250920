// path: src/components/HomeDashboards/DashboardComponents/DashboardCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';

const DashboardCard = ({ titleKey, count, onClick }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClick = () => {
        if (onClick) {
            navigate(onClick);
        }
    };

    return (
        <Grid item xs={12} sm={6} md={3}>
            <Card onClick={handleClick} sx={{ cursor: onClick ? 'pointer' : 'default' }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        {t(titleKey)}
                    </Typography>
                    <Typography variant="h5">
                        {count}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );
};

DashboardCard.propTypes = {
    titleKey: PropTypes.string.isRequired,
    count: PropTypes.number,
    onClick: PropTypes.string // The path to navigate to when the card is clicked
};

DashboardCard.defaultProps = {
    count: 0,
    onClick: null
};

export default DashboardCard;
