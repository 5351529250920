import React, { useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { loginWithAzure, signUpUser } from '../services/authService';
import { jwtDecode } from 'jwt-decode'; 
import SnackbarAlert from './SnackbarAlert';
import { Typography, Box, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const tenantURL = process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME;
const tenantClientId = process.env.REACT_APP_AZURE_AD_B2C_CLIENT_ID;

function AuthRedirect() {
    const { instance, accounts, inProgress } = useMsal();
    const { t } = useTranslation();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const location = useLocation();
    const { login, loginPostInformation } = useAuth();
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'info',
    });

    // Retrieve any state or query parameters
    const params = new URLSearchParams(location.search);
    const signUpType = params.get('signuptype') || 'default'; // Adjust as per your needs
    const customScope = `https://${tenantURL}.onmicrosoft.com/API-Scopes/Permission.All`;

    useEffect(() => {
        const handleAuthentication = async () => {
            await instance.initialize();
            let attempts = 0;
            const maxRetries = 2;

            while (attempts < maxRetries) {
                try {
                    const authResult = await instance.handleRedirectPromise();

                    if (authResult) {
                        const { accessToken, idToken, account, state } = authResult;
                        const decodedToken = jwtDecode(idToken);
                        const email = decodedToken.emails ? decodedToken.emails[0] : '';

                        // Parse the state parameter
                        const decodedState = state ? JSON.parse(state) : {};

                        if (decodedState.signUpType) {
                            // Sign-up flow
                            const userData = {
                                homeAccountId: account.homeAccountId,
                                azSubject: decodedToken.sub,
                                email: email,
                                oldEmail: decodedState.email,
                                organizationId: decodedState.organizationId,
                            };

                            await signUpUser(userData);

                            // Redirect based on signUpType
                            let redirectPath = '/';
                            switch (decodedState.signUpType) {
                                case 'guided':
                                    redirectPath = '/auth/guided-signup';
                                    break;
                                case 'organization':
                                    redirectPath = '/auth/organization-signup';
                                    break;
                                default:
                                    break;
                            }
                            // Pass state when navigating
                            navigate(redirectPath, { state: decodedState });
                        } else {
                            // Sign-in flow
                            await login(accessToken);
                            const userData = await loginWithAzure(email, account.homeAccountId, accessToken);
                            await loginPostInformation(userData);
                            navigate('/');
                        }
                        return; // Exit after successful handling
                    } else {
                        // If authResult is null, wait and retry
                        attempts += 1;
                        if (attempts < maxRetries) {
                            await new Promise(res => setTimeout(res, 500)); // Wait 1 second before retry
                        }
                    }
                } catch (error) {
                    console.error('Error during authentication process:', error);
                    setSnackbar({
                        open: true,
                        message: t('Authentication.error', { error: error.message }),
                        severity: 'error',
                    });
                    navigate('/auth');
                    return;
                }
            }

            // After retries, check if user is authenticated via accounts
            if (accounts.length > 0) {
                try {
                    const account = accounts[0];
                    const response = await instance.acquireTokenSilent({
                        scopes: [customScope],
                        account: account
                    });
                    const decodedToken = jwtDecode(response.idToken);
                    const email = decodedToken.emails ? decodedToken.emails[0] : '';

                    await login(response.accessToken);
                    const userData = await loginWithAzure(email, account.homeAccountId, response.accessToken);
                    await loginPostInformation(userData);
                    navigate('/');
                } catch (silentError) {
                    console.error('Silent token acquisition failed:', silentError);
                    setSnackbar({
                        open: true,
                        message: t('Authentication.silentAuthFailed'),
                        severity: 'warning',
                    });
                    navigate('/login');
                }
            } else {
                // No accounts found, prompt user to login
                setSnackbar({
                    open: true,
                    message: t('Authentication.notAuthenticated'),
                    severity: 'warning',
                });
                navigate('/login');
            }
        };

        handleAuthentication();
    }, [instance, accounts, navigate, login, loginPostInformation, t, customScope]);

    return (
        <div>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography variant="h6">{t('Authentication.waitingText')}</Typography>
                <LinearProgress />
            </Box>
            <SnackbarAlert
                open={snackbar.open}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </div>
    );
}

export default AuthRedirect;
