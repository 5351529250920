import { useEffect } from 'react';

const useMapDocumentToDeliveryNote = (state, initialDeliveryNoteData, setDeliveryNoteData) => {
    useEffect(() => {
        if (state?.fromDocument) {
            const { sourceDocumentType, ...fromDocument } = state.fromDocument;

            let mappedDeliveryNoteData = { ...initialDeliveryNoteData };

            switch (sourceDocumentType) {
                case 'salesOrder':
                    mappedDeliveryNoteData = {
                        ...mappedDeliveryNoteData,
                        customerName: fromDocument.customerName,
                        customerId: fromDocument.customerId,
                        companyName: fromDocument.companyName,
                        companyBRN: fromDocument.companyBRN,
                        recipientType: fromDocument.recipientType,
                        deliveryDate: fromDocument.deliveryDate || initialDeliveryNoteData.deliveryDate,
                        issueDate: fromDocument.orderDate || initialDeliveryNoteData.issueDate,
                        totalAmount: fromDocument.totalAmount,
                        totalNetAmount: fromDocument.totalNetAmount,
                        taxAmount: fromDocument.taxAmount,
                        organizationId: fromDocument.organizationId,
                        address: { ...fromDocument.address },
                        rows: fromDocument.rows.map(row => ({
                            description: row.description,
                            itemId: row.itemId,
                            quantity: row.quantity,
                            bruttoPrice: row.bruttoPrice,
                            netPrice: row.netPrice,
                            discountPercentage: row.discountPercentage,
                            discountAmount: row.discountAmount,
                            taxRate: row.taxRate,
                            rowTotal: row.rowTotal,
                            type: row.type,
                        })),
                        references: [...fromDocument.references],
                    };
                    break;

                case 'costEstimation':
                    mappedDeliveryNoteData = {
                        ...mappedDeliveryNoteData,
                        customerName: fromDocument.customerName,
                        customerId: fromDocument.customerId,
                        companyName: fromDocument.companyName,
                        companyBRN: fromDocument.companyBRN,
                        recipientType: fromDocument.recipientType,
                        deliveryDate: fromDocument.deliveryDate || initialDeliveryNoteData.deliveryDate,
                        issueDate: fromDocument.orderDate || initialDeliveryNoteData.issueDate,
                        totalAmount: fromDocument.totalAmount,
                        totalNetAmount: fromDocument.totalNetAmount,
                        taxAmount: fromDocument.taxAmount,
                        organizationId: fromDocument.organizationId,
                        address: { ...fromDocument.address },
                        rows: fromDocument.rows.map(row => ({
                            description: row.description,
                            itemId: row.itemId,
                            quantity: row.quantity,
                            bruttoPrice: row.bruttoPrice,
                            netPrice: row.netPrice,
                            discountPercentage: row.discountPercentage,
                            discountAmount: row.discountAmount,
                            taxRate: row.taxRate,
                            rowTotal: row.rowTotal,
                            type: row.type,
                        })),
                        references: [...fromDocument.references],
                    };
                    break;

                // Add cases for other document types as needed.
                default:
                    console.warn(`Unsupported document type: ${sourceDocumentType}`);
                    break;
            }

            setDeliveryNoteData(mappedDeliveryNoteData);
        }
    }, [state]);
};

export default useMapDocumentToDeliveryNote;
