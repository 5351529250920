// src/pages/DeliveryPlanning_Routes/RouteOverviewPage.js
import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Paper,
    Typography,
    IconButton,
    LinearProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
    ViewModule as ViewModuleIcon,
    ViewList as ViewListIcon,
    Visibility as EyeIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import GenericTableView from '../../components/GenericTableView';
import GenericCardView from '../../components/GenericCardView';
import SnackbarAlert from '../../components/SnackbarAlert';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { usePreferences } from '../../context/UserPreferencesContext';
import { useAuth } from '../../context/AuthContext';
import { fetchAllRoutes, deleteRoute } from '../../services/RouteService';
import { useTranslation } from 'react-i18next';

const RouteOverviewPage = () => {
    const navigate = useNavigate();
    const { devicePreferences, updateDevicePreferences } = usePreferences();
    const { apiKey, organizationId, token: jwtToken } = useAuth();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [routes, setRoutes] = useState([]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: '',
    });
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [selectedRouteId, setSelectedRouteId] = useState(null);
    const [viewMode, setViewMode] = useState(
        devicePreferences.viewMode || 'table'
    );

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const routeData = await fetchAllRoutes(apiKey, organizationId, jwtToken);
                setRoutes(routeData);
                setIsLoading(false);
            } catch (error) {
                console.error(t('RouteOverviewPage.errorFetchingRoutes'), error);
                setSnackbar({
                    open: true,
                    message: t('RouteOverviewPage.errorFetchingRoutes'),
                    severity: 'error',
                });
                setIsLoading(false);
            }
        };

        fetchData();
    }, [apiKey, organizationId, jwtToken, t]);

    const handleRowClick = (routeId) => {
        navigate(`/deliveryPlanning/routes/overview/${routeId}`);
    };

    const handleDeleteClick = (routeId) => {
        setSelectedRouteId(routeId);
        setConfirmDialogOpen(true);
    };

    const handleDeleteConfirmation = async () => {
        try {
            await deleteRoute(selectedRouteId, apiKey, organizationId, jwtToken);
            setRoutes(routes.filter((route) => route.id !== selectedRouteId));
            setSnackbar({
                open: true,
                message: t('RouteOverviewPage.routeDeleted'),
                severity: 'success',
            });
            setConfirmDialogOpen(false);
        } catch (error) {
            console.error(t('RouteOverviewPage.errorDeletingRoute'), error);
            setSnackbar({
                open: true,
                message: t('RouteOverviewPage.errorDeletingRoute'),
                severity: 'error',
            });
            setConfirmDialogOpen(false);
        }
    };

    const toggleViewMode = () => {
        const newViewMode = viewMode === 'table' ? 'card' : 'table';
        setViewMode(newViewMode);
        updateDevicePreferences('viewMode', newViewMode);
    };

    if (isLoading) {
        return (
            <div>
                <Typography variant="h6">{t('RouteOverviewPage.loadingRoutes')}</Typography>
                <LinearProgress />
            </div>
        );
    }

    const structure = {
        headers: [
            {
                key: 'name',
                label: t('RouteOverviewPage.table.routeName'),
                display: { value: true },
            },
            {
                key: 'customerCount',
                label: t('RouteOverviewPage.table.customers'),
                display: { value: true },
            },
        ],
        rows: routes.map((route) => {
            const customerCount = route.customers.length;
            return {
                id: route.id,
                name: { value: route.name },
                customerCount: { value: customerCount },
                actions: [
                    {
                        icon: <EyeIcon />,
                        handler: () => navigate(`/deliveryPlanning/routes/overview/${route.id}`),
                        ariaLabel: t('RouteOverviewPage.viewRouteAriaLabel'),
                    },
                    {
                        icon: <EditIcon />,
                        handler: () => navigate(`/deliveryPlanning/routes/edit/${route.id}`),
                        ariaLabel: t('RouteOverviewPage.editRouteAriaLabel'),
                    },
                    {
                        icon: <DeleteIcon />,
                        handler: () => handleDeleteClick(route.id),
                        ariaLabel: t('RouteOverviewPage.deleteRouteAriaLabel'),
                    },
                ],
            };
        }),
    };

    return (
        <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
            <Typography variant="h4" gutterBottom>
                {t('RouteOverviewPage.title')}
            </Typography>

            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mt: 4, mb: 2 }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/deliveryPlanning/routes/edit/new')}
                    startIcon={<AddIcon />}
                >
                    {t('RouteOverviewPage.addRouteButton')}
                </Button>

                <IconButton onClick={toggleViewMode} color="primary" aria-label={t('RouteOverviewPage.toggleViewModeAriaLabel')}>
                    {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
                </IconButton>
            </Box>

            {viewMode === 'table' ? (
                <GenericTableView structure={structure} onClick={handleRowClick} />
            ) : (
                <GenericCardView structure={structure} onClick={handleRowClick} />
            )}

            <SnackbarAlert
                open={snackbar.open}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
                severity={snackbar.severity}
            />

            <ConfirmationDialog
                isOpen={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                onConfirm={handleDeleteConfirmation}
                text={t('RouteOverviewPage.deleteConfirmation')}
            />
        </Paper>
    );
};

export default RouteOverviewPage;
