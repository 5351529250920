// src/components/RidingLessons/RidingLessonsOverview.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Paper, Typography, LinearProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Chip from '@mui/material/Chip';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import GenericCardView from '../../components/GenericCardView'; // Assuming you have this component
import { IconButton } from '@mui/material';
import RidingLessonBookingModal from '../../components/RidingLessonBookingModal'; // Assuming you have this component
import { getAllHorses, getHorsesByOwnerID } from '../../services/horseService';
import { getAllOwners } from '../../services/ownerService';
import { fetchTeacherSettings } from '../../services/TeacherSettingsService';
import { fetchServiceTypes } from '../../services/serviceTypesService';
import { fetchOwnerBookings, fetchBookings } from '../../services/bookingService';
import { useTheme, useMediaQuery } from '@mui/material';

const RidingLessonsOverview = () => {
  const { apiKey, userId, userEmail, userType, organizationId, token: jwtToken } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [ridingLessons, setRidingLessons] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [horses, setHorses] = useState([]);
  const [owners, setOwners] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const initialBookingData = {
    id: '',
    riderName: '',
    riderId: '',
    horseId: '',
    bookingName: '',
    teacherId: '',
    dateTime: new Date(),
    duration: '',
    serviceTypeId: '',
    status: 'Booked',
    isPaid: false
  };
  const [selectedBooking, setSelectedBooking] = useState(initialBookingData);


  const renderPaymentStatus = (isPaid) => {
    if (isPaid) {
      return <Chip icon={<CheckCircleIcon />} label={t('RidingLessonsOverview.table.paid')} color="success" />;
    } else {
      return <Chip icon={<CancelIcon />} label={t('RidingLessonsOverview.table.unpaid')} color="error" />;
    }
  };

  const [structure, setStructure] = useState({
    headers: [{
      key: 'riderName', label: t('RidingLessonsOverview.table.name'), display: { value: true, chipColor: false }
    }, {
      key: 'horseName', label: t('RidingLessonsOverview.table.horseName'), display: { value: true, chipColor: false }
    }, {
      key: 'teacher', label: t('RidingLessonsOverview.table.teacher'), display: { value: true, chipColor: false }
    }, {
      key: 'dateTime', label: t('RidingLessonsOverview.table.dateTime'), display: { value: true, chipColor: false }
    }, {
      key: 'duration', label: t('RidingLessonsOverview.table.duration'), display: { value: true, chipColor: false }
    }, {
      key: 'isPaid', label: t('RidingLessonsOverview.table.isPaid'), display: { value: true, chipColor: false }
    }],
    rows: []
  });

  //mockedData
  useEffect(() => {

    Promise.all([
      fetchTeacherSettings(apiKey, organizationId, jwtToken),
      fetchServiceTypes(apiKey, organizationId, jwtToken),
      getAllHorses(apiKey, organizationId, jwtToken),
      getAllOwners(apiKey, organizationId, jwtToken),
      fetchBookings(apiKey, organizationId, jwtToken)
    ]).then(([teachersData, serviceTypesData, horsesData, ownersData, bookingsData]) => {
      setTeachers(teachersData);
      setServiceTypes(serviceTypesData);
      setHorses(horsesData);
      setOwners(ownersData);
      setBookings(bookingsData);
      setStructure(prevStructure => ({
        ...prevStructure,
        headers: prevStructure.headers, // Assuming headers remain unchanged
        rows: bookingsData.map(row => ({
          id: row.id,
          riderName: {
            value: row.riderName,
            icon: null
          },
          horseName: {
            value: horsesData?.find(h => h.id === row.horseId)?.name,
            icon: null
          },
          teacher: {
            value: teachersData?.find(t => t.id === row.teacherId)?.name,
            icon: null
          },
          dateTime: {
            value: new Date(row.dateTime).toLocaleString(),
            icon: null
          },
          duration: {
            value: row.duration,
            icon: null
          },
          isPaid: {
            value: null,
            icon: renderPaymentStatus(row.isPaid)
          }
        }))
      }));
    }).catch(err => console.error("Failed to fetch teachers or service types:", err))
      .finally(() => setIsLoading(false));

  }, []);

  const openBookingModal = (selectedbookingId) => {
    if (selectedbookingId) {
      setSelectedBooking(bookings.find(b => b.id === selectedbookingId));
    }
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedBooking(initialBookingData);
  }

  const handleSave = (bookingData) => {
    // fetch bookings again
    fetchBookings(apiKey, organizationId, jwtToken)
      .then(bookingsData => {
        setBookings(bookingsData);
        setStructure(prevStructure => ({
          ...prevStructure,
          headers: prevStructure.headers, // Assuming headers remain unchanged
          rows: bookingsData.map(row => ({
            id: row.id,
            riderName: {
              value: row.riderName,
              icon: null
            },
            horseName: {
              value: horses.find(h => h.id === row.horseId)?.name,
              icon: null
            },
            teacher: {
              value: teachers.find(t => t.id === row.teacherId)?.name,
              icon: null
            },
            dateTime: {
              value: new Date(row.dateTime).toLocaleString(),
              icon: null
            },
            duration: {
              value: row.duration,
              icon: null
            },
            status: {
              value: row.status,
              icon: null
            },
            isPaid: {
              value: null,
              icon: renderPaymentStatus(row.isPaid)
            }
          }))
        }))
      }).catch(err => console.error("Failed to fetch bookings:", err))
      .finally(() => setIsLoading(false));
    handleModalClose();
  }



  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {t('RidingLessonsOverview.title')}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 4, mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => openBookingModal()}
        >
          {t('RidingLessonsOverview.addLesson')}
        </Button>
      </Box>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <GenericCardView structure={structure} onClick={openBookingModal} />
      )}
      <RidingLessonBookingModal open={modalOpen} onClose={handleModalClose} lessonData={selectedBooking} horses={horses} teachers={teachers} serviceTypes={serviceTypes} owners={owners} onSave={handleSave} />
    </Paper>
  );
};

export default RidingLessonsOverview;
