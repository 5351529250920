// src/components/OrgTabs/FoodSettings/FoodSettings.js

import React from 'react';
import { Button, Box, Typography, LinearProgress } from '@mui/material';
import useFoodSettings from './useFoodSettings';
import { useAuth } from '../../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import FoodItem from './FoodItem';

const FoodSettings = () => {
    const { apiKey, organizationId, token } = useAuth();
    const { t } = useTranslation();
    const {
        foods, loading, items, measureUnits,
        handleInputChange, handleCheckboxChange,
        handleAddFood, handleRemoveFood, handleSubmit,
        handlePortionChange, handleAddPortion, handleRemovePortion, handleAutocompleteChange
    } = useFoodSettings(apiKey, organizationId, token);

    if (loading) return <LinearProgress />;

    return (
        <form onSubmit={handleSubmit}>
            <Typography variant="h4">{t('OrganizationSettings.FoodSettings.title')}</Typography>
            {foods.map((food, index) => (
                <FoodItem
                    key={index}
                    food={food}
                    index={index}
                    items={items}
                    measureUnits={measureUnits}
                    onInputChange={handleInputChange}
                    onCheckboxChange={handleCheckboxChange}
                    onRemove={handleRemoveFood}
                    onPortionChange={handlePortionChange}
                    onAddPortion={handleAddPortion}
                    onRemovePortion={handleRemovePortion}
                    handleAutocompleteChange={handleAutocompleteChange}
                />
            ))}
            <Box>
                <Button variant="contained" color="primary" onClick={handleAddFood}>{t('OrganizationSettings.FoodSettings.Add')}</Button>
                <Button variant="contained" color="secondary" type="submit">{t('OrganizationSettings.FoodSettings.Save')}</Button>
            </Box>
        </form>
    );
};

export default FoodSettings;
