import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button,
    TextField, Box, Typography, Chip, IconButton, Select, MenuItem, Card, CardContent, Paper
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { fetchCategories, saveCategory, updateCategory } from '../services/categoryService';
import { fetchMeasureUnits, saveMeasureUnit } from '../services/measureUnitsService';
import { createItem } from '../services/itemService';
import SnackbarAlert from './SnackbarAlert';
import { useAuth } from '../context/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import CategoryIcon from '@mui/icons-material/Category';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DeleteIcon from '@mui/icons-material/Delete';

const SuggestionModal = ({ open, onClose, suggestions, onSave }) => {
    const { t } = useTranslation();
    const [localSuggestions, setLocalSuggestions] = useState({ rows: [], categories: [], measureUnits: [] });
    const { apiKey, organizationId, token } = useAuth();
    const [categories, setCategories] = useState([]);
    const [measureUnits, setMeasureUnits] = useState([]);
    const [combinedCategories, setCombinedCategories] = useState([]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
    });

    useEffect(() => {
        setLocalSuggestions({ rows: suggestions?.rows, categories: suggestions?.suggestions.categories, measureUnits: suggestions?.suggestions.measureUnits });
        console.log(suggestions);
    }, [suggestions]);

    useEffect(() => {
        fetchCategories(apiKey, organizationId, token).then(data => setCategories(data));
        fetchMeasureUnits(apiKey, organizationId, token).then(data => setMeasureUnits(data));
    }, [apiKey, organizationId, token]);

    useEffect(() => {
        setCombinedCategories(combineData());
    }, [categories, localSuggestions.categories]);

    const combineData = () => {
        const combinedCategories = categories.map(cat => ({
            ...cat,
            subcategories: cat.subcategories.map(subcat => ({
                ...subcat,
                subcategoryId: subcat.subcategoryId || uuidv4()
            }))
        }));

        localSuggestions?.categories?.forEach(suggestedCategory => {
            let existingCategory = combinedCategories.find(category => category.categoryId === suggestedCategory.categoryId);
            if (!existingCategory) {
                suggestedCategory.categoryId = suggestedCategory.categoryId || uuidv4();
                suggestedCategory.subcategories = suggestedCategory.subcategories.map(subcategory => ({
                    ...subcategory,
                    subcategoryId: subcategory.subcategoryId || uuidv4(),
                    subcategoryName: subcategory.name,
                }));
                combinedCategories.push(suggestedCategory);
            } else {
                mergeSubcategoriesAndUnits(existingCategory, suggestedCategory);
            }
            suggestedCategory.suggestionforRows?.forEach((rowIndex) => {
                localSuggestions.rows.find(row => parseInt(row.rowNumber) === rowIndex).categoryId = suggestedCategory.categoryId;
            });
            suggestedCategory.subcategories.forEach(suggestedSubcategory => {
                suggestedSubcategory.suggestionforRows?.forEach((rowIndex) => {
                    localSuggestions.rows.find(row => parseInt(row.rowNumber) === rowIndex).subcategoryId = suggestedSubcategory.subcategoryId;
                })
            });
        });
        return combinedCategories;
    };

    const mergeSubcategoriesAndUnits = (existingCategory, suggestedCategory) => {
        suggestedCategory.subcategories.forEach(suggestedSubcategory => {
            const existingSubcategory = existingCategory.subcategories.find(
                subcategory => subcategory.subcategoryId === suggestedSubcategory.subcategoryId
            );

            if (!existingSubcategory) {
                existingCategory.subcategories.push({
                    subcategoryId: suggestedSubcategory.subcategoryId || uuidv4(),
                    subcategoryName: suggestedSubcategory.subcategoryName,
                    measureUnits: [],
                    suggestionforRows: suggestedSubcategory.suggestionforRows
                });
            } else {
                existingSubcategory.suggestionforRows = suggestedSubcategory.suggestionforRows;
            }
        });
    };

    const handleInputChange = (type, field, parentIdx, value, subIdx) => {
        const updatedSuggestions = JSON.parse(JSON.stringify(localSuggestions));
        if (type === 'row') {
            updatedSuggestions.rows[parentIdx][field] = value;
        } else if (type === 'category') {
            updatedSuggestions.categories[parentIdx][field] = value;
        } else if (type === 'subcategory') {
            updatedSuggestions.categories[parentIdx].subcategories[subIdx][field] = value;
        }
        setLocalSuggestions(updatedSuggestions);
    };

    const handleRemove = (type, parentIdx, subIdx) => {
        const updatedSuggestions = JSON.parse(JSON.stringify(localSuggestions));
        if (type === 'row') {
            updatedSuggestions.rows.splice(parentIdx, 1);
        } else if (type === 'category') {
            updatedSuggestions.categories.splice(parentIdx, 1);
        } else if (type === 'subcategory') {
            updatedSuggestions.categories[parentIdx].subcategories.splice(subIdx, 1);
        }
        setLocalSuggestions(updatedSuggestions);
    };

    const renderCategoryChip = (newOrExisting) => (
        <Chip label={newOrExisting === 'new' ? t('new') : t('existing')} />
    );

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleSave = async () => {
        try {
            const cleanSuggestions = localSuggestions;

            await saveAllMeasureUnits(cleanSuggestions);
            await saveAllCategories(cleanSuggestions);
            await saveAllItems(cleanSuggestions);

            setSnackbar({
                open: true,
                message: t('suggestions.saveSuccess'),
                severity: 'success',
            });

            onSave(cleanSuggestions); // Send the updated suggestions back
        } catch (error) {
            console.error('Failed to save suggestions:', error);
            setSnackbar({
                open: true,
                message: t('suggestions.saveError'),
                severity: 'error',
            });
        }
    };

    const saveAllMeasureUnits = async (updatedSuggestions) => {
        for (const measureUnit of updatedSuggestions.measureUnits) {
            if (measureUnit.newOrExisting === 'new') {
                const newMeasureUnit = {
                    id: uuidv4(),
                    nameLong: measureUnit.nameLong,
                    nameShort: measureUnit.nameShort
                };
                const savedMeasureUnit = await saveMeasureUnit(newMeasureUnit, apiKey, organizationId, token);
                measureUnit.id = savedMeasureUnit.id;
            }
        }
    };

    const saveAllCategories = async (updatedSuggestions) => {
        for (const category of updatedSuggestions.categories) {
            if (category.newOrExisting === 'new') {
                await saveNewCategory(category);
            } else {
                await updateExistingCategory(category);
            }
        }
    };

    const saveNewCategory = async (category) => {
        const newCategory = {
            categoryId: uuidv4(),
            categoryName: category.categoryName,
            subcategories: []
        };

        for (const subcategory of category.subcategories) {
            if (subcategory.newOrExisting === 'new') {
                const newSubcategory = {
                    subcategoryId: uuidv4(),
                    subcategoryName: subcategory.name,
                    measureUnits: []
                };
                newCategory.subcategories.push(newSubcategory);
                subcategory.subcategoryId = newSubcategory.subcategoryId;
            }
        }
        const savedCategory = await saveCategory(newCategory, apiKey, organizationId, token);
        category.categoryId = savedCategory.id;
    };

    const updateExistingCategory = async (category) => {
        const existingCategory = categories.find(cat => cat.categoryId === category.categoryId);
        if (existingCategory) {
            const updatedSubcategories = existingCategory.subcategories.slice();
            for (const subcategory of category.subcategories) {
                if (subcategory.newOrExisting === 'new') {
                    const newSubcategory = {
                        subcategoryId: uuidv4(),
                        subcategoryName: subcategory.name,
                        measureUnits: []
                    };
                    updatedSubcategories.push(newSubcategory);
                    subcategory.subcategoryId = newSubcategory.subcategoryId;
                } else if (!subcategory.id) {
                    continue;
                }
            }
            const updatedCategory = {
                ...existingCategory,
                id: category.categoryId,
                subcategories: updatedSubcategories.filter(subcat => subcat.subcategoryId)
            };
            await updateCategory(updatedCategory, apiKey, organizationId, token);
            setLocalSuggestions({
                ...localSuggestions,
                categories: localSuggestions.categories.map(cat => ({
                    ...cat,
                    categoryId: cat.categoryId || updatedCategory.id,
                    subcategories: cat.subcategories.map(subcat => ({
                        ...subcat,
                        subcategoryId: subcat.subcategoryId || updatedCategory.subcategories.find(updatedSubcat => updatedSubcat.subcategoryName === subcat.name).subcategoryId
                    }))
                }))
            });
        }
    };

    const saveAllItems = async (updatedSuggestions) => {
        for (const item of updatedSuggestions.rows) {
            if (item.newOrExisting === 'new' && item.stockItem) {
                const newItem = {
                    name: item.itemDescription,
                    category: item.categoryId,
                    subcategory: item.subcategoryId,
                    measureUnit: item.measureUnitId,
                    stockItem: item.stockItem,
                    lastBoughtPrice: 0,
                    quantity: 0,
                    warningQuantity: 0,
                    showInDashboard: false,
                    organizationId: organizationId,
                };
                const savedItem = await createItem(newItem, apiKey, organizationId, token);
                item.itemId = savedItem.id;
            }
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                {t('suggestions.title')}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Paper elevation={3} sx={{ padding: 2, mb: 3 }}>
                    <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                        <ListAltIcon sx={{ mr: 1 }} />
                        {t('suggestions.item.title')}
                    </Typography>
                    {localSuggestions?.rows?.filter(row => row.stockItem && row.newOrExisting === 'new').map((row, rowIdx) => (
                        <ItemCard
                            key={rowIdx}
                            row={row}
                            rowIdx={rowIdx}
                            combinedCategories={combinedCategories}
                            measureUnits={measureUnits}
                            handleInputChange={handleInputChange}
                            handleRemove={handleRemove}
                            renderCategoryChip={renderCategoryChip}
                            t={t}
                        />
                    ))}
                </Paper>
                <Paper elevation={3} sx={{ padding: 2 }}>
                    <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                        <CategoryIcon sx={{ mr: 1 }} />
                        {t('suggestions.category.title')}
                    </Typography>
                    {localSuggestions?.categories?.map((category, catIdx) => (
                        <CategoryCard
                            key={catIdx}
                            category={category}
                            catIdx={catIdx}
                            handleInputChange={handleInputChange}
                            handleRemove={handleRemove}
                            renderCategoryChip={renderCategoryChip}
                            t={t}
                        />
                    ))}
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">{t('cancel')}</Button>
                <Button onClick={handleSave} color="primary">{t('save')}</Button>
            </DialogActions>
            <SnackbarAlert
                open={snackbar.open}
                onClose={handleSnackbarClose}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </Dialog>
    );
};

const ItemCard = ({ row, rowIdx, combinedCategories, measureUnits, handleInputChange, handleRemove, renderCategoryChip, t }) => (
    <Card variant="outlined" sx={{ mb: 2 }}>
        <CardContent>
            <Typography variant="h6">
                {row.itemDescription} {renderCategoryChip(row.newOrExisting)}
                <IconButton
                    aria-label="delete"
                    onClick={() => handleRemove('row', rowIdx)}
                    sx={{ float: 'right' }}
                    disabled={row.newOrExisting === 'existing'}
                >
                    <DeleteIcon />
                </IconButton>
            </Typography>
            <TextField
                label={t('suggestions.item.name')}
                value={row.itemDescription}
                onChange={(e) => handleInputChange('row', 'itemDescription', rowIdx, e.target.value)}
                fullWidth
                margin="normal"
                disabled={row.newOrExisting === 'existing'}
            />
            <Select
                label={t('suggestions.item.category')}
                value={row.categoryId}
                onChange={(e) => handleInputChange('row', 'categoryId', rowIdx, e.target.value)}
                fullWidth
                margin="normal"
                disabled={row.newOrExisting === 'existing'}
            >
                {combinedCategories.map((category) => (
                    <MenuItem key={category.categoryId} value={category.categoryId}>
                        {category.categoryName}
                    </MenuItem>
                ))}
            </Select>
            <Select
                label={t('suggestions.item.subcategory')}
                value={row.subcategoryId}
                onChange={(e) => handleInputChange('row', 'subcategoryId', rowIdx, e.target.value)}
                fullWidth
                margin="normal"
                disabled={row.newOrExisting === 'existing'}
            >
                {combinedCategories
                    .find(category => category.categoryId === row.categoryId)
                    ?.subcategories?.map(subcategory => (
                        <MenuItem key={subcategory.subcategoryId} value={subcategory.subcategoryId}>
                            {subcategory.subcategoryName}
                        </MenuItem>
                    ))}
            </Select>
            <Select
                label={t('suggestions.item.measureUnit')}
                value={row.measureUnitId}
                onChange={(e) => handleInputChange('row', 'measureUnitId', rowIdx, e.target.value)}
                fullWidth
                margin="normal"
                disabled={row.newOrExisting === 'existing'}
            >
                {measureUnits.map(unit => (
                    <MenuItem key={unit.id} value={unit.id}>
                        {unit.nameLong}
                    </MenuItem>
                ))}
            </Select>
        </CardContent>
    </Card>
);

const CategoryCard = ({ category, catIdx, handleInputChange, handleRemove, renderCategoryChip, t }) => (
    <Card variant="outlined" sx={{ mb: 2 }}>
        <CardContent>
            <Typography variant="h6">
                {category.categoryName} {renderCategoryChip(category.newOrExisting)}
                <IconButton
                    aria-label="delete"
                    onClick={() => handleRemove('category', catIdx)}
                    sx={{ float: 'right' }}
                    disabled={category.newOrExisting === 'existing'}
                >
                    <DeleteIcon />
                </IconButton>
            </Typography>
            <TextField
                label={t('suggestions.category.name')}
                value={category.categoryName}
                onChange={(e) => handleInputChange('category', 'categoryName', catIdx, e.target.value)}
                fullWidth
                margin="normal"
                disabled={category.newOrExisting === 'existing'}
            />
            {category.subcategories.map((subcategory, subIdx) => (
                <SubcategoryCard
                    key={subIdx}
                    subcategory={subcategory}
                    catIdx={catIdx}
                    subIdx={subIdx}
                    handleInputChange={handleInputChange}
                    handleRemove={handleRemove}
                    renderCategoryChip={renderCategoryChip}
                    t={t}
                />
            ))}
        </CardContent>
    </Card>
);

const SubcategoryCard = ({ subcategory, catIdx, subIdx, handleInputChange, handleRemove, renderCategoryChip, t }) => (
    <Box ml={2} mt={2}>
        <Typography variant="subtitle1">
            {subcategory.name} {renderCategoryChip(subcategory.newOrExisting)}
            <IconButton
                aria-label="delete"
                onClick={() => handleRemove('subcategory', catIdx, subIdx)}
                sx={{ float: 'right' }}
                disabled={subcategory.newOrExisting === 'existing'}
            >
                <DeleteIcon />
            </IconButton>
        </Typography>
        <TextField
            label={t('suggestions.subcategory.name')}
            value={subcategory.name}
            onChange={(e) => handleInputChange('subcategory', 'name', catIdx, subIdx, e.target.value)}
            fullWidth
            margin="normal"
            disabled={subcategory.newOrExisting === 'existing'}
        />
    </Box>
);

export default SuggestionModal;
