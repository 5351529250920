// src/pages/DeliveryPlanEditPage.js

import React, { useState, useEffect, useRef } from 'react';
import {
    Box,
    Button,
    Paper,
    Typography,
    TextField,
    IconButton,
    LinearProgress,
    Chip,
    Snackbar,
    Alert,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';

import RouteComponent from './RouteComponent';
import SortableSalesOrderItem from './SortableSalesOrderItem';
import AddRouteDialog from '../../components/DeliveryComponents/AddRouteDialog';
import { useAuth } from '../../context/AuthContext';
import { fetchVehicles } from '../../services/VehicleService';
import { fetchDrivers } from '../../services/DriverService';
import { fetchSummarySalesOrders } from '../../services/SalesOrderService';
import { fetchDeliveryPlanById, saveDeliveryPlan, updateDeliveryPlan } from '../../services/DeliveryPlanService';
import { fetchAllRoutes } from '../../services/RouteService';

import { useTranslation } from 'react-i18next';
import { format, isValid } from 'date-fns';
import { useDateLocale } from '../../hooks/useDateLocale';

const DeliveryPlanEditPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const isEditMode = id !== 'new';
    const { apiKey, organizationId, token: jwtToken } = useAuth();

    const [deliveryPlan, setDeliveryPlan] = useState({
        id: '',
        deliveryDate: new Date(),
        routes: [],
        status: 'draft',
        deliveryPlanNumber: '',
    });
    const [loading, setLoading] = useState(true);
    const [vehicles, setVehicles] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [salesOrders, setSalesOrders] = useState([]);

    const [itemsByContainer, setItemsByContainer] = useState({});
    const [activeId, setActiveId] = useState(null);

    const [addRouteDialogOpen, setAddRouteDialogOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const unassignedOrdersRef = useRef(null);

    const { t } = useTranslation();
    const dateLocale = useDateLocale();

    const availableHeight = 'calc(100vh - 300px)';

    const isCompleted = deliveryPlan.status === 'complete';

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                const [vehiclesData, driversData] = await Promise.all([
                    fetchVehicles(apiKey, organizationId, jwtToken),
                    fetchDrivers(apiKey, organizationId, jwtToken),
                ]);

                setVehicles(vehiclesData);
                setDrivers(driversData);

                const filters = {
                    status: ['approved', 'draft'], // Exclude 'complete' if desired
                };

                // Fetch sales orders with specific statuses
                const salesOrdersData = await fetchSummarySalesOrders(apiKey, organizationId, jwtToken, filters);

                let initialDeliveryPlan = deliveryPlan;

                if (isEditMode) {
                    const existingPlan = await fetchDeliveryPlanById(apiKey, organizationId, jwtToken, id);
                    if (existingPlan) {
                        initialDeliveryPlan = existingPlan;
                        setDeliveryPlan(existingPlan);
                    }
                }

                // Extract sales orders from routes, regardless of their status
                const routeSalesOrders = initialDeliveryPlan.routes.flatMap(route => route.salesOrders);

                // Convert routeSalesOrders to a map for easy access and to prevent duplicates
                const routeSalesOrdersMap = {};
                routeSalesOrders.forEach(order => {
                    routeSalesOrdersMap[order.id] = order;
                });

                // Merge fetched sales orders with route sales orders
                const allSalesOrdersMap = { ...routeSalesOrdersMap };
                salesOrdersData.forEach(order => {
                    allSalesOrdersMap[order.id] = order;
                });

                const allSalesOrders = Object.values(allSalesOrdersMap);

                setSalesOrders(allSalesOrders);

                const initialItemsByContainer = {};

                let assignedOrderIds = [];
                initialDeliveryPlan.routes.forEach((route) => {
                    assignedOrderIds = assignedOrderIds.concat(route.salesOrders.map(order => order.id));
                });

                // Unassigned orders are those fetched but not assigned to any route
                const unassignedOrders = allSalesOrders.filter(
                    (order) => !assignedOrderIds.includes(order.id)
                );

                initialItemsByContainer['unassigned'] = unassignedOrders.map((order) => order.id);

                initialDeliveryPlan.routes.forEach((route) => {
                    initialItemsByContainer[route.id] = route.salesOrders.map((order) => order.id);
                });

                setItemsByContainer(initialItemsByContainer);

            } catch (error) {
                console.error('Error loading data:', error);
                setSnackbar({ open: true, message: t('DeliveryPlanEditPage.errorLoadingData', { error: error.message }), severity: 'error' });
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, [isEditMode, id, apiKey, organizationId, jwtToken, t]);

    const handleDateChange = (date) => {
        if (isCompleted) return; // Prevent changing date if completed
        setDeliveryPlan((prevPlan) => ({
            ...prevPlan,
            deliveryDate: date,
        }));
        setTimeout(() => {
            scrollToDate(date);
        }, 100);
    };

    const scrollToDate = (date) => {
        if (unassignedOrdersRef.current) {
            const dateString = formatDate(date);
            const dateSection = document.getElementById(`date-section-${dateString}`);
            if (dateSection) {
                dateSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    const formatDate = (date) => {
        if (!date || !isValid(new Date(date))) return '';
        return format(new Date(date), 'P', { locale: dateLocale });
    };

    const handleAddRouteClick = () => {
        if (isCompleted) {
            setSnackbar({ open: true, message: t('DeliveryPlanEditPage.cannotEditCompletedPlan'), severity: 'warning' });
            return;
        }
        setAddRouteDialogOpen(true);
    };

    const handleAddRoute = async (option, selectedRouteIds) => {
        if (isCompleted) {
            setSnackbar({ open: true, message: t('DeliveryPlanEditPage.cannotEditCompletedPlan'), severity: 'warning' });
            return;
        }

        if (option === 'new') {
            // Create a new, empty route
            const newRouteId = uuidv4();
            const newRoute = {
                id: newRouteId,
                vehicleId: '',
                driverId: '',
                name: `${t('DeliveryPlanEditPage.routeNamePrefix')} ${deliveryPlan.routes.length + 1}`,
                salesOrders: [],
            };
            setDeliveryPlan((prevPlan) => ({
                ...prevPlan,
                routes: [...prevPlan.routes, newRoute],
            }));

            setItemsByContainer((prevItems) => ({
                ...prevItems,
                [newRouteId]: [],
            }));

            setSnackbar({ open: true, message: t('DeliveryPlanEditPage.newRouteCreated'), severity: 'success' });
        } else if (option === 'existing') {
            if (selectedRouteIds.length === 0) {
                setSnackbar({ open: true, message: t('DeliveryPlanEditPage.noRoutesSelected'), severity: 'warning' });
                return;
            }

            try {
                // Fetch all routes and filter selected ones
                const existingAllRoutes = await fetchAllRoutes(apiKey, organizationId, jwtToken);
                const existingRoutes = existingAllRoutes.filter((route) => selectedRouteIds.includes(route.id));

                existingRoutes.forEach((route) => {
                    const salesOrdersToAdd = [];

                    // Iterate over customers in the route, maintaining their order
                    route.customers.forEach((customer) => {
                        // Find matching sales order for the customer on the delivery date
                        const matchingOrder = salesOrders.find((order) => {
                            return order.customerId === customer.id && formatDate(order.deliveryDate) === formatDate(deliveryPlan.deliveryDate);
                        });
                        if (matchingOrder) {
                            salesOrdersToAdd.push(matchingOrder);
                        }
                    });

                    if (salesOrdersToAdd.length > 0) {
                        const newRouteId = uuidv4();
                        const newRoute = {
                            id: newRouteId,
                            vehicleId: route.vehicleId || '',
                            driverId: route.driverId || '',
                            name: route.name || `${t('DeliveryPlanEditPage.routeNamePrefix')} ${deliveryPlan.routes.length + 1}`,
                            salesOrders: [], // We'll manage sales orders via itemsByContainer
                        };
                        setDeliveryPlan((prevPlan) => ({
                            ...prevPlan,
                            routes: [...prevPlan.routes, newRoute],
                        }));

                        setItemsByContainer((prevItems) => ({
                            ...prevItems,
                            [newRouteId]: [...(prevItems[newRouteId] || []), ...salesOrdersToAdd.map(order => order.id)],
                            unassigned: prevItems.unassigned.filter(id => !salesOrdersToAdd.map(order => order.id).includes(id)),
                        }));
                    }
                });

                setSnackbar({ open: true, message: t('DeliveryPlanEditPage.routesAddedSuccessfully'), severity: 'success' });
            } catch (error) {
                console.error(t('DeliveryPlanEditPage.errorAddingRoutes'), error);
                setSnackbar({ open: true, message: t('DeliveryPlanEditPage.errorAddingRoutes', { error: error.message }), severity: 'error' });
            }
        }
    };

    const handleRemoveRoute = (routeId) => {
        if (isCompleted) {
            setSnackbar({ open: true, message: t('DeliveryPlanEditPage.cannotEditCompletedPlan'), severity: 'warning' });
            return;
        }

        setDeliveryPlan((prevPlan) => ({
            ...prevPlan,
            routes: prevPlan.routes.filter((route) => route.id !== routeId),
        }));

        setItemsByContainer((prevItems) => {
            const newItems = { ...prevItems };
            const routeOrderIds = newItems[routeId];
            newItems['unassigned'] = [...newItems['unassigned'], ...routeOrderIds];
            delete newItems[routeId];
            return newItems;
        });

        setSnackbar({ open: true, message: t('DeliveryPlanEditPage.routeRemovedSuccessfully'), severity: 'info' });
    };

    const handleRouteChange = (updatedRoute) => {
        if (isCompleted) {
            setSnackbar({ open: true, message: t('DeliveryPlanEditPage.cannotEditCompletedPlan'), severity: 'warning' });
            return;
        }

        setDeliveryPlan((prevPlan) => ({
            ...prevPlan,
            routes: prevPlan.routes.map((route) =>
                route.id === updatedRoute.id ? updatedRoute : route
            ),
        }));
    };

    const handleSave = async () => {
        if (isCompleted) {
            setSnackbar({ open: true, message: t('DeliveryPlanEditPage.cannotEditCompletedPlan'), severity: 'warning' });
            return;
        }

        const updatedRoutes = deliveryPlan.routes.map((route) => {
            const orderIds = itemsByContainer[route.id] || [];
            return {
                ...route,
                salesOrders: orderIds.map((orderId, index) => ({
                    id: orderId,
                    position: index + 1,
                })),
            };
        });

        const updatedDeliveryPlan = {
            ...deliveryPlan,
            routes: updatedRoutes,
        };

        try {
            if (isEditMode) {
                await updateDeliveryPlan(updatedDeliveryPlan, apiKey, organizationId, jwtToken);
                setSnackbar({ open: true, message: t('DeliveryPlanEditPage.deliveryPlanUpdated'), severity: 'success' });
            } else {
                await saveDeliveryPlan(updatedDeliveryPlan, apiKey, organizationId, jwtToken);
                setSnackbar({ open: true, message: t('DeliveryPlanEditPage.deliveryPlanCreated'), severity: 'success' });
            }
            navigate('/deliveryPlanning');
        } catch (error) {
            console.error(t('DeliveryPlanEditPage.errorSavingDeliveryPlan'), error);
            setSnackbar({ open: true, message: t('DeliveryPlanEditPage.errorSavingDeliveryPlan', { error: error.message }), severity: 'error' });
        }
    };

    // DnD Sensors and Handlers
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragStart = (event) => {
        if (isCompleted) return;
        setActiveId(event.active.id);
    };

    const handleDragEnd = (event) => {
        if (isCompleted) return;

        const { active, over } = event;

        if (!over) {
            setActiveId(null);
            return;
        }

        const activeContainer = findContainer(active.id);
        const overContainer = findContainer(over.id);

        if (!activeContainer || !overContainer) {
            setActiveId(null);
            return;
        }

        if (activeContainer === overContainer) {
            // Reordering within the same container
            const items = itemsByContainer[activeContainer];
            const oldIndex = items.indexOf(active.id);
            const newIndex = items.indexOf(over.id);

            const newItems = arrayMove(items, oldIndex, newIndex);

            // Update the itemsByContainer state
            setItemsByContainer((prev) => ({
                ...prev,
                [activeContainer]: newItems,
            }));
        } else {
            // Moving between containers
            setItemsByContainer((prev) => {
                const activeItems = prev[activeContainer].filter((id) => id !== active.id);
                const overItems = prev[overContainer] || [];
                const newOverItems = [...overItems, active.id];

                return {
                    ...prev,
                    [activeContainer]: activeItems,
                    [overContainer]: newOverItems,
                };
            });
        }

        setActiveId(null);
    };

    const findContainer = (id) => {
        if (id in itemsByContainer) {
            return id;
        }

        return Object.keys(itemsByContainer).find((key) =>
            itemsByContainer[key].includes(id)
        );
    };

    const handleRemoveOrder = (orderId, containerId) => {
        if (isCompleted) {
            setSnackbar({ open: true, message: t('DeliveryPlanEditPage.cannotEditCompletedPlan'), severity: 'warning' });
            return;
        }

        setItemsByContainer((prev) => {
            const containerItems = prev[containerId].filter((id) => id !== orderId);
            const unassignedItems = [...prev['unassigned'], orderId];

            return {
                ...prev,
                [containerId]: containerItems,
                unassigned: unassignedItems,
            };
        });

        setSnackbar({ open: true, message: t('DeliveryPlanEditPage.salesOrderRemoved'), severity: 'info' });
    };

    useEffect(() => {
        // Scroll to the selected date when component mounts or date changes
        scrollToDate(deliveryPlan.deliveryDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryPlan.deliveryDate]);

    if (loading) {
        if (isEditMode && !deliveryPlan.id) {
            return (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    minHeight="100vh"
                >
                    <Typography variant="h6">{t('DeliveryPlanEditPage.loadingDeliveryPlan')}</Typography>
                    <LinearProgress sx={{ width: '100%', mt: 2 }} />
                </Box>
            );
        } else {
            return (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    minHeight="100vh"
                >
                    <Typography variant="h6">{t('DeliveryPlanEditPage.loadingData')}</Typography>
                    <LinearProgress sx={{ width: '100%', mt: 2 }} />
                </Box>
            );
        }
    }

    const unassignedOrderIds = itemsByContainer['unassigned'] || [];
    const unassignedOrders = salesOrders.filter((order) =>
        unassignedOrderIds.includes(order.id)
    );

    // Group unassigned orders by delivery date
    const ordersByDate = unassignedOrders.reduce((acc, order) => {
        let date = order.deliveryDate;
        if (typeof date === 'string') {
            // Convert string to Date object if necessary
            date = new Date(date);
        }
        const dateString = formatDate(date);
        if (!acc[dateString]) {
            acc[dateString] = [];
        }
        acc[dateString].push(order);
        return acc;
    }, {});

    return (
        <Paper elevation={3} sx={{ mt: 4, p: 3, position: 'relative' }}>
            {isCompleted && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        bgcolor: 'rgba(255, 255, 255, 0.6)',
                        zIndex: 10,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="h4" color="text.secondary">
                        {t('DeliveryPlanEditPage.readOnlyMode')}
                    </Typography>
                </Box>
            )}
            <Typography variant="h4" gutterBottom>
                <IconButton onClick={() => navigate(-1)} aria-label={t('DeliveryPlanEditPage.backButtonAriaLabel')}>
                    <ArrowBackIcon />
                </IconButton>
                {isEditMode ? t('DeliveryPlanEditPage.editDeliveryPlan') : t('DeliveryPlanEditPage.createDeliveryPlan')}
            </Typography>
            <Box sx={{ mt: 4 }}>
                {/* Display Delivery Plan Number and Status */}
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" sx={{ mr: 2 }}>
                        {t('DeliveryPlanEditPage.deliveryPlanNumber')}: {deliveryPlan.deliveryPlanNumber || t('DeliveryPlanEditPage.toBeAssigned')}
                    </Typography>
                    <Chip
                        label={deliveryPlan.status ? t(`DeliveryPlanEditPage.statusOptions.${deliveryPlan.status}`) : t('DeliveryPlanEditPage.draft')}
                        color={deliveryPlan.status === 'confirmed' ? 'success' : 'default'}
                    />
                </Box>

                {/* Flex container for DatePicker and Add Route Button */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: { sm: 'center' },
                        justifyContent: 'space-between',
                        gap: 2,
                        mb: 4,
                    }}
                >
                    <DatePicker
                        label={t('DeliveryPlanEditPage.deliveryDate')}
                        value={new Date(deliveryPlan.deliveryDate)}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} fullWidth disabled={isCompleted} />}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleAddRouteClick}
                        sx={{ minWidth: '150px' }}
                        disabled={isCompleted}
                    >
                        {t('DeliveryPlanEditPage.addRouteButton')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleSave} disabled={isCompleted}>
                        {isEditMode ? t('DeliveryPlanEditPage.updateDeliveryPlan') : t('DeliveryPlanEditPage.createDeliveryPlan')}
                    </Button>
                </Box>

                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                >
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {/* Unassigned Orders List */}
                        <Box
                            sx={{
                                flex: 1,
                                maxWidth: '300px',
                                overflowY: 'auto',
                                maxHeight: availableHeight,
                            }}
                            ref={unassignedOrdersRef}
                        >
                            <Typography variant="h5">{t('DeliveryPlanEditPage.unassignedSalesOrders')}</Typography>
                            <SortableContext
                                id="unassigned"
                                items={unassignedOrderIds}
                                strategy={verticalListSortingStrategy}
                            >
                                {Object.keys(ordersByDate).map((date) => (
                                    <Box
                                        key={date}
                                        id={`date-section-${date}`}
                                        sx={{ mt: 2 }}
                                    >
                                        <Typography variant="subtitle1">{date}</Typography>
                                        {ordersByDate[date].map((order) => (
                                            <SortableSalesOrderItem
                                                key={order.id}
                                                id={order.id}
                                                salesOrder={order}
                                                onRemove={() => handleRemoveOrder(order.id, 'unassigned')}
                                                disabled={isCompleted}
                                            />
                                        ))}
                                    </Box>
                                ))}
                            </SortableContext>
                        </Box>

                        {/* Routes */}
                        <Box
                            sx={{
                                flex: 2,
                                maxHeight: availableHeight,
                                overflowY: 'auto',
                            }}
                        >
                            {deliveryPlan.routes.map((route) => (
                                <RouteComponent
                                    key={route.id}
                                    route={route}
                                    vehicles={vehicles}
                                    drivers={drivers}
                                    salesOrders={salesOrders}
                                    items={itemsByContainer[route.id] || []}
                                    onRouteChange={handleRouteChange}
                                    onRemoveRoute={handleRemoveRoute}
                                    handleRemoveOrder={handleRemoveOrder}
                                    activeId={activeId}
                                    isCompleted={isCompleted}
                                />
                            ))}
                        </Box>
                    </Box>

                    <DragOverlay>
                        {activeId ? (
                            <SortableSalesOrderItem
                                id={activeId}
                                salesOrder={salesOrders.find((order) => order.id === activeId)}
                                dragOverlay
                                disabled={isCompleted}
                            />
                        ) : null}
                    </DragOverlay>
                </DndContext>
            </Box>

            {/* Add Route Dialog */}
            <AddRouteDialog
                open={addRouteDialogOpen}
                onClose={() => setAddRouteDialogOpen(false)}
                onAdd={handleAddRoute}
                disabled={isCompleted} // Optionally disable within dialog
            />

            {/* Snackbar for User Feedback */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default DeliveryPlanEditPage;
