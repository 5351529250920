// src/pages/AuthPage.js
import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import AzureB2CAuthentication from '../components/AzureB2CAuthentication';

const AuthPage = () => {
  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
      <Container maxWidth="sm">
        <Box sx={{ py: 8 }}>
          <Typography variant="h4" align="center" gutterBottom>
          </Typography>
          <AzureB2CAuthentication />
        </Box>
      </Container>
    </Box>
  );
};

export default AuthPage;
