// src/pages/inventoryManagement/inventoryManagementPage.js
import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Typography, Paper } from '@mui/material';
import InventoryList from './itemManagement/ItemListPage'; // Component to list and define items
import InventoryTransactions from './inventoryManagement/InventoryTransactions'; // Component to manage transactions
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const InventoryManagement = () => {
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tab = query.get('tab');
  const [value, setValue] = React.useState(tab ? parseInt(tab) : 0);
  const { t } = useTranslation(); // Hook for translation

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setValue(newValue);
  };


  useEffect(() => {
    const tabloc = query.get('tab');
    if (tab !== null) {
      setValue(parseInt(tab));
      setTabValue(parseInt(tab));
    }
  }, [location]);

  // This useEffect updates the URL when the value changes
  useEffect(() => {
    if (parseInt(query.get('tab')) !== value) {
      const newQuery = new URLSearchParams(location.search);
      newQuery.set('tab', value);
      navigate(`${location.pathname}?${newQuery.toString()}`, { replace: true });
    }
  }, [value]);

  return (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Box>
        <Typography variant="h4"v  gutterBottom>{t('inventoryManagement.title')}</Typography>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label={t('inventoryManagement.tabs.items')} />
          <Tab label={t('inventoryManagement.tabs.inventoryTransactions')} />
        </Tabs>
        {tabValue === 0 && <InventoryList />}
        {tabValue === 1 && <InventoryTransactions />}
      </Box>
    </Paper>
  );
};

export default InventoryManagement;
