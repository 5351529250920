import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, TextField, Button, Grid, Container } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { passwordResetRequest } from '../context/authConfig'; // Adjust path as needed

const ForgotPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const { instance } = useMsal();

  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    }
  }, [location.state]);

  const handleForgotPassword = () => {
    instance.loginPopup(passwordResetRequest)
      .then((authResult) => {
        console.log('Password reset successful');
      })
      .catch(error => {
        console.error('Password reset error:', error);
      });
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();
    handleForgotPassword();
  };

  const handleBack = () => {
    navigate(-1); // Navigates back to the previous page
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Forgot Password
      </Typography>
      <form onSubmit={handleResetPassword}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button fullWidth variant="contained" color="primary" type="submit">
              Reset Password
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="outlined" color="secondary" onClick={handleBack}>
              Go Back
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default ForgotPassword;
