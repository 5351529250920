import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { fetchUnpaidInvoices } from '../../services/InvoiceService';
import { fetchInvoicePaymentSettings } from '../../services/InvoicePaymentSettingsService';
import { fetchUnPaidReminders, savePaymentReminder, updatePaymentReminder, fetchPaymentReminderById, deletePaymentReminder, fetchPaymentReminderPDF, sendPaymentReminderEmail } from '../../services/PaymentReminderService';
import { getAllOwners } from '../../services/ownerService';
import { useAuth } from '../../context/AuthContext';
import { createPayment } from '../../services/PaymentsService';

export const usePaymentReminder = (reminderId) => {
    const { apiKey, organizationId, token, userId } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [isEditMode, setIsEditMode] = useState(reminderId !== 'new');
    const [unpaidInvoices, setUnpaidInvoices] = useState([]);
    const [owners, setOwners] = useState([]);
    const [previousReminders, setPreviousReminders] = useState([]);
    const [paymentReminderSettings, setPaymentReminderSettings] = useState([]);
    const [confirmationAction, setConfirmationAction] = useState(null);
    const location = useLocation();
    const { state } = location;
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const emptyReminder = {
        id: '',
        creationDate: new Date(),
        dueDate: new Date(),
        openInvoicesSumGross: 0,
        noticeFeeInPercentage: 0,
        totalGrossAmount: 0,
        status: 'unpaid',
        payerName: '',
        printAdditionalText: '',
        reminderType: 'paymentReminder',
        customerId: '',
        invoices: [],
        level: 0,
        previousReminderIds: [],
    };
    const [paymentReminder, setPaymentReminder] = useState(emptyReminder);
    const prefilledReminder = location.state?.prefilledReminder;


    useEffect(() => {
        const fetchData = async () => {
            try {
                const [invoices, settings, owners, previousReminders] = await Promise.all([
                    fetchUnpaidInvoices(apiKey, organizationId, token, userId),
                    fetchInvoicePaymentSettings(apiKey, organizationId, token),
                    getAllOwners(apiKey, organizationId, token),
                    fetchUnPaidReminders(apiKey, organizationId, token),
                ]);

                setUnpaidInvoices(invoices);
                setPaymentReminderSettings(settings[0].reminderLevels);
                setOwners(owners);
                setPreviousReminders(previousReminders);

                if (isEditMode) {
                    let reminder = await fetchPaymentReminderById(apiKey, organizationId, token, reminderId);
                    reminder = {
                        ...reminder,
                        creationDate: new Date(reminder.creationDate),
                        dueDate: new Date(reminder.dueDate),
                    };
                    setPaymentReminder(reminder);
                }
            } catch (error) {
                console.error("Failed to fetch data", error);
            } finally {
                setLoading(false);
            }
        };


        fetchData();
    }, [apiKey, organizationId, token, userId, isEditMode]);

    useEffect(() => {
        if (prefilledReminder) {
            // Set the prefilled data as the current reminder for editing
            const newLevelSettings = paymentReminderSettings.find(level => level.reminderLevel === parseInt(prefilledReminder.level));
            const newReminder = {
                ...emptyReminder,
                ...prefilledReminder,
                creationDate: new Date(prefilledReminder.creationDate),
                dueDate: calculateReminderDueDate(new Date(prefilledReminder.creationDate), parseInt(newLevelSettings.reminderDueDays)),
                noticeFeeInPercentage: parseFloat(newLevelSettings.diningNoticeFee),
                totalGrossAmount: calculateTotalGrossAmount(prefilledReminder.openInvoicesSumGross, parseFloat(newLevelSettings.diningNoticeFee)),
                printAdditionalText: newLevelSettings.reminderPrintAdditionalText,
                reminderType: newLevelSettings.reminderType,
            };
            setPaymentReminder(newReminder);
            setIsEditMode(false);
        }
    }, [prefilledReminder, setPaymentReminder]);

    useEffect(() => {
        const fetchPreviousReminders = async () => {
            try {
                if (isEditMode && paymentReminder.previousReminderIds?.length > 0) {
                    const fetchedReminders = await Promise.all(
                        paymentReminder.previousReminderIds.map(async (reminderId) => {
                            return await fetchPaymentReminderById(apiKey, organizationId, token, reminderId);
                        })
                    );
                    setPreviousReminders(fetchedReminders);
                }
            } catch (error) {
                console.error("Failed to fetch previous reminders:", error);
            }
        };

        fetchPreviousReminders();
    }, [paymentReminder.previousReminderIds, isEditMode]);


    const calculateTotalGrossAmount = (openSum, percentage) => {
        return parseFloat((openSum + (openSum * percentage / 100)).toFixed(2));
    };

    const calculateNoticeFeePercentage = (openSum, totalGross) => {
        return parseFloat(((totalGross - openSum) / openSum * 100).toFixed(2));
    };

    const calculateReminderDueDate = (dueDate, reminderDueDays) => {
        const reminderDueDate = new Date(dueDate);
        reminderDueDate.setDate(reminderDueDate.getDate() + reminderDueDays);
        return new Date(reminderDueDate);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setPaymentReminder((prevReminder) => {
            let updatedReminder = { ...prevReminder, [name]: value };

            if (name === 'level') {
                const selectedLevel = paymentReminderSettings.find(level => level.reminderLevel === parseInt(value));
                if (selectedLevel) {
                    const noticeFeeInPercentage = selectedLevel.diningNoticeFee;
                    const totalGrossAmount = calculateTotalGrossAmount(prevReminder.openInvoicesSumGross, noticeFeeInPercentage);
                    const reminderDueDate = calculateReminderDueDate(prevReminder.creationDate, parseInt(selectedLevel.reminderDueDays));

                    updatedReminder = {
                        ...updatedReminder,
                        noticeFeeInPercentage,
                        printAdditionalText: selectedLevel.reminderPrintAdditionalText,
                        totalGrossAmount,
                        reminderType: selectedLevel.reminderType,
                        dueDate: reminderDueDate,
                    };
                } else {
                    updatedReminder = {
                        ...updatedReminder,
                        noticeFeeInPercentage: 0,
                        totalGrossAmount: prevReminder.openInvoicesSumGross,
                        dueDate: prevReminder.dueDate,
                    };
                }
            } else if (name === 'reminderType') {
                if (value === 'paymentReminder') {
                    updatedReminder.noticeFeeInPercentage = 0;
                    updatedReminder.totalGrossAmount = prevReminder.openInvoicesSumGross;
                }
            } else if (name === 'noticeFeeInPercentage') {
                const totalGrossAmount = calculateTotalGrossAmount(prevReminder.openInvoicesSumGross, parseFloat(value));
                updatedReminder = {
                    ...updatedReminder,
                    totalGrossAmount,
                };
            } else if (name === 'totalGrossAmount') {
                const noticeFeeInPercentage = calculateNoticeFeePercentage(prevReminder.openInvoicesSumGross, parseFloat(value));
                updatedReminder = {
                    ...updatedReminder,
                    noticeFeeInPercentage,
                };
            }

            return updatedReminder;
        });
    };

    const handleInvoicesChange = (event, newValue) => {
        let newTotal = 0;

        if (newValue.length > 0) {
            newValue.forEach(invoice => {
                newTotal += invoice.totalAmount;
            });

            const firstInvoice = newValue[0];
            setPaymentReminder((prevReminder) => {
                const totalGrossAmount = calculateTotalGrossAmount(newTotal, prevReminder.noticeFeeInPercentage);
                return {
                    ...prevReminder,
                    invoices: newValue,
                    openInvoicesSumGross: newTotal,
                    totalGrossAmount,
                };
            });
        } else {
            setPaymentReminder((prevReminder) => ({
                ...prevReminder,
                invoices: newValue,
                openInvoicesSumGross: 0,
                totalGrossAmount: 0,
                noticeFeeInPercentage: 0,
            }));
        }
    };

    const handleDateChange = (date, name) => {
        setPaymentReminder((prevReminder) => {
            let updatedReminder = { ...prevReminder, [name]: date };

            if (name === 'creationDate' && prevReminder.level) {
                const selectedLevel = paymentReminderSettings.find(level => level.reminderLevel === parseInt(prevReminder.level));
                if (selectedLevel) {
                    updatedReminder.dueDate = calculateReminderDueDate(date, parseInt(selectedLevel.reminderDueDays));
                } else {
                    updatedReminder.dueDate = date;
                }
            }

            return updatedReminder;
        });
    };

    const handleAutocompleteChange = async (event, value) => {
        const selectedOwner = value || {};
        const firstName = selectedOwner.firstname || '';
        const surName = selectedOwner.surname || '';
        const customerId = selectedOwner.id || '';
        console.log(selectedOwner);
        setPaymentReminder((prevReminder) => ({
            ...prevReminder,
            payerName: `${firstName} ${surName}`,
            customerId: customerId,
        }));
    };

    const handlePreviousReminderChange = (event, newValue) => {
        const selectedIds = newValue.map(reminder => reminder.id);
        setPaymentReminder((prevReminder) => ({
            ...prevReminder,
            previousReminderIds: selectedIds,
        }));
    };


    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (isEditMode) {
                await updatePaymentReminder(paymentReminder, apiKey, organizationId, token);
            } else {
                await savePaymentReminder(paymentReminder, apiKey, organizationId, token);
            }
            navigate('/sales-process?tab=5');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            await deletePaymentReminder(paymentReminder.id, apiKey, organizationId, token);
            navigate('/sales-process?tab=5');
        } finally {
            setLoading(false);
        }
    }
    const handleMarkAsPaid = async () => {
        try {
            // Iterate over each unpaid invoice and create a payment
            for (const invoice of paymentReminder.invoices) {
                const paymentData = {
                    amount: invoice.totalAmount,
                    date: new Date().toISOString(),
                    payerName: paymentReminder.payerName,
                    payerContact: invoice?.address?.email || '',
                    payerReference: invoice.id,
                    paymentMethod: 'cash', // Default payment method
                    referenceId: invoice.id,
                    referenceType: 'Invoice',
                    notes: `Payment for Invoice ${invoice.invoiceNumber} from Payment Reminder ${paymentReminder.id}`,
                };
                await createPayment(paymentData, apiKey, organizationId, token);
            }

            // Create a payment for the fee (difference)
            if (paymentReminder.noticeFeeInPercentage > 0) {
                const feePaymentData = {
                    amount: paymentReminder.totalGrossAmount - paymentReminder.openInvoicesSumGross,
                    date: new Date().toISOString(),
                    payerName: paymentReminder.payerName,
                    payerContact: '', // No email for fee payment
                    payerReference: paymentReminder.id,
                    paymentMethod: 'cash',
                    referenceId: paymentReminder.id,
                    referenceType: 'PaymentReminder',
                    notes: `Fee for Payment Reminder ${paymentReminder.id}`,
                };
                await createPayment(feePaymentData, apiKey, organizationId, token);

                const updatedReminder = {
                    ...paymentReminder,
                    status: 'paid',
                };

                await updatePaymentReminder(updatedReminder, apiKey, organizationId, token);
            }

            // Display success message or handle the state accordingly
            setSnackbar({ open: true, message: 'Payment successfully processed for all invoices.', severity: 'success' });



            // Navigate or update the state to reflect changes
            navigate('/sales-process?tab=5');
        } catch (error) {
            setSnackbar({ open: true, message: 'Error processing payments.', severity: 'error' });
            console.error("Error processing payments: ", error);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };
    const handleMarkAsPaidClick = () => {
        setConfirmationAction('markAsPaid');
        setConfirmDialogOpen(true);
    };

    const handlePaymentConfirmation = async () => {
        if (confirmationAction === 'markAsPaid') {
            await handleMarkAsPaid();
        }
        // Reset confirmation action and close the dialog
        setConfirmationAction(null);
        setConfirmDialogOpen(false);
    };

    const handleEscalateReminder = async () => {
        try {
            // Pre-fill a new reminder with an incremented level and the same details as the current one
            const newReminder = {
                ...paymentReminder,
                id: '', // Ensure a new ID is generated
                level: paymentReminder.level + 1,
                previousReminderIds: [...paymentReminder.previousReminderIds, paymentReminder.id],

                status: 'unpaid', // Reset status for the new reminder
            };

            // Navigate to the edit screen with the new reminder data
            navigate('/payment-reminders/new', { state: { prefilledReminder: newReminder } });
        } catch (error) {
            console.error('Error preparing escalation:', error);
            setSnackbar({ open: true, message: 'Failed to prepare escalation.', severity: 'error' });
        }
    };

    const handleSendReminderEmail = async () => {
        try {
            const response = await sendPaymentReminderEmail(paymentReminder.id, apiKey, organizationId, token);
            if (response.status === 200) {
                setSnackbar({ open: true, message: 'Reminder email sent successfully.', severity: 'success' });
            } else {
                setSnackbar({ open: true, message: 'Failed to send reminder email.', severity: 'error' });
            }
        } catch (error) {
            console.error('Error sending reminder email:', error);
            setSnackbar({ open: true, message: 'Failed to send reminder email.', severity: 'error' });
        }
    };

    const handleDownloadReminderPDF = async () => {
        try {
            const response = await fetchPaymentReminderPDF(paymentReminder.id, apiKey, organizationId, token);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `PaymentReminder_${paymentReminder.id}.pdf`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error downloading reminder PDF:', error);
            setSnackbar({ open: true, message: 'Failed to download reminder PDF.', severity: 'error' });
        }
    }


    return {
        loading,
        paymentReminderSettings,
        owners,
        paymentReminder,
        unpaidInvoices,
        isEditMode,
        previousReminders,
        handleInvoicesChange,
        handleSubmit,
        handleInputChange,
        handleAutocompleteChange,
        handleDateChange,
        handlePreviousReminderChange,
        handleDelete,
        snackbar,
        handleSnackbarClose,
        confirmDialogOpen,
        handleConfirmDialogClose,
        handlePaymentConfirmation,
        handleMarkAsPaidClick,
        handleEscalateReminder,
        handleSendReminderEmail,
        handleDownloadReminderPDF,
    };
};