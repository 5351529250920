// src/components/MachineBookingModal.js

import React, { useState, useEffect } from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    TextField, Select, MenuItem, Box, FormControl, InputLabel,
    Typography, IconButton, Autocomplete
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/AuthContext';
import { useDateLocale } from '../hooks/useDateLocale';

// Import services
import { createBooking } from '../services/guidingMachineService';
import { fetchMachineSettings } from '../services/MachineSettingsService';
import { getAllHorses, getHorsesByUserId } from '../services/horseService';
import SnackbarAlert from './SnackbarAlert'; // Adjust the import path as necessary

const MachineBookingModal = ({ isOpen, onClose, horsesProp = [], devicesProps = [] }) => {
    const { t } = useTranslation();
    const dateLocale = useDateLocale();
    const { apiKey, organizationId, token: jwtToken, userType, ownerId, userId } = useAuth();
    const [deviceData, setDeviceData] = useState([]);
    const [horseData, setHorseData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDevice, setSelectedDevice] = useState('');
    const [selectedHorse, setSelectedHorse] = useState(null);
    const [managementType, setManagementType] = useState('');
    const [selectedSlot, setSelectedSlot] = useState('');
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [availableSlots, setAvailableSlots] = useState({});
    const [isSlotAvailable, setIsSlotAvailable] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // Possible values: 'error', 'warning', 'info', 'success'
    });


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const devices = devicesProps.length > 0 ? devicesProps : await fetchMachineSettings(apiKey, organizationId, jwtToken);
                const horses = horsesProp.length > 0 ? horsesProp : userType === 'admin' ? await getAllHorses(apiKey, organizationId, jwtToken) : await getHorsesByUserId(userId, apiKey, organizationId, jwtToken);
                setDeviceData(devices);
                setHorseData(horses.filter(horse => horse.permissions.includes('handleBookings')));
            } catch (error) {
                console.error('Error fetching data for booking modal:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [apiKey, organizationId, jwtToken]);

    useEffect(() => {
        if (selectedDevice) {
            const device = deviceData.find(device => device.id === selectedDevice);
            const availability = device.availability;
            const duration = device.time;

            const calculatedSlots = {};
            Object.keys(availability).forEach(day => {
                const { from, to } = availability[day];
                calculatedSlots[day] = calculateSlots(from, to, parseInt(duration));
            });

            setAvailableSlots(calculatedSlots);
            const day = selectedDate.toLocaleDateString('en-US', { weekday: 'long' });
            const daySlots = calculatedSlots[day] || [];
            setIsSlotAvailable(daySlots.length > 0);
            if (calculatedSlots[day] && calculatedSlots[day].length > 0) {
                setSelectedSlot(calculatedSlots[day][0]);
            }
        }
    }, [selectedDevice, deviceData, selectedDate]);

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };

    const handleDeviceChange = (event) => {
        setSelectedDevice(event.target.value);
    };

    const handleHorseChange = (event, newValue) => {
        setSelectedHorse(newValue.id);
        console.log('selectedHorse:', newValue.id);
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };


    const handleManagementTypeChange = (event) => {
        setManagementType(event.target.value);
    };

    const calculateSlots = (from, to, duration) => {
        const slots = [];

        // Parse the UTC date string and get the hours and minutes
        let startDate = new Date(from);
        let endDate = new Date(to);

        let startHour = startDate.getHours();
        let startMin = startDate.getMinutes();

        let endHour = endDate.getHours();
        let endMin = endDate.getMinutes();

        let start = startHour * 60 + startMin; // convert to minutes
        const end = endHour * 60 + endMin; // convert to minutes

        while ((start + duration) <= end) {
            const slotStart = `${String(startHour).padStart(2, '0')}:${String(startMin).padStart(2, '0')}`;
            start += duration; // move to the next slot
            [startHour, startMin] = [Math.floor(start / 60), start % 60];
            const slotEnd = `${String(startHour).padStart(2, '0')}:${String(startMin).padStart(2, '0')}`;
            slots.push({ start: slotStart, end: slotEnd });
        }
        return slots;
    };


    const handleCloseModal = () => {
        onClose();
        resetDialogForm();
    };

    // Function to reset dialog form state
    const resetDialogForm = () => {
        setSelectedDate(new Date());
        setComment('');
        setManagementType('');
        setSelectedHorse(null);
        setSelectedDevice(null);
        setSelectedSlot(null);
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (!selectedHorse || !managementType || !selectedSlot) {
                setErrorMessage(t('GuidingMachinePage.Bookings.ErrorMessage'));
                //setIsErrorOpen(true);
                return;
            }

            // Find the owner of the selected horse
            const selectedH = horseData.find(h => h.id === selectedHorse);
            const realOwnerId = selectedH ? selectedH.ownerId : null;


            // Construct a new Date object based on selectedDate and the hours and minutes from selectedSlot.start
            const [startHours, startMinutes] = selectedSlot.start.split(':').map(Number); // I assume selectedSlot has a start property
            const newSelectedTime = new Date(selectedDate);
            newSelectedTime.setHours(startHours, startMinutes, 0, 0); // Set hours, minutes, seconds, and milliseconds

            // Calculate the endDateTime based on your logic. Here I am adding 1 hour as an example
            const [endHours, endMinutes] = selectedSlot.end.split(':').map(Number); // Adjust as per your logic
            const newEndDateTime = new Date(selectedDate);
            newEndDateTime.setHours(endHours, endMinutes, 0, 0); // Set hours, minutes, seconds, and milliseconds

            // Use realOwnerId if the user is an Admin, else use userId
            const idToUse = realOwnerId;

            await createBooking(
                newSelectedTime,
                newEndDateTime,
                idToUse,
                selectedHorse,
                comment,
                managementType,
                selectedDevice,
                apiKey,
                organizationId,
                jwtToken
            ).then(response => {
                if (response.status === 200 || response.status === 201 || response.status === 204) {
                    // Assuming a 200 OK status means the booking was successfully created
                    setSuccessMessage(t('GuidingMachinePage.Bookings.SuccessMessage'));
                    setIsSuccessOpen(true);

                    // Reset the dialog form state here
                    resetDialogForm();

                } else {
                    // Parse the error message from the response JSON
                    return response.json().then(data => {
                        setErrorMessage(data.message || 'An error occurred.');
                        setIsErrorOpen(true);
                    });
                }
            }).then((updatedBookings) => {
                setSnackbar({
                    open: true,
                    message: t('GuidingMachinePage.Bookings.SuccessMessage'),
                    severity: 'success',
                });
                handleCloseModal();
                onClose();
            }).catch((error) => {

                if (error.message.startsWith('Maximum number of') || error.message === 'MAX_BOOKINGS_REACHED') {
                    setSnackbar({
                        open: true,
                        message: t('GuidingMachinePage.Bookings.MaxBookingsReached'),
                        severity: 'error',
                    });
                    setIsErrorOpen(true);
                } else {
                    setSnackbar({
                        open: true,
                        message: t('GuidingMachinePage.Bookings.ErrorMessage'),
                        severity: 'error',
                    });
                }

            });

        } catch (error) {
            if (error.message === 'MAX_BOOKINGS_REACHED') {
                setErrorMessage(t('GuidingMachinePage.Bookings.MaxBookingsReached'));
                setIsErrorOpen(true);
            } else {
                setErrorMessage(t('GuidingMachinePage.Bookings.ErrorMessage'));
                setIsErrorOpen(true);
            }

        } finally {
            setLoading(false);
        }
    };

    // Function to close the Snackbar
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
            <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>
                    {t('GuidingMachinePage.dialogTitle')}
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {loading ? (
                        <Typography>Loading...</Typography>
                    ) : (
                        <Box>
                            <Box mt={2}>
                                <Select
                                    value={selectedDevice}
                                    onChange={handleDeviceChange}
                                    label={t('GuidingMachinePage.deviceName')}
                                    fullWidth
                                    required
                                >
                                    {deviceData.map((device) => (
                                        <MenuItem key={device.id} value={device.id}>
                                            {device.machineType}
                                            {device.machineDescription ? ` (${device.machineDescription})` : ''}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            <Box display="flex" flexDirection="row" mt={2}>
                                <Box mr={2}>
                                    <DatePicker
                                        label={t('GuidingMachinePage.date')}
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    {!isSlotAvailable && (
                                        <Typography variant="body2" color="error">
                                            {t('GuidingMachinePage.noAvailableSlots')}
                                        </Typography>
                                    )}
                                </Box>
                                <Box>
                                    <Select
                                        value={selectedSlot}
                                        disabled={!isSlotAvailable}
                                        onChange={(event) => setSelectedSlot(event.target.value)}
                                        label={t('GuidingMachinePage.time')}
                                        inputProps={{ id: 'time' }}
                                    >
                                        {(availableSlots[selectedDate.toLocaleDateString('en-US', { weekday: 'long' })] || []).length === 0 ? (
                                            <MenuItem value="" disabled>
                                                {t('GuidingMachinePage.noAvailableSlotsTime')}
                                            </MenuItem>
                                        ) : (
                                            availableSlots[selectedDate.toLocaleDateString('en-US', { weekday: 'long' })].map((slot, index) => (
                                                <MenuItem key={index} value={slot}>
                                                    {`${slot.start} - ${slot.end}`}
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </Box>
                            </Box>
                            <Box mt={2}>
                                <Autocomplete
                                    options={horseData}
                                    getOptionLabel={(option) => option.name} // Assuming the horse object has a 'name' field
                                    value={horseData.find((h) => h.id === selectedHorse)} // Find the selected horse in horseData
                                    onChange={handleHorseChange}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('GuidingMachinePage.horse')} fullWidth required />
                                    )}
                                />
                            </Box>
                            <Box mt={2}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>{t('GuidingMachinePage.managementType')}</InputLabel>
                                    <Select
                                        value={managementType}
                                        onChange={handleManagementTypeChange}
                                        label={t('GuidingMachinePage.managementType')}
                                        fullWidth
                                        required
                                    >
                                        <MenuItem value={t('GuidingMachinePage.managementOption1')}>{t('GuidingMachinePage.managementOption1')}</MenuItem>
                                        <MenuItem value={t('GuidingMachinePage.managementOption2')}>{t('GuidingMachinePage.managementOption2')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box mt={2}>
                                <TextField
                                    label={t('GuidingMachinePage.comment')}
                                    value={comment}
                                    onChange={handleCommentChange}
                                    fullWidth
                                />
                            </Box>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary" disabled={loading}>
                        {t('GuidingMachinePage.cancel')}
                    </Button>
                    <Button onClick={handleSubmit} color="primary" disabled={!isSlotAvailable || loading}>
                        {t('GuidingMachinePage.submit')}
                    </Button>
                </DialogActions>
            </Dialog>
            <SnackbarAlert
                open={snackbar.open}
                onClose={handleSnackbarClose}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </LocalizationProvider>
    );
};

export default MachineBookingModal;
