// src/services/PaymentsService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/payments';

export async function fetchAllPayments(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(API_BASE_URL);
  return response.data;
}

export async function fetchPaymentById(apiKey, organizationId, jwtToken, id) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${id}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function fetchPaymentByReference(apiKey, organizationId, jwtToken, referenceId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?referenceId=${referenceId}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function createPayment(paymentData, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(API_BASE_URL, paymentData);
  return response.data;
}

export async function updatePayment(paymentData, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${paymentData.id}`;
  const response = await axiosInstance.put(url, paymentData);
  return response.data;
}

export async function deletePayment(paymentId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?id=${paymentId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

export default {
  fetchAllPayments,
  fetchPaymentById,
  fetchPaymentByReference,
  createPayment,
  updatePayment,
  deletePayment
};
