// src/services/TaxSettingsService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig';

const API_BASE_URL = '/api/taxsettings';

export async function fetchTaxSettings(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}`);
  return response.data;
}

export async function saveTaxSettings(taxSettings, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(API_BASE_URL, { ...taxSettings, organizationId });
  return response.data;
}

export default {
  fetchTaxSettings,
  saveTaxSettings,
};
