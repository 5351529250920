import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    IconButton,
    Checkbox,
    FormControlLabel,
    Grid,
    InputAdornment,
    Typography,
    Divider,
    Box
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { saveLocationSettings, updateLocationSettings } from '../services/LocationSettingsService';
import { useAuth } from '../context/AuthContext';

const LocationDialog = ({ open, onClose, initialData }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({ name: '', buildings: [] });
    const [errors, setErrors] = useState({}); // For validation
    const { apiKey, organizationId, token: jwtToken } = useAuth();
    const buildingTypes = [
        { value: 'warehouse', label: t('locations.buildingTypes.warehouse') },
        { value: 'stable', label: t('locations.buildingTypes.stable') },
        { value: 'ridingArena', label: t('locations.buildingTypes.ridingArena') },
    ];

    useEffect(() => {
        if (initialData) {
            setFormData({
                ...initialData,
                buildings: initialData.buildings.map(building => ({
                    ...building,
                    width: building.width || '',
                    length: building.length || '',
                })),
            });
        } else {
            setFormData({ name: '', buildings: [] });
        }
        setErrors({});
    }, [initialData]);

    const handleSave = async () => {
        if (!validateForm()) return;

        try {
            if (formData.id) {
                await updateLocationSettings(formData.id, formData, apiKey, organizationId, jwtToken);
            } else {
                await saveLocationSettings(formData, apiKey, organizationId, jwtToken);
            }
            onClose(true); // Indicate success
        } catch (error) {
            console.error('Failed to save location:', error);
            onClose(false); // Indicate failure
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
        // Validate on change
        if (name === 'name' && value.trim() === '') {
            setErrors(prev => ({ ...prev, name: t('locations.nameRequired') }));
        } else {
            setErrors(prev => ({ ...prev, name: '' }));
        }
    };

    const handleBuildingChange = (index, name, value) => {
        const newBuildings = formData.buildings.map((building, i) =>
            i === index ? { ...building, [name]: value } : building
        );
        setFormData(prevState => ({
            ...prevState,
            buildings: newBuildings,
        }));
        // Validate on change
        if ((name === 'width' || name === 'length') && (value === '' || Number(value) <= 0)) {
            setErrors(prev => ({
                ...prev,
                [`building-${index}-${name}`]: t(`locations.invalid${capitalize(name)}`),
            }));
        } else {
            setErrors(prev => ({
                ...prev,
                [`building-${index}-${name}`]: '',
            }));
        }
    };

    const addBuilding = () => {
        setFormData(prevState => ({
            ...prevState,
            buildings: [
                ...prevState.buildings,
                {
                    id: uuidv4(),
                    name: '',
                    type: { warehouse: false, stable: false, ridingArena: false },
                    width: '',
                    length: '',
                },
            ],
        }));
    };

    const removeBuilding = (index) => {
        const newBuildings = formData.buildings.filter((_, i) => i !== index);
        setFormData(prevState => ({
            ...prevState,
            buildings: newBuildings,
        }));
        // Remove any errors associated with this building
        const updatedErrors = { ...errors };
        ['name', 'width', 'length'].forEach(field => {
            delete updatedErrors[`building-${index}-${field}`];
        });
        setErrors(updatedErrors);
    };

    const handleCheckboxChange = (index, type) => {
        const newBuildings = formData.buildings.map((building, i) =>
            i === index ? { ...building, type: { ...building.type, [type]: !building.type[type] } } : building
        );
        setFormData(prevState => ({
            ...prevState,
            buildings: newBuildings,
        }));
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        // Validate location name
        if (formData.name.trim() === '') {
            newErrors.name = t('locations.nameRequired');
            valid = false;
        }

        // Validate each building
        formData.buildings.forEach((building, index) => {
            if (building.name.trim() === '') {
                newErrors[`building-${index}-name`] = t('locations.buildingNameRequired');
                valid = false;
            }
            if (building.width === '' || Number(building.width) <= 0) {
                newErrors[`building-${index}-width`] = t('locations.invalidWidth');
                valid = false;
            }
            if (building.length === '' || Number(building.length) <= 0) {
                newErrors[`building-${index}-length`] = t('locations.invalidLength');
                valid = false;
            }
            const hasType = Object.values(building.type).some(val => val);
            if (!hasType) {
                newErrors[`building-${index}-type`] = t('locations.typeRequired');
                valid = false;
            }
        });

        setErrors(newErrors);
        return valid;
    };

    const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

    return (
        <Dialog open={open} onClose={() => onClose(null)} fullWidth maxWidth="md">
            <DialogTitle>{initialData ? t('locations.editLocation') : t('locations.addLocation')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {/* Location Name */}
                    <Grid item xs={12}>
                        <TextField
                            label={t('locations.name')}
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            fullWidth
                            required
                            error={!!errors.name}
                            helperText={errors.name}
                        />
                    </Grid>

                    {/* Buildings Section */}
                    <Grid item xs={12}>
                        <Typography variant="h6">{t('locations.buildings')}</Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Button
                            variant="outlined"
                            startIcon={<AddCircle />}
                            onClick={addBuilding}
                            sx={{ mb: 2 }}
                        >
                            {t('locations.addBuilding')}
                        </Button>
                        {formData.buildings.length > 0 ? (
                            formData.buildings.map((building, index) => (
                                <Box key={building.id || index} mb={3} p={2} border="1px solid #ccc" borderRadius={2}>
                                    <Grid container spacing={2} alignItems="center">
                                        {/* Building Name */}
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label={t('locations.buildingName')}
                                                name="name"
                                                value={building.name}
                                                onChange={(e) => handleBuildingChange(index, e.target.name, e.target.value)}
                                                fullWidth
                                                required
                                                error={!!errors[`building-${index}-name`]}
                                                helperText={errors[`building-${index}-name`]}
                                            />
                                        </Grid>

                                        {/* Building Types */}
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                {t(`locations.buildingTypes.title`)}
                                            </Typography>
                                            <Grid container>
                                                {buildingTypes.map(type => (
                                                    <Grid item xs={6} key={type.value}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={building.type[type.value]}
                                                                    onChange={() => handleCheckboxChange(index, type.value)}
                                                                    name={type.value}
                                                                />
                                                            }
                                                            label={type.label}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            {errors[`building-${index}-type`] && (
                                                <Typography variant="caption" color="error">
                                                    {errors[`building-${index}-type`]}
                                                </Typography>
                                            )}
                                        </Grid>

                                        {/* Width Field */}
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label={t('locations.width')}
                                                name="width"
                                                type="number"
                                                value={building.width}
                                                onChange={(e) => handleBuildingChange(index, e.target.name, e.target.value)}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                                }}
                                                error={!!errors[`building-${index}-width`]}
                                                helperText={errors[`building-${index}-width`]}
                                            />
                                        </Grid>

                                        {/* Length Field */}
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label={t('locations.length')}
                                                name="length"
                                                type="number"
                                                value={building.length}
                                                onChange={(e) => handleBuildingChange(index, e.target.name, e.target.value)}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                                }}
                                                error={!!errors[`building-${index}-length`]}
                                                helperText={errors[`building-${index}-length`]}
                                            />
                                        </Grid>

                                        {/* Remove Building Button */}
                                        <Grid item xs={12}>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                startIcon={<RemoveCircle />}
                                                onClick={() => removeBuilding(index)}
                                            >
                                                {t('locations.removeBuilding')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))
                        ) : (
                            <Typography variant="body2" color="textSecondary">
                                {t('locations.noBuildings')}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(null)}>{t('common.cancel')}</Button>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                    disabled={
                        !formData.name ||
                        formData.buildings.length === 0 ||
                        Object.values(errors).some(error => error)
                    }
                >
                    {t('common.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LocationDialog;
