// src/services/userPrefrencesService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/userPreferences';

export async function fetchUserPreferences(apiKey, organizationId, jwtToken, preferences) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(API_BASE_URL+'?language='+preferences.language);
  return response.data;
}

export async function updateUserPreferences(preferences, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(API_BASE_URL, preferences);
  return response.data;
}

export default {
  fetchUserPreferences,
  updateUserPreferences
};