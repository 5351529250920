// src/pages/dewormingDetailsPage/DewormingTable.jsx

import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Icon } from '@mui/material';
import { ArrowDownward as ArrowDownwardIcon, ArrowUpward as ArrowUpwardIcon, Payment } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useAuth } from '../../context/AuthContext';
import { useSnackbar } from './useSnackbar';
import { useDeworming } from './useDeworming';
import { DatePicker } from '@mui/x-date-pickers';
import { Select, MenuItem, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import PaymentIcon from '@mui/icons-material/Payment';
import { CreditCardOff } from '@mui/icons-material';


const DewormingTable = ({ rows, addRow, handleRowChange, horseIds, removeRow, isEditMode, addAllHorses }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { openSnackbar } = useSnackbar();

    const dewormerOptions = {
        'ivermectin': 'Ivermectin',
        'moxidectin': 'Moxidectin',
        'fenbendazole': 'Fenbendazole',
        'pyrantel': 'Pyrantel',
        'praziquantel': 'Praziquantel',
        'mpp': 'Moxidectin + Praziquantel',
        'other': 'Other',
    };

    const dewormerPersonOptions = {
        'vet': t('dewormingDetailsPage.table.vet'),
        'owner': t('dewormingDetailsPage.table.owner'),
        'stableManager': t('dewormingDetailsPage.table.stableManager'),
    };

    return (
        <TableContainer component={Paper} style={{ marginTop: '20px',marginBottom: '20px' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {t('dewormingDetailsPage.table.horse')}
                        </TableCell>
                        <TableCell>
                            {t('dewormingDetailsPage.table.additionalInfo')}
                        </TableCell>
                        <TableCell>
                            {t('dewormingDetailsPage.table.dewormer')}
                        </TableCell>
                        <TableCell>
                            {t('dewormingDetailsPage.table.dewormerOption')}
                        </TableCell>
                        <TableCell>
                            {t('dewormingDetailsPage.table.isPaid')}
                        </TableCell>
                        <TableCell>
                            {t('dewormingDetailsPage.table.actions')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <Select
                                    value={row.horseId}
                                    onChange={(e) => handleRowChange(index, 'horseId', e.target.value)}
                                    fullWidth
                                    disabled={isEditMode}
                                >
                                    {horseIds.map((horse) => (
                                        <MenuItem
                                            key={horse.id}
                                            value={horse.id}
                                            disabled={rows.some(r => r.horseId === horse.id && r.horseId !== row.horseId)} // Disable option if already selected in another row
                                        >
                                            {horse.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={row.additionalInfo}
                                    onChange={(e) => handleRowChange(index, 'additionalInfo', e.target.value)}
                                    fullWidth
                                    disabled={isEditMode}
                                />
                            </TableCell>
                            <TableCell>
                                <Select
                                    value={row.dewormer}
                                    onChange={(e) => handleRowChange(index, 'dewormer', e.target.value)}
                                    fullWidth
                                    disabled={isEditMode}
                                >
                                    {Object.entries(dewormerPersonOptions).map(([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                            <TableCell>
                                <Select
                                    value={row.dewormerOption}
                                    onChange={(e) => handleRowChange(index, 'dewormerOption', e.target.value)}
                                    fullWidth
                                    disabled={isEditMode}
                                >
                                    {Object.entries(dewormerOptions).map(([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                            <TableCell>
                                {row.isPaid ?  <CheckCircleOutlineIcon color="success" /> : <CancelOutlined color="error" />}
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => handleRowChange(index, 'isPaid', !row.isPaid)} disabled={!isEditMode}>
                                    {row.isPaid ? <CreditCardOff /> : <PaymentIcon /> }
                                </IconButton>
                                <IconButton onClick={() => removeRow(index)} disabled={isEditMode}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell colSpan={5} align="center">
                            {/* Button for adding a single row */}
                            <Tooltip title={t('Add Row')}>
                                <IconButton onClick={() => addRow()} disabled={isEditMode}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                            {/* Button for adding all horses */}
                            <Tooltip title={t('Add All Horses')}>
                                <IconButton onClick={() => addAllHorses()} disabled={isEditMode}>
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default DewormingTable;