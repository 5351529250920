// src/MainContent.js
import React from 'react';
import { Box } from '@mui/material';
import { useDrawerState } from '../context/DrawerContext';
import DrawerStates from '../components/Bars/DrawerStates';
import { useTheme, useMediaQuery } from '@mui/material';
import { margin } from '@mui/system';

const drawerWidth = 240;
const sidebarCollapsedWidth = 60;
const sidebarPadding = 3;

const MainContent = ({ children }) => {

  const { drawerState } = useDrawerState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const sidebarPadding = 3;

  let marginLeft = sidebarPadding;
  if (isMobile && drawerState === DrawerStates.OPEN) {
    marginLeft = sidebarPadding + 60;
  }else
  {
    marginLeft += drawerState === DrawerStates.OPEN ? 240 : drawerState === DrawerStates.PEEK ? 60 : 0;
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        marginLeft: `${marginLeft}px`,
        padding: sidebarPadding,
      }}
    >
      {children}
    </Box>
  );
};

export default MainContent;
