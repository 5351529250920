import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardActionArea, CardActions, CardContent, Grid, IconButton, Typography, Tooltip } from '@mui/material';

const GenericCardView = ({ structure, onClick, actions }) => {
  const navigate = useNavigate();

  const handleCardClick = (rowId, event) => {
    onClick(rowId);
  };

  const handleActionClick = (action, rowId, event) => {
    action.handler(rowId);
  };

  if (!structure || structure.rows.length === 0) {
    return <Typography>No data available.</Typography>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {structure.rows.map((row) => (
          <Grid item xs={12} sm={6} md={4} key={row.id}>
            <Card sx={{ maxWidth: 345, m: 1 }}>
              <CardActionArea onClick={(event) => handleCardClick(row.id, event)}>
                <CardContent>
                  {structure.headers.filter(header => header.display.value).map((header) => (
                    <Typography gutterBottom variant="body1" component="div" key={header.key}>
                      {header.label}: {row[header.key].value} {row[header.key].icon && row[header.key].icon}
                    </Typography>
                  ))}
                </CardContent>
              </CardActionArea>
              {(actions || row.actions) && (
                <CardActions>
                  {actions && actions.map(action => (
                    <Tooltip title={action.label} key={action.label}>
                      <span>
                        <IconButton onClick={(event) => handleActionClick(action, row.id, event)} disabled={action.disabled}>
                          {action.icon}
                        </IconButton>
                      </span>
                    </Tooltip>
                  ))}
                  {row.actions && row.actions.map(action => (
                    <Tooltip title={action.label} key={action.label}>
                      <span>
                        <IconButton onClick={(event) => handleActionClick(action, row.id, event)} disabled={action.disabled}>
                          {action.icon}
                        </IconButton>
                      </span>
                    </Tooltip>
                  ))}
                </CardActions>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GenericCardView;
