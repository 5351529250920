// src/components/FoodManagementStep.js
import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Typography, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FoodManagementStep = ({ horses = [], foodSettings = [], onNext }) => {
    const { t } = useTranslation();
    const [localFoodPortions, setLocalFoodPortions] = useState(() => {
        return horses.reduce((acc, horse) => ({
            ...acc,
            [horse.id]: horse.foodPortions || {}
        }), {});
    });

    const handleFoodChange = (horseId, foodId, mealTime, portion) => {
        setLocalFoodPortions(prev => ({
            ...prev,
            [horseId]: {
                ...prev[horseId],
                [foodId]: {
                    ...(prev[horseId][foodId] || {}),
                    [mealTime]: portion
                }
            }
        }));
    };

    useEffect(() => {
        // If horses are updated, reinitialize the localFoodPortions state
        setLocalFoodPortions(horses.reduce((acc, horse, index) => {
            const id = horse.id || `temp-id-${index}`;
            return {
                ...acc,
                [id]: horse.foodPortions || {}
            };
        }, {}));
    }, [horses]);

    const handleNext = () => {
        const updatedHorses = horses.map(horse => ({
            ...horse,
            foodPortions: localFoodPortions[horse.id || `temp-id-${horses.indexOf(horse)}`] // Assign the updated food portions to each horse
        }));
        onNext(updatedHorses); // Pass the updated horses with their respective food portions
    };

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h6">{t('SelfSignupPage.foodManagement.title')}</Typography>
            {horses.map(horse => (
                <Box key={horse.id} sx={{ mt: 3, mb: 3, border: '1px dashed grey', p: 2 }}>
                    <Typography variant="h5">{horse.name}</Typography>
                    {foodSettings.map(food => (
                        <Box key={food.id} sx={{ mt: 2 }}>
                            <Typography variant="subtitle1">{food.name}</Typography>
                            {Object.entries(food.availability).map(([mealTime, isAvailable]) => isAvailable && (
                                <Box key={mealTime} sx={{ mt: 1 }}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t(`SelfSignupPage.foodManagement.mealTimes.${mealTime}`)}</InputLabel>
                                        <Select
                                            name={`${food.id}-${mealTime}`}
                                            value={localFoodPortions[horse.id][food.id]?.[mealTime] || ''}
                                            onChange={(e) => handleFoodChange(horse.id, food.id, mealTime, e.target.value)}
                                        >
                                            {food.portions.map(portion => (
                                                <MenuItem key={portion.id} value={portion.id}>
                                                    {portion.printName || portion.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            ))}
                        </Box>
                    ))}
                </Box>
            ))}
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" color="primary" onClick={handleNext}>
                    {t('SelfSignupPage.buttons.next')}
                </Button>
            </Box>
        </Box>
    );
};

export default FoodManagementStep;
