// src/pages/expenseDetailsPage/useExpense.js

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatISO } from 'date-fns';
import { getAllItems } from '../../services/itemService';
import {
  fetchExpenseById,
  saveExpense,
  updateExpense,
  deleteExpense
} from '../../services/expenseService';
import { useSnackbar } from './useSnackbar';
import { fetchMeasureUnits } from '../../services/measureUnitsService';
import { fetchCategories } from '../../services/categoryService';

export const useExpense = (expenseId, apiKey, organizationId, jwtToken, userId, state) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [measureUnits, setMeasureUnits] = useState([]);
  const isEditMode = expenseId !== 'new';
  const { openSnackbar, SnackbarComponent } = useSnackbar();
  const [isCancelled, setIsCancelled] = useState(false);
  const [suggestions, setSuggestions] = useState(null);
  const [isSuggestionModalOpen, setIsSuggestionModalOpen] = useState(false);

  const initialExpenseData = {
    id: '',
    expenseNumber: '',
    vendorId: '',
    vendor: '',
    vendorDocumentNumber: '',
    notes: '',
    cancel: false,
    isInvoice: false,
    isDeliveryNote: false,
    expenseDate: formatISO(new Date()),
    totalBruttoAmount: 0,
    totalNetAmount: 0,
    taxAmount: 0,
    rows: [],
  };
  const [expenseData, setExpenseData] = useState(initialExpenseData);
  
  // Function to handle checkbox change
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setExpenseData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const calculateTotals = () => {
    let totalExpense = 0.0;
    let totalTaxes = 0.0;
    let totalNetAmount = 0.0;

    expenseData.rows.forEach(row => {
      const bruttoPrice = parseFloat(row.bruttoPrice) || 0;
      const quantity = parseFloat(row.quantity) || 0;
      const taxRate = parseFloat(row.taxRate) / 100 || 0;
      const discountPercentage = parseFloat(row.discountPercentage) / 100 || 0;
      const discountAmount = parseFloat(row.discountAmount) || 0;

      const discountedPrice = parseFloat((bruttoPrice * (1 - discountPercentage) - discountAmount).toFixed(2));
      const taxAmountForRow = parseFloat(((discountedPrice - (discountedPrice / (1 + taxRate))) * quantity).toFixed(2));

      totalExpense += parseFloat((discountedPrice * quantity).toFixed(2));
      totalNetAmount += parseFloat(((discountedPrice * quantity) - taxAmountForRow).toFixed(2));
      totalTaxes += taxAmountForRow;
    });

    totalTaxes = parseFloat(totalTaxes.toFixed(2));
    totalNetAmount = parseFloat(totalNetAmount.toFixed(2));
    totalExpense = parseFloat(totalExpense.toFixed(2));

    return { totalExpense, totalTaxes, totalNetAmount };
  };

  const checkForCancellation = () => {
    if (expenseData.cancel) {
      setIsCancelled(true);
    } else {
      setIsCancelled(false);
    }
  };

  useEffect(() => {
    checkForCancellation();
  }, [expenseData.id, apiKey, organizationId, jwtToken]);

  useEffect(() => {
    if (expenseData.rows.length > 0) {
      expenseData.rows.forEach(row => {
        if (row.category) {
          filterSubcategories(row.category);
        }
      });
    }
  }, [expenseData.rows, categories]);


  const handleCancel = async () => {
    try {
      const response = await updateExpense({ ...expenseData, cancel: true }, apiKey, organizationId, jwtToken, userId);
      setExpenseData({ ...expenseData, cancel: true });
      openSnackbar('Expense successfully cancelled', 'success');
      navigate('/expenses');
    } catch (error) {
      console.error('Error cancelling invoice:', error);
      openSnackbar('Failed to cancel invoice', 'error');
    }
  };

  useEffect(() => {
    if (state?.fromDocument) {
      const fromDocument = state.fromDocument;
      const mappedExpenseData = {
        ...expenseData,
        id: '',
        expenseNumber: '',
        expenseDate: new Date(fromDocument.expenseDate) || expenseData.expenseDate,
        totalBruttoAmount: fromDocument.totalBruttoAmount || expenseData.totalBruttoAmount,
        totalNetAmount: fromDocument.totalNetAmount || expenseData.totalNetAmount,
        taxAmount: fromDocument.taxAmount || expenseData.taxAmount,
        vendor: fromDocument.vendor || expenseData.vendor,
        vendorDocumentNumber: fromDocument.expenseNumber || expenseData.vendorDocumentNumber,
        isInvoice: Boolean(fromDocument.isInvoice) || Boolean(expenseData.isInvoice),
        isDeliveryNote: Boolean(fromDocument.isDeliveryNote) || Boolean(expenseData.isDeliveryNote),
        notes: fromDocument.notes || expenseData.notes,
        rows: fromDocument.rows.map(row => ({
          itemId: row.itemId,
          description: row.itemDescription,
          quantity: row.quantity,
          measureUnit: row.measureUnitId,
          category: row.categoryId,
          subcategory: row.subcategoryId,
          netPrice: row.netPrice,
          taxRate: row.taxRate,
          bruttoPrice: row.bruttoPrice,
          discountPercentage: row.discountPercentage || 0,
          discountAmount: row.discountAmount || 0,
          rowTotal: row.rowTotal,
          rowNumber: parseInt(row.rowNumber) || 0,
          notes: row.item + ' ' + row.measureUnit + ' ' + row.notes,
        })),
      };
      setExpenseData(mappedExpenseData);
    }
  }, [state, setExpenseData]);

  useEffect(() => {
    const { totalExpense, totalTaxes, totalNetAmount } = calculateTotals();
    setExpenseData(prevExpenseData => ({
      ...prevExpenseData,
      totalBruttoAmount: totalExpense,
      totalNetAmount: totalNetAmount,
      taxAmount: totalTaxes,
    }));
  }, [expenseData.rows]);

  const handleRowChange = (index, name, value) => {
    let updatedRows = [...expenseData.rows];
    let updatedRow = { ...updatedRows[index], [name]: value };

    if (name === 'discountPercentage' || name === 'discountAmount') {
      const bruttoPrice = parseFloat(updatedRow.bruttoPrice) || 0;
      let discountPercentage = parseFloat(updatedRow.discountPercentage) / 100 || 0;
      let discountAmount = parseFloat(updatedRow.discountAmount) || 0;

      if (name === 'discountPercentage') {
        discountAmount = parseFloat((bruttoPrice * discountPercentage).toFixed(2));
        updatedRow.discountAmount = discountAmount;
      } else if (name === 'discountAmount') {
        discountPercentage = parseFloat((discountAmount / bruttoPrice).toFixed(2));
        updatedRow.discountPercentage = (discountPercentage * 100).toFixed(2);
      }
    }

    if (name === 'quantity' || name === 'bruttoPrice' || name === 'discountPercentage' || name === 'discountAmount') {
      const quantity = parseFloat(updatedRow.quantity) || 0;
      const bruttoPrice = parseFloat(updatedRow.bruttoPrice) || 0;
      const taxRate = parseFloat(updatedRow.taxRate) / 100 || 0;
      const discountPercentage = parseFloat(updatedRow.discountPercentage) / 100 || 0;
      const discountAmount = parseFloat(updatedRow.discountAmount) || 0;

      const discountedPrice = parseFloat((bruttoPrice * (1 - discountPercentage) - discountAmount).toFixed(2));
      const rowTotal = parseFloat((quantity * discountedPrice).toFixed(2));
      const netPrice = parseFloat((discountedPrice / (1 + taxRate)).toFixed(2));

      updatedRow = { ...updatedRow, rowTotal, netPrice };
    }

    if (name === 'description') {
      const selectedItem = items.find((item) => item.name === value);
      if (selectedItem) {
        updatedRow = {
          ...updatedRow,
          itemId: selectedItem.id,
          measureUnit: selectedItem.measureUnit,
          category: selectedItem.category,
          subcategory: selectedItem.subcategory,
        };
      } else {
        updatedRow = { ...updatedRow, itemId: '', measureUnit: '', category: '', subcategory: '' };
      }
    } else if (name === 'itemId') {
      const selectedItem = items.find((item) => item.id === value);
      if (selectedItem) {
        updatedRow = {
          ...updatedRow,
          description: selectedItem.name,
          measureUnit: selectedItem.measureUnit,
          category: selectedItem.category,
          subcategory: selectedItem.subcategory,
        };
        filterSubcategories(selectedItem.category);
      } else {
        updatedRow = { ...updatedRow, description: '', measureUnit: '', category: '', subcategory: '' };
      }
    }

    if (name === 'category') {
      updatedRow = { ...updatedRow, subcategory: '', measureUnit: '' };
      filterSubcategories(value);
    }

    updatedRows[index] = updatedRow;
    setExpenseData({ ...expenseData, rows: updatedRows });
  };

  const filterSubcategories = (categoryId) => {
    const selectedCategory = categories.find(category => category.categoryId === categoryId);
    if (selectedCategory) {
      setFilteredSubcategories(selectedCategory.subcategories || []);
    } else {
      setFilteredSubcategories([]);
    }
  };

  useEffect(() => {
    const fetchExpenseData = async () => {
      if (expenseId !== 'new') {
        setLoading(true);
        try {
          const data = await fetchExpenseById(apiKey, organizationId, jwtToken, expenseId, userId);
          setExpenseData(data);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchExpenseData();
  }, [expenseId, apiKey, organizationId, jwtToken, userId]);

  const fetchItems = async () => {
    try {
      const fetchedItems = await getAllItems(apiKey, organizationId, jwtToken);
      setItems(fetchedItems);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchMeasureUnitsData = async () => {
    try {
      const fetchedMeasureUnits = await fetchMeasureUnits(apiKey, organizationId, jwtToken);
      setMeasureUnits(fetchedMeasureUnits);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchCategoriesData = async () => {
    try {
      const fetchedCategories = await fetchCategories(apiKey, organizationId, jwtToken);
      setCategories(fetchedCategories);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    try {
      fetchMeasureUnitsData();
      fetchItems();
      fetchCategoriesData();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [apiKey]);

  const handleHeaderInputChange = (event) => {
    const { name, value } = event.target;
    setExpenseData({ ...expenseData, [name]: value });
  };

  const removeRow = (index) => {
    const updatedRows = [...expenseData.rows];
    updatedRows.splice(index, 1);
    setExpenseData({ ...expenseData, rows: updatedRows });
  };

  const addRow = () => {
    const newRow = {
      itemId: '',
      description: '',
      rowNumber: '',
      quantity: '',
      measureUnit: '',
      netPrice: '',
      taxRate: '',
      bruttoPrice: '',
      discountPercentage: '',
      discountAmount: '',
      rowTotal: '',
      notes: '',
      category: '',
      subcategory: ''
    };
    setExpenseData({ ...expenseData, rows: [...expenseData.rows, newRow] });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      let response;
      if (expenseId === 'new') {
        response = await saveExpense(expenseData, apiKey, organizationId, jwtToken, userId);
      } else {
        response = await updateExpense(expenseData, apiKey, organizationId, jwtToken, userId);
      }

      openSnackbar(response.message, 'success');
      navigate('/expenses');
    } catch (err) {
      setError(err.message);
      openSnackbar('Failed to save expense', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveSuggestions = async (updatedSuggestions) => {
    if (updatedSuggestions.categories !== 0 || updatedSuggestions.rows.filter(row => row.newOrExisting === 'new').length !== 0) {
      await fetchCategoriesData();
      await fetchItems();
      await fetchMeasureUnitsData();
    }

    const updatedRows = [...expenseData.rows];
    if (updatedSuggestions.categories.filter(category => category.newOrExisting === 'new').length !== 0 || updatedSuggestions.categories.filter(category => category.subcategories.filter(subcategory => subcategory.newOrExisting === 'new').length !== 0).length !== 0) {

      updatedSuggestions.categories.forEach((category) => {
        if (category.newOrExisting === 'new') {
          category.suggestionforRows?.forEach((rowIndex) => {
            const row = updatedRows.find(row => row.rowNumber === rowIndex);
            row.category = category.categoryId;

            // Filter subcategories after setting the category
            filterSubcategories(category.categoryId);
          });
        }

        category.subcategories.filter(subcategory => subcategory.c).forEach((subcategory) => {
          subcategory.suggestionforRows?.forEach((rowIndex) => {
            const row = updatedRows.find(row => row.rowNumber === rowIndex);
            row.subcategory = subcategory.subcategoryId;
          });
        });
      });

      setExpenseData({ ...expenseData, rows: updatedRows });
    }
    updatedSuggestions.rows?.filter(row => Boolean(row.stockItem) === true && row.newOrExisting === 'new').forEach((suggestedRow) => {
      const row = updatedRows.find(row => parseInt(row.rowNumber) === parseInt(suggestedRow.rowNumber));
      row.itemId = suggestedRow.itemId;
    });


    updatedRows.forEach((row) => {
      delete row.rowNumber;
    });
    console.log("")
    console.log("updatedRows", updatedRows);

    setIsSuggestionModalOpen(false);
  };



  return {
    expenseData,
    handleRowChange,
    handleHeaderInputChange,
    items,
    handleSubmit,
    handleCheckboxChange,
    setExpenseData,
    removeRow,
    addRow,
    measureUnits,
    categories,
    filteredSubcategories,
    isEditMode,
    loading,
    error,
    isCancelled,
    handleCancel,
    suggestions,
    setSuggestions,
    isSuggestionModalOpen,
    setIsSuggestionModalOpen,
    handleSaveSuggestions
  };
};
