import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllOwners } from '../../../services/ownerService';
import { getAllHorses } from '../../../services/horseService';
import { fetchUserBookings } from '../../../services/guidingMachineService';
import { fetchDashboardStatistics, fetchMonthlyCategorizedTransactions } from '../../../services/AdminDashboardService';
import { getHorseActivityVotesAdminStatistics } from '../../../services/horseActivityVoteService';
import { categories } from '../../../pages/inventoryManagement/itemManagement/ItemUtils';

export const useDashboardData = (apiKey, organizationId, jwtToken, userId, month, year, isEquistab) => {
    const { t } = useTranslation();
    const [horseCount, setHorseCount] = useState(0);
    const [ownerCount, setOwnerCount] = useState(0);
    const [coRiderCount, setCoRiderCount] = useState(0);
    const [todayBookingCount, setTodayBookingCount] = useState(0);
    const [monthlyExpenses, setMonthlyExpenses] = useState(0);
    const [monthlyIncome, setMonthlyIncome] = useState(0);
    const [currentMonthIncomeByCategory, setCurrentMonthIncomeByCategory] = useState([]);
    const [financialStatus, setFinancialStatus] = useState(0);
    const [taxStatus, setTaxStatus] = useState(0);
    const [last12MonthsData, setLast12MonthsData] = useState([]);
    const [currentMonthStackedExpenses, setCurrentMonthStackedExpenses] = useState([]);
    const [activeSubcategories, setActiveSubcategories] = useState(new Set());
    const [translatedExpenseKey, setTranslatedExpenseKey] = useState('');
    const [translatedIncomeKey, setTranslatedIncomeKey] = useState('');
    const [last30DaysItemUsageData, setLast30DaysItemUsageData] = useState([]);
    const [warningItems, setWarningItems] = useState({ data: [], columns: [] });
    const [services, setServices] = useState({ data: [], columns: [] });
    const [busiestTimes, setBusiestTimes] = useState({});
    const [busiestTimesDate, setBusiestTimesDate] = useState(new Date());
    const baseOrange = '#FFA500'; // Base orange color in hex

    const inventoryColumns = [
        { id: 'name', label: t('Home.AdminDashboard.itemName', { defaultValue: 'Item Name' }) },
        { id: 'quantity', label: t('Home.AdminDashboard.quantity', { defaultValue: 'Quantity' }) },
        { id: 'warningLevel', label: t('Home.AdminDashboard.warningLevel', { defaultValue: 'Warning Level' }) }
    ];

    const serviceGroupColumns = [
        { id: 'boxType', label: t('Home.AdminDashboard.boxType', { defaultValue: 'Box Type' }) },
        { id: 'serviceType', label: t('Home.AdminDashboard.serviceType', { defaultValue: 'Service Type' }) },
        { id: 'horses', label: t('Home.AdminDashboard.horses', { defaultValue: 'Horses' }) }
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const newActiveSubcategories = new Set();

                if (isEquistab) {
                    const horses = await getAllHorses(apiKey, organizationId, jwtToken);
                    setHorseCount(horses.length);

                    const owners = await getAllOwners(apiKey, organizationId, jwtToken);
                    setOwnerCount(owners.length);
                    setCoRiderCount(0);

                    const today = new Date().toISOString().split('T')[0];
                    const bookings = await fetchUserBookings(userId, apiKey, organizationId, jwtToken);
                    setTodayBookingCount(bookings.filter(booking => booking.start_time.startsWith(today)).length);
                }

                const stats = await fetchDashboardStatistics(apiKey, organizationId, jwtToken, userId);
                const currentMonthData = stats.Statistics.find(
                    (monthData) => monthData.monthNumber === month && monthData.year === year
                ) || { expenses: 0, income: 0 };
                setMonthlyExpenses(currentMonthData.expenses);
                setMonthlyIncome(currentMonthData.income);
                setFinancialStatus(currentMonthData.income - currentMonthData.expenses);
                setTaxStatus(stats.currentMonth.taxes.estimatedTax);

                setTranslatedExpenseKey(t('Home.AdminDashboard.expense', { defaultValue: 'Expenses' }));
                setTranslatedIncomeKey(t('Home.AdminDashboard.income', { defaultValue: 'Income' }));

                const translatedLast12MonthsData = stats.Statistics.map(monthData => ({
                    ...monthData,
                    [t('Home.AdminDashboard.expense')]: monthData.expenses,
                    [t('Home.AdminDashboard.income')]: monthData.income
                }));

                setLast12MonthsData(translatedLast12MonthsData);

                const totalCategoriesAndSubcategories = stats.currentMonth.expenses.reduce((acc, category) => acc + 1 + category.subcategories.length, 0);
                const orangeShades = generateOrangeShades(baseOrange, totalCategoriesAndSubcategories);
                let shadeIndex = 0; // To keep track of which shade to assign

                // Use the new structure to generate expense data for the chart
                const expensesData = stats.currentMonth.expenses.map(category => {
                    const categoryData = {
                        category: category.name, // Using category name as the main identifier
                        color: orangeShades[shadeIndex++] || baseOrange, // Assign a shade of orange to the category
                    };

                    // Reduce subcategories into a single object with keys as subcategory names and values as their totals
                    category.subcategories.forEach(subcategory => {
                        categoryData[subcategory.name] = subcategory.total;
                        // Update activeSubcategories with new subcategories
                        newActiveSubcategories.add({
                            label: subcategory.name,
                            // Assuming you have a method to determine color based on subcategory name
                            color: orangeShades[shadeIndex++] || baseOrange
                        });
                    });

                    return categoryData;
                });

                const currentMonthInvoiceData = Object.entries(stats.currentMonth.income).map(([category, amount]) => ({
                    category: getItemTypeTranslation(category),
                    amount
                }));
                setLast30DaysItemUsageData(stats.currentMonth.inventory);
                const inventoryWarnings = prepareInventoryWarnings(stats.stockWarnings);
                const serviceGroups = prepareServiceGroups(stats.serviceGroups);

                if (isEquistab) {
                    setWarningItems({ data: inventoryWarnings, columns: inventoryColumns });
                    setServices({ data: serviceGroups, columns: serviceGroupColumns });
                }

                // Fetch busiest times per space
                const date = busiestTimesDate.toISOString().split('T')[0];
                const busiestTimesData = await getHorseActivityVotesAdminStatistics(apiKey, organizationId, jwtToken, date);
                setBusiestTimes(busiestTimesData);

            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };

        fetchData();
    }, [apiKey, organizationId, jwtToken, userId, t, month, year]);

    useEffect(() => {
        const fetchMonthData = async () => {
            try {
                // Ensure the month passed to fetchMonthlyCategorizedTransactions is correct
                const currentMonthData = await fetchMonthlyCategorizedTransactions(apiKey, organizationId, jwtToken, userId, month, year);

                const newActiveSubcategories = new Set();
                const totalCategoriesAndSubcategories = currentMonthData.expenses.reduce((acc, category) => acc + 1 + category.subcategories.length, 0);
                const orangeShades = generateOrangeShades(baseOrange, totalCategoriesAndSubcategories);
                let shadeIndex = 0;

                const expensesData = currentMonthData.expenses.map(category => {
                    const categoryData = {
                        category: category.name,
                        color: orangeShades[shadeIndex++] || baseOrange,
                    };

                    category.subcategories.forEach(subcategory => {
                        categoryData[subcategory.name] = subcategory.total;
                        newActiveSubcategories.add({
                            label: subcategory.name,
                            color: orangeShades[shadeIndex++] || baseOrange
                        });
                    });

                    return categoryData;
                });

                setActiveSubcategories(newActiveSubcategories);
                setCurrentMonthStackedExpenses(expensesData);
                const currentMonthInvoiceData = Object.entries(currentMonthData.income).map(([category, amount]) => ({
                    category: getItemTypeTranslation(category),
                    amount
                }));
                setCurrentMonthIncomeByCategory(currentMonthInvoiceData);
            } catch (error) {
                console.error('Error fetching month data:', error);
            }
        };

        if (month && year) {
            fetchMonthData();
        }
    }, [t, month, year]);


    const getTranslatedLabelForCategory = (categoryKey) => {
        const category = categories[categoryKey];
        if (!category) return categoryKey;
        return t(category.label);
    };

    const getTranslatedLabelForSubcategory = (categoryKey, subcategoryKey) => {
        const category = categories[categoryKey];
        if (!category) return subcategoryKey;

        const subcategory = category.subcategories[subcategoryKey];
        if (!subcategory) return subcategoryKey;
        return t(subcategory.label);
    };

    const getItemTypeTranslation = (type) => {
        return t(`invoiceDetailPage.itemTypes.${type.toLowerCase()}`);
    };

    const prepareServiceGroups = (serviceGroups) => {
        const groups = serviceGroups?.map(group => ({
            boxType: group.boxTypeName,
            serviceType: group.services.map(service => service.serviceTypeName).join(", "),
            horses: group.services.find(service => service.horses.length > 0)?.horses.map(horse => horse.name).join(", ")
        }));
        return groups;
    };

    const prepareInventoryWarnings = (inventoryData) => {
        const warnings = inventoryData?.map(item => ({
            id: item.id,
            name: item.name,
            warningLevel: item.warningQuantity,
            quantity: `${item.quantity} units`,
        }));
        return warnings;
    };

    function hexToHSL(H) {
        let r = 0, g = 0, b = 0;
        if (H.length === 4) {
            r = parseInt(H[1] + H[1], 16);
            g = parseInt(H[2] + H[2], 16);
            b = parseInt(H[3] + H[3], 16);
        } else if (H.length === 7) {
            r = parseInt(H[1] + H[2], 16);
            g = parseInt(H[3] + H[4], 16);
            b = parseInt(H[5] + H[6], 16);
        }
        r /= 255;
        g /= 255;
        b /= 255;
        let cmin = Math.min(r, g, b),
            cmax = Math.max(r, g, b),
            delta = cmax - cmin,
            h = 0,
            s = 0,
            l = 0;

        if (delta === 0) h = 0;
        else if (cmax === r) h = ((g - b) / delta) % 6;
        else if (cmax === g) h = (b - r) / delta + 2;
        else h = (r - g) / delta + 4;

        h = Math.round(h * 60);
        if (h < 0) h += 360;

        l = (cmax + cmin) / 2;
        s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
        s = +(s * 100).toFixed(1);
        l = +(l * 100).toFixed(1);

        return "hsl(" + h + "," + s + "%," + l + "%)";
    }

    function generateOrangeShades(baseOrange, count) {
        const shades = [];
        const baseHSL = hexToHSL(baseOrange);
        const match = baseHSL.match(/^hsl\((\d+),(\d+)%,(\d+)%\)$/);
        if (match) {
            const baseLightness = parseInt(match[3], 10);
            for (let i = 0; i < count; i++) {
                const lightness = baseLightness - (i * 5) % 50;
                shades.push(`hsl(${match[1]}, ${match[2]}%, ${lightness}%)`);
            }
        }
        return shades;
    }

    async function busiestTimeNextDay() {
        const nextDay = new Date(busiestTimesDate);
        nextDay.setDate(nextDay.getDate() + 1);
        setBusiestTimesDate(nextDay);
        const fetchedData = await getHorseActivityVotesAdminStatistics(apiKey, organizationId, jwtToken, nextDay.toISOString().split('T')[0]);
        setBusiestTimes(fetchedData);
    }
    async function busiestTimePrevDay() {
        const prevDay = new Date(busiestTimesDate);
        prevDay.setDate(prevDay.getDate() - 1);
        setBusiestTimesDate(prevDay);
        const fetchedData = await getHorseActivityVotesAdminStatistics(apiKey, organizationId, jwtToken, prevDay.toISOString().split('T')[0]);
        setBusiestTimes(fetchedData);
    }

    return {
        horseCount,
        ownerCount,
        coRiderCount,
        todayBookingCount,
        activeSubcategories,
        monthlyExpenses,
        monthlyIncome,
        financialStatus,
        taxStatus,
        currentMonthStackedExpenses,
        currentMonthIncomeByCategory,
        last12MonthsData,
        translatedExpenseKey,
        translatedIncomeKey,
        last30DaysItemUsageData,
        warningItems,
        services,
        busiestTimes, // Add busiestTimes to the return object
        busiestTimePrevDay,
        busiestTimeNextDay,
        busiestTimesDate
    };
};
