import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/tokenHorsePermissions';

export async function generateInviteLink(horseId, permissions, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}`;
  const response = await axiosInstance.post(url, { horseId, permissions });
  return response.data;
}

export async function validateTokenAndAssignPermissions(token, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?token=${token}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export default {
  generateInviteLink,
  validateTokenAndAssignPermissions
};
