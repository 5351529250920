import React from 'react';
import { Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DeliveryNoteAddress = ({ deliveryNoteData, handleInputChange }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      {/* Street Address */}
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="address.street"
          label={t('deliveryNoteDetailPage.address.street')}
          variant="outlined"
          value={deliveryNoteData.address?.street || ''}
          onChange={handleInputChange}
        />
      </Grid>

      {/* Zip Code */}
      <Grid item xs={6} sm={3}>
        <TextField
          fullWidth
          name="address.zipCode"
          label={t('deliveryNoteDetailPage.address.zipCode')}
          variant="outlined"
          value={deliveryNoteData.address?.zipCode || ''}
          onChange={handleInputChange}
        />
      </Grid>

      {/* City */}
      <Grid item xs={6} sm={3}>
        <TextField
          fullWidth
          name="address.city"
          label={t('deliveryNoteDetailPage.address.city')}
          variant="outlined"
          value={deliveryNoteData.address?.city || ''}
          onChange={handleInputChange}
        />
      </Grid>

      {/* Country */}
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="address.country"
          label={t('deliveryNoteDetailPage.address.country')}
          variant="outlined"
          value={deliveryNoteData.address?.country || ''}
          onChange={handleInputChange}
        />
      </Grid>

      {/* Email */}
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          name="address.email"
          label={t('deliveryNoteDetailPage.address.email')}
          variant="outlined"
          value={deliveryNoteData.address?.email || ''}
          onChange={handleInputChange}
        />
      </Grid>
    </Grid>
  );
};

export default DeliveryNoteAddress;
